import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import AlertDialog from "../layout/alertDialogo";
import { MenuEspecialistas } from '../layout/MenuEspecialistas';
import ProgressCircular from '../layout/progress/progressCircular';

/* Material UI */
// import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

/*Components */
import PaginacionCitas from "./paginacionCitas";
import ModalFullScreenCitas from "../citas/modalFullScreenCitas";

/* Actions */
import { citasActions, alertConfirmActions, menuActions } from '../../../../_actions'

/* Estilos con Material UI */
import { styles } from "./eConsultorioStyle";

class HomeConsultorio extends Component {
    constructor(props) {
        super(props);
        this.props.dispatch(menuActions.menuActive(4));
        this.props.dispatch(citasActions.getCitasConsultorio(this.props.user))
    }

    handleModalOpen = data => () => {
        this.props.dispatch(citasActions.selectCita(data.id))
        this.props.dispatch(citasActions.detalleViewModal())
    };

    handleModalClose = () => {
        this.props.dispatch(citasActions.detalleViewModal())
    };

    handleAtenderCita = datos => () => {
        var a = document.createElement("a");
        a.href = datos.consultorio;
        a.target = "_blank";
        a.click();
        this.setState({ anchorEl: null });
    }

    /* Handles Marcar como atendida la cita */
    handleCloseAlert = () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleMarcarAtendida = data => () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        this.props.dispatch(citasActions.selecCitaParaMarcarAtendida(data))
    }

    handleDelateItem = () => {
        this.props.dispatch(alertConfirmActions.sumitedRequest())
        this.props.dispatch(citasActions.citaAtendida(this.props.user, this.props.atendida))
    }
    /* Handles Para Burrar algun elemento */

    render() {
        const { openConfirm, loadingConfirm, submitedConfrim, } = this.props;
        const { loadingCita, citas, openModalDetalle, selectCita } = this.props;
        return (
            <section className="body" ref={this.container}>
                <MenuEspecialistas />
                {loadingCita 
                    ? <ProgressCircular />
                    : <div className="content sin-bar econsultorio">
                            <div className="cont-citas">
                                <PaginacionCitas
                                    itemsperpage={1}
                                    nocolumns={1}
                                    items={citas.length ? citas.filter(q => (q.status === 'Verificada')) : []}
                                    pagesspan={1}
                                    handleModalOpen={this.handleModalOpen}
                                    handleAtenderCita={this.handleAtenderCita}
                                    handleMarcarAtendida={this.handleMarcarAtendida}
                                />
                            </div>
                            <ModalFullScreenCitas
                                open={openModalDetalle}
                                cita={selectCita}
                                handleModalClose={this.handleModalClose}
                            />

                            <AlertDialog
                                open={openConfirm}
                                loading={loadingConfirm}
                                submitted={submitedConfrim}
                                procesar={this.handleDelateItem}
                                handleClose={this.handleCloseAlert}
                                message='¿Esta seguro de marcar esta cita como atendida?'
                            />
                        </div>
                }
            </section>
        )
    }
}

HomeConsultorio.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { selectCita, loadingCita, openModalDetalle, citas, atendida } = state.citasReducer;
    return {
        user,
        openConfirm, loadingConfirm, submitedConfrim,
        selectCita, loadingCita, openModalDetalle, citas, atendida
    };
}

const connectedHomeConsultorio = connect(mapStateToProps)(withStyles(styles)(HomeConsultorio));
export { connectedHomeConsultorio as HomeConsultorio };