// const drawerWidth = 240;
export const styles = theme => ({
    paper: {
        color: theme.palette.text.secondary,
    },
    contBodyPaper: {
        padding: '0 16px 16px 16px',
    },
    formControl: {
        width: '90%',
    },
    text_white: {
        color: "#fff",
    },
    p1: {
        padding: '1rem'
    },
    p_L_R_0:{
        paddingLeft: 0,
        paddingRight: 0 
    },
    verMas: {
        margin: 0,
        cursor:'pointer',
        color: theme.palette.primary.main
    },
    mostar:{
        whiteSpace: 'normal',
    },
    infoTruncate:{
        maxHeight: 45,
        overflow: 'hidden'
    },
    iconFloatBorrar:{
        top: -12,
        right: 11,
        position: 'absolute',
    },
    iconFloatEdit: {
        top: -12,
        right: -22,
        position: 'absolute',
    },
    mt_1: {
        marginTop: '1rem'
    },
    mr_1:{
        marginRight: '1rem'
    },
    btnFlotante:{
        top: -14,
        right: 0,
        position: 'absolute',
    },
    formControlEducacion:{
        width: '100%'
    },
    icon:{
        fontSize: 7
    },
    tituloItem:{
        color:'#595955',
        fontSize: '.9rem',
        fontWeight: 'bold',
        [theme.breakpoints.down("xs")]: {
            width: '84%',
        },
        [theme.breakpoints.up("sm")]: {
            width: '90%',
        },
    },
    tituloItemDos:{
        color: '#595955',
        fontSize: '.9rem',
    },
    tituloItemTres:{
        color: '#7a7a77',
        fontSize: '.75rem',
    },
    /* Lista para movil*/
    avatar:{
        [theme.breakpoints.down("xs")]: {
            position: 'absolute',
            right:'45%'
        }
    },
    listCont:{
        [theme.breakpoints.down("xs")]: {
            paddingTop: 45
        }
    },
    dividerList:{
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0
        },
        [theme.breakpoints.up("sm")]: {
            marginLeft: 55
        }
    },
    dividerListDos:{
        marginLeft: 0
    },
    chip: {
        margin: theme.spacing.unit / 2,
    },
    /* Modal */
    modalTitle:{
        padding: 0
    }, 
    titleModal:{
        padding: '16px 24px 11px',
        fontWeight: 'bold',
    },
    contFech:{
        [theme.breakpoints.up("sm")]: {
            paddingRight: '.8rem',
        },
    },
    btnTransform: {
        textTransform: 'initial !important'
    }
})