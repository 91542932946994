import { configConstants } from '../../_constants';

const initialState = {
    preloaderRequi: true,
    loadingRequisitos: false,
    openDialogRequisito: false,
    submitRequisito: false,
};

export function requisitosReducer(state = initialState, action) {
    switch (action.type) {
        case configConstants.GET_REQUISITOS_CITAS:
            return {
                ...state,
                listRequisitos: action.data.requisitos,
                tipoDato: action.data.tipoDeDato,
                preloaderRequi: !state.preloaderRequi,
            };
        case configConstants.OPEN_MODAL_REQUISITOS:
            return {
                ...state,
                openDialogRequisito: !action.data
            };
        case configConstants.REQUISITOS_CITAS_REQUEST:
            return {
                ...state,
                loadingRequisitos: true,
            };
        case configConstants.REQUISITOS_CITAS_INSERT:
            return {
                ...state,
                listRequisitos: action.data,
                loadingRequisitos: false,
            };
        case configConstants.REQUISITOS_CITAS_DELETE:
            return {
                ...state,
                itemRequisitoDelete: '',
                listRequisitos: state.listRequisitos.filter(j => j.id !== action.data),
            };
        case configConstants.SELECT_ITEM_REQUISITO_DELETE:
            return {
                ...state,
                itemRequisitoDelete: action.data,
            };
        case configConstants.REQUISITOS_OPCION_CITAS_DELETE:
            return {
                ...state,
                listRequisitos: action.data
            };
        case configConstants.SUBMIT_REQUISITO_REQUEST:
            return {
                ...state,
                submitRequisito: !state.submitRequisito
            };

        default:
            return state
    }
}
