import { configConstants } from '../../_constants';

const initialState = {
    loadingConfig: false,
    editPass: false,
    viewSelect: 'general',
    editDatosPerso: false,
    preloaderConfig: false,

    submitPass: false,
    submitGeneral: false,
    completeProfile:[],
};

export function configReducer(state = initialState, action) {
    switch (action.type) {
        case configConstants.SELECT_VIEW:
            return Object.assign({}, state, {
                viewSelect: action.data,
            });
        case configConstants.VIEW_EDIT_DATOS_PERSO:
            return Object.assign({}, state, {
                editDatosPerso: action.data,
            });
        case configConstants.VIEW_EDIT_PASSWORD:
            return Object.assign({}, state, {
                editPass: action.data,
            });
        case configConstants.LOADING_REQUEST:
            return Object.assign({}, state, {
                loadingConfig: !state.loadingConfig,
            });
        case configConstants.SUBMIT_REQUEST:
            return Object.assign({}, state, {
                [action.data]: !state[action.data],
            });
        case configConstants.COMPLETAR_PERFIL:
            return Object.assign({}, state, {
                completeProfile: action.data,
                loading: !state.loading,
            });
        default:
            return state
    }
}