import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/* Actions */
import { ePageActions } from '../../../../_actions'

// /* MAterial UI */
import { styles } from "./homePageStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Button } from '@material-ui/core';

class AccesosDirectos extends Component {

    handleVolverAtras = data => (e) => {
        e.preventDefault();
        this.props.dispatch(ePageActions.mostrarViewEpage(data))
    }

    render() {
        const { classes } = this.props
        return (
            <Grid container spacing={8}>
                <Grid item xs={6} className={classes.paperConsultorioVirtual} align='center'>
                    <Paper className='p-1 cursor-pointer'>
                        <Typography component='p' color='secondary' className='mb-06'>Solicitar interconsulta </Typography>
                        <Link to={'/interconsulta'}>
                            <Button variant="contained" color='primary'>
                                Entrar
                            </Button>
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={6} className={classes.paperConsultorioTexto} align='center'>
                    <Paper className='p-1 cursor-pointer'>
                        <Typography component='p' color='secondary' className='mb-06'>Mis interconsultas</Typography>
                        <Link to={'/mis-interconsultas'}>
                            <Button variant="contained" color='primary'>
                                Entrar
                            </Button>
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={6} className={classes.paperConsultorioVirtual} align='center'>
                    <Paper className='p-1 cursor-pointer'>
                        <Typography component='p' color='secondary' className='mb-06'> Consultorio virtual</Typography>
                        <Link to={'/econsultorio'}>
                            <Button variant="contained" color='primary'>
                                Entrar
                            </Button>
                        </Link>
                    </Paper>
                </Grid>
                <Grid item xs={6} className={classes.paperConsultorioTexto} align='center'>
                    <Paper className='p-1 cursor-pointer'>
                        <Typography component='p' color='secondary' className='mb-06'> Consulta vía texto</Typography>
                        <Link to={'/asesoria-via-texto'}>
                            <Button variant="contained" color='primary'>
                                Entrar
                            </Button>
                        </Link>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

AccesosDirectos.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user,
    };
}

const connectedAccesosDirectos = connect(mapStateToProps)(withStyles(styles)(AccesosDirectos));
export { connectedAccesosDirectos as AccesosDirectos };