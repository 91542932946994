import React from 'react'

/* MAterial UI */
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Button, Divider, FormControl, InputLabel, Select, MenuItem, TextField, CircularProgress } from '@material-ui/core';


/* Iconos */
// import DoneIcon from '@material-ui/icons/Done';

const BuscarEspecialista = ({ classes, especialidades, select, inputSearch, loadingInter, handleChangeSearch, handleSearchEspecialista }) => (
    <Grid container spacing={24}>
        <Grid item xs={12}>
            <Paper className="paper">
                <div className="title-paper interconsulta-title">
                    <Typography color="primary" component="span" className="mb-06">Selecciona una especialidad</Typography>
                    <Divider />
                </div>
                <div>
                    <Grid container spacing={24} >
                        <Grid item xs={12} sm={4}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel id="label-especialidades">Especialidades</InputLabel>
                                <Select
                                    id="select-especialidad"
                                    name='select'
                                    value={select}
                                    onChange={handleChangeSearch}
                                >   
                                    {especialidades.length
                                        ? especialidades.map(k => {
                                            return <MenuItem value={k.id} key={k.id}>{k.name}</MenuItem>
                                        })
                                        
                                        : <MenuItem value="">No hay especialidades disponibles</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                //margin="none"
                                id="inputSearch"
                                name="inputSearch"
                                autoComplete="off"
                                label="Escribe nombre, país o déjalo en blanco"
                                value={inputSearch}
                                onChange={handleChangeSearch}
                                //error={props.submitGeneral && !props.userEdit.username}
                                //helperText={props.submitGeneral && !props.userEdit.username &&
                                //    <span className="lblError" >Campo Requerido</span>
                                //}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} className={`paper ql-align-center ${classes.pdBtn}`}>
                            <Button 
                                type="submit"
                                color="primary" 
                                variant="contained" 
                                onClick={handleSearchEspecialista}
                                disabled={(loadingInter && (select !== ''))}
                            >
                                Buscar
                                {(loadingInter && (select !== '')) && <CircularProgress size={24} className="ProgressAbsolut" />}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </Grid>


    </Grid>
)


export default withStyles(styles)(BuscarEspecialista);