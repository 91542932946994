import { historiaConstants } from '../../_constants';

const initialState = {
    loading: false,
    preloaderHist:true,
    viewDetail: false,
    submitted: false,
};

export function historia(state = initialState, action) {
    switch (action.type) {
        case historiaConstants.REQUEST_HISTORIA:
            return {
                ...state,
                preloaderHist: action.data
            };
        case historiaConstants.GET_HISTORIA:
            return {
                ...state,
                pacientesHistoria: action.data.persons,
                citasSinHistoria: action.data.histpor_crear,
                preloaderHist: !state.preloaderHist,
            };
        case historiaConstants.FAIL_HISTORIA:
            return {
                ...state,
                pacientesHistoria: [],
                citasSinHistoria: [],
                preloaderHist: !state.preloaderHist,
            };
        case historiaConstants.SELECT_PERSON:
            return Object.assign({}, state, {
                edit: false,
                personSelected: action.data,
            });
        case historiaConstants.SELECT_PERSON_EDIT_HISTORY:
            return Object.assign({}, state, {
                edit: true,
                personSelected: action.data,
            });
        case historiaConstants.VIEW_DETALLE_PERSON:
            return Object.assign({}, state, {
                viewDetail: !state.viewDetail,
                histEspecialidad: '',
            });
        case historiaConstants.REMOVE_PATIENT_HIST:
            return Object.assign({}, state, {
                citasSinHistoria: state.citasSinHistoria.filter(q => (q.cita !== action.data)),
            });
            case historiaConstants.SUBMIT_HISTORIA:
            return Object.assign({}, state, {
                submitted: !state.submitted,
                loading: !state.loading,
            });
        case historiaConstants.SUCCESS_INSERT_HISTORIA:
            return Object.assign({}, state, {
                citasSinHistoria: state.citasSinHistoria.filter(q => (q.cita !== action.data.cita)),
                viewDetail: false,
            });
        case historiaConstants.ESPECIALIDAD_SELECT_HIST:
            return Object.assign({}, state, {
                histEspecialidad: action.data,
            });
        case historiaConstants.CANCELAR_CREAR_HISTORIA:
            return Object.assign({}, state, {
                viewDetail: action.data,
            });

        default:
            return state
    }
}