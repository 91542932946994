import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';


const FormLogin = (props) => (
    <div>
        <article className="logo">
            <img src={props.logo} alt="logo" />
        </article>
        <h1 className="title">Iniciar sesión</h1>
        <form className="form" id="formlogin" name="formlogin" onSubmit={props.onSubmitForm}>
            <div className="row ml-0 mr-0">
                <div className="input-field col s12">
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Correo"
                        margin="normal"
                        defaultValue={props.email}
                        onChange={props.onChangeInputs}
                        error={props.submitted && !props.emai}
                        helperText={ props.submitted && !props.email && 
                            <span className="lblError" >Campo Requerido</span>
                        }
                    />
                </div>
                <div className="input-field col s12">
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        type="password"
                        margin="normal"
                        label="Contraseña"
                        value={props.password}
                        autoComplete="current-password"
                        onChange={props.onChangeInputs}
                        error={props.submitted && !props.emai}
                        helperText={props.submitted && !props.email &&
                            <span className="lbl-error" >Campo Requerido</span>
                        }
                    />
                </div>
            </div>
            <div className="loading"></div>
            <div className="cont-btn row">
                <div className="col s6">
                    <Button 
                        type="submit"
                        color="primary" 
                        variant="contained" 
                        disabled={props.loading}
                        >
                        Acceder
                        {props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                    </Button> 
                   
                </div>
                <div className="col s6">
                    <div className="g-signin2 right" data-onsuccess="onSignIn"></div>
                </div>

            </div>

            <div className="row ml-0 mr-0">
                <div className="col s12">
                    <div className="recupass left-align" onClick={props.onClickPass}>
                        <span className="recu-pass cs-p bg-secundario-text">¿Haz olvidado tu contraseña?</span>
                    </div>
                </div>

                <div className="col s12" id="google_auth">

                </div>
            </div>
        </form>
    </div>
)


export default FormLogin