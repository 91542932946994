import React, { Component } from 'react';
import {
    Route,
    Router,
    Switch
} from 'react-router-dom'
import { connect } from 'react-redux';


import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { history } from '../_helpers';
import { PrivateRoute } from '../_helpers';
import { alertActions } from '../_actions';
import './index.css';

import { LoginPage } from './views/public/LoginPage';
import Error404 from './views/public/error/Error404'
import { HomePage }  from './views/private/dashboard/homePage';
import { Profile } from './views/private/perfil/homePerfil';
import { Homecitas } from './views/private/citas/homeCitas';
import { HomeConsultorio } from './views/private/econsultorio/homeConsultorio';
import { HomeHistorias } from './views/private/historias/homeHistorias';
import { CrearHistoria } from './views/private/historias/crearHistoria';
import { HomeIncidencias } from './views/private/incidencias/homeIncidencias';
import { HomeConfiguracion } from './views/private/configuracion/homeConfiguracion';
import { HomePagos } from './views/private/pagos/homePagos';
import { VerPost } from './views/private/post/verPost';
import { HomeAsesoriaViaChat } from './views/private/asesoriachat/homeAsesoriaViaChat';
import { ViewDetalleDirecto } from './views/private/asesoriachat/viewDetalleDirecto';
import { HomeDirect } from "./views/private/direct/homeDirect";
import { HomeContactos } from "./views/private/perfil/contactos/homeContactos";
import { HomeInterConsulta } from "./views/private/interconsulta/solicitar/homeInterConsulta";
import { EditarInterconsulta } from "./views/private/interconsulta/listado/editarInterconsulta";
import { DetalleInterconsulta } from "./views/private/interconsulta/listado/detalleInterconsulta";
import { HomeMisInterConsultas } from "./views/private/interconsulta/listado/homeMisInterconsultas";
import { SolicitudInterConsulta } from "./views/private/interconsulta/solicitar/solicitudInterConsulta";

import { Header } from './views/private/layout/Header';

import { notifyActions } from '../_actions'

class App extends Component {
    constructor(props) {
        super(props);
        
        history.listen((location, action) => {
            // clear alert on location change
            this.handlleNofificacion()
        });
                
        //this.handleCloseNotify = this.handleCloseNotify.bind(this)
    }

    handlleNofificacion = () =>{
        const { loggedIn, user, dispatch } = this.props;
        loggedIn && dispatch(notifyActions.getNotify(user))
    }
    
    handleCloseNotify = (event, reason) => {
        const { dispatch } = this.props;
        if (reason === 'clickaway') {
            return;
        }
        dispatch(alertActions.clear());
    };
    
    render() {
        const { alert, loggedIn } = this.props;
       
        return (
            <div className="App">
                <div>
                    {alert.message &&
                        //<div className={`alert ${alert.type}`}>{alert.message}</div>
                        <Snackbar
                            className={alert.classe}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={alert.open}
                            autoHideDuration={6000}
                            onClose={this.handleCloseNotify}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{alert.message}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={this.handleCloseNotify}
                                >
                                    <CloseIcon />
                                </IconButton>,

                            ]}
                        />
                    }
                </div>
                

                <Router history={history}>
                    <div>
                        {loggedIn &&  <Header/>  }
                        {/*<section className="body">
                            {user && <MenuEspecialistas />  } */}
                            <Switch>
                                <Route path="/login" component={LoginPage} />
                                <PrivateRoute exact path="/" component={HomePage} />
                                <PrivateRoute exact path="/epage/:nickname" component={Profile} />
                                <PrivateRoute exact path="/post/:keydPost" component={VerPost} />
                                <PrivateRoute exact path="/citas" component={Homecitas} />
                                <PrivateRoute exact path="/econsultorio" component={HomeConsultorio} />
                                <PrivateRoute exact path="/historias" component={HomeHistorias} />
                                <PrivateRoute exact path="/nueva-historia" component={CrearHistoria} />
                                <PrivateRoute exact path="/pagos" component={HomePagos} />
                                <PrivateRoute exact path="/configuracion/:viewPage" component={HomeConfiguracion} />
                                <PrivateRoute exact path="/reportar-problema" component={HomeIncidencias} />
                                <PrivateRoute exact path="/asesoria-via-texto" component={HomeAsesoriaViaChat} />
                                <PrivateRoute exact path="/mensaje-asesoria-texto/:keydQuote" component={ViewDetalleDirecto} />
                                <PrivateRoute exact path="/mensajes" component={HomeDirect} />
                                <PrivateRoute exact path="/contactos" component={HomeContactos} />
                                <PrivateRoute exact path="/interconsulta" component={HomeInterConsulta} />
                                <PrivateRoute exact path="/mis-interconsultas" component={HomeMisInterConsultas} />
                                <PrivateRoute exact path="/editar-interconsulta" component={EditarInterconsulta} />
                                <PrivateRoute exact path="/detalle-interconsulta" component={DetalleInterconsulta} />
                                <PrivateRoute exact path="/interconsulta/:nickname" component={SolicitudInterConsulta} />
                                <Route component={Error404} />
                            </Switch>
                        {/* </section> */}
                    </div>
                </Router>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert } = state;
    const { loggedIn, user } = state.authentication;
    return {
        alert,
        user,
        loggedIn,
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 