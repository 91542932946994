export const styles = theme => ({
    flex: {
        flex: 1,
    },
    modal: {
        overflowX: 'hidden',
    },
    contHistoria: {
        marginTop: 16,
        marginBottom: 8,
    },
    span: {
        fontSize: '.8rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    pHistoria: {
        padding: '6px 0 7px'
    },
    pDocumento: {
        fontSize: 15,
        fontWeight: 'bold',
        padding: '6px 0 7px',
    },
    error: {
        color: '#d32f2f',
    },
    succes: {
        color: '#43a047'
    },
    iconStatus: {
        float: 'left',
        marginRight: 13
    },
    estatura: {
        width: '46%',
        display: 'inline-block',
        marginRight: '4%'
    },
    peso: {
        width: '50%',
        display: 'inline-block',
    },
    carDocumento: {
        textAlign: 'center',
        padding: '.5rem',
    },

});