export const menu = [
    {
        "id": 1,
        "name": "Inicio",
        "ruta": "/"
    },
    {
        "id": 2,
        "name": "Citas",
        "ruta": "/citas"
    },
    {
        "id": 3,
        "name": "Historias",
        "ruta": "/historias"
    },
    {
        "id": 4,
        "name": "Consultorio virtual",
        "ruta": "/econsultorio"
    },
    {
        "id": 5,
        "name": "Consulta vía texto",
        "ruta": "/asesoria-via-texto"
    },
    {
        "id": 6,
        "name": "Historial de pagos",
        "ruta": "/pagos"
    },
    {
        "id": 7,
        "name": "Reporte de problema",
        "ruta": "/reportar-problema"
    },
    {
        "id": 8,
        "name": "Solicitar interconsulta",
        "ruta": "/interconsulta"
    },
    {
        "id": 9,
        "name": "Mis interconsulta",
        "ruta": "/mis-interconsultas"
    },
]