import { notifyConstants } from '../_constants';

const initialState = {
    loadig: false,
    notify: [],
    directSinLeer: '0',
};

export function notifyReducer(state = initialState, action) {
    switch (action.type) {
        case notifyConstants.GET_NOTIFY:
            return Object.assign({}, state, {
                notify: action.data.notify,
                directSinLeer: action.data.direct,
            });
        case notifyConstants.GET_FAIL_NOTIFY:
            return Object.assign({}, state, {
                notify: [],
            });
        case notifyConstants.REQUEST_NOTIFY:
            return Object.assign({}, state, {
                loadig: !state.loadig,
            });
        case notifyConstants.MARCAR_VISTO_SUCCES:
            return Object.assign({}, state, {
                loadig: !state.loadig,
                notify: state.notify.map(k => {
                    if (k.type_notify === action.data){
                        k.view = '1'
                    }
                    return k
                })
            });
        case notifyConstants.MARCAR_VISTO_FAIL:
            return Object.assign({}, state, {
                loadig: !state.loadig,
            });
        case notifyConstants.MARCAR_VISTO_DIRECT_SUCCES:
            return Object.assign({}, state, {
                directSinLeer: state.directSinLeer !== '0' ? (state.directSinLeer - 1).toString() : state.directSinLeer,
            });
        default:
            return state
    }
}