import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/* Actions */
import { ePageActions } from '../../../../_actions'

// /* MAterial UI */
import { styles } from "./homePageStyle";
import { Grid, Paper, Typography, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


/* Layouts */
// import AlertDialog from "../layout/alertDialogo";

/* Url's */
import { apiCardio } from '../../../../_constants/';



class SugerenciasSeguir extends Component {
    // constructor(props) {
    //     super(props);
    // }

    handleSeguirEspecialista = data => (e) => {
        e.preventDefault();
        const { dispatch, user } = this.props;
        dispatch(ePageActions.seguirEspecialistaSugeridos(user, true, data))
    }

    handleVerSeguidoresSiguiendo = data => (e) => {
        e.preventDefault();
        this.props.dispatch(ePageActions.mostrarViewEpage(data))
    } 

    render() {
        const { classes, sugerencias, siguiendo, seguidores } = this.props;
        let i = 0;
        return (
            <Grid container spacing={24}>
                <Grid item xs={6} align="center">
                    <Paper className="paper-bar-left cursor-pointer" onClick={this.handleVerSeguidoresSiguiendo(3)}>
                        <div className="title-paper">
                            <Typography color="primary" component="span" >Siguiendo</Typography>
                        </div>
                        <Divider />
                        <div className="cant-follower">
                            <Typography component="h2">{siguiendo}</Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} align="center">
                    <Paper className="paper-bar-left cursor-pointer" onClick={this.handleVerSeguidoresSiguiendo(2)}>
                        <div className="title-paper">
                            <Typography color="primary" component="span">Seguidores</Typography>
                        </div>
                        <Divider />
                        <div className="cant-follower">
                            <Typography component="h2">{seguidores}</Typography>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="paper-bar-left">
                        <div className="title-paper">
                            <Typography color="primary" component="span" className="left-dashboard">Puedes seguir a</Typography>
                            <Typography component="span" className="right-dashboard cursor-pointer" onClick={this.handleVerSeguidoresSiguiendo(4)}> Ver más</Typography>
                            <div className="clear"/>
                        </div>
                        <Divider/>
                        <div>
                            <List className="list-sugerencias">
                                {sugerencias.slice(0, 3).map(k => {
                                    return(
                                        <div key={i++}>
                                            <ListItem alignItems="flex-start" className=" cursor-pointer">
                                                <ListItemAvatar>
                                                    <Avatar src={apiCardio.urlMedia + '' + k.foto} alt={k.name} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    className={classes.resetPaddingListIten}
                                                    primary={k.name}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography component="span" className="especialidades truncate">{k.viewEspecialidad}</Typography>
                                                            <Link to={'/epage/'+k.nickname}>
                                                                <Button variant="contained" color="primary" className={classes.button}>
                                                                    Perfil
                                                                </Button>
                                                            </Link>
                                                            <Button variant="contained" className={classes.button} onClick={this.handleSeguirEspecialista(k.nickname)}>
                                                                Seguir
                                                            </Button>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider component="li" />
                                        </div>
                                    )
                                })

                                }
                            </List>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

SugerenciasSeguir.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { sugerencias, siguiendo, seguidores } = state.ePage;
    return {
        user,
        sugerencias, siguiendo, seguidores
    };
}

const connectedSugerenciasSeguir = connect(mapStateToProps)(withStyles(styles)(SugerenciasSeguir));
export { connectedSugerenciasSeguir as SugerenciasSeguir };