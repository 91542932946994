import { menuConstants } from '../_constants';

export const menuActions = {
    isOpen,
    barRight,
    menuActive,
};

function isOpen(actiomenu) {
    return { type: menuConstants.MOBILEOPEN, actiomenu };
}

function barRight() {
    return { type: menuConstants.BAR_RIGHT_SUCCESS };
}

function menuActive(data) {
    return { type: menuConstants.MAIN_ACTIVE, data };
}
