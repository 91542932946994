import { postConstants } from '../../_constants';

const initialState = {
    loading:false,
    loaderPost: true,
    disableBtn: true,
    offset: 0,
    post:[],
    newPost: {
        id: 0,
        description: '',
        image: '',
        video: '',
    },
    selectPost: '',
    comentaryReply: '',
};

export function postReducer(state = initialState, action) {
    switch (action.type) {
        case postConstants.GET_POSTS_REQUEST:
            return Object.assign({}, state, {
                loaderPost: action.data
            });
            
        case postConstants.RESET_OFFSET:
            return Object.assign({}, state, {
                post: [],
                offset: 0,
            });
        case postConstants.NEW_POST:
            return {
                ...state,
                newPost: {
                    ...state.newPost,
                    [action.data.name]: action.data.value
                },
                disableBtn: false
            }
        case postConstants.EDIT_POST:
            return Object.assign({}, state, {
                selectPost: {
                    ...state.selectPost,
                    post:{
                        ...state.selectPost.post,
                        [action.data.name]: action.data.value,
                        typeFile: action.data.typeFile
                    }
                },
                disableBtn: false,
            });
        case postConstants.REMOVE_IMG_POST:
            return Object.assign({}, state, {
                newPost: {
                    ...state.newPost,
                    [action.data]: ''
                }
            });
        case postConstants.DISABLE_BTN:
            return Object.assign({}, state, {
                disableBtn: action.data
            });
        case postConstants.SUBMIT_REQUEST_POST:
            return Object.assign({}, state, {
                submitted: action.data
            });
        case postConstants.SUBMIT_POST_SUCCESS:
            return Object.assign({}, state, {
                submitted: !state.submitted,
                post: [
                    ...state.post,
                    action.data
                ],
                newPost: {
                    id: 0,
                    description: '',
                    image: '',
                    video: '',
                },
                fade: !state.fade,
                // case para cuando el post se registro con exito
            });
        case postConstants.SUBMIT_POST_SUCCESS_EDIT:
            return Object.assign({}, state, {
                submitted: !state.submitted,
                post: state.post.filter(k => {
                    if (k.keyd === action.data.keyd){
                        k.files = action.data.files
                        k.typeFile = action.data.typeFile
                        k.description = action.data.description
                    }
                    return k
                }),
                selectPost: '',
                // case para cuando el post se registro con exito
            });
        case postConstants.DELETE_POST:
            return Object.assign({}, state, {
                post: state.post.filter(q => (q.keyd !== action.data)),
                selectPost: '',
            });
        case postConstants.OPEN_O_CLOSE_NEW_POST:
            return {
                ...state,
                fade: !state.fade
            }
        case postConstants.GET_POSTS:
            return Object.assign({}, state, {
                // post: action.data.length
                //     ? state.post.concat(action.data)
                //     : state.post,
                post: (state.offset !== 0)
                    ? state.post.concat(action.data)
                    : action.data,
                offset: action.data.length ? (state.offset + action.data.length) : state.offset,
                //loaderPost: !state.loaderPost,
            });
        case postConstants.GET_POST_UNIQUE:
            return Object.assign({}, state, {
                post: action.data,
                offset: 0,
            });
        case postConstants.GET_POSTS_PERFIL:
            return Object.assign({}, state, {
                post: action.data,
                offset: action.data.length,
            });
        case postConstants.FAIL_GET_POST:
            return Object.assign({}, state, {
                // loaderPost: !state.loaderPost,
                post: state.post
            });
        case postConstants.FAIL_GET_POST_PERFIL:
            return Object.assign({}, state, {
                //loaderPost: !state.loaderPost,
                post: []
            });
        case postConstants.UPDATE_LIKE:
            return Object.assign({}, state, {
                post: state.post.map(k =>{
                    if (action.data.keyd === k.keyd ) {
                        k.like = action.data.like
                        k.mylike = action.data.mylike
                    }
                    return k;
                })
            });
        case postConstants.FAIL_UPDATE_LIKE:
            return Object.assign({}, state, {
                post: state.post.map(k =>{
                    if (action.data.post === k.keyd ) {
                        k.like = action.data.like
                        k.mylike = action.data.mylike
                    }
                    return k;
                })
            });
        case postConstants.VIEW_COMMNETS:
            return Object.assign({}, state, {
                post: state.post.filter(k =>{ 
                    if (action.data.post === k.keyd ) {
                        k.coments = action.data.coments
                    }
                    return k;
                }),
                loading: !state.loading,
                selectPost: ''
            });
        case postConstants.SELECT_POST_TO_COMENTARY:
            return Object.assign({}, state, {
                selectPost: action.data
            });
        case postConstants.SUBMIT_REQUEST_COMENTARY:
            return Object.assign({}, state, {
                loading: !state.loading
            });
        case postConstants.SUBMIT_SUCCESS_COMENTARY:
            return Object.assign({}, state, {
                loading: !state.loading,
                post: state.post.map(k => {
                    if (action.data.post === k.keyd) {
                        k.coments = [
                            ...k['coments'],
                            action.data.comentary
                        ]
                        k.countComentary = k.countComentary + 1
                    }
                    return k;
                })
            });
        case postConstants.DELETE_COMENTARY:
            return Object.assign({}, state, {
                post: state.post.map(k => {
                    if (action.data.post === k.keyd) {
                        k.coments = k.coments.filter(q => (q.keyds !== action.data.comentario))
                        k.countComentary = k.countComentary - 1
                    }
                    return k;
                }),
                selectPost: '',
            });
        case postConstants.CHANGE_VALUE_COMENTARY:
            return Object.assign({}, state, {
                post: state.post.filter(k =>{
                    if (action.data.post === k.keyd) {
                        k.coments.filter(q => {
                            if (q.keyds === action.data.idComentary ) {
                                q.commentary = action.data.comentary
                            }
                            return q;
                        })
                    }
                    return k;
                })
            });
        case postConstants.CHANGE_VALUE_REPLY_COMENTARY:
            return Object.assign({}, state, {
                comentaryReply: action.data
            });
        case postConstants.SUBMIT_SUCCESS_COMENTARY_REPLY:
            return Object.assign({}, state, {
                post: state.post.map(k => {
                    if (k.keyd === action.data.keyd_post) {
                        k.comentaryReply = action.data.reply
                    }
                    return k;
                }),
                loading: !state.loading,
                comentaryReply: '',
            });
        case postConstants.CHANGE_VALUE_REPLY_COMENTARY_EDIT:
            return Object.assign({}, state, {
                post: state.post.filter(k => {
                    if (action.data.post === k.keyd) {
                        k.comentaryReply.filter(q => {
                            if (q.keyds === action.data.idComentary) {
                                q.commentary_reply = action.data.commentary_reply
                            }
                            return q;
                        })
                    }
                    return k;
                })
            });
        case postConstants.DELETE_COMENTARY_REPLY:
            return Object.assign({}, state, {
                post: state.post.map(k => {
                    if (action.data.post === k.keyd) {
                        k.comentaryReply = k.comentaryReply.filter(q => (q.keyds !== action.data.comentario))
                    }
                    return k;
                }),
                selectPost: '',
            });
        default:
            return state
    }
}