import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Material UI */
import './post.css';
import { styles } from "./postStyle";
import { Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Layouts */
import Preloader from '../layout/preloader';
import { MenuEspecialistas } from '../layout/MenuEspecialistas';
import { ListPost } from "../post/listPost";
/* Actions */
import { postActions } from '../../../../_actions'

class VerPost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            keyd: this.props.match.params.keydPost
        }
        this.props.dispatch(postActions.getUniquePost(this.props.user, this.props.match.params.keydPost))
    }
    componentDidUpdate() { 
        if (this.state.keyd !== this.props.match.params.keydPost) {
            this.setState({ keyd: this.props.match.params.keydPost });
            this.props.dispatch(postActions.getUniquePost(this.props.user, this.props.match.params.keydPost))
        }
    }
    render() {
        const { loaderPost } = this.props
        return (loaderPost)
            ? <Preloader />
            :(
                <Fade in={true}>
                    <section className="body" ref={this.container}>
                        <MenuEspecialistas />
                        <div className="content">
                            <ListPost />
                        </div>
                    </section>
                </Fade>
            )
    }
}

VerPost.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { post, selectPost, loading, loaderPost } = state.postReducer;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    return {
        user,
        post, selectPost, loading, loaderPost,
        openConfirm, loadingConfirm, submitedConfrim
    };
}

const connectedVerPost = connect(mapStateToProps)(withStyles(styles)(VerPost));
export { connectedVerPost as VerPost };