export const configConstants = {
    SELECT_VIEW: 'SELECT_VIEW',
    VIEW_EDIT_PASSWORD: 'VIEW_EDIT_PASSWORD',
    VIEW_EDIT_DATOS_PERSO: 'VIEW_EDIT_DATOS_PERSO',

    SUBMIT_REQUEST: 'SUBMIT_REQUEST',
    LOADING_REQUEST: 'LOADING_REQUEST',

    //COMPLETAR PERFIL 
    COMPLETAR_PERFIL: 'COMPLETAR_PERFIL',

    // REQUISITOS
    GET_REQUISITOS_CITAS: 'GET_REQUISITOS_CITAS',
    OPEN_MODAL_REQUISITOS: 'OPEN_MODAL_REQUISITOS',
    REQUISITOS_CITAS_INSERT: 'REQUISITOS_CITAS_INSERT',
    REQUISITOS_CITAS_DELETE: 'REQUISITOS_CITAS_DELETE',
    REQUISITOS_CITAS_REQUEST: 'REQUISITOS_CITAS_REQUEST',
    SUBMIT_REQUISITO_REQUEST: 'SUBMIT_REQUISITO_REQUEST',
    SELECT_ITEM_REQUISITO_DELETE: 'SELECT_ITEM_REQUISITO_DELETE',
    REQUISITOS_OPCION_CITAS_DELETE: 'REQUISITOS_OPCION_CITAS_DELETE',

    //FORMA DE PAGO
    ADD_FORM_NEW_BANCO: 'ADD_FORM_NEW_BANCO',
    EDIT_FORM_NEW_BANCO: 'EDIT_FORM_NEW_BANCO',
    GET_METODO_PAGO: 'GET_METODO_PAGO_ESPECIALISTA',
    SELECT_METODO_PAGO_DELE: 'SELECT_METODO_PAGO_DELE',
    GET_METODO_PAGO_REQUESt: 'GET_METODO_PAGO_REQUESt',
    SUBMITED_NUEVO_BANCO_NACIONAL: 'SUBMITED_NUEVO_BANCO_NACIONAL',
    MOSTRAR_FORMULARIO_NUEVO_BANCO: 'MOSTRAR_FORMULARIO_NUEVO_BANCO',

    // Honorarios
    SELECT_HONORARIO: 'SELECT_HONORARIO',
    GET_METODO_ASESORIA: 'GET_METODO_ASESORIA',
    FAIL_SUMIT_HONORARIO: 'FAIL_SUMIT_HONORARIO',
    GET_HONORARIOS: 'GET_HONORARIOS_ESPECIALISTA',
    GET_HONORARIOS_REQUEST: 'GET_HONORARIOS_REQUEST',
    INSERT_HONORARIOS: 'INSERT_HONORARIOS_ESPECIALISTA',
    SUBMIT_REQUEST_HONORARIO: 'SUBMIT_REQUEST_HONORARIO',
    SELECTED_SUCCESS_MODALIDAD: 'SELECTED_SUCCESS_MODALIDAD',
    ACTIVAR_MONTO_ASESORIA_TEXTO: 'ACTIVAR_MONTO_ASESORIA_TEXTO',
    SUBMIT_REQUEST_HONORARIO_CHAT: 'SUBMIT_REQUEST_HONORARIO_CHAT',
    SUCCES_DESACTIVAR_ASESORIA_CHAT: 'SUCCES_DESACTIVAR_ASESORIA_CHAT',


    //Horarios
    GET_HORARIO: 'GET_HORARIO',
    SELECT_HORARIO: 'SELECT_HORARIO',
    GET_HORARIO_REQUEST: 'GET_HORARIO_REQUEST',
    REGIS_HORARIO_REQUEST: 'REGIS_HORARIO_REQUEST',
    SUMBITED_HORARIO_REQUEST: 'SUMBITED_HORARIO_REQUEST',
    ACTIVAR_DIA_ESPECIALISTA: 'ACTIVAR_DIA_ESPECIALISTA',
    INACTIVAR_DIA_ESPECIALISTA: 'INACTIVAR_DIA_ESPECIALISTA',

    //Especialidad
    GET_ESPECILIDAD: 'GET_ESPECILIDAD',
    SELET_ESPECIALIDAD: 'SELET_ESPECIALIDAD',
    GET_FAIL_ESPECILIDAD: 'GET_FAIL_ESPECILIDAD',
    GET_ESPECILIDAD_REQUESt: 'GET_ESPECILIDAD_REQUESt',
    
    
};