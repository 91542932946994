import { apiCardio } from '../_constants';

export const directService = {
    getListDirect,
    getYarnDirect,
    insertDirect,
    insertComentary,
    marcarVistoMensaje,
    deleteChat,
    getDirectContactos,
    getVerificarExisteDirect
};

function getListDirect(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlDirect}getAllDirect`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function getYarnDirect(user, keyd) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlDirect}getYarnDirect`),
        params = { keyd }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function insertDirect(message,user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ message })
    };

    return fetch(`${apiCardio.urlDirect}insertDirect`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function insertComentary(message,user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ message })
    };

    return fetch(`${apiCardio.urlDirect}insertComentary`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function marcarVistoMensaje(keyd,user) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ keyd })
    };

    return fetch(`${apiCardio.urlDirect}marcarVistoMensaje`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function deleteChat(message, user) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ message })
    };

    return fetch(`${apiCardio.urlDirect}deleteDirect`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getDirectContactos(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlEPage}getContactos`),
        params = { "nickname": user.username }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getVerificarExisteDirect(user,keyd) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlDirect}verificYarnDirect`),
        params = { "keyd": keyd }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}