import React from 'react'

/* MAterial UI */
import { Grid, Paper, Typography, Divider, InputAdornment, TextField, IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
/* Iconos */
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';

/* Url's */
import { apiCardio } from '../../../../_constants/';

/* Estilos con Material UI */
import { styles } from "./historiasStyles";

/*Component */
import Chips from "../layout/chips";

const DetalleHistoriaPersona = (props) => (
    <Grid container spacing={24} className={props.classes.contDetalleGeneral}>
        <Grid item xs={12}>
            <Paper className="paper-historia">
                <Grid container>
                    <div className={props.classes.contImgPerson}>
                        <img className={props.classes.imgPerso} src={`${apiCardio.urlMedia}${props.detalle.img}`} alt={props.detalle.paciente} />
                    </div>
                    <div className={props.classes.contInfo}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} sm={6}>
                                <div className={props.classes.contHistoria}>
                                    <Typography className={props.classes.span} component="span"> Paciente </Typography>
                                    <Typography className={props.classes.pHistoria} component="p"> {props.detalle.paciente} </Typography>
                                    <Divider />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={props.classes.contHistoria}>
                                    <Typography className={props.classes.span} component="span"> Fecha de nacimiento</Typography>
                                    <Typography className={props.classes.pHistoria} component="p">{props.detalle.nacimiento}</Typography>
                                    <Divider />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={props.classes.contHistoria}>
                                    <Typography className={props.classes.span} component="span"> Pais de residencia </Typography>
                                    <Typography className={props.classes.pHistoria} component="p"> {props.detalle.residencia} </Typography>
                                    <Divider />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div className={props.classes.contHistoria}>
                                    <Typography className={props.classes.span} component="span"> Empresa </Typography>
                                    <Typography className={props.classes.pHistoria} component="p"> {props.detalle.empresa} </Typography>
                                    <Divider />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Paper>
        </Grid>

        {/* HISTORIA CLINICA */}
        {props.detalle.hist_clinica.length ?
            <Grid item xs={12}>
                <Paper className="paper-historia">
                    <div className='paper'>
                        <Grid container spacing={24}>
                            {props.detalle.hist_clinica.map(h => {
                                return (
                                    <Grid key={h.id} item xs={12} sm={6}>
                                        <div className={`${props.classes.contHistoria}`} >
                                            <Typography className={props.classes.span} component="span"> {h.name} </Typography>


                                            {h.name !== 'Estatura' && h.name !== 'Peso' && !h.campo_espesificar ? <Typography className={props.classes.pHistoria} component="p"> {h.valor} </Typography> : ''}
                                            {h.name === 'Estatura' ? <Typography className={props.classes.pHistoria} component="p"> {h.valor} cm </Typography> : ''}
                                            {h.name === 'Peso' ? <Typography className={props.classes.pHistoria} component="p"> {h.valor} kg </Typography> : ''}
                                            {h.campo_espesificar ? <Typography className={props.classes.pHistoria} component="p"> {h.valor}, {h.campo_espesificar} </Typography> : ''}
                                            <Divider />
                                        </div>

                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </Paper>
            </Grid>
            : ""
        }
        
        {/* ESPECIALIDADES  */}
        <Grid item xs={12}>
            <Paper className="paper">
                <div className="title-paper">
                    <Typography color="primary" component="span">Historias por especialidad</Typography>
                </div>
                <div>
                    <Grid item xs={12} className={props.classes.mb_1}>
                        <TextField
                            id="filterEspecialidad"
                            name="filterEspecialidad"
                            label="Escribe una especialidad"
                            className={props.classes.margin}
                            onChange={props.handleFilterEspecialidad}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    {
                        console.log(props.especialidades)
                    }
                    <Chips
                        list={props.especialidades}
                        handleSelect={props.handleSelect}
                    />
                </div>
            </Paper>
        </Grid>

        {/* HISTORIA DE CITAS */}
        {props.histEspecialidad && 
            <Grid item xs={12}>
                <Paper className="paper">
                    <div className="title-paper">
                        <Typography color="primary" component="span">{props.histEspecialidad.name}</Typography>
                    </div>
                    <div>
                        {props.histEspecialidad.value.length ?
                            props.histEspecialidad.value.map(k => {
                                const datos = { especialidad: props.histEspecialidad, datos: props.detalle, value: k }
                                return(
                                    <ExpansionPanel key={k.historia} expanded={props.expanded === k.historia} onChange={props.handleChangeExpansion(k.historia)}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={props.classes.title_expansion}><b>Medico: {k.medico}</b>,  fecha de creación: {k.fecha_creacion}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={24}>
                                                <Grid item xs={10} sm={11}>
                                                    <div className={props.classes.contHistoria}>
                                                        <Typography className={props.classes.span} component="span"> Motivo de la cita </Typography>
                                                        <Typography className={props.classes.pHistoria} component="p"> {k.motive} </Typography>
                                                        <Divider />
                                                    </div>
                                                </Grid>
                                                {k.cnd === '1' &&
                                                    <Grid item xs={2} sm={1}>
                                                        <Tooltip title="Editar historia">
                                                            <IconButton aria-label="Editar Historia" onClick={props.handleEditarHistoria(datos)}>
                                                                <EditIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <div className={props.classes.contHistoria}>
                                                        <Typography className={props.classes.span} component="span"> Historia de la cita </Typography>
                                                        <div>
                                                            <div  dangerouslySetInnerHTML={{ __html: k.history }}></div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            })
                            : <div><Typography className={props.classes.span} component="span"> Aun no existe historia para esta especialidad</Typography></div>
                        }
                    </div>
                </Paper>
            </Grid>
        }
        
    </Grid>
)

export default withStyles(styles)(DetalleHistoriaPersona);