/* Url Desarrollo*/
// const base_url = 'http://localhost/ecardioline/apicardio/'
// const base_shared = 'http://localhost/ecardioline/cardioline/post/'

/* Url Calidad*/
// const base_url = 'https://apicalidad.ecardioline.com/'
// const base_shared = 'https://calidadsistema.ecardioline.com/post/'

/* Url Produccion*/
const base_url = 'https://apicardio.ecardioline.com/'
const base_shared = 'https://ecardioline.com/post/'

export const apiCardio = {
    urlMedia: base_url,
    urlUser: base_url+'public/user/',
    urlPost: base_url +'public/post/',
    urlBanco: base_url+'public/banco/',
    urlCitas: base_url +'public/cita/',
    urlEPage: base_url +'public/epage/',
    urlMedico: base_url+'public/medico/',
    urlPais: base_url +'public/country/',
    urlNotify: base_url +'public/notify/',
    urlDirect: base_url +'public/direct/',
    urlHistoria: base_url +'public/historia/',
    urlIncidencia: base_url +'public/incidencia/',
    urlHorario: base_url +'public/horariomedico/',
    urlSharedPost: base_shared, 
};