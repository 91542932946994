import React, { Component } from 'react';
import PropTypes from "prop-types";
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { history } from '../../../../_helpers';

/* Layouts */
import { BarRigth } from "../layout/barRight";
import { MenuEspecialistas } from '../layout/MenuEspecialistas';

import { Grid, Paper, Typography, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles";
import 'react-quill/dist/quill.snow.css';
/* Icons */
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/*Component's */

/* Estilos con Material UI */
import { styles } from "./historiasStyles";

/* Actions */
import { historiaActions } from '../../../../_actions'

class CrearHistoria extends Component {
    constructor(props) {
        super(props);

        this.state = { editorHtml: this.props.edit ? this.props.personSelected.history : '' }
        this.handleChange = this.handleChange.bind(this)
        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.handleCancelar = this.handleCancelar.bind(this)
    }

    handleChange(html) {
        this.setState({ editorHtml: html });
    }

    handleOnSubmit(e) {
        e.preventDefault();
        const { editorHtml } = this.state
        const { dispatch, user, personSelected } = this.props
        if (editorHtml && editorHtml !== '<p><br></p>') {
            // dispatch(historiaActions.getSubmit())
            dispatch(historiaActions.regisHistoria(user, personSelected, editorHtml))
        }
    }

    handleCancelar = () => {
        this.props.dispatch(historiaActions.cancelarCrearHistoria())
    }

    render() {
        const { classes, personSelected, submitted, loading } = this.props;

        return (!personSelected) 
            ? <div> {history.push('/historias')} </div> 
            : (
                <section className="body" ref={this.container}>
                    <MenuEspecialistas />
                    <BarRigth historia={true} />
                    <div className="content">
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper className="paper">
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={4}>
                                            <Typography color="primary" component="label">Paciente</Typography>
                                            <Typography color="secondary" component="p">{personSelected.paciente}</Typography>

                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Typography color="primary" component="label">Fecha de la cita</Typography>
                                            <Typography color="secondary" component="p">{personSelected.fecha_cita}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Typography color="primary" component="label">Especialidad</Typography>
                                            <Typography color="secondary" component="p">{personSelected.nom_especialidad}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography color="primary" component="label">Motivo de la consulta</Typography>
                                            <Typography color="secondary" component="p">{personSelected.motive}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className="">
                                    <div className="edit-html">
                                        <ReactQuill
                                            theme="snow"
                                            onChange={this.handleChange}
                                            value={this.state.editorHtml}
                                            modules={CrearHistoria.modules}
                                            formats={CrearHistoria.formats}
                                            placeholder={this.props.placeholder}
                                        />
                                    </div>
                                    <div className={classes.contBtn}>
                                        <Button 
                                            variant="contained" 
                                            className={classes.mr_1}
                                            onClick={this.handleCancelar}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            disabled={submitted && loading}
                                            onClick={this.handleOnSubmit}
                                        >
                                            Registrar
                                                {submitted && loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                                        </Button>
                                    </div>
                                </Paper>

                            </Grid>
                        </Grid>

                    </div>
                </section>
            );
    }
}

CrearHistoria.modules = {
  toolbar: [
    [{'header': '2'}],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    [{ 'color': [] }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
CrearHistoria.formats = [
    'header', 'align', 'color',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
]
CrearHistoria.propTypes = {
  placeholder: PropTypes.string,
}

CrearHistoria.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { loading, personSelected, submitted, edit } = state.historia;
    return {
        user,
        edit,
        loading,
        submitted,
        personSelected,
    };
}

const connectedCrearHistoria = connect(mapStateToProps)(withStyles(styles)(CrearHistoria));
export { connectedCrearHistoria as CrearHistoria };