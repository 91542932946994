export const historiaConstants = {
    GET_HISTORIA: 'GET_HISTORIA',
    FAIL_HISTORIA: 'FAIL_HISTORIA',
    REQUEST_HISTORIA: 'REQUEST_HISTORIA',

    SELECT_PERSON: 'SELECT_PERSON',
    VIEW_DETALLE_PERSON: 'VIEW_DETALLE_PERSON',

    REMOVE_PATIENT_HIST: 'REMOVE_PATIENT_HIST',

    SUBMIT_HISTORIA: 'SUBMIT_HISTORIA',
    SUCCESS_INSERT_HISTORIA: 'SUCCESS_INSERT_HISTORIA',

    ESPECIALIDAD_SELECT_HIST: 'ESPECIALIDAD_SELECT_HIST',

    CANCELAR_CREAR_HISTORIA: 'CANCELAR_CREAR_HISTORIA',
    SELECT_PERSON_EDIT_HISTORY: 'SELECT_PERSON_EDIT_HISTORY',
}