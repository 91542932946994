import { alertActions } from '../alert.action';
import { configCuentaService } from "../../_services";
import { alertConfirmActions } from '../alertConfirm.action';
import { configConstants } from '../../_constants';

export const formaPagoAction = {
    // Forma de pago
    getMetodosDePago,
    selectFormaDePago,
    addNewBancoForm,
    submitRequestBanco,
    registrarBancoNacional,
    loaddingFail,
    editBancoNacional,
    selectBancoDelete,
    deleteBanco,
    viewFormBanco,
    addCuentaAsociada,
    regisCuentaAsociada,

    //Honorarios
    getHonorarios,
    selectHonorario,
    registrarMontoHonorario,
    submitRequestHonorario,
    submitRequestHonorarioChat,
    montoHonorario,
    registrarMontoHonorarioChat,
    desactivarAsesoriaChat,
    activarMontosAsesoriaTexto,

    //Metodo de citas
    getMetodosCitas,
    selectModalidaCita,
};

/*FORMA DE PAGO */
function getMetodosDePago(user) {
    return dispatch => {
        configCuentaService.getMetodoDePago(user)
            .then(
                data => dispatch(success(data)),
                // error => dispatch(failure(error.toString()))
            );
        //  type: perfilConstants.GET_HONORARIOS 
    };

    function success(data) { return { type: configConstants.GET_METODO_PAGO, data } }
}

function selectFormaDePago(selected, user, chipMetodoPago) {
    chipMetodoPago.map(function (e) {
        if (e.id === selected.id) {
            e.selected = !selected.selected;
        }
        return { chipMetodoPago }
    });
    const data = chipMetodoPago

    return dispatch => {
        dispatch(request());
        dispatch(success(data));
        configCuentaService.selectMetodoDePago(user, chipMetodoPago, false)

    };
    function request() { return { type: configConstants.GET_METODO_PAGO_REQUESt } }
    function success(data) { return { type: configConstants.GET_METODO_PAGO, data } }
}

function addNewBancoForm(name, value) {
    const data = { name, value }
    return dispatch => {
        dispatch(request(data));
    }
    function request(data) { return { type: configConstants.ADD_FORM_NEW_BANCO, data } }
}

function submitRequestBanco() {
    return dispatch => {
        dispatch(request());
    }
    function request() { return { type: configConstants.SUBMITED_NUEVO_BANCO_NACIONAL } }
}

function registrarBancoNacional(user, chipMetodoPago, newbanco) {
    let id = 0
    chipMetodoPago.map(function (e) {
        if (e.codg === "BANK") { id = e.id }
        return id
    });
    return dispatch => {
        dispatch(request(true));
        configCuentaService.regisNuevoBanco(user, newbanco, id)
            .then(
                data => {
                    if (data.success) {
                        chipMetodoPago.map(function (e) {
                            if (e.codg === "BANK") {
                                e.datos = data.data
                            }
                            return chipMetodoPago
                        });
                        dispatch(submitSucces());
                        dispatch(success(chipMetodoPago));
                        dispatch(alertActions.success(data.message));
                        dispatch(viewBanco(false));
                    } else {
                        dispatch(success(chipMetodoPago));
                        dispatch(alertActions.error(data.message));
                    }
                }
            )
    }
    function request(data) { return { type: configConstants.GET_METODO_PAGO_REQUESt, data } }
    function success(data) { return { type: configConstants.GET_METODO_PAGO, data } }
    function submitSucces() { return { type: configConstants.SUBMITED_NUEVO_BANCO_NACIONAL } }
    function viewBanco(data) { return { type: configConstants.MOSTRAR_FORMULARIO_NUEVO_BANCO, data } }
}

function loaddingFail(params) {
    return dispatch => {
        dispatch(request(false));
    }
    function request(data) { return { type: configConstants.GET_METODO_PAGO_REQUESt, data } }
}

function editBancoNacional(datos) {
    return dispatch => {
        dispatch(request(datos));
    }
    function request(data) { return { type: configConstants.EDIT_FORM_NEW_BANCO, data } }
}

function selectBancoDelete(item) {
    return dispatch => {
        dispatch(success(item));
    }
    function success(data) { return { type: configConstants.SELECT_METODO_PAGO_DELE, data } }
}
function deleteBanco(banco, user, chipMetodoPago) {
    return dispatch => {
        dispatch(request());
        configCuentaService.deleteBanco(user, banco.id)
            .then(
                data => {
                    if (data.success) {
                        chipMetodoPago.map(function (e) {
                            if (e.codg === "BANK") {
                                e.datos = data.data
                            }
                            return chipMetodoPago
                        });
                        dispatch(success(chipMetodoPago));
                        dispatch(alertActions.success(data.message));
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(success(chipMetodoPago));
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            )
    }
    function request() { return { type: configConstants.GET_METODO_PAGO_REQUESt } }
    function success(data) { return { type: configConstants.GET_METODO_PAGO, data } }
}

function viewFormBanco(data) {
    return { type: configConstants.MOSTRAR_FORMULARIO_NUEVO_BANCO, data };
}

function addCuentaAsociada(id, value, chipMetodoPago) {
    chipMetodoPago.map(function (e) {
        if (e.id === id) {
            e.datos = { 
                ...e.datos,
                email: value
            }
        }
        if (e.codg === ''+id) {
            e.datos = {
                ...e.datos,
                titular: value
            }
        }
        return { chipMetodoPago }
    });
    const data = chipMetodoPago
    console.log(data)
    return dispatch => {
        dispatch(request());
        dispatch(success(data));
    };
    function request() { return { type: configConstants.GET_METODO_PAGO_REQUESt } }
    function success(data) { return { type: configConstants.GET_METODO_PAGO, data } }
}

function regisCuentaAsociada(user, chipMetodoPago) {
    return dispatch => {
        dispatch(request(true));
        configCuentaService.selectMetodoDePago(user, chipMetodoPago, true)
        .then(
                data => {
                    if (data.success) {
                        dispatch(success(chipMetodoPago));
                        dispatch(request_Submit());
                        dispatch(alertActions.success('Se registro con exito sus cuentas asociadas'));
                    } else {
                        dispatch(alertActions.error('Ocurrio un error'));
                    }
                }
           )
        };
    function request(data) { return { type: configConstants.GET_METODO_PAGO_REQUESt, data } }
    function success(data) { return { type: configConstants.GET_METODO_PAGO, data } }
    function request_Submit() { return { type: configConstants.SUBMITED_NUEVO_BANCO_NACIONAL } }
}
        
function getHonorarios(user) {
    return dispatch => {
        configCuentaService.getHonorarios(user)
        .then(
            data => dispatch(success(data)),
            // error => dispatch(failure(error.toString()))
            );
        };
        
        function success(data) { return { type: configConstants.GET_HONORARIOS, data } }
    }
            
function selectHonorario(selected, user, chipMonedas) {
    chipMonedas.map(k =>{
        if (k.id === selected.id) {
            k.selected = !selected.selected;
            if (k.selected) {
                k.montoAsesoria = 15; k.montoAsesoriaChat = 10;
                k.montoInterconsultaVivo = 15; k.montoInterconsultaTexto = 10;
            } else {
                k.montoAsesoria = ""; k.montoAsesoriaChat = "";
                k.montoInterconsultaVivo = ""; k.montoInterconsultaTexto = "";
            }
        }
        return k;
    })
    // const data = chipMonedas
    return dispatch => {
        dispatch(request());
        dispatch(success(chipMonedas));
        configCuentaService.selectHonorarios(user, chipMonedas)
        
    };
    function request() { return { type: configConstants.GET_HONORARIOS_REQUEST } }
    function success(data) { return { type: configConstants.SELECT_HONORARIO, data } }
}
            
function registrarMontoHonorario(user, chipMonedas) {
    let validacion = 0;
    return dispatch => {
        dispatch(request());
        chipMonedas.map(k => {
            if (k.selected === true && (k.montoAsesoria < 15 || k.montoAsesoria > 20 )) {
               validacion++; 
            }
            return k;
        })
        if (validacion === 0) {
            configCuentaService.selectHonorarios(user, chipMonedas)
                .then(
                    data => {
                        if (data.success) {
                            dispatch(success(chipMonedas));
                            dispatch(alertActions.success('Se registro con exito su honorario'));
                        } else {
                            dispatch(alertActions.error('Ocurrio un error'));
                        }
                        dispatch(sutmit_request(false));

                    }
                )
        }else{
            dispatch(fail(false));
            dispatch(alertActions.info('Estimado especialista, el baremo establecido para esta modalidad es de 15 – 20 USD/EUR. Coloca el precio entre estos rangos.'));
        }           
            
    };
    function request() { return { type: configConstants.GET_HONORARIOS_REQUEST } }
    function success(data) { return { type: configConstants.GET_HONORARIOS, data } }
    function fail() { return { type: configConstants.FAIL_SUMIT_HONORARIO,  } }
    function sutmit_request(data) { return { type: configConstants.SUBMIT_REQUEST_HONORARIO, data } }
}

function submitRequestHonorario(data) {
    return { type: configConstants.SUBMIT_REQUEST_HONORARIO, data };
}

function submitRequestHonorarioChat(data) {
    return { type: configConstants.SUBMIT_REQUEST_HONORARIO_CHAT, data };
}

function montoHonorario(id, value, chipMonedas, type) {
    chipMonedas.map(function (e) {
        if (e.id === id) {
            switch (type) {
                case 2:
                    e.montoAsesoria = value;
                    break;
                case 3:
                    e.montoAsesoriaChat = value;
                    break;
                case 4:
                    e.montoInterconsultaVivo = value;
                    break;
                case 5:
                    e.montoInterconsultaTexto = value;
                    break;
                default:
                    e.montoConsulta = value;
                    break;
            }
        }
        return { chipMonedas }
    });
    const data = chipMonedas
    return dispatch => {
        dispatch(request());
        dispatch(success(data));
    };
    function request() { return { type: configConstants.GET_HONORARIOS_REQUEST } }
    function success(data) { return { type: configConstants.GET_HONORARIOS, data } }
}

function registrarMontoHonorarioChat(user, chipMonedas, modalidad) {
    let validacion = 0;
    const baremo_1 = modalidad === 'montoInterconsultaVivo' ? 15 : 10
    const baremo_2 = modalidad === 'montoInterconsultaVivo' ? 20 : 15
    return dispatch => {
        dispatch(request());
        chipMonedas.map(k => {
            if (k.selected === true && (k[modalidad] < baremo_1 || k[modalidad] > baremo_2 )) {
                validacion++;
            }
            return k;
        })
        if (validacion === 0) {
            configCuentaService.selectHonorarios(user, chipMonedas)
                .then(
                    data => {
                        if (data.success) {
                            dispatch(success(chipMonedas));
                            dispatch(alertActions.success('Se registro con exito su honorario'));
                        } else {
                            dispatch(alertActions.error('Ocurrio un error'));
                        }
                        dispatch(sutmit_request(false));

                    }
                )
        } else {
            dispatch(fail(false));
            dispatch(alertActions.info(`Estimado especialista, el baremo establecido para esta modalidad es de ${baremo_1} – ${baremo_2} USD/EUR. Coloca el precio entre estos rangos.`));
        }

    };
    function request() { return { type: configConstants.GET_HONORARIOS_REQUEST } }
    function success(data) { return { type: configConstants.GET_HONORARIOS, data } }
    function fail() { return { type: configConstants.FAIL_SUMIT_HONORARIO, } }
    function sutmit_request(data) { return { type: configConstants.SUBMIT_REQUEST_HONORARIO_CHAT, data } }
}

function desactivarAsesoriaChat(user, variableReducer, typeQoute) {
    return dispatch => {
        configCuentaService.activarCitaGratis(user, typeQoute, 0)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(variableReducer))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function success(data) { return { type: configConstants.ACTIVAR_MONTO_ASESORIA_TEXTO, data } }
}

function activarMontosAsesoriaTexto(user, variableReducer, typeQoute) {
    return dispatch => {
        configCuentaService.activarCitaGratis(user, typeQoute, 1)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(variableReducer))
                    }
                } 
                // error => dispatch(failure(error.toString()))
            );
    };

    function success(data) { return { type: configConstants.ACTIVAR_MONTO_ASESORIA_TEXTO, data } }
}

function getMetodosCitas(user) {
    return dispatch => {
        configCuentaService.getMetodosCitas(user)
            .then(
                data => dispatch(success(data)),
                // error => dispatch(failure(error.toString()))
            );
    };

    function success(data) { return { type: configConstants.GET_METODO_ASESORIA, data } }
}
function selectModalidaCita(user, modalidad) {
    return dispatch => {
        configCuentaService.activarAsesoriaChat(user, !modalidad.selected ? 1 : 0, modalidad.id)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(modalidad))
                    }
                } 
                // error => dispatch(failure(error.toString()))
            );
    };
    function success(data) { return { type: configConstants.SELECTED_SUCCESS_MODALIDAD, data } }
}