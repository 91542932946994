import React, { Component } from 'react'
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'

import { styles } from '../headerStyle'
import { IconButton, Badge }  from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

/* Actions */
import { notifyActions } from '../../../../../_actions'

/* Iconos */
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';

/*Component */
import MenuNotify from "./menuNotify";

const StyledBadge = withStyles(theme => ({
    badge: {
        background: '#fa3e3e',
        color: 'white'
    },
}))(Badge);
class NotyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openNotify: false,
            typeNotify: '',
        };      
    }
    

    handleProfileMenuOpen =  data => () => {
        const { openNotify, typeNotify } = this.state
        if (typeNotify === '') { this.setState({ openNotify: !openNotify , typeNotify: data }) } 
        else if (typeNotify.name === data.name) { this.setState({ openNotify: !openNotify , typeNotify: '' }) }
        else{ this.setState({ typeNotify: data }) }
    };

    handleMarcarVistoNotificaciones = (event) =>{
        event.preventDefault();
        console.log('marcare todas como vistas')
        this.props.dispatch(notifyActions.markAsSeenNotify(this.props.user, this.state.typeNotify.type))
    }

    render() {
        const { openNotify, typeNotify } = this.state;
        const { classes, notify, directSinLeer } = this.props
        return (
            <div className={classes.contBtnNotify}>
                <Link to="/mensajes" className={classes.colorWhite}>
                    <IconButton color="inherit" className={classes.p08}>
                        {directSinLeer !== '0'
                            ? <StyledBadge badgeContent={directSinLeer} color="secondary">
                                <MailIcon />
                            </StyledBadge>
                            : <MailIcon />
                        }
                    </IconButton>
                </Link>
                <IconButton color="inherit" className={classes.p08} onClick={this.handleProfileMenuOpen({name:'Notificaciones', type: '1' })}>
                    {   notify.filter(q => (q.type_notify === '1'&& q.view === '0')).length 
                            ? <StyledBadge badgeContent={notify && notify.filter(q => (q.type_notify === '1' && q.view === '0')).length} color="secondary">
                                <NotificationsIcon />
                            </StyledBadge>
                            : <NotificationsIcon />
                    }
                    <div className={`beeperNub ${typeNotify.type === '1' ? '' : 'dsp-none'}`}></div>
                </IconButton> 

                <MenuNotify 
                    type={typeNotify}
                    isMenuOpen={openNotify}
                    handleMarcarVistoNotificaciones={this.handleMarcarVistoNotificaciones}
                    list={notify.filter(q => (q.type_notify === typeNotify.type))}
                />
            </div>
        );
    }
}

NotyInfo.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotyInfo)