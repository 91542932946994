import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import {BarRigth} from "../layout/barRight";
import { MenuEspecialistas } from '../layout/MenuEspecialistas';

import { Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

/* Icons */
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/*Component's */
import ComponentBuscar from "./componetBuscar";
import PacientesConHistoria from "./pacientesConHistoria";
import DetalleHistoriaPersona from "./detalleHistoriaPersona";
import ProgressCircular from '../layout/progress/progressCircular';

/* Estilos con Material UI */
import { styles } from "./historiasStyles";

/* Actions */
import { historiaActions, menuActions } from '../../../../_actions'

class HomeHistorias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            expanded: null,
            filterEspecialidad: '',
        }
        const { user, dispatch } = this.props;
        dispatch(menuActions.menuActive(3));
        dispatch(historiaActions.getHistorias(user))

        this.handleVolver = this.handleVolver.bind(this)
        this.handleOnFilter = this.handleOnFilter.bind(this)
        this.handleFilterPerson = this.handleFilterPerson.bind(this)
        this.handleSelectPerson = this.handleSelectPerson.bind(this)
        this.handleChangeExpansion = this.handleChangeExpansion.bind(this)
        this.handleOnFilterEspecilidad = this.handleOnFilterEspecilidad.bind(this)
        this.handleSelectEspecialidadHistoria = this.handleSelectEspecialidadHistoria.bind(this)
    }

    handleOnFilter(filterIdioma, data) {
        let regex = new RegExp(filterIdioma, 'i')
        return data.filter(q => (regex.test(q.paciente)))
    }
    handleFilterPerson(e) {
        const { name, value } = e.target
        this.setState({ [name]: value });
    }

    handleSelectPerson = data => () =>{
        this.props.dispatch(historiaActions.selectPerso(data))
    }

    handleVolver(event){
        event.preventDefault();
        this.props.dispatch(historiaActions.personsView())
    }

    handleSelectEspecialidadHistoria = data => () =>{
        this.props.dispatch(historiaActions.selectHistEspecialidad(data))
    }

    handleOnFilterEspecilidad(filter, data) {
        let regex = new RegExp(filter, 'i')
        return data.filter(q => (regex.test(q.name)))
    }

    handleChangeExpansion = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleEditarHistoria = data => () =>{
        const  array = {
            cita: data.value.cita,
            especialidad: data.especialidad.id,
            fecha_cita: data.value.fecha_creacion,
            motive: data.value.motive,
            nom_especialidad: data.especialidad.name,
            num_paciente: data.datos.paciente_num,
            paciente: data.datos.paciente,
            history: data.value.history,
            num_historia: data.value.historia
        }
        this.props.dispatch(historiaActions.SeletPacienteEditarHistoria(array));
    }

    render() {
        const { filter, filterEspecialidad, expanded } = this.state;
        const { viewDetail, preloaderHist, personSelected, histEspecialidad, pacientesHistoria } = this.props;

        return ( 
            <section className="body" ref={this.container}>
                <MenuEspecialistas />
                <BarRigth
                    historia={true}
                />
                {preloaderHist 
                    ? <ProgressCircular />
                    : viewDetail 
                        ? <div className="content">
                            <div className="breadcrumbs">
                                <Typography color="primary" className="cursor-pointer" onClick={this.handleVolver}>
                                    Ver pacientes 
                                </Typography>
                                <NavigateNextIcon color="primary"/>
                                <Typography color="secondary">Detalle paciente</Typography>
                            </div>
                            
                            <DetalleHistoriaPersona 
                                expanded={expanded}
                                detalle={personSelected}
                                histEspecialidad={histEspecialidad}
                                handleEditarHistoria={this.handleEditarHistoria}    
                                handleFilterEspecialidad={this.handleFilterPerson}
                                handleChangeExpansion={this.handleChangeExpansion}
                                handleSelect={this.handleSelectEspecialidadHistoria}    
                                especialidades={this.handleOnFilterEspecilidad(filterEspecialidad, personSelected.hist_citas)}
                            />
                        </div>
                        :<div className="content">
                            <ComponentBuscar
                                handleFilter={this.handleFilterPerson}
                            />
                            <PacientesConHistoria
                                list={this.handleOnFilter(filter, pacientesHistoria)}
                                select={this.handleSelectPerson}
                            />
                        </div>
                }
            </section>
        )
    }
}

HomeHistorias.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { viewDetail, preloaderHist, personSelected, histEspecialidad, pacientesHistoria } = state.historia;
    return {
        user,
        viewDetail,
        preloaderHist,
        personSelected,
        histEspecialidad,
        pacientesHistoria,
    };
}

const connectedHomeHistorias = connect(mapStateToProps)(withStyles(styles)(HomeHistorias));
export { connectedHomeHistorias as HomeHistorias };