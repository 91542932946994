import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
/* Material UI */
import { styles } from "../perfilStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, CircularProgress, Paper, Avatar, Button } from '@material-ui/core';

/* Actions */
import { apiCardio } from '../../../../../_constants';
import { ePageActions } from '../../../../../_actions'

/* Component */


/* Url's */
// import { apiCardio } from '../../../../_constants';


class Seguidores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewOnState: this.props.viewOn
        }
        const { dispatch, viewOn, nickName, user } = this.props
        dispatch(ePageActions.getFolowers(viewOn, nickName ? nickName : user.username, user ))
    }

    componentDidUpdate() {
        if (this.state.viewOnState !== this.props.viewOn) {
            const { dispatch, viewOn, nickName, user } = this.props
            this.setState({ viewOnState: viewOn });
            dispatch(ePageActions.getFolowers(viewOn, nickName ? nickName : user.username, user)) 
        }
    }

    hlandleSeguirODejarSeguir = data => (e) => {
        e.preventDefault();
        const { dispatch, user, viewOn, visitor } = this.props;
        dispatch(ePageActions.hlandleSeguirODejarSeguir(user, !data.sigo, data.nickname, viewOn, visitor))
    }

    render() {
        const { classes, user, loading, listFollowers, nameEspecialista, viewOn } = this.props;
        return (loading) 
            ? <Grid container spacing={24}>
                <Grid item xs={12} align="center">
                    <div className="cont-progress-comentary"><CircularProgress size={40} className={`ProgressAbsolut`} /></div>
                </Grid>
             </Grid>
            :(
            <Grid container spacing={16}>
                {listFollowers.length 
                    ? listFollowers.map(k =>{
                        return (
                            <Grid item xs={12} sm={6} key={k.keyd}>
                                <Paper className="paper paperSeguidores">
                                    <div className="cont-info-header">
                                        <Avatar alt={k.nom_seguidor} src={`${apiCardio.urlMedia}${k.img}`} className={classes.avatarSeguidor} />
                                        <div className="cont-text">
                                            <Typography component="p">{k.nom_seguidor}</Typography>
                                            <Typography component="span">{k.type_user}</Typography>
                                            {k.type_user === 'Especialista' &&
                                                <Typography component="p" className="truncate">{k.extracto}</Typography>
                                            }
                                        </div>
                                    </div>
                                    {k.type_user === 'Especialista' &&
                                        <div className="cont-action">
                                            {k.nickname === user.username
                                            ? <Typography component="p">{viewOn === 2 ? 'Sigues a '+nameEspecialista : nameEspecialista+' te sigue' }  </Typography>
                                                :<div>
                                                    <Link to={'/epage/' + k.nickname}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className="mr_1"
                                                        >
                                                            Perfil
                                                    </Button>
                                                    </Link>
                                                    <Button
                                                        variant="contained"
                                                        color={k.sigo ? 'primary' : null}
                                                        onClick={this.hlandleSeguirODejarSeguir(k)}
                                                        className={k.sigo ? null : classes.seguirTow}
                                                    >
                                                        {k.sigo ? 'Siguiendo' : 'Seguir'}
                                                    </Button>
                                                </div>
                                            }
                                            
                                        </div>
                                    }
                                </Paper>
                            </Grid>
                        )
                    })
                    : <Grid item xs={12}>
                        <Paper className="paper post">
                            <Typography>No se encontraron resultados.</Typography>
                        </Paper>
                    </Grid>
                }
                
                
            </Grid>
        );
    }
}

Seguidores.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { loading, nickName, viewOn, listFollowers, nameEspecialista, visitor } = state.ePage;
    return {
        user,
        loading, nickName, viewOn, listFollowers, nameEspecialista, visitor
    };
}

const connectedSeguidores = connect(mapStateToProps)(withStyles(styles)(Seguidores));
export { connectedSeguidores as Seguidores };