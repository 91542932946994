import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { history } from '../../../../../_helpers';

/* Layouts */
import { MenuEspecialistas } from '../../layout/MenuEspecialistas';
import ProgressCircular from '../../layout/progress/progressCircular';

/*Component */
import ModalPagar from "./modalPagar";
import AlertDialog from "../../layout/alertDialogo";
import CardInterconsultas from "./cardInterconsultas";
/* Material UI */
import '../index.css';
import { styles } from "../interConsultaStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from '@material-ui/core';

/* Actions */
import { interConsultaActions, alertConfirmActions, alertActions } from '../../../../../_actions'

class HomeMisInterConsultas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: 4,
            comprobante: '',
        };
        const { user, dispatch } = this.props;

        dispatch(alertConfirmActions.closeAlert())
        dispatch(interConsultaActions.viewPagar(false))
        dispatch(interConsultaActions.getMisInterconsulas(user));
        dispatch(interConsultaActions.getBanksCountry());
    }

    handleFiltarInterconsultas = data => () => {
        this.props.dispatch(interConsultaActions.updateFilter(data));
    }

    handeleActivitePaper(){
        const porPagar = this.props.list.filter(q => (q.status === '4')).length
        const aprobada = this.props.list.filter(q => (q.status === '1')).length
        const montoInsufi = this.props.list.filter(q => (q.status === '3')).length
        const porAprobacion = this.props.list.filter(q => (q.status === '2')).length
        if (porPagar > 0) {
            this.setState({filter: 4,})
            return 4
        } else if (porPagar === 0 && montoInsufi > 0) {
            this.setState({filter: 3,})
            return 3;
        } else if (porPagar === 0 && montoInsufi === 0 && porAprobacion > 0) {
            this.setState({filter: 2,})
            return 2
        } else if (porPagar === 0 && montoInsufi === 0 && porAprobacion === 0 && aprobada > 0) {
            this.setState({filter: 1,})
            return 1
        } else {
            this.setState({filter: 4,})
            return 4
        }
    }

    handleViewDetail = data => () =>{
        this.props.dispatch(interConsultaActions.seletInterconsulta(data));
        history.push('/detalle-interconsulta');
    }

    /* Handles para borrar cita */
    handleCloseAlert = () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };
    
    handleDeleteCita = data => () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        this.props.dispatch(interConsultaActions.seletInterconsulta(data));
    }

    handleDelateItem = () => {
        this.props.dispatch(alertConfirmActions.sumitedRequest())
        this.props.dispatch(interConsultaActions.deleteInterconsulta(this.props.interconsultaSelect))
    }
    /* Handles Para Burrar algun elemento */

    handleUpdateInter = data => () => {
        this.props.dispatch(interConsultaActions.seletInterconsulta(data));
        history.push('/editar-interconsulta');
    }

    handleConsultorio = data => () => {
        var a = document.createElement("a");
        a.href = data.consultorio;
        if (data.type_quotes !== '5') {
            a.target = "_blank";
        }
        a.click();
    }

    /**Pagar */
    handleViewPagar = data => () => {
        this.props.dispatch(interConsultaActions.seletInterconsulta(data));
        this.props.dispatch(interConsultaActions.viewPagar(true))
    }
    
    closeModalPagar = data => () => {
        this.props.dispatch(interConsultaActions.viewPagar(data))
        // this.props.dispatch(interConsultaActions.seletInterconsulta());
    }

    handleChangePago = data => event => {
        const { name, value } = event.target
        if (data === 'file') {
            this.setState({
                comprobante: event.target.files[0]
            })
        } else {
            this.props.dispatch(interConsultaActions.changeInpuPagos(name, value));
        }
    }

    handleSubmitPago = e => {
        e.preventDefault();
        const { interconsultaSelect, qoute, bancos, dispatch } = this.props
        const bank = bancos.filter(k => (k.way_to_pay_id === '' + interconsultaSelect.forma_pago_id))[0]
        if (this.state.comprobante.name) {
            if (qoute) {
                if (qoute.confirmacion !== '' && qoute.montopagar !== '' ) {
                    const datosPagar = {
                        cita: interconsultaSelect.var,
                        tipoPago: interconsultaSelect.forma_pago_id,
                        confirmacion: qoute.confirmacion,
                        montopagar: qoute.montopagar
                    }
                    dispatch(interConsultaActions.insertPago(datosPagar, this.state.comprobante, bank.banks_id));
                }else{
                    dispatch(alertActions.info('Id. de confirmacion y monto deben estar llenos.'))
                }
            }else{
                dispatch(alertActions.info('Id. de confirmacion y monto deben estar llenos.'))
            }
        } else {
            dispatch(alertActions.info('Debe adjuntar soporte de pago'))
        }
    }
    /**Pagar */

    render() {
        // const { filter} = this.state
        const { user, lisInter, openConfirm, loadingConfirm, submitedConfrim, loadingInter, filter, viewPagar, bancos, interconsultaSelect } = this.props
        return (
            <section className="body" ref={this.container}>
                {/* <Header titlePage='Inicio' /> */}
                <MenuEspecialistas />

                <div className="content sin-bar">
                    {loadingInter
                        ? <ProgressCircular />
                        : <div>
                            <Grid container className='cont-contador' spacing={24}>
                                <Grid item xs={6} sm={3} align='center'>
                                    <Paper className={`paper-count-interconsultas ${(1 === filter) ? 'active' : ''}`} onClick={this.handleFiltarInterconsultas(1)}>
                                        <Typography component='span'>Aprobadas</Typography>
                                        <Typography variant='h3'>{lisInter.length
                                            ? lisInter.filter(k => (k.status === '1')).length
                                            : 0
                                        }</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} sm={3} align='center'>
                                    <Paper className={`paper-count-interconsultas ${(2 === filter) ? 'active' : ''}`} onClick={this.handleFiltarInterconsultas(2)}>
                                        <Typography component='span'>Por aprobación</Typography>
                                        <Typography variant='h3'>{lisInter.length
                                            ? lisInter.filter(k => (k.status === '2')).length
                                            : 0
                                        }</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} sm={3} align='center'>
                                    <Paper className={`paper-count-interconsultas ${(3 === filter) ? 'active' : ''}`} onClick={this.handleFiltarInterconsultas(3)}>
                                        <Typography component='span'>Monto Insuficiente</Typography>
                                        <Typography variant='h3'>{lisInter.length
                                            ? lisInter.filter(k => (k.status === '3')).length
                                            : 0
                                        }</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} sm={3} align='center'>
                                    <Paper className={`paper-count-interconsultas ${(4 === filter) ? 'active' : ''}`} onClick={this.handleFiltarInterconsultas(4)}>
                                        <Typography component='span'>Por pagar</Typography>
                                        <Typography variant='h3'>{lisInter.length
                                            ? lisInter.filter(k => (k.status === '4')).length
                                            : 0
                                        }</Typography>
                                    </Paper>
                                </Grid>
                            </Grid>

                            <CardInterconsultas
                                lisInter={lisInter.filter(q => (q.status === filter.toString()))}
                                handleViewPagar={this.handleViewPagar}
                                handleViewDetail={this.handleViewDetail}
                                handleDeleteCita={this.handleDeleteCita}
                                handleUpdateInter={this.handleUpdateInter}
                                handleConsultorio={this.handleConsultorio}
                            />

                            <ModalPagar
                                user={user}
                                btnOff={true}
                                viewPagar={viewPagar}
                                qoute={interconsultaSelect}
                                closeDialog={this.closeModalPagar}
                                handleChangePago={this.handleChangePago}
                                handleSubmitPago={this.handleSubmitPago}
                                bancos={bancos.length && bancos.filter(k => (k.way_to_pay_id === interconsultaSelect.forma_pago_id))[0]}
                            />

                            <AlertDialog
                                open={openConfirm}
                                loading={loadingConfirm}
                                submitted={submitedConfrim}
                                procesar={this.handleDelateItem}
                                handleClose={this.handleCloseAlert}
                                message='¿Esta seguro de eliminar esta interconsulta?'
                            />
                        </div>
                    }
                    
                </div>
            </section>
        )
    }
}

HomeMisInterConsultas.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { lisInter, interconsultaSelect, loadingInter, filter, viewPagar, bancos, qoute } = state.interConsultaReducer;
    return {
        user,
        openConfirm, loadingConfirm, submitedConfrim,
        lisInter, interconsultaSelect, loadingInter, filter, viewPagar, bancos, qoute
    };
}

const connectedHomeMisInterConsultas = connect(mapStateToProps)(withStyles(styles)(HomeMisInterConsultas));
export { connectedHomeMisInterConsultas as HomeMisInterConsultas };