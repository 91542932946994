export const interConsultaConstants = {
    GET_REQUEST_INTER: 'GET_REQUEST_INTER',
    GET_SUCCESS_INTER: 'GET_SUCCESS_INTER',
    GET_FAILURE_INTER: 'GET_FAILURE_INTER',

    GET_SUCCESS_ESPECIALIDAD_INTER: 'GET_SUCCESS_ESPECIALIDAD_INTER',

    REQUEST_INTERCONSULTA: 'REQUEST_INTERCONSULTA',
    FAIL_SEARCH_ESPECIALISTA: 'FAIL_SEARCH_ESPECIALISTA',
    SUCCESS_SEARCH_ESPECIALISTA: 'SUCCESS_SEARCH_ESPECIALISTA',


    SUCCESS_GET_INFO_CITA: 'SUCCESS_GET_INFO_CITA',
    SUCCESS_GET_DAY_WORK: 'SUCCESS_GET_DAY_WORK',
    SELECT_ITEM_INTERCONSULTA: 'SELECT_ITEM_INTERCONSULTA',
    
    UPDATE__FILTER: 'UPDATE__FILTER',
    OPEN_CLOSE_DIALOG_HOUR: 'OPEN_CLOSE_DIALOG_HOUR',
    
    SELECT_HOUR_WORK: 'SELECT_HOUR_WORK',
    SUCCESS_INSERT_PAYMENT: 'SUCCESS_INSERT_PAYMENT',
    SUCCESS_GET_HOUR_WORK: 'SUCCESS_GET_HOUR_WORK',
    REQUEST_GET_HOUR_WORK: 'REQUEST_GET_HOUR_WORK',
    SUCCESS_GET_METHOD_PAYMENT: 'SUCCESS_GET_METHOD_PAYMENT',
    CHANGE_IMPUT: 'CHANGE_IMPUT',
    CHANGE_IMPUT_PAGO: 'CHANGE_IMPUT_PAGO',
    SUCCESS_GET_FORM_HISTORIA: 'SUCCESS_GET_FORM_HISTORIA',
    CHANGE_IMPUT_PARACLINICOS: 'CHANGE_IMPUT_PARACLINICOS',
    SUCCESS_GET_BANK: 'SUCCESS_GET_BANK',
    SUCCESS_INSERT_QOUTE: 'SUCCESS_INSERT_QOUTE',
    VIEW_PAYMEN: 'VIEW_PAYMEN',
    CLEAR_INFO_INTERCONSULTA: 'CLEAR_INFO_INTERCONSULTA',
    
    SUCESS_GET_MIS_INTERCONSULTAS: 'SUCESS_GET_MIS_INTERCONSULTAS',
    SELECT_INTERCONSULTA: 'SELECT_INTERCONSULTA',
    SUCESS_DELETE_INTERCONSULTA: 'SUCESS_DELETE_INTERCONSULTA',
    SUCESS_UPDATE_INTERCONSULTA: 'SUCESS_UPDATE_INTERCONSULTA',
    
    
};
