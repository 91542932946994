import React from 'react'

import PagarInterconsulta from "../solicitar/pagarInterconsulta";

/* MAterial UI */
// import { styles } from "../../direct/directStyle";
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';


const ModalPagar = (props) => (
    <Dialog
        maxWidth='md'
        fullWidth={true}
        open={props.viewPagar}
        onClose={props.closeDialog(!props.viewPagar)}
        aria-describedby="alert-dialog-description"
        // className="dialog-hour-inter"
    >
        <DialogContent className="dialog-content">
            <PagarInterconsulta
                user={props.user}
                qoute={props.qoute}
                bancos={props.bancos}
                btnOff={props.btnOff}
                handleChangePago={props.handleChangePago}
            />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={props.closeDialog(!props.viewPagar)}>
                Cencelar
            </Button>
            <Button variant="contained" color='primary' onClick={props.handleSubmitPago}>
                Enviar pago
            </Button>
        </DialogActions>
    </Dialog>
);

export default withStyles(styles)(ModalPagar);