import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
// import Popper from '@material-ui/core/Popper';
import { withStyles } from '@material-ui/core/styles';

/* Actions */
//import { ePageActions } from '../../../../../_actions'


function renderInputComponent(inputProps) {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.name_province, query);
    const parts = parse(suggestion.name_province, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) =>
                    part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </span>
                    ) : (
                            <strong key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </strong>
                        ),
                )}
            </div>
        </MenuItem>
    );
}

function getSuggestions(value, list) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;

    let count = 0;
    return inputLength === 0
        ? []
        : list.filter(suggestion => {
            const keep =
                count < 5 && suggestion.name_province.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
                count += 1;
            }

            return keep;
        });
}

function getSuggestionValue(suggestion) {
    return suggestion.name_province;
}

const styles = theme => ({
    root: {
        height: 250,
        flexGrow: 1,
    },
    container: {
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

class SugerenciaProvincia extends React.Component {
    state = {
        single: this.props.valor,
        popper: '',
        suggestions: [],
    };

    handleSuggestionsFetchRequested = ({ value }) => {
        const { list } = this.props
        this.setState({
            suggestions: getSuggestions(value, list),
        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = name => (event, { newValue }) => {
        this.setState({
            single: newValue,
        });
        this.props.handleChangeInput(name, newValue)
    };

    render() {
        const { classes, name,  } = this.props;

        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
        };

        return (
            <div>
                <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                        classes,
                        name: name,
                        required: true,
                        value: this.props.valor,
                        margin: "dense",
                        onChange: this.handleChange(name),
                        // onChange: handleChangeInput,
                    }}
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />
            </div>
        );
    }
}

SugerenciaProvincia.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SugerenciaProvincia);
