import React from 'react'

/* MAterial UI */
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, Typography, Paper, Divider, TextField, MenuItem,
    FormControl, Select, 
    FormLabel, Button,
    RadioGroup, InputLabel,
    FormControlLabel, Radio, CircularProgress
} from '@material-ui/core';


const FormPagoMenuItem = (data) => {
    if (data.select) {
        return (
            <MenuItem value={data.id} key={data.id}>{data.name}</MenuItem>
        )
    }
}

/* Iconos */
// import DoneIcon from '@material-ui/icons/Done';
var i = 0;
const InterconsultaEscrita = ({ classes, loadingInter, infoInterconsulta, paraclinicosFile, handleChangeInput, changeInputParaclinicos, handleProbando, handleSolicitarInterconsulta }) => (
    <Grid item xs={12}>
        <Paper className="paper">
            <div className="title-paper">
                <Typography color="primary" component="span" className="mb-06">Motivo de consulta</Typography>
                <Divider />
            </div>
            <div>
                <Typography component="label" className="obligatorio mt-1">*</Typography>
                <TextField
                    fullWidth
                    multiline
                    id="motivo"
                    rowsMax="5"
                    name="motivoCita"
                    className='pt-0'
                    placeholder="Escribe tú motivo"
                    value={infoInterconsulta.motivo}
                    onChange={handleChangeInput}
                />
            </div>
            <div className="mt-1">
                <Typography component="label" align="justify" color="textSecondary">
                    Si desea puede completar los siguientes campos o adjuntar algún documento, recuerde que no pueden enviar documentos con datos de pacientes (Nombre, apellido,
                    edad ni número de cédula o identidad) para proteger la privacidad de los pacientes.
                </Typography>
                <Grid container spacing={24} className="mt-1">
                    {infoInterconsulta.getForm.length
                        ? infoInterconsulta.getForm.map(k => {
                            i++;
                            handleProbando({ valor: i, indice: k.id })
                            switch (k.tipo) {
                                case 'select':
                                    return (
                                        <Grid item xs={12} sm={6} key={k.id}>
                                            <TextField
                                                select
                                                fullWidth
                                                id={`campo${k.id}`}
                                                name={`${k.id}`}
                                                label={k.name}
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            >
                                                {k.opciones.length &&
                                                    k.opciones.map(j => {
                                                        return (
                                                            <MenuItem value={j.valor} key={j.id}>{j.valor}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                    )
                                case 'select-multiple':
                                    return (
                                        <Grid item xs={12} sm={6} key={k.id} >
                                            <TextField
                                                fullWidth
                                                id={`campo${k.id}`}
                                                select
                                                multiple
                                                name={`${k.id}[]`}
                                                label={k.name}
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            >
                                                {k.opciones.length &&
                                                    k.opciones.map(j => {
                                                        return (
                                                            <MenuItem value={j.valor} key={j.id}>{j.valor}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </Grid>
                                    )
                                case 'textarea':
                                    return (
                                        <Grid item xs={12} sm={6} key={k.id}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                id={`campo${k.id}`}
                                                rowsMax="5"
                                                name={k.id}
                                                label={k.name}
                                                placeholder="Escribe tú motivo"
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            />
                                        </Grid>
                                    )
                                case 'fech':
                                    return (
                                        <Grid item xs={12} sm={6} key={k.id}>
                                            <TextField
                                                fullWidth
                                                id={`campo${k.id}`}
                                                name={k.id}
                                                label={k.name}
                                                type="date"
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    )
                                case 'number':
                                    return (
                                        <Grid item xs={12} sm={6} key={k.id}>
                                            <TextField
                                                fullWidth
                                                id={`campo${k.id}`}
                                                name={k.id}
                                                label={k.name}
                                                type="number"
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            />
                                        </Grid>
                                    )
                                case 'si_o_no':
                                    return (
                                        <Grid item xs={12} sm={6} key={k.id} >
                                            <FormControl component="fieldset" fullWidth>
                                                <FormLabel component="legend">{k.name}</FormLabel>
                                                <RadioGroup
                                                    className={classes.radioButtons}
                                                    name={k.id}
                                                    onChange={changeInputParaclinicos(k.tipo)}
                                                    value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                                >
                                                    <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    )
                                case 'file':
                                    return (
                                        <Grid item xs={12} sm={6} key={k.id}>
                                            <input
                                                accept="image/*, application/pdf"
                                                className={classes.input}
                                                id={`campo${k.id}`}
                                                type="file"
                                                name={k.id}
                                                onChange={changeInputParaclinicos(k.tipo)}
                                            //value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            />
                                            <label htmlFor={`campo${k.id}`} className="cont-label-file">
                                                <Button variant="contained" className="text-transform-initial" color="primary" component="span">{k.name}</Button>
                                                <Typography component="label" align="justify" color="textSecondary" className='truncate-text'>{paraclinicosFile[k.id] ? paraclinicosFile[k.id].name : 'Selecciona un documento o una imagen'}</Typography>
                                            </label>
                                        </Grid>
                                    )
                                default:
                                    return (
                                        <Grid item xs={12} sm={6} key={k.id}>
                                            <TextField
                                                fullWidth
                                                name={k.id}
                                                label={k.name}
                                                id={`campo${k.id}`}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                                onChange={changeInputParaclinicos(k.tipo)}
                                            />
                                        </Grid>
                                    )
                            }
                        })
                        : ''

                    }

                </Grid>

            </div>
            <div className="title-paper mt-1">
                <Typography color="primary" component="span" className="mb-06">Forma de pago</Typography>
                <Divider />
            </div>
            <div>
                <Grid container spacing={24} className="mt-1">
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="label_forma_pago"><b className="obligatorio">*</b> Forma de pago</InputLabel>

                            {infoInterconsulta.methoPayment.precioCita
                                ? <Select
                                    id="forma_pago"
                                    value={infoInterconsulta.formaPagoSelect}
                                    name='formaPagoSelect'
                                    onChange={handleChangeInput}
                                >
                                    {infoInterconsulta.methoPayment.formaPago &&
                                        infoInterconsulta.methoPayment.formaPago.filter(k => (k.id === '2')).length &&
                                        <MenuItem value="6">Tarjeta de débito/crédito</MenuItem>
                                    }
                                    {infoInterconsulta.methoPayment.formaPago
                                        ? infoInterconsulta.methoPayment.formaPago.map(k => {
                                            return (
                                                FormPagoMenuItem(k)
                                            )
                                        })
                                        : <MenuItem value="">Sin opciones disponibles</MenuItem>

                                    }
                                </Select>
                                : <Select
                                    id="forma_pago"
                                    value={infoInterconsulta.formaPagoSelect}
                                    name='formaPagoSelect'
                                    onChange={handleChangeInput}
                                >
                                    <MenuItem value={8}>Gratis</MenuItem>
                                </Select>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="labal_forma_costo"><b className="obligatorio">*</b> Costo de la cita</InputLabel>
                            {infoInterconsulta.methoPayment.precioCita
                                ?<Select
                                    id="costo"
                                    value={infoInterconsulta.precioSelect}
                                    name='precioSelect'
                                    onChange={handleChangeInput}
                                >
                                    {infoInterconsulta.methoPayment.precioCita
                                        ? infoInterconsulta.methoPayment.precioCita.map(k => {
                                            if ((infoInterconsulta.formaPagoSelect === '2' || infoInterconsulta.formaPagoSelect === '6')) {
                                                const comision = (parseFloat(k.monto) * 0.054) + 0.3
                                                return (
                                                    <MenuItem
                                                        key={k.id}
                                                        value={`${parseFloat(k.monto) + parseFloat(comision.toFixed(2))}, ${k.currency}`}
                                                    >
                                                        {k.monto} {k.name} + {comision.toFixed(2)}
                                                    </MenuItem>
                                                )
                                            } else {
                                                return (
                                                    <MenuItem
                                                        key={k.id}
                                                        value={`${parseFloat(k.monto)}, ${k.currency}`}
                                                    >
                                                        {k.monto} {k.name}
                                                    </MenuItem>
                                                )
                                            }

                                        })
                                        : <MenuItem value="">Sin opciones disponibles</MenuItem>

                                    }
                                </Select>
                                : <Select
                                    id="costo"
                                    value={infoInterconsulta.precioSelect}
                                    name='precioSelect'
                                    onChange={handleChangeInput}
                                >
                                    <MenuItem value={`0,4`}>0</MenuItem>
                                </Select>
                            }
                        </FormControl>
                    </Grid>
                    {(infoInterconsulta.formaPagoSelect === '2' || infoInterconsulta.formaPagoSelect === '6') &&
                        <Grid item xs={12}>
                            <Typography component="span" className="obligatorio">
                                Recuerde que PayPal cobra una comisión de 5.4% + 0.3 USD. Sumaremos esta cantidad para que recibamos el monto exacto de su interconsulta.
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={(infoInterconsulta.precioSelect !== '') && loadingInter}
                            onClick={handleSolicitarInterconsulta}
                        >
                            Solicitar interconsulta
                                {(infoInterconsulta.precioSelect !== '') && loadingInter && <CircularProgress size={24} className="ProgressAbsolut" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    </Grid>
)


export default withStyles(styles)(InterconsultaEscrita);