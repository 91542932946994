import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Actions */
import { ePageActions } from '../../../../_actions'

// /* MAterial UI */
import { styles } from "./homePageStyle";
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Icons */
import NavigateNextIcon from '@material-ui/icons/NavigateNext';



class BreadcrumbsDashboard extends Component {

    handleVolverAtras = data => (e) => {
        e.preventDefault();
        this.props.dispatch(ePageActions.mostrarViewEpage(data))
    }

    render() {
        return (
            <div className="breadcrumbs mb-06">
                <Typography color="primary" className="cursor-pointer" onClick={this.handleVolverAtras(1)}>
                    Ver publicaciones
                </Typography>
                <NavigateNextIcon color="primary" />
                <Typography color="secondary">{this.props.title}</Typography>
            </div>
        )
    }
}

BreadcrumbsDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user,
    };
}

const connectedBreadcrumbsDashboard = connect(mapStateToProps)(withStyles(styles)(BreadcrumbsDashboard));
export { connectedBreadcrumbsDashboard as BreadcrumbsDashboard };