import React from 'react'

/* Compoments */
import Chips from "../../layout/chips";

/* MAterial UI */
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Divider } from '@material-ui/core';


/* Iconos */
// import DoneIcon from '@material-ui/icons/Done';

const EspecialidadYModalidad = ({ infoInterconsulta, handleSelectChip }) => (
        <Grid item xs={12}>
            <Paper className="paper">
                <div className="title-paper">
                    <Typography color="primary" component="span" className="mb-06"> 
                        {infoInterconsulta.especialidades.length > 1 &&
                            <b className="obligatorio">*</b>
                        }
                        Selecciona una especialidad
                        </Typography>
                    <Divider />
                </div>
                <div>
                    <Chips
                        list={infoInterconsulta.especialidades}
                        handleSelect={handleSelectChip}
                    />
                </div>
                <div className="title-paper">
                    <Typography color="primary" component="span" className="mb-06">
                        {infoInterconsulta.modalidad_cita.length > 1 &&
                            <b className="obligatorio">*</b>
                        }
                        Selecciona la modalidad de la interconsulta que prefieras
                    </Typography>
                    <Divider />
                </div>
                <div>
                    <Chips
                        list={infoInterconsulta.modalidad_cita}
                        handleSelect={handleSelectChip}
                    />
                </div>
            </Paper>
        </Grid>
)


export default withStyles(styles)(EspecialidadYModalidad);