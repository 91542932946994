import { configConstants } from '../../_constants';

const initialState = {
    horarioMedico: [],
    diasInactivos: [],
    loading: false,
    preloaderHorario: true,
};

export function horarioReducer(state = initialState, action) {
    switch (action.type) {
        case configConstants.GET_HORARIO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case configConstants.GET_HORARIO:
            return Object.assign({}, state, {
                horarioMedico: action.data.horarioMedico,
                diasInactivos: action.data.diaInactivo,
                preloaderHorario: false,
            })
        case configConstants.SELECT_HORARIO:
            return Object.assign({}, state, {
                horarioMedico: action.data,
                loading: false
            })
        case configConstants.REGIS_HORARIO_REQUEST:
            return Object.assign({}, state, {
                loading: action.data,
                submitted: action.data,
            })
        case configConstants.SUMBITED_HORARIO_REQUEST:
            return Object.assign({}, state, {
                loading: false,
                submitted: action.data,
            })

        case configConstants.INACTIVAR_DIA_ESPECIALISTA:
            return {
                ...state,
                horarioMedico: state.horarioMedico,
                diasInactivos: action.data,
                loading: false,
                submitted: false,
            };  
        case configConstants.ACTIVAR_DIA_ESPECIALISTA:
            return Object.assign({}, state, {
                diasInactivos: state.diasInactivos.filter(fecha => fecha.fech_inactive !== action.data),
                loading: false,
                submitted: false,
            }) 
        default:
            return state
    }
}