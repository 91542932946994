import React from 'react'
import { Link } from 'react-router-dom'

/* MAterial UI */
import { styles } from '../headerStyle'
import './menu.css';
import { Grid, Fade, Paper, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';

/* Iconos */
const MenuNotify = (props) => (
    <Fade in={props.isMenuOpen}>
        <div className={`menu-notify ${props.isMenuOpen ? '' : 'dsp-none'}`}>
            <Paper className="cont-menu-notify">
                <div className="title">
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography component="span">{props.type.name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {props.type.type === '1' &&
                                <Button color="primary" className={props.classes.buttonActionNotify} onClick={props.handleMarcarVistoNotificaciones}>
                                    Marcar todo como leído
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </div>
                <div className="cont-info-notify">
                    {props.list.length
                        ? props.list.map(k => {
                            if(k.rut_view === ''){
                                return(
                                    <Grid container className={`info-notify ${k.view === '0' ? 'sinleer':'' }`} key={k.keyd}>
                                        <Grid item xs={10}>
                                            <Typography color="primary">{k.title}</Typography>
                                        </Grid>
                                        <Grid item xs={2} className="fecha">
                                            {k.fech_reg}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {k.description}
                                        </Grid>
                                    </Grid>
                                )
                            } else {
                                return (
                                    <Link to={k.rut_view} key={k.keyd}>
                                        <Grid container className={`info-notify ${k.view === '0' ? 'sinleer' : ''}`}>
                                            <Grid item xs={10}>
                                                <Typography color="primary">{k.title}</Typography>
                                            </Grid>
                                            <Grid item xs={2} className="fecha">
                                                {k.fech_reg}
                                            </Grid>
                                            <Grid item xs={10} className={props.classes.descriptionNotyfy}>
                                                {k.description}
                                            </Grid>
                                            <Grid item xs={2} className="fecha">
                                                <VisibilityIcon className={props.classes.iconVisible}/>
                                            </Grid>
                                        </Grid>
                                    </Link>
                                )
                            }
                        })
                        : <Grid container className="info-notify">
                            <Grid item xs={12}>
                                No posee {props.type.name}
                            </Grid>
                        </Grid>
                    }
                </div>
                
            </Paper>
        </div>
    </Fade>
)

export default withStyles(styles)(MenuNotify);