import React from 'react'

/* MAterial UI */
// import { styles } from "../../direct/directStyle";
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, Button, CircularProgress, Typography } from '@material-ui/core';


const DialogHorario = ({ classes, openDialog, closeDialog, hour, loadingInter, handleSelectHora }) => (
    <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={openDialog}
        onClose={closeDialog(!openDialog)}
        aria-describedby="alert-dialog-description"
        className="dialog-hour-inter"
    >
        <DialogTitle id="alert-dialog-title" className={`colorTitle title-dialog-hour`}>
            Horario disponible para el día seleccionado
        </DialogTitle>
        <DialogContent className="dialog-content">
            <Grid container >
                {loadingInter 
                    ? <Grid item xs={12} align='center' className="loading-hour"><CircularProgress size={50} className="ProgressAbsolut" /></Grid>
                    : hour.length
                        ? hour.map(k => {
                               // const styles = k.disponible === 'Disponible' ? 'disponible' : 'sin-disponibilidad'
                            const select = k.disponible === 'disponible' ? k : ''
                            return (
                                <Grid item xs={12} key={k.attention_id} className={`cont-hour-dialog-inter ${k.disponible}`} onClick={handleSelectHora(select)}>
                                    <Typography component="span" color='primary' align='center'>{k.attencion}</Typography>
                                    <Typography component="span" color='primary' align='center'>{k.disponible === 'disponible' ? 'Disponible' : 'No disponible'}</Typography>
                                </Grid>
                            )
                        })
                        : <Grid item xs={12} >
                            <Typography component="span" color='primary' align='center'>Horario no disponible</Typography>
                        </Grid>
                }
               

            </Grid>
        </DialogContent>
        <DialogActions className={classes.btnAction}>
            <Button variant="contained" onClick={closeDialog(!openDialog)}>
                Cencelar
            </Button>
        </DialogActions>
    </Dialog>
);

export default withStyles(styles)(DialogHorario);