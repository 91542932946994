import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import { MenuEspecialistas } from '../layout/MenuEspecialistas';
import ProgressCircular from '../layout/progress/progressCircular';

/* Material UI */
// import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

/*Components */

import PaginacionPagos from "./paginacionPagos";

/* Actions */
import { citasActions } from '../../../../_actions'

/* Estilos con Material UI */
import { styles } from "./pagosStyles";

class HomePagos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
        };
        this.props.dispatch(citasActions.getCitasPagadas(this.props.user))
    }

    handleChangeExpanded = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    }


    render() {
        const { expanded } = this.state;
        const { loadingCita, citas} = this.props;
        return (
            <section className="body" ref={this.container}>
                <MenuEspecialistas />
                <div className="content">
                    {loadingCita
                        ? <ProgressCircular />
                        : <PaginacionPagos
                            itemsperpage={10}
                            nocolumns={4}
                            items={citas}
                            pagesspan={4}
                            expanded={expanded}
                            handleChangeExpanded={this.handleChangeExpanded}
                        />
                    }
                </div>
            </section>
        )
    }
}

HomePagos.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { loadingCita, citas } = state.citasReducer;
    return {
        user,
        loadingCita, citas
    };
}

const connectedHomePagos = connect(mapStateToProps)(withStyles(styles)(HomePagos));
export { connectedHomePagos as HomePagos };