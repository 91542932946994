import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

/* Estilos con Material UI */
import { styles } from "./horarioAtencionStyle";

/* Actions */
import { horarioActions } from '../../../../../_actions'

/*Components */
import HorasParaAtencion from "./horasParaAtencion";
import Chips from "../../layout/chips";
import Calendar from "../../layout/calendarioComponent";

class HorarioAtencion extends Component {
    constructor(props) {
        super(props)
        this.handleSelectDia = this.handleSelectDia.bind(this)
        this.handleSelectHora = this.handleSelectHora.bind(this)
        this.handleOnSubmitHora = this.handleOnSubmitHora.bind(this)
    }
    
    handleSelectDia= data => () =>{
        const { user, horarioMedico, dispatch } = this.props;
        dispatch(horarioActions.selectDiaTrabajo(data, horarioMedico, user))
    }


    handleSelectHora = data => (e, id) => {
        const { name, value } = e.target;
        const { horarioMedico, dispatch } = this.props;
        dispatch(horarioActions.horarioTrabajo(data, name, value, horarioMedico))

    };

    handleOnSubmitHora(e){
        e.preventDefault();
        const { user, horarioMedico, dispatch } = this.props;
        dispatch(horarioActions.regisHorarioEspecialista(user, horarioMedico))
    }
    
   
    render() {
        const { user, classes, horarioMedico, diasInactivos, loading, submitted, dispatch } = this.props
        return(
            <Grid container spacing={24} direction="column">
                <Grid item xs={12} className="pb-0">
                    <Typography color="primary" component="span" > Horario de atención</Typography>
                    
                </Grid>
                <Grid item xs={12}>
                    <Paper className="paper">
                        <div className="title-paper">
                            <Typography color='primary' component="span" className="mb-06">
                                Selecciona los dias que estarás disponible <span className="obligatorio">*</span>
                            </Typography>
                            <Divider />
                            <br/>
                            <Typography color="textSecondary" className="color-gris" component="span" >Cuando le hayan solicitado una cita y sea aprobada por la administración, recibirá una notificación a su cuenta y un correo electrónico con los detalles de la cita.</Typography>
                        </div>
                        <div >
                            <Chips
                                list={horarioMedico}
                                handleSelect={this.handleSelectDia}
                            />
                            <HorasParaAtencion
                                list={horarioMedico}
                                loading={loading}
                                submitted={submitted}
                                handleSelectHora={this.handleSelectHora}
                                handleOnSubmitHora={this.handleOnSubmitHora}
                            />
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className="paper">
                        <div className="title-paper mb-06">
                            <Typography color='primary' component="span" className="mb-06">
                                Marca el dia que no estarás disponible
                            </Typography>
                            <Divider />
                        </div>
                        <div className={`${classes.calendario}`}>
                            <Calendar
                                dispatch={dispatch}
                                list={horarioMedico}
                                fechas={diasInactivos}
                                user={user}
                             />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}


HorarioAtencion.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { horarioMedico, loading, submitted, diasInactivos } = state.horarioReducer;
    return {
        user,
        loading,
        submitted,
        diasInactivos,
        horarioMedico,
    };
}

const connectedHorarioAtencion = connect(mapStateToProps)(withStyles(styles)(HorarioAtencion));
export { connectedHorarioAtencion as HorarioAtencion };
