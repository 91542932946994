import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import { BarRigth } from "../layout/barRight";
import { MenuEspecialistas } from '../layout/MenuEspecialistas';
import ProgressCircular from '../layout/progress/progressCircular';

/* Material UI */
import { Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

/* Icons */
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/*Components */
import ListCitasChat from "./listCitaschat";
import {DetalleAsesoriaChat} from "./detalleAsesoriaChat";

/* Actions */
import { citasChatActions, menuActions } from '../../../../_actions'

/* Estilos con Material UI */
import { styles } from "./asesoriaChatStyle";

class HomeAsesoriaViaChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
        };
        this.props.dispatch(menuActions.menuActive(5));
        this.props.dispatch(citasChatActions.mostrasView(false))
        this.props.dispatch(citasChatActions.getCitasViaChat(this.props.user))
    }

    handleVerHilo = data => (event) => {
        event.preventDefault();
        this.props.dispatch(citasChatActions.getYarnCitasChat(this.props.user, data))
    }

    handleView = data => (e) => {
        e.preventDefault();
        this.props.dispatch(citasChatActions.mostrasView(data))
    }

    render() {
        const { user, loadingCitaChat, citasChat, viewDetail } = this.props;
        return (
            <section className="body" ref={this.container}>
                <MenuEspecialistas />
                <BarRigth
                    asesoriaText={true}
                />
                <div className="content">
                    {loadingCitaChat
                        ? <ProgressCircular />
                        : !viewDetail
                            ? <ListCitasChat
                                user={user}
                                list={citasChat}
                                handleVerHilo={this.handleVerHilo}
                            />
                            :<div>
                                <div className="breadcrumbs mb-06">
                                    <Typography color="primary" className="cursor-pointer" onClick={this.handleView(false)}>
                                        Ir a consultas 
                                    </Typography>
                                    <NavigateNextIcon color="primary" />
                                    <Typography color="secondary">Detalle de la consulta</Typography>
                                </div>
                                <DetalleAsesoriaChat/>
                            </div>
                    }
                </div>
            </section>
        )
    }
}

HomeAsesoriaViaChat.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { loadingCitaChat, citasChat, viewDetail } = state.citasChatReducer;
    return {
        user,
        loadingCitaChat, citasChat, viewDetail
    };
}

const connectedHomeAsesoriaViaChat = connect(mapStateToProps)(withStyles(styles)(HomeAsesoriaViaChat));
export { connectedHomeAsesoriaViaChat as HomeAsesoriaViaChat };