import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import dateFns from "date-fns";

/* Layouts */
import { MenuEspecialistas } from '../../layout/MenuEspecialistas';

/*Component */
import DialogHorario from "../solicitar/dialogHorario";
import Calendar from "../../layout/calendarioComponent";

/* Material UI */
import '../index.css';
import { styles } from "../interConsultaStyle";
import { withStyles } from "@material-ui/core/styles";
import {
    Grid, Typography, Paper, Divider, TextField, MenuItem,
    FormControl,
    FormLabel, Button,
    RadioGroup, CircularProgress,
    FormControlLabel, Radio} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/* Actions */
import { interConsultaActions, alertActions } from '../../../../../_actions'

// Require Esperanto locale
var esLocale = require('date-fns/locale/es');
var i = 0;
class EditarInterconsulta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paraclinicosFile: [],
            comprobante: '',
        };
        //const { user, dispatch } = this.props;
        this.props.dispatch(interConsultaActions.getDayWork(this.props.user, this.props.interconsultaSelect.varmed));
        this.props.dispatch(interConsultaActions.detalleEditQuotes(this.props.interconsultaSelect));
        if (!this.props.interconsultaSelect.var) {
            window.location.href = '/mis-interconsultas'
        }       
    }

    handleChangeInput = (e) =>{
        const { name, value } = e.target
        this.props.dispatch(interConsultaActions.changeInput(name, value));
    }

    handleSelectFech = data => {
        const { user, dispatch, interconsultaSelect } = this.props
        dispatch(interConsultaActions.getHorasDisponibles(user, data, interconsultaSelect.medico))
    }
    changeInputParaclinicos = data => event => {
        const { name, value } = event.target
        if (data === 'file') {
            this.setState({
                paraclinicosFile: {
                    ...this.state.paraclinicosFile,
                    [name]: event.target.files[0]
                }
            })
        } else {
            this.setState({
                paraclinicosFile: {
                    ...this.state.paraclinicosFile,
                    [name]: value
                }
            })
            //this.props.dispatch(interConsultaActions.changeInputParaclinicos(name, value));
        }
    };

    handleSelectHora = data => () => {
        if (data) {
            this.props.dispatch(interConsultaActions.selectHour(data))
        }
    }

    handleOpenAndCloseDialog = data => () => {
        this.props.dispatch(interConsultaActions.openCloseDialog(data))
    }

    handleProbando = data => {
        if (this.props.interconsultaSelect.form) {
            if (data.valor <= this.props.interconsultaSelect.form.length) {
                this.props.dispatch(interConsultaActions.changeInputParaclinicos('campo' + data.valor, data.indice));
            }
        }
    }

    handleEditarInterconsulta = e => {
        e.preventDefault();
        const { dispatch, infoInterconsulta, interconsultaSelect, user } = this.props
        const v5 = (infoInterconsulta.motivoCita !== '') ? true : false
        if (v5 ) {
            const data = {
                "paraClinicosText": infoInterconsulta.paraClinicos,
                "paraClinicosFile": this.state.paraclinicosFile,
                "medicoslect": interconsultaSelect.medico,
                "form": interconsultaSelect.form,
                "forma_pago": 0,
                "fechacita": infoInterconsulta.daySelect.fech ? infoInterconsulta.daySelect.fech : interconsultaSelect.infocita.fech_quotes,
                "horacita": infoInterconsulta.hourSelect.attention_id ? infoInterconsulta.hourSelect.attention_id : interconsultaSelect.infocita.idHora,
                "campos": interconsultaSelect.form.length,
                "motivo": infoInterconsulta.motivoCita,
                "cita": interconsultaSelect.var,
            }
            dispatch(interConsultaActions.editCita(user, data))
        } else {
            dispatch(alertActions.info('Los campos con (*) son obligatorios'))
        }
    }

    render() {
        const { paraclinicosFile } = this.state
        const { classes, dispatch, interconsultaSelect, infoInterconsulta, loadingInter, dialogHour } = this.props
        return (
            <section className="body" ref={this.container}>
                {/* <Header titlePage='Inicio' /> */}
                <MenuEspecialistas />

                <div className="content sin-bar">
                    <div className="breadcrumbs mb-06">
                        <Link to={'/mis-interconsultas'}>
                            <Typography color="primary" className="cursor-pointer">Regresar a mis interconsultas</Typography>
                        </Link>
                        <NavigateNextIcon color="primary" />
                        <Typography color="secondary">Editar interconsulta </Typography>
                    </div>

                    <Grid container className='cont-contador' spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <Paper className="paper">
                                <div className="title-paper">
                                    <Typography color="primary" component="span" className="mb-06">Motivo de consulta</Typography>
                                    <Divider />
                                </div>
                                <div>
                                    <TextField
                                        fullWidth
                                        multiline
                                        id="motivo"
                                        rowsMax="5"
                                        name="motivoCita"
                                        className='pt-0'
                                        placeholder="Escribe tú motivo"
                                        value={infoInterconsulta.motivoCita}
                                        onChange={this.handleChangeInput}
                                    />
                                </div>
                                <div className="mt-1">
                                    {interconsultaSelect.form
                                        ? interconsultaSelect.form.map(k => {
                                            i++;
                                            this.handleProbando({ valor: i, indice: k.id, requisitoLleno: k.requisitoLleno })
                                            switch (k.tipo) {
                                                case 'select':
                                                    return (
                                                        <div key={k.id} className="mb-06">
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                id={`campo${k.id}`}
                                                                name={`${k.id}`}
                                                                label={k.name}
                                                                onChange={this.changeInputParaclinicos(k.tipo)}
                                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : k.requisitoLleno}
                                                            >
                                                                {k.opciones.length &&
                                                                    k.opciones.map(j => {
                                                                        return (
                                                                            <MenuItem value={j.valor} key={j.id}>{j.valor}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </TextField>
                                                        </div>
                                                    )
                                                case 'select-multiple':
                                                    return (
                                                        <div key={k.id} className="mb-06">
                                                            <TextField
                                                                fullWidth
                                                                id={`campo${k.id}`}
                                                                select
                                                                multiple
                                                                name={`${k.id}[]`}
                                                                label={k.name}
                                                                onChange={this.changeInputParaclinicos(k.tipo)}
                                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : k.requisitoLleno}
                                                            >
                                                                {k.opciones.length &&
                                                                    k.opciones.map(j => {
                                                                        return (
                                                                            <MenuItem value={j.valor} key={j.id}>{j.valor}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </TextField>
                                                        </div>
                                                    )
                                                case 'textarea':
                                                    return (
                                                        <div key={k.id}>
                                                            <TextField
                                                                fullWidth
                                                                multiline
                                                                id={`campo${k.id}`}
                                                                rowsMax="5"
                                                                name={k.id}
                                                                label={k.name}
                                                                placeholder="Escribe tú motivo"
                                                                onChange={this.changeInputParaclinicos(k.tipo)}
                                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : k.requisitoLleno}
                                                            />
                                                        </div>
                                                    )
                                                case 'fech':
                                                    return (
                                                        <div key={k.id} className="mt-1 mb-06">
                                                            <TextField
                                                                fullWidth
                                                                id={`campo${k.id}`}
                                                                name={k.id}
                                                                label={k.name}
                                                                type="date"
                                                                onChange={this.changeInputParaclinicos(k.tipo)}
                                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : k.requisitoLleno}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                case 'number':
                                                    return (
                                                        <div key={k.id}>
                                                            <TextField
                                                                fullWidth
                                                                id={`campo${k.id}`}
                                                                name={k.id}
                                                                label={k.name}
                                                                type="number"
                                                                onChange={this.changeInputParaclinicos(k.tipo)}
                                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : k.requisitoLleno}
                                                            />
                                                        </div>
                                                    )
                                                case 'si_o_no':
                                                    return (
                                                        <div key={k.id} className="mt-1">
                                                            <FormControl component="fieldset" fullWidth>
                                                                <FormLabel component="legend">{k.name}</FormLabel>
                                                                <RadioGroup
                                                                    className={classes.radioButtons}
                                                                    name={k.id}
                                                                    onChange={this.changeInputParaclinicos(k.tipo)}
                                                                    value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : k.requisitoLleno}
                                                                >
                                                                    <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                    )
                                                case 'file':
                                                    return (
                                                        <div key={k.id} className="mt-1">
                                                            <input
                                                                accept="image/*, application/pdf"
                                                                className={classes.input}
                                                                id={`campo${k.id}`}
                                                                type="file"
                                                                name={k.id}
                                                                onChange={this.changeInputParaclinicos(k.tipo)}
                                                            //value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : k.requisitoLleno}
                                                            />
                                                            {/* <label htmlFor={`campo${k.id}`}>
                                                                <Button variant="contained" color="primary" component="span">{k.name}</Button>
                                                            </label> */}
                                                            <Typography></Typography>
                                                            <label htmlFor={`campo${k.id}`} className="cont-label-file">
                                                                <Button variant="contained" className="text-transform-initial" color="primary" component="span">{k.name}</Button>
                                                                <Typography component="label" align="justify" color="textSecondary" className='truncate-text'>{k.requisitoLleno}</Typography>
                                                            </label>
                                                        </div>
                                                    )
                                                default:
                                                    return (
                                                        <div key={k.id}>
                                                            <TextField
                                                                fullWidth
                                                                name={k.id}
                                                                label={k.name}
                                                                className="mb-06"
                                                                id={`campo${k.id}`}
                                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : k.requisitoLleno}
                                                                onChange={this.changeInputParaclinicos(k.tipo)}
                                                            />
                                                        </div>
                                                    )
                                            }
                                        })
                                        : ''
                                    }
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Paper className="paper">
                                <div className="title-paper">
                                    <Typography color="primary" component="span" className="mb-06">Selecciona una fecha en el calendario</Typography>
                                    <Divider />
                                </div>
                                <div>
                                    <Calendar
                                        dispatch={dispatch}
                                        interconsulta={true}
                                        list={infoInterconsulta.dayWork}
                                        handleSelectFech={this.handleSelectFech}
                                    />
                                </div>
                                <div className="mt-1">
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography component="label" className={classes.labelInterconsulta}><b className="obligatorio">*</b> Fecha</Typography>
                                            <Typography component="p" className={classes.borderBotonP}>
                                                {infoInterconsulta.daySelect.fech
                                                    ? dateFns.format(infoInterconsulta.daySelect.fech, 'D [de] MMMM / YYYY', { locale: esLocale })
                                                    : interconsultaSelect.infocita ? interconsultaSelect.infocita.fecha : ''
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography component="label" className={classes.labelInterconsulta}><b className="obligatorio">*</b> Hora</Typography>
                                            <Typography component="p" className={classes.borderBotonP}>
                                                {infoInterconsulta.hourSelect.attencion 
                                                    ? infoInterconsulta.hourSelect.attencion 
                                                    : interconsultaSelect.infocita ? interconsultaSelect.infocita.hour_attention : ''
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography component="label" align="justify" color="textSecondary">
                                                Estimado usuario en caso de que el profesional seleccionado tenga alguna emergencia el dia de su interconsulta y no
                                                 pueda ser atendido se reagendara la interconsulta automaticamente a la fecha más cercana disponible
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} align="center">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={loadingInter}
                                                onClick={this.handleEditarInterconsulta}
                                            >
                                                Editar interconsulta
                                                {(infoInterconsulta.precioSelect !== '') && loadingInter && <CircularProgress size={24} className="ProgressAbsolut" />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <DialogHorario
                        openDialog={dialogHour}
                        loadingInter={loadingInter}
                        hour={infoInterconsulta.hourWork}
                        handleSelectHora={this.handleSelectHora}
                        closeDialog={this.handleOpenAndCloseDialog}
                    />
                </div>
            </section>
        )
    }
}

EditarInterconsulta.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { interconsultaSelect, infoInterconsulta, loadingInter, dialogHour } = state.interConsultaReducer;
    return {
        user,
        interconsultaSelect, infoInterconsulta, loadingInter, dialogHour
    };
}

const connectedEditarInterconsulta = connect(mapStateToProps)(withStyles(styles)(EditarInterconsulta));
export { connectedEditarInterconsulta as EditarInterconsulta };