import React from 'react'

/* MAterial UI */
import { Grid, Paper, Typography, Divider, TextField, InputAdornment } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import SearchIcon from '@material-ui/icons/Search';

/* Component */
import Chips from '../../layout/chips'

/* Estilos con Material UI */
import { styles } from "../configuracionStyle";

const Especialidades = (props) => (
    <Paper className="paper">
        <div className="title-paper">
            <Typography color="primary" className="mb-06" component="span">Selecciona tu especialidad</Typography>
            <Divider />
        </div>
        <div>
            <Grid container>
                <Grid item xs={12} className={props.classes.contCardBody}>
                    <TextField
                        id="filterEspecialidad"
                        name="filterEspecialidad"
                        label="Escribe una especialidad"
                        className={props.classes.margin}
                        onChange={props.handleFilterEspecialidad}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} className={props.classes.contCardBody}>
                    <div className="mt-1">
                        <Chips
                            list={props.especialidades}
                            handleSelect={props.handleSelect}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    </Paper>
)


export default withStyles(styles)(Especialidades);