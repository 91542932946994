import React from 'react'

/* MAterial UI */
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Button, TextField, CircularProgress } from '@material-ui/core';


/* Iconos */
// import DoneIcon from '@material-ui/icons/Done';

const PagarInterconsulta = ({ classes, user, qoute, comprobante, bancos, loading, handleSubmitPago, handleChangePago, btnOff  }) => (
    <Grid container spacing={24}>
        <Grid item xs={12} sm={4}>
            <Paper className="paper paper-pagar-interconsulta">
                <div className="title-paper one">
                    <Typography component="span" className="mb-06">{bancos.name}</Typography>
                </div>
                <div className='body-paper-pagar-interconsulta'>
                    <article>
                        <img src={`https://ecardioline.com/assets_media/bancos/${bancos.image}`} alt={bancos.name} />
                    </article>
                    <Typography component="label">Empresa</Typography>
                    <Typography component="p" className="mb-06">{bancos.agent}</Typography>
                    <Typography component="label">Correo afiliado</Typography>
                    <Typography component="p" className="mb-06">{bancos.email}</Typography>
                </div>
            </Paper>
        </Grid>

        {console.log(qoute)}

        <Grid item xs={12} sm={8}>
            <Paper className="paper paper-pagar-interconsulta">
                <div className="title-paper two">
                    <Typography color="primary" component="span" className="mb-06">Datos del Pago</Typography>
                </div>
                <div className='body-paper-pagar-interconsulta'>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled
                                fullWidth
                                name='user'
                                id='usuario'
                                label='Especialista'
                                value={user.name+''+user.surname}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled 
                                fullWidth
                                id='monto'
                                name='monto'
                                label='Monto a pagar'
                                value={(qoute.monto || qoute.costo) +' '+qoute.moneda}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                name='confirmacion'
                                label='Id. de Confirmación'
                                id='idConfir'
                                value={qoute ? qoute.confirmacion : ''}
                                onChange={handleChangePago('text')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id='montopagar'
                                name='montopagar'
                                label='Monto'
                                value={qoute ? qoute.montopagar : ''}
                                onChange={handleChangePago('text')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                accept="image/*, application/pdf"
                                className={classes.input}
                                id='comprobante'
                                type="file"
                                name='comprobante'
                                onChange={handleChangePago('file')}
                                //value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                            />
                            <label htmlFor='comprobante'>
                                <Button variant="contained" className="text-transform-initial" color="primary" component="span">Adjuntar soporte de pago</Button>
                            </label>
                            <Typography className='truncate-text' color='secondary'>
                                {!comprobante ? 'Seleccione una soporte.' : comprobante.name}
                            </Typography>
                        </Grid>
                        {!btnOff &&
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={loading}
                                    onClick={handleSubmitPago}
                                >
                                    Enviar pago
                                    {loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </div>
            </Paper>
        </Grid>

    </Grid>
)


export default withStyles(styles)(PagarInterconsulta);