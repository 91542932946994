// const drawerWidth = 240;
export const styles = theme => ({
    paper: {
        color: theme.palette.text.secondary,
    },
    calendario:{
        paddingTop:12,
        paddingBottom: 16,
    },
    contDiasHorario:{
        width: '14.2857%',
        display: 'inline-block'
    },
    headerHorario:{
        padding: '.2rem',
        border: '1px solid #DAD9D6',
        backgroundColor: theme.palette.primary.main,
    },
    cont_horas:{
        padding: 3
    },
    contHorarioDesdeHasta:{
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            paddingTop: '.8rem',
            paddingBottom: '.8rem',
        },
        [theme.breakpoints.up("sm")]: {
            width: '50%',
            paddingBottom: '.8rem',
            display: 'inline-block'
        },
    },
    formControl:{
        width: '90%',
    },
    text_white:{
        color: "#fff",
    },
    p1: {
        padding: '1rem'
    },
})