import { interConsultaConstants } from '../../_constants';

const initialState = {
    qoute:{},
    bancos:[],
    medicos: [],
    lisInter:[],
    message: '',
    viewPagar: false,
    dialogHour: false,
    submitCita: false,
    especialidades: [],
    loadingInter: false,
    preloaderInter: true,
    interconsultaSelect:[],
    infoInterconsulta: {
        medic: '',
        getForm:[],
        hourWork: [],
        daySelect:[],
        motivoCita:'',
        ubicacion: '',
        hourSelect:[],
        precioSelect:'',
        methoPayment:[],
        paraClinicos: [],
        especialidades:[],
        formaPagoSelect:'',
        modalidad_cita: [],
    }
};

export function interConsultaReducer(state = initialState, action) {
    switch (action.type) {
        case interConsultaConstants.OPEN_CLOSE_DIALOG_HOUR:
            return Object.assign({}, state, {
                dialogHour: action.data,
            });
        case interConsultaConstants.GET_SUCCESS_INTER:
            return Object.assign({}, state, {
                preloaderInter: false,
                loadingInter: false,
                medicos: action.data
            });
        case interConsultaConstants.GET_FAILURE_INTER:
            return Object.assign({}, state, {
                preloaderInter: false
            });
        case interConsultaConstants.GET_SUCCESS_ESPECIALIDAD_INTER:
            return Object.assign({}, state, {
                especialidades: action.data
            });
        case interConsultaConstants.REQUEST_INTERCONSULTA:
            return Object.assign({}, state, {
                loadingInter: true
            });
        case interConsultaConstants.SUCCESS_SEARCH_ESPECIALISTA:
            return Object.assign({}, state, {
                medicos: action.data,
                loadingInter: false
            });
        case interConsultaConstants.SUCCESS_GET_INFO_CITA:
            return Object.assign({}, state, {
                loadingInter: false,
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    medic: action.data.medic,
                    ubicacion: action.data.ubicacion,
                    modalidad_cita: action.data.modalidad_cita.length >= 2 
                        ? action.data.modalidad_cita.map(k => { k.selected = ''; return k })
                        : action.data.modalidad_cita, 
                    especialidades: action.data.especialidades.length === 1 
                        ? action.data.especialidades.map(k => { k.selected = true;  return k  })
                        : action.data.especialidades.map(k => { k.selected = ''; return k }),
                },
            });
        case interConsultaConstants.SELECT_ITEM_INTERCONSULTA:
            return Object.assign({}, state, {
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    [action.data.typeReducer]: state.infoInterconsulta[action.data.typeReducer].map(k => {
                        k.name === action.data.data.name ? k.selected = true : k.selected = ''
                        return k;
                    }),
                },
            });
        case interConsultaConstants.SUCCESS_GET_DAY_WORK:
            return Object.assign({}, state, {
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    dayWork: action.data.length ? action.data.map(k => { k.selected = true; return k}) : []
                },
            });
        case interConsultaConstants.REQUEST_GET_HOUR_WORK:
            return Object.assign({}, state, {
                dialogHour: true,
                loadingInter: true,
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    hourWork: []
                },
            });
        case interConsultaConstants.SUCCESS_GET_HOUR_WORK:
            return Object.assign({}, state, {
                loadingInter: false,
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    hourWork: action.data.horas,
                    daySelect: action.data.diaSelect
                },
            });
        case interConsultaConstants.SELECT_HOUR_WORK:
            return Object.assign({}, state, {
                loadingInter: false,
                dialogHour: false,
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    hourSelect: action.data
                },
            });
        case interConsultaConstants.SUCCESS_GET_METHOD_PAYMENT:
            return Object.assign({}, state, {
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    methoPayment: action.data
                },
            });
        case interConsultaConstants.CHANGE_IMPUT:
            return Object.assign({}, state, {
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    [action.data.name]: action.data.value
                },
            });
        case interConsultaConstants.CHANGE_IMPUT_PARACLINICOS:
            return Object.assign({}, state, {
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    paraClinicos:{
                        ...state.infoInterconsulta.paraClinicos,
                        [action.data.name]: action.data.value
                    }
                },
            });
        case interConsultaConstants.SUCCESS_GET_FORM_HISTORIA:
            return Object.assign({}, state, {
                loadingInter: false,
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    getForm: action.data
                },
            });
        case interConsultaConstants.SUCCESS_GET_BANK:
            return Object.assign({}, state, {
                bancos: action.data,
            });
        case interConsultaConstants.SUCCESS_INSERT_QOUTE:
            return Object.assign({}, state, {
                loadingInter: false,
                qoute: { 
                    cita: action.data.qoute.cita, 
                    monto: action.data.qoute.monto, 
                    moneda: action.data.qoute.moneda, 
                    tipoPago: action.data.tipoPago
                },
                message: action.data.message,
            });
        case interConsultaConstants.VIEW_PAYMEN:
            return Object.assign({}, state, {
                loadingInter: false,
                viewPagar: action.data,
            });
        case interConsultaConstants.CHANGE_IMPUT_PAGO:
            return Object.assign({}, state, {
                qoute:{
                    ...state.qoute,
                    [action.data.name]: action.data.value
                }
            });
        case interConsultaConstants.SUCESS_GET_MIS_INTERCONSULTAS:
            return Object.assign({}, state, {
                loadingInter: false,
                lisInter: action.data ? action.data : [],
                filter: !action.data ? 4 : action.data.filter(q => (q.status === '4')).length > 0
                    ? 4 : action.data.filter(q => (q.status === '3')).length > 0 
                    ? 3 : action.data.filter(q => (q.status === '2')).length > 0 
                    ? 2 : action.data.filter(q => (q.status === '1')).length > 0 
                    ? 1 : 4,
            });
        case interConsultaConstants.SELECT_INTERCONSULTA:
            return Object.assign({}, state, {
                interconsultaSelect: action.data,
            });
        case interConsultaConstants.SUCESS_DELETE_INTERCONSULTA:
            return Object.assign({}, state, {
                lisInter: state.lisInter.filter(k => (k.var !== action.data)),
                interconsultaSelect: {},
        });
        case interConsultaConstants.SUCESS_UPDATE_INTERCONSULTA:
            return Object.assign({}, state, {
                loadingInter: false,
                interconsultaSelect: action.data,
                infoInterconsulta: {
                    ...state.infoInterconsulta,
                    motivoCita: action.data.infocita.motive
                }
        });
        case interConsultaConstants.SUCCESS_INSERT_PAYMENT:
            return Object.assign({}, state, {
                viewPagar: false,
                dialogHour: false,
                loadingInter: false,
                interconsultaSelect: [],
                infoInterconsulta: {
                    medic: '',
                    getForm: [],
                    hourWork: [],
                    daySelect: [],
                    motivoCita: '',
                    ubicacion: '',
                    hourSelect: [],
                    precioSelect: '',
                    methoPayment: [],
                    paraClinicos: [],
                    especialidades: [],
                    formaPagoSelect: '',
                    modalidad_cita: [],
                }
        });
        case interConsultaConstants.CLEAR_INFO_INTERCONSULTA:
            return Object.assign({}, state, {
                viewPagar: false,
                dialogHour: false,
                loadingInter: false,
                interconsultaSelect: [],
                infoInterconsulta: {
                    medic: '',
                    getForm: [],
                    hourWork: [],
                    daySelect: [],
                    motivoCita: '',
                    ubicacion: '',
                    hourSelect: [],
                    precioSelect: '',
                    methoPayment: [],
                    paraClinicos: [],
                    especialidades: [],
                    formaPagoSelect: '',
                    modalidad_cita: [],
                }
        });
        case interConsultaConstants.UPDATE__FILTER:
            return Object.assign({}, state, {
                filter: action.data,
        });
        default:
            return state
    }
}