import React from 'react'
import dateFns from "date-fns";

/* Compoments */
import Calendar from "../../layout/calendarioComponent";

/* MAterial UI */
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Divider, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress } from '@material-ui/core';

// Require Esperanto locale
var esLocale = require('date-fns/locale/es');

const FormPagoMenuItem = (data) => {
    if (data.select) {
        return (
            <MenuItem value={data.id} key={data.id}>{data.name}</MenuItem>
        )
    }
}


const Viewcalendario = ({ classes, loadingInter,  infoInterconsulta, dispatch, handleSelectFech, handleChangeInput, handleSolicitarInterconsulta }) => (
    <Grid item xs={12} sm={6}>
        <Paper className="paper">
            <div className="title-paper">
                <Typography color="primary" component="span" className="mb-06">Selecciona una fecha y hora en el calendario para tu interconsulta</Typography>
                <Divider />
            </div>
            <Typography component="label" align="justify" color="textSecondary">
                Le informamos que el horario de la cita corresponde a la zona horaria del lugar de residencia del
                especialista: <b>{infoInterconsulta.ubicacion}</b>. Tenga en cuenta esto en caso de existir alguna diferencia horaria con su lugar
                de residencia.
            </Typography>
            <div className='mt-1'>
                <Calendar
                    dispatch={dispatch}
                    interconsulta={true}
                    list={infoInterconsulta.dayWork}
                    handleSelectFech={handleSelectFech}
                />
            </div>
            <div className="mt-1">
                <Grid container spacing={24}>
                    <Grid item xs={12} sm={6}>
                        <Typography component="label" className={classes.labelInterconsulta}><b className="obligatorio">*</b> Fecha</Typography>
                        <Typography component="p" className={classes.borderBotonP}>
                            {infoInterconsulta.daySelect.fech 
                                ? dateFns.format(infoInterconsulta.daySelect.fech, 'D [de] MMMM / YYYY', { locale: esLocale }) 
                                : 'Seleccione una fecha'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="label" className={classes.labelInterconsulta}><b className="obligatorio">*</b> Hora</Typography>
                        <Typography component="p" className={classes.borderBotonP}>
                            {infoInterconsulta.hourSelect.attencion ? infoInterconsulta.hourSelect.attencion : 'Seleccione una Hora'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="label_forma_pago"><b className="obligatorio">*</b> Forma de pago</InputLabel>
                            {infoInterconsulta.methoPayment.precioCita
                                ? <Select
                                    id="forma_pago"
                                    value={infoInterconsulta.formaPagoSelect}
                                    name='formaPagoSelect'
                                    onChange={handleChangeInput}
                                >
                                    {infoInterconsulta.methoPayment.formaPago &&
                                        infoInterconsulta.methoPayment.formaPago.filter(k => (k.id === '2')).length &&
                                        <MenuItem value="6">Tarjeta de débito/crédito</MenuItem>
                                    }
                                    {infoInterconsulta.methoPayment.formaPago
                                        ? infoInterconsulta.methoPayment.formaPago.map(k =>{
                                            return(
                                                FormPagoMenuItem(k)
                                            )
                                        })
                                        : <MenuItem value="">Sin opciones disponibles</MenuItem>

                                    }
                                </Select>
                                : <Select
                                    id="forma_pago"
                                    value={infoInterconsulta.formaPagoSelect}
                                    name='formaPagoSelect'
                                    onChange={handleChangeInput}
                                >
                                    <MenuItem value={8}>Gratis</MenuItem>
                                </Select>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="labal_forma_costo"><b className="obligatorio">*</b> Costo de la cita</InputLabel>
                            {infoInterconsulta.methoPayment.precioCita
                                ?<Select
                                    id="costo"
                                    value={infoInterconsulta.precioSelect}
                                    name='precioSelect'
                                    onChange={handleChangeInput}
                                >   
                                    {infoInterconsulta.methoPayment.precioCita 
                                        ? infoInterconsulta.methoPayment.precioCita.map(k => {
                                            if((infoInterconsulta.formaPagoSelect === '2' || infoInterconsulta.formaPagoSelect === '6')){
                                                const comision = (parseFloat(k.monto) * 0.054) + 0.3
                                                return (
                                                    <MenuItem key={k.id} value={`${parseFloat(k.monto) + parseFloat(comision.toFixed(2))}, ${k.currency}`} >
                                                        {k.monto} {k.name} + {comision.toFixed(2)}
                                                    </MenuItem>
                                                )
                                            } else if (infoInterconsulta.formaPagoSelect === '7'){
                                                if (k.currency === '1'){
                                                    return (
                                                        <MenuItem key={k.id} value={`${parseFloat(k.monto)}, ${k.currency}`} >
                                                            {k.monto} {k.name}
                                                        </MenuItem>
                                                    )
                                                }else{
                                                    return ''
                                                }
                                            }
                                            else{
                                                return (
                                                    <MenuItem  key={k.id} value={`${parseFloat(k.monto)}, ${k.currency}`} >
                                                        {k.monto} {k.name}
                                                    </MenuItem>
                                                )
                                            }
                                        })
                                        : <MenuItem value="">Sin opciones disponibles</MenuItem>
                                    }
                                </Select>
                                : <Select
                                    id="costo"
                                    value={infoInterconsulta.precioSelect}
                                    name='precioSelect'
                                    onChange={handleChangeInput}
                                >
                                    <MenuItem value={`0,4`}>0</MenuItem>
                                </Select>
                            }
                        </FormControl>
                    </Grid>
                    {(infoInterconsulta.formaPagoSelect === '2' || infoInterconsulta.formaPagoSelect === '6') &&
                        <Grid item xs={12}>
                            <Typography component="span" align="justify" color="textSecondary">
                                Recuerde que PayPal cobra una comisión de <b>5.4% + 0.3 USD.</b> Sumaremos esta cantidad para que recibamos el monto exacto de su interconsulta.
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12} align="center">
                        <Button 
                            color="primary" 
                            variant="contained" 
                            disabled={(infoInterconsulta.precioSelect !== '') && loadingInter}
                            onClick={handleSolicitarInterconsulta}
                        >
                            Solicitar interconsulta
                                {(infoInterconsulta.precioSelect !== '') && loadingInter && <CircularProgress size={24} className="ProgressAbsolut" />}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    </Grid>
)


export default withStyles(styles)(Viewcalendario);