import { apiCardio } from '../_constants';

export const configCuentaService = {
    // CONFIGURACION GENERAL
    editUser,
    editPassword,

    // REQUISITOS PARA LA CITA
    getRequisitos,
    registrarRequisito,
    deleteRequisito,
    deleteOpcionRequisito,

    //FORMA DE PAGO
    getMetodoDePago,
    selectMetodoDePago,
    deleteBanco,
    regisNuevoBanco,

    //Honorarios
    getHonorarios,
    getMetodosCitas,
    selectHonorarios,
    activarAsesoriaChat,
    activarCitaGratis,

    //HORARIO DE TRABAJO
    getHorario,
    registHorarioMedio,
    deSelectDiaHorario,
    activarInactivarFecha,

    //COMPLETAR PERFIL
    getCompletarPerdil,
};

function editUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ user })
    };

    return fetch(`${apiCardio.urlMedico}editInfoEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.success === true) {
                localStorage.setItem('user', JSON.stringify(data.data));
            }
            return data;
        });
}

function editPassword(user, userPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ userPassword, aplication: 2 })
    };

    return fetch(`${apiCardio.urlUser}changPassEspecialistayAdmin`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.success === true) {
                localStorage.setItem('user', JSON.stringify(data.data));
            }
            return data;
        });
}

/* REQUISITOS DE CITA */
function getRequisitos(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlHistoria}getRequisitos`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function registrarRequisito(user, requisitos, idRequisito) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ requisitos, idRequisito })
    };

    return fetch(`${apiCardio.urlHistoria}regisHistoriaCita`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deleteRequisito(user, requisito) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ requisito })
    };

    return fetch(`${apiCardio.urlHistoria}deleteHistoriaCita`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deleteOpcionRequisito(user, opcion) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ opcion })
    };

    return fetch(`${apiCardio.urlHistoria}deleteOpcionRequisito`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

/* FORMA DE PAGO */
function getMetodoDePago(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlMedico}getFormaPagoMedico`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}

function selectMetodoDePago(user, chipMetodoPago, regis) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ chipMetodoPago, regis: regis })
    };

    return fetch(`${apiCardio.urlMedico}selectUpdateFormaPago`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deleteBanco(user, id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ "idbancoEspecialista": id })
    };

    return fetch(`${apiCardio.urlMedico}deleteBancoEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function regisNuevoBanco(user, newbanco, id) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ newbanco, id })
    };

    return fetch(`${apiCardio.urlMedico}saveNuevoBanco`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

/*Honorarios */
function getHonorarios(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlMedico}getHonorarios`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}
function getMetodosCitas(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlMedico}metodoCitaEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}

function activarAsesoriaChat(user, activate, type_modalidad) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ activate, type_modalidad })
    };
    return fetch(`${apiCardio.urlMedico}activeAsesoriaChat`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function activarCitaGratis(user, type_quotes, free) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ type_quotes, free})
    };
    return fetch(`${apiCardio.urlMedico}activarCitaGratis`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function selectHonorarios(user, chipMoneda) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ chipMoneda })
    };

    return fetch(`${apiCardio.urlMedico}selectUpdateHonorarios`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

/*Horario Especialista */
function getHorario(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlHorario}getAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}

function registHorarioMedio(user, horarioMedico) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ horarioMedico })
    };

    console.log(horarioMedico)
    return fetch(`${apiCardio.urlHorario}regisHorarioEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deSelectDiaHorario(user, select) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ select })
    };

    return fetch(`${apiCardio.urlHorario}deleteHorarioMedico`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function activarInactivarFecha(dia, user, tipo) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ dia, tipo })
    };

    return fetch(`${apiCardio.urlHorario}activarInactivarFecha`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getCompletarPerdil(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlMedico}completeProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}