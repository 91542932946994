import { alertConstants } from '../_constants';

export const alertConfirmActions = {
    openCloseAlert,
    sumitedRequest,
    success,
    closeAlert,
};

function openCloseAlert() {
    return { type: alertConstants.OPEN_CLOSE_CONFIRM };
}

function sumitedRequest() {
    return { type: alertConstants.SUMITED_REQUEST };
}

function success() {
    return { type: alertConstants.SUMITED_SUCCES };
}
function closeAlert() {
    return { type: alertConstants.CLOSE_CONFIRM };
}
