import { alertActions } from '../alert.action';
import { perfilService } from "../../_services";
import { loginConstants, ePegeConstants, apiCardio } from '../../_constants';
// import { perfilConstants } from '../../_constants';
// import { alertConfirmActions } from '../alertConfirm.action';

export const perfilActions = {
    //DATOS BASICOS
    editAvatarPerfil,
    editAvatarPortada,
    openOClosetDialog,
    recortarImgMedico,
};


function editAvatarPerfil(user, file) {
    const formData = new FormData();
    formData.append('avatar', file);
    const http = new XMLHttpRequest();
    const url = apiCardio.urlMedico + 'changeAvatarEspecialista';

    return dispatch => {
        dispatch(request(user));
        /*Progreso */
        http.upload.addEventListener("progress", (event) => {
            let porcentaje = Math.round((event.loaded / event.total) * 100)
            dispatch(progrees(porcentaje));
        })

        /*Finalizado */
        http.addEventListener("load", () => {
            //console.log('finalizo la carga')
            const data = JSON.parse(http.response)
            if (data.success === true) {
                localStorage.setItem('user', JSON.stringify(data.data));
                dispatch(progrees(0));
                dispatch(success(data.data));
                dispatch(alertActions.success(data.message));
                dispatch(openDialog())
            }else{
                dispatch(alertActions.error(data.message));
            }
        })

        /*Enviar Dato */
        http.open('POST', url, true);
        http.setRequestHeader('token', user.token);
        http.onreadystatechange = function () {//Call a function when the state changes.
            if (http.readyState === 4 && http.status === 200) {
                //handleResponse(http.response)
                // const data = JSON.parse(http.response)
                // console.log(data);
            }else{
                console.log('error a cargar la imagen')
            }
        }
        http.send(formData);
    }

    // function request() { return { type: loginConstants.EDIT_REQUEST } }
    function request(user) { return { type: loginConstants.LOGIN_REQUEST, user } }
    function progrees(porcentaje) { return { type: ePegeConstants.PROGRESO_DE_SUBIDA_IMG, porcentaje } }
    function success(user) { return { type: loginConstants.LOGIN_SUCCESS, user } }
    function openDialog() { return { type: ePegeConstants.OPEN_DIALOG_RECORTAR_MODAL } }
}

function editAvatarPortada(user, file) {
    return dispatch => {
        dispatch(request(user));
        perfilService.editPortada(user, file)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                        dispatch(alertActions.success(data.message));
                    } else {
                        dispatch(alertActions.error(data.message));
                    }
                }
            )
    }

    // function request() { return { type: loginConstants.EDIT_REQUEST } }
    function request(user) { return { type: loginConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: loginConstants.LOGIN_SUCCESS, user } }
}
function openOClosetDialog() {
    return { type: ePegeConstants.OPEN_DIALOG_RECORTAR_MODAL }
}

function recortarImgMedico(user, crop, dimensionView) {
    return dispatch => {
        dispatch(request(user));
        perfilService.recortarImgMedico(user, crop, dimensionView)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                        dispatch(alertActions.success(data.message));
                        dispatch(openDialog())
                    } else {
                        dispatch(alertActions.error(data.message));
                    }
                }
            )
    }
    function request(user) { return { type: loginConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: loginConstants.LOGIN_SUCCESS, user } }
    function openDialog() { return { type: ePegeConstants.OPEN_DIALOG_RECORTAR_MODAL } }
}