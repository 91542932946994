import React from 'react'

import logoCardio from '../../../assets/media/icon-logo.gif';

const Preloader = (props) => (
    <div className="cont-preloader ">
        <div className="preloader">
            <div className="circle-girar centrar"></div>
            <div className="cont-img centrar">
                <img className="circle" src={logoCardio} alt="logo"/>
            </div>
        </div>
    </div>
)


export default (Preloader);