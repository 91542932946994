import { citasConstants } from '../../_constants';

const initialState = {
    loadingCitaChat: false,
    viewDetail: false,
    citasChat: [],
    yarnChat: [],
    descriptionYarn: '',
    submitChatAse: false,
    loadingSubmit: false,
};

export function citasChatReducer(state = initialState, action) {
    switch (action.type) {
        case citasConstants.GET_REQUEST_CITAS_CHAT:
            return Object.assign({}, state, {
                loadingCitaChat: !state.loadingCitaChat,
            });
        case citasConstants.GET_SUCCESS_CITAS_CHAT:
            return Object.assign({}, state, {
                citasChat: action.data,
                loadingCitaChat: !state.loadingCitaChat,
            });
        case citasConstants.GET_FAIL_CITA_CHAT:
            return Object.assign({}, state, {
                citasChat: [],
                loadingCitaChat: !state.loadingCitaChat,
            });
        case citasConstants.GET_SUCCESS_HILO_CITAS_CHAT:
            return Object.assign({}, state, {
                yarnChat: action.data,
                viewDetail: true,
                loadingCitaChat: !state.loadingCitaChat,
            });
        case citasConstants.UPDATE_VIEW:
            return Object.assign({}, state, {
                viewDetail: action.data,
            });
        case citasConstants.CHANGE_DESCRIPTION:
            return Object.assign({}, state, {
                descriptionYarn: action.data,
            });
        case citasConstants.SUBMIT_REQUEST_YARN:
            return Object.assign({}, state, {
                submitChatAse: !state.submitChatAse,
                loadingSubmit: !state.loadingSubmit,
            });
        case citasConstants.SUBMIT_SUCCESS_HILO_CITAS_CHAT:
            return Object.assign({}, state, {
                [state.yarnChat.yarn_quotes]: state.yarnChat.yarn_quotes = action.data,
                descriptionYarn: '',
                submitChatAse: !state.submitChatAse,
                loadingSubmit: !state.loadingSubmit,
            });
        case citasConstants.ASESORIA_TEXTO_ATENDIDA_SUCCES:
            return Object.assign({}, state, {
                yarnChat: {
                    ...state.yarnChat,
                    status : '5'
                },
                citasChat: state.citasChat.map(k => {
                    if (k.keyd === action.data) {
                        k.status = '5'
                    }
                    return k
                }),
            });
        default:
            return state
    }
}