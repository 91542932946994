import { menuConstants } from '../_constants';

const initialState = { 
    isOpen: false,
    menuActivo: 1,
    classOpenBar: false,
};

export function menuOpen(state = initialState, action) {
    switch (action.type) {
        case menuConstants.MOBILEOPEN:
            return Object.assign({}, state, {
                isOpen: action.actiomenu,
            });
        case menuConstants.BAR_RIGHT_SUCCESS:
            return Object.assign({}, state, {
                classOpenBar: !state.classOpenBar,
            });
        case menuConstants.MAIN_ACTIVE:
            return Object.assign({}, state, {
                menuActivo: action.data
            });
        default:
            return state
    }
}