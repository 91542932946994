import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';


/* Material UI */
import './post.css';
import { styles } from "./postStyle";
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, IconButton, Divider, Avatar, FormControl, InputBase} from '@material-ui/core';
import { Dialog, DialogContent, Tooltip, Button, CircularProgress } from '@material-ui/core';

/* Actions */
import { postActions, alertActions } from '../../../../_actions'

/* Estilos con Material UI */

/* Icons */
import ClearIcon from '@material-ui/icons/Clear';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternateOutlined';

/* Url's */
import { apiCardio } from '../../../../_constants';


class EditPost extends Component {
    constructor() {
        super()
        this.state = {
            imagePreviewUrlEdit: '',
            videoPreviewUrlEdit: '',
        };

        this.handleImgClear = this.handleImgClear.bind(this)
        this.handleVideoClear = this.handleVideoClear.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmitPostEdit = this.handleSubmitPostEdit.bind(this)
    }

    handleModalClose = data => () => {
        const { dispatch } = this.props;
        dispatch(postActions.selectPostComentary(''))
    };

    handleChangeInput(e) {
        const { name, value } = e.target
        const { dispatch, selectPost } = this.props;
        dispatch(postActions.llenarEditPost(name, value,selectPost.post.typeFile))
        if ((value === '') && (selectPost.post.image === '')) { 
            dispatch(postActions.disableBoton(true)) 
        }
    }

    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        const { dispatch } = this.props;
        dispatch(postActions.llenarEditPost('files', file, 'image'))

        reader.onloadend = () => {
            this.setState({
                imagePreviewUrlEdit: reader.result,
                videoPreviewUrlEdit: ''
            });
        }
        if (file) {reader.readAsDataURL(file) }
    }

    handleImgClear() {
        const { dispatch, selectPost } = this.props;
        this.setState({ imagePreviewUrlEdit: '' });
        dispatch(postActions.llenarEditPost('files', '', selectPost.post.typeFile))
        selectPost.post.description === '' && dispatch(postActions.disableBoton(true))
    }

    handleVideoChangeEdit(e) {
        e.preventDefault();
        let file = e.target.files[0];
        if (file.type === 'video/x-ms-wmv' || file.type === 'video/avi') {
            this.props.dispatch(alertActions.error('Formato de vídeo no permitido, se recomienda subir vídeos con formato .mp4'));
        }else{
            /*Obtencion de la duracion del video */
            window.URL = window.URL || window.webkitURL;
            var video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(file);
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                var duration = video.duration;
                this.validarDuracionVideo(duration, file)
            }
            /*Obtencion de la duracion del video */
        }
    }

    validarDuracionVideo(duration, file) {
        const { dispatch } = this.props;
        if (duration > 60.999) {
            dispatch(alertActions.error('La duración máxima del vídeo es 1 minuto'));
        } else {
            dispatch(postActions.llenarEditPost('files', file, 'video'))
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    videoPreviewUrlEdit: reader.result,
                    imagePreviewUrlEdit: '',
                });
            }
            if (file) {
                reader.readAsDataURL(file)
            }
        }
    }

    handleVideoClear() {
        const { dispatch, selectPost } = this.props;
        this.setState({ videoPreviewUrlEdit: '' });
        dispatch(postActions.llenarEditPost('files', '', selectPost.post.typeFile))
        selectPost.post.description === '' && dispatch(postActions.disableBoton(true))

    }

    handleSubmitPostEdit(e) {
        e.preventDefault();
        const { dispatch, selectPost, user } = this.props;

        dispatch(postActions.submitRequest(true))
        if (selectPost.post.description_edit !== '' || selectPost.post.image !== '') {
            dispatch(postActions.editPost(user, selectPost.post))
        }
    }

    render() {
        const { imagePreviewUrlEdit, videoPreviewUrlEdit } = this.state
        const { classes, user, selectPost, disableBtn, submitted } = this.props;

        let $videoPreview = null;
        let $imagePreviewEdit = null;
        if (selectPost.post) {
            if (selectPost.post.files) {   
                if (selectPost.post.typeFile === 'image') {
                    selectPost.post.files.length
                        ? $imagePreviewEdit = (<img alt={user.name} src={`${apiCardio.urlMedia}${selectPost.post.files}`} className={classes.bigAvatar} />)
                        : $imagePreviewEdit = (<img alt={user.name} src={imagePreviewUrlEdit} className={classes.bigAvatar} />)
                }else{
                    selectPost.post.files.length
                        ? $videoPreview = (<video src={`${apiCardio.urlMedia}${selectPost.post.files}`} loop controls></video>)
                        : $videoPreview = (<video src={videoPreviewUrlEdit} loop controls ></video>)
                }
            }
        } 
        
        return (
            <Dialog
                open={selectPost.editar ? true : false}
                fullWidth
                maxWidth='sm'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    className={classes.editPost}
                >
                    <Paper className={`paper p-0 cont-new-post`}>
                        <div className={classes.headerNewPost}>
                            <Typography className={classes.paddingTitle} component="h3" color="primary">
                                Editar publicación
                                <IconButton aria-label="Delete" className={classes.btnClose} onClick={this.handleModalClose()}>
                                    <ClearIcon color="primary" fontSize="small" />
                                </IconButton>
                            </Typography>
                            <Divider />
                        </div>
                        <div className={classes.textBody}>
                            <Avatar alt={user.name} src={`${apiCardio.urlMedia}${user.imgperfil}`} className={classes.bigAvatar} />
                            <div className="cont-text-post">
                                <FormControl className="text-post"
                                    fullWidth
                                >
                                    <InputBase
                                        multiline
                                        fullWidth
                                        autoFocus
                                        rowsMax="8"
                                        id="description_edit"
                                        name="description_edit"
                                        autoComplete="off"
                                        placeholder={`¿Que deseas pubicar, ${user.name}?`}
                                        value={selectPost.post && selectPost.post.description_edit}
                                        onChange={this.handleChangeInput}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="action">
                            {imagePreviewUrlEdit !== ''
                                ?<div className="imgPreviewPost">
                                    <IconButton aria-label="Delete" className="clear-img" onClick={this.handleImgClear} >
                                        <ClearIcon color="primary" fontSize="small" />
                                    </IconButton>
                                    {$imagePreviewEdit}
                                </div>
                                : selectPost.post &&
                                    selectPost.post.files && selectPost.post.typeFile === 'image' &&
                                    <div className="imgPreviewPost">
                                        <IconButton aria-label="Delete" className="clear-img" onClick={this.handleImgClear} >
                                            <ClearIcon color="primary" fontSize="small" />
                                        </IconButton>
                                        {$imagePreviewEdit}
                                    </div> 
                            }
                            {videoPreviewUrlEdit !== ''
                                ? <div className="videoView">
                                    <IconButton aria-label="Delete" className="clear-video" onClick={this.handleVideoClear} >
                                        <ClearIcon color="primary" fontSize="small" />
                                    </IconButton>
                                    {$videoPreview}
                                </div>
                                : selectPost.post &&
                                    selectPost.post.files && selectPost.post.typeFile === 'video' &&
                                    <div className="videoView">
                                        <IconButton aria-label="Delete" className="clear-video" onClick={this.handleVideoClear} >
                                            <ClearIcon color="primary" fontSize="small" />
                                        </IconButton>
                                        {$videoPreview}
                                    </div>

                            }
                            <Divider />
                            <input accept="image/*" className={classes.uploadImg} id="image-post-edit" type="file" onChange={(e) => this.handleImageChange(e)} />
                            <label htmlFor="image-post-edit">
                                <Tooltip title="Añadir foto o gif" aria-label="Add">
                                    <IconButton color="primary" className={classes.button} component="span">
                                        <AddPhotoAlternateIcon />
                                    </IconButton>
                                </Tooltip>
                            </label>
                            <input accept="video/*" className={classes.uploadImg} id="video-post-edit" type="file" onChange={(e) => this.handleVideoChangeEdit(e)} />
                            <label htmlFor="video-post-edit">
                                <Tooltip title="Añadir un video" aria-label="Add">
                                    <IconButton color="primary" className={classes.button} component="span">
                                        <LocalMoviesIcon />
                                    </IconButton>
                                </Tooltip>
                            </label>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.compartir}
                                disabled={disableBtn}
                                onClick={this.handleSubmitPostEdit}
                            >
                                Editar
                                    {submitted && disableBtn && <CircularProgress size={24} className="ProgressAbsolut" />}
                            </Button>
                        </div>
                    </Paper>
                </DialogContent>
            </Dialog>
        );
    }
}

EditPost.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { selectPost, loading, submitted, disableBtn } = state.postReducer;
    return {
        user,
        selectPost, loading, submitted, disableBtn
    };
}

const connectedEditPost = connect(mapStateToProps)(withStyles(styles)(EditPost));
export { connectedEditPost as EditPost };


