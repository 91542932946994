import React from "react";

/* Material UI */
import { styles } from "../configuracionStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, List, ListItem, ListItemText, ListItemSecondaryAction, Tooltip, IconButton, Divider    } from "@material-ui/core";

/* Iconos */
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Create';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ListRequisitosAgregados = ({ classes, listRequisitos, expanded, handleChangeExpansion, handleOpenCloseDialog, handleDeleteOpcionRequisito, 
handleSelectDelateRequisito }) => {
    return(
        <div className="mt-1">
            {listRequisitos && listRequisitos.map(k => {
                const datos = { condicion: 'editR', dato: k }
                return(
                    <ExpansionPanel key={k.id} expanded={expanded === k.id} onChange={handleChangeExpansion(k.id)}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.title_expansion}>{k.name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>
                                        <span className={classes.textPrimario}>Tipo de dato: </span>
                                        {k.tipoName}
                                    </Typography>
                                </Grid>
                                {k.opcion &&
                                    <Grid item xs={12}>
                                        <Typography color="primary" component="span">
                                            Opciones:
                                                        </Typography>
                                        <List className={classes.listOpciones} >
                                            {k.opcion.map(j => {
                                                let datos = { nombreOpcion: j.value, condicion: '2', idopcion: j.id }
                                                return (
                                                    <div key={j.id ? j.id : j.value}>
                                                        <ListItem >
                                                            <ListItemText
                                                                primary={j.value}
                                                                className="listaRequisitos"
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <Tooltip title="Eliminar opción">
                                                                    <IconButton aria-label="Delete" onClick={handleDeleteOpcionRequisito(datos)}>
                                                                        <ClearIcon color="primary" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider />
                                                    </div>
                                                )
                                            })}
                                        </List>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Tooltip title="Eliminar requisito" className="toolTip">
                                        <IconButton aria-label="Delete" onClick={handleSelectDelateRequisito(k)}>
                                            <ClearIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Editar requisito">
                                        <IconButton aria-label="Edit" onClick={handleOpenCloseDialog(datos)}>
                                            <EditIcon color="primary" />
                                        </IconButton>

                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )
                })
            }
        </div>
    )
}

export default withStyles(styles)(ListRequisitosAgregados)