import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { menuActions } from '../../../../_actions/menu.action';

/* MAterial UI */
import { Fab, Hidden, Slide  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Icons */
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

/*Components */
import {ListaPacientePorHistoria} from '../historias/listaPacientePorHistoria';
import { ListaCitaPorAtender } from '../citas/listaCitaPorAtender';
import { CrearIncidencia } from "../incidencias/crearIncidencia";
import { BarRightAsesoriaText } from "../asesoriachat/barRightAsesoriaText";
import { SugerenciasSeguir } from "../dashboard/sugerenciaSeguir";

/* Estilos con Material UI */
const styles = theme => ({
    media: {
        height: 140,
    },
    iconEvent: {
        float: 'left',
        marginTop: 0,
        marginRight: 6,
        fontSize: '1.2rem'
    },
    fab: {
        position: 'fixed',
        zIndex: 1300,
        bottom: theme.spacing.unit * 2,
        right: theme.spacing.unit * 2,
    },
});


class BarRigth extends Component {

    hlandleOpenBarRight = (event) =>{
        event.preventDefault();
        this.props.dispatch(menuActions.barRight())
    }
    
    render() {
        const { classes, classOpenBar } = this.props
        return (
            <div>
                <div className={`${classOpenBar ? 'capa-barRight' : ''} `}/>

                <Slide direction="left" in={classOpenBar} >
                    <div className={`barRight ${!classOpenBar ? 'dsp-none' : ''} `}>
                        <div className="cont-fixed">
                            { this.props.historia && <ListaPacientePorHistoria />  }
                            { this.props.cita && <ListaCitaPorAtender />  }
                            { this.props.asesoriaText && <BarRightAsesoriaText />  }
                            {/* REPORTES DE INCIDENCIAS */}
                            { this.props.crearIncidencia && <CrearIncidencia />  }
                            {/* FIN REPORTES DE INCIDENCIAS */}
                            {/* Barra lateral del inicio */}
                            {this.props.dashboard && <SugerenciasSeguir />}
                        </div>
                    </div>
                </Slide>
                <Hidden mdUp implementation="css">
                    <Fab className={classes.fab} size="medium" onClick={this.hlandleOpenBarRight}>
                        {!classOpenBar ? <VisibilityIcon color="primary"/> : <VisibilityOffIcon color="primary"/> }
                    </Fab>

                </Hidden>
            </div>
        );
    }
}

BarRigth.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { classOpenBar } = state.menuOpen;
    return {
        classOpenBar
    };
}

const connectedBarRigth = connect(mapStateToProps)(withStyles(styles)(BarRigth));
export { connectedBarRigth as BarRigth };