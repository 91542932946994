import React from 'react'

/* MAterial UI */
import { styles } from "./directStyle";
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Divider, Typography, Avatar } from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';


/* Url's */
import { apiCardio } from '../../../../_constants/';

const LayoutList = ({ classes, list, handleAction }) => (
    list.length
        ? (<List>
                <Divider component="li" />
                {list.map(k => {
                    return(
                        <div key={k.keyd}>
                            <ListItem alignItems="flex-start" className="li-message cursor-pointer" onClick={handleAction(k)}>
                                <ListItemAvatar>
                                    {k.nom_contacto 
                                        ? <Avatar src={apiCardio.urlMedia+''+k.img} alt={k.nom_contacto ? k.nom_contacto : k.participantes[0].nombre} />
                                        : k.participantes.length 
                                            ? <Avatar src={apiCardio.urlMedia+''+k.participantes[0].img} alt={k.nom_contacto ? k.nom_contacto : k.participantes[0].nombre} />
                                            : <Avatar><SupervisorAccountIcon /></Avatar>

                                    }
                                    
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        k.nom_contacto
                                            ? k.nom_contacto
                                            : k.participantes.length 
                                                ? (k.participantes.length > 1)
                                                    ? k.nom_group
                                                    : k.participantes[0].nombre
                                                : 'No hay participantes dentro de este chat'
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography component="span" variant="body2" className="fech-message"> 
                                                {k.nickname ? '@'+k.nickname : k.fech_reg }
                                            </Typography>
                                            {k.lastMessage 
                                                ? <Typography component="span" className="description-message truncate">
                                                    {k.lastMessage}
                                                </Typography>
                                                : null
                                            }
                                        </React.Fragment>
                                    }
                                />
                                {!k.viewed &&
                                    <ListItemSecondaryAction>
                                        <Typography component="span" className="notify-direct"></Typography>
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                            <Divider component="li" />
                        </div>
                    )
                })}
            </List>
        )
        : <Typography component="span" className={classes.p12} align="center"> No se encontraron resultados </Typography>
);

export default withStyles(styles)(LayoutList);