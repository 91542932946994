import { history } from '../../_helpers';
import { alertActions } from '../alert.action';
import { historiaConstants } from '../../_constants';
import { alertConfirmActions } from '../alertConfirm.action';
import { historiaService, citasService } from "../../_services";

export const historiaActions = {
    getHistorias,
    selectPerso,
    personsView,
    SeletPacienteHistoria,
    removerPacienteHistoria,
    SeletPacienteCrearHistoria,
    SeletPacienteEditarHistoria,
    getSubmit,
    regisHistoria,
    cancelarCrearHistoria,
    selectHistEspecialidad,
};

function getHistorias(user) {
    return dispatch => {
        dispatch(request(true))
        historiaService.getHistoria(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail(data.data))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request(data) { return { type: historiaConstants.REQUEST_HISTORIA, data } }
    function success(data) { return { type: historiaConstants.GET_HISTORIA, data } }
    function fail(data) { return { type: historiaConstants.FAIL_HISTORIA, data } }
}

function selectPerso(data) {
    return dispatch => {
        dispatch(succes(data))
        dispatch(view(data))
    }

    function succes(data) { return { type: historiaConstants.SELECT_PERSON, data }  }
    function view() { return { type: historiaConstants.VIEW_DETALLE_PERSON }  }
}

function personsView() {
    return { type: historiaConstants.VIEW_DETALLE_PERSON };
}

function SeletPacienteHistoria(data) {
    return { type: historiaConstants.SELECT_PERSON, data }
}
function removerPacienteHistoria(user, paciente) {
    return dispatch => {
        citasService.updateStatusCita(user, paciente, 6)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success("Se removio al usuario de la lista de historia por crear."))
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            );
    }
    function success(data) { return { type: historiaConstants.REMOVE_PATIENT_HIST, data } }
}

function SeletPacienteCrearHistoria(data) {
    return dispatch => {
        dispatch(success(data))
        history.push('/nueva-historia');
    }
    function success(data) { return { type: historiaConstants.SELECT_PERSON, data } }
}

function SeletPacienteEditarHistoria(data) {
    return dispatch => {
        dispatch(success(data))
        history.push('/nueva-historia');
    }
    function success(data) { return { type: historiaConstants.SELECT_PERSON_EDIT_HISTORY, data } }
}

function getSubmit() {
    return { type: historiaConstants.SUBMIT_HISTORIA }
}

function regisHistoria(user, paciente, historia) {
    return dispatch => {
        dispatch(request())
        historiaService.regisHistoria(user, paciente, historia)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success(data.message))
                        dispatch(request())
                        history.push('/historias');
                    } else {
                        dispatch(fail())
                        dispatch(alertActions.error(data.message))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: historiaConstants.SUBMIT_HISTORIA } }
    function success(data) { return { type: historiaConstants.SUCCESS_INSERT_HISTORIA, data } }
    function fail() { return { type: historiaConstants.SUBMIT_HISTORIA } }
}
function cancelarCrearHistoria() {
    return dispatch => {
        dispatch(success(false))
        history.push('/historias');
    }
    function success(data) { return { type: historiaConstants.CANCELAR_CREAR_HISTORIA, data } }
}

function selectHistEspecialidad(data) {
    return dispatch => {
        dispatch(success(data))
    }
    function success(data) { return { type: historiaConstants.ESPECIALIDAD_SELECT_HIST, data } }
}