import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Estilos con Material UI */
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./incidenciasStyle";
import { Grid, Typography, Fab } from '@material-ui/core';

/* Icons */
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

/* Actions */
import { incidenciaActions, alertConfirmActions } from '../../../../_actions'

/* Layouts */
import AlertDialog from "../layout/alertDialogo";

class CrearIncidencia extends Component {

    handleOpenCrearIncidencia = () => {
        this.props.dispatch(incidenciaActions.mostrarSlide())
    } 

    
    /* Handles Marcar como atendida la cita */
    handleCloseAlert = () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };
    
    handleOpenConfirm = (e) => {
        e.preventDefault();
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    }

    handleDeleteReporte = () => {
        this.props.dispatch(alertConfirmActions.sumitedRequest())
        this.props.dispatch(incidenciaActions.deleteIncidens(this.props.user, this.props.deleteIncidencia))
    }
    /* Handles Para Burrar algun elemento */

    render() {
        const { classes, deleteIncidencia, openConfirm, loadingConfirm, submitedConfrim, } = this.props
        return(
            <Grid container spacing={24}>
                <Grid item xs={12} align="center">
                    <Fab variant="extended" aria-label="Add" className={classes.fab} onClick={this.handleOpenCrearIncidencia}>
                        <AddIcon color="primary" className={classes.addIcon} />
                        <Typography color="primary" className="ft-bold" component="span">Crear reporte</Typography>
                    </Fab>
                </Grid>
                {deleteIncidencia.length 
                    ? <Grid item xs={12} align="center">
                        <Fab variant="extended" aria-label="Add" className={classes.fab} onClick={this.handleOpenConfirm}>
                            <DeleteIcon color="primary" className={classes.addIcon} />
                            <Typography color="primary" className="ft-bold" component="span">Eliminar seleccion</Typography>
                        </Fab>
                    </Grid>
                    : ''
                }
                <AlertDialog
                    open={openConfirm}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDeleteReporte}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de eliminar los reportes de problemas seleccionados?'
                />
            </Grid>
        )
    }
}

CrearIncidencia.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm
    const { preloaderInci, viewDetail, incidencias, deleteIncidencia } = state.incidenciaReducer;
    return {
        user,
        openConfirm, loadingConfirm, submitedConfrim,
        preloaderInci, viewDetail, incidencias, deleteIncidencia
    };
}

const connectedCrearIncidencia = connect(mapStateToProps)(withStyles(styles)(CrearIncidencia));
export { connectedCrearIncidencia as CrearIncidencia };