import { apiCardio } from '../_constants';

export const especialidadesService = {
    getEspecialidades,
    selectEspecialidad,
};

function getEspecialidades(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };
    return fetch(`${apiCardio.urlMedico}getSelectEspecialidad`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function selectEspecialidad(user,select) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ select })
    };
    
    return fetch(`${apiCardio.urlMedico}selectEspecialidad`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
    });
    
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}