import React from 'react'

/* MAterial UI */
import { Grid, Menu, MenuItem, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


/* Estilos con Material UI */
import { styles } from "../perfilStyle";

const MenuSeguidorContacto = (props) => (
    <Grid item xs={1} className="cursor-pointer br-top  br-right" align="center">
        <Button
            variant="contained"
            id="menu-mas-opciones"
            className={props.classes.btnMasOption}
            onClick={props.handleOpenMenuOptSeguir}
        >
            Más
        </Button>
        <Menu
            id={`menu-mas-opciones`}
            //anchorEl={postEl}
            //open={(selectPost === k.keyd) && postEl ? true : false}
            //onClose={this.handleCloseMenu('postEl')}

            anchorEl={props.optanchorEl}
            open={Boolean(props.optanchorEl)}
            onClose={props.handleCloseMenuOptSeguir}

        >
            <MenuItem
                className={props.seguidor ? props.classes.seguiendo : props.classes.seguir}
                onClick={props.hlandleSeguirEspecialista(props.seguidor)}
            >
                {props.seguidor ? 'Siguiendo' : 'Seguir'}
            </MenuItem>
            {props.contacto.type_contact 
                ? props.contacto.contacto === '2'
                    ? <MenuItem className={props.classes.seguiendo}>
                        Enviaste una solicitud para conectar
                    </MenuItem>
                    : <MenuItem
                        className={props.contacto.contacto ? props.classes.seguiendo : props.classes.seguir}
                        onClick={props.handleSelectnickNameSolicitud(props.contacto.contacto)}
                    >
                        {props.contacto.contacto ? 'Contacto' : 'Conectar'}
                    </MenuItem>
                : props.contacto.contacto === '2'
                    ? <MenuItem 
                        className={props.classes.seguiendo}
                        onClick={props.handleAceptarSolicitud}
                    >
                        Aceptar solicitud
                    </MenuItem>
                    : <MenuItem
                        className={props.contacto.contacto ? props.classes.seguiendo : props.classes.seguir}
                        onClick={props.handleSelectnickNameSolicitud(props.contacto.contacto)}
                    >
                        {props.contacto.contacto ? 'Contacto' : 'Conectar'}
                    </MenuItem>
                
            }
            {/* {props.contacto.contacto === '2'
                ? <MenuItem className={props.classes.seguiendo}>
                    Enviaste una solicitud para conectar
                </MenuItem>
                : <MenuItem
                    className={props.contacto.contacto ? props.classes.seguiendo : props.classes.seguir}
                    onClick={props.handleSelectnickNameSolicitud(props.contacto.contacto)}
                >
                    {props.contacto.contacto ? 'Contacto' : 'Conectar'}
                </MenuItem>
            } */}
            
        </Menu>

    </Grid>
)


export default withStyles(styles)(MenuSeguidorContacto);