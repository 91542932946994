import React from 'react'
// import { Link } from 'react-router-dom'

/* MAterial UI */
import '../index.css';
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, Paper} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import PaymentIcon from '@material-ui/icons/Payment';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const CardInterconsultas = ({ lisInter, handleViewDetail, handleDeleteCita, handleUpdateInter, handleConsultorio, handleViewPagar }) => (
    <Grid container spacing={24} className='mt-1'>
        {lisInter.length
            ? lisInter.map(k => {
                return (
                    <Grid item xs={12} sm={3} key={k.var}>
                        <Paper className='paper-mis-interconsulta'>
                            <div className='title-inter'>
                                <Typography>{k.especialidad}</Typography>
                            </div>
                            <div className='body-inter'>
                                <Typography className='lbl-name'>Tipo de interconsulta</Typography>
                                <Typography className='lbl-description'>{k.consulta}</Typography>
                                <Typography className='lbl-name'>Especialista Seleccionado</Typography>
                                <Typography className='lbl-description'>{k.name} {k.surname}</Typography>
                                <Typography className='lbl-name'>Fecha Selecciona</Typography>
                                <Typography className='lbl-description'>{k.fech_quotes}</Typography>
                                <Typography className='lbl-name'>Hora Seleccionada</Typography>
                                <Typography className='lbl-description'>{k.attencion}</Typography>
                            </div>  
                            <div className='footer-inter'>
                                {(k.status === '1') && k.consultorio && (k.tipo !== '5') &&
                                    <Button
                                        color="primary"
                                        className='btn-paper-inter min-wd-btm'
                                        onClick={handleConsultorio(k)}
                                    >
                                        <SupervisedUserCircleIcon />
                                        <Typography color='primary'>Consultorio</Typography>
                                    </Button>
                                }
                                {(k.status === '4' || k.status === '3')  &&
                                    <Button
                                        color="primary"
                                        className='btn-paper-inter min-wd-btm'
                                        onClick={handleViewPagar(k)}
                                    >
                                        <PaymentIcon />
                                        <Typography color='primary'>Pagar</Typography>
                                    </Button>
                                }
                                {k.editar && 
                                    <Button
                                        color="primary"
                                        className='btn-paper-inter min-wd-btm'
                                        onClick={handleUpdateInter(k)}
                                    >
                                        <EditIcon />
                                        <Typography color='primary'>Editar</Typography>
                                    </Button>
                                }
                                <Button
                                    color="primary"
                                    className='btn-paper-inter min-wd-btm'
                                    onClick={handleViewDetail(k)}
                                >
                                    <EyeIcon />
                                    <Typography color='primary'>Ver</Typography>
                                </Button>
                                {k.status === '4' &&
                                    <Button
                                        color="primary"
                                        className='btn-paper-inter min-wd-btm'
                                        onClick={handleDeleteCita(k)}
                                    >
                                        <ClearIcon />
                                        <Typography color='primary'>Eliminar</Typography>
                                    </Button>
                                }
                            </div>
                        </Paper>
                    </Grid>
                )
            })
            : <Grid item xs={12} align='center'>
                <Typography color="primary" component="span" className="truncate first-span">No se encontraron resultados</Typography>
            </Grid>
        }
    </Grid>
)


export default withStyles(styles)(CardInterconsultas);