import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux';


/* MAterial UI */
import { styles } from "./perfilPublicoStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress, TextField, Button,  } from '@material-ui/core';

/*Components */
import InputSugerencia from "./inputSugerencia";

/* Actions */
import { ePageActions } from '../../../../../_actions'


class FormAddMembrecia extends Component {
    constructor(props) {
        super(props)

        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleOnSubmitEdit = this.handleOnSubmitEdit.bind(this)
    }

    handleChangeInput(e) {
        const { name, value } = e.target
        const { dispatch } = this.props;
        dispatch(ePageActions.addForm_ePAge(name, value))
    }

    handleOpenModalForm = value => () => {
        const { dispatch } = this.props;
        dispatch(ePageActions.openModal(value))
    };

    handleOnSubmit(e) {
        e.preventDefault();
        const { dispatch, form_ePage, user } = this.props;
        dispatch(ePageActions.sumitedRequest())
        if (form_ePage.nombre && form_ePage.fecha ) {
            dispatch(ePageActions.inserInfo_ePage(user, form_ePage, 4, 'membrecia'))
        }
    }
    handleOnSubmitEdit(e) {
        e.preventDefault();
        const { dispatch, form_ePage, user, itemSelected } = this.props;
        dispatch(ePageActions.sumitedRequest())
        
        if (form_ePage.nombre  && form_ePage.fecha ) {
            dispatch(ePageActions.editInfo_ePage(user, form_ePage, itemSelected.info.id, 4, 'membrecia'))
        }else{
            const edit = {
                nombre: !form_ePage.nombre ? itemSelected.info.sociedad : form_ePage.nombre,
                fecha: !form_ePage.fecha_edit ? itemSelected.info.fecha_edit : form_ePage.fecha_edit,
                // descripcion: !form_ePage.descripcion ? itemSelected.info.descripcion : form_ePage.descripcion,
            }
            dispatch(ePageActions.editInfo_ePage(user, edit, itemSelected.info.id, 4, 'membrecia'))
        }
    }

    render() {
        const { classes, submitted, loading, itemSelected, insitutos, dispatch } = this.props
        return (
            <form onSubmit={itemSelected.info ? this.handleOnSubmitEdit : this.handleOnSubmit}>
                <Grid container>
                
                    <Grid item xs={12} >
                        <InputSugerencia
                            name="nombre"
                            nombre="nombre"
                            dispatch={dispatch}
                            list={insitutos.filter(q => (q.type === '3'))}
                            placeholder="P. ej. Sociedad Venezolana de Cardiología"
                            valor={itemSelected.info ? itemSelected.info.sociedad : ''}
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.contFech}>
                        <TextField
                            required
                            fullWidth
                            id="fecha"
                            type="month"
                            name="fecha"
                            label="Fecha"
                            margin="dense"
                            className={classes.textField}
                            onChange={this.handleChangeInput}
                            defaultValue={itemSelected.info ? itemSelected.info.fecha_edit : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} >
                        <TextField
                            multiline
                            fullWidth
                            rowsMax="8"
                            margin="dense"
                            id="descripcion"
                            name="descripcion"
                            label="Descripción"
                            placeholder=""
                            onChange={this.handleChangeInput}
                            defaultValue={itemSelected.info ? itemSelected.info.descripcion : ''}
                        />
                    </Grid> */}
                </Grid>
                <div className={classes.mt_1}>
                    <Button
                        variant="contained"
                        className={classes.mr_1}
                        onClick={this.handleOpenModalForm()}
                        disabled={submitted && loading}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={submitted && loading}
                    >
                        {itemSelected.info ? 'Editar' : 'Registrar'}
                            {submitted && loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                    </Button>
                </div>
            </form>
        )
    }
}


FormAddMembrecia.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { form_ePage, submitted, loading, itemSelected } = state.ePage;
    return {
        user,
        form_ePage,
        submitted,
        loading,
        itemSelected
    };
}

const connectedFormAddMembrecia = connect(mapStateToProps)(withStyles(styles)(FormAddMembrecia));
export { connectedFormAddMembrecia as FormAddMembrecia };
