export const styles = theme => ({
    // Nuevo Post
    headerNewPost:{
        borderRadius: 4,
        backgroundColor: '#f5f6f7'
    },
    paddingTitle: {
        padding: '5px 12px',
        fontWeight: 'bold',
        fontSize: '.8rem !important',
    },
    btnClose:{
        padding: 0,
        float: 'right',
    },
    textBody: {
        padding: '12px 15px',
        cursor: 'pointer',
        display: 'flex'
    },
    textoPen:{
        padding: '9px 12px 0'
    },

    cont_body: {
        padding: '7px 15px',
        [theme.breakpoints.up("xl")]: {
            width: '70%',
            margin: 'auto',
        },
    },
    uploadImg: {
       display: 'none',
    },
    compartir:{
        marginTop: 5,
        float: 'right',
    },

    purpleAvatar: {
        width: 30,
        height: 30,
        fontSize: '1rem',
        color: '#fff',
        fontWeight: 'bold',
        marginRight: 12,
        backgroundColor: theme.palette.primary.main
    },
    progresComentario:{
        color: '#fff',
    },

    // Editar Post
    editPost:{
        padding: '0 !important',
        // [theme.breakpoints.up("sm")]: {
        //     width: 500,
        // },
        // [theme.breakpoints.up("md")]: {
        //     width: 600,
        // },
    },
    contLoadingMasPost:{
        textAlign: 'center'
    },
    loadingMasPost:{
        postion: 'relative'
    },
})