import React from 'react'

/* MAterial UI */
import { Grid, Paper, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import VisibilityIcon from '@material-ui/icons/Visibility';

/* Url's */
import { apiCardio } from '../../../../_constants/';

/* Estilos con Material UI */
import { styles } from "./historiasStyles";

const PacientesConHistoria = (props) => (
    <Grid container spacing={24}>
        {props.list.length 
            ?
            props.list.map(k => {  
                return(
                    <Grid item xs={12} sm={6} key={k.paciente_num}>
                        <Paper className="paper-historia">
                            <div className="img-paper">
                                <img className="circle" src={`${apiCardio.urlMedia}${k.img}`} alt={k.paciente} />
                            </div>
                            <div className="cont-paper">
                                <Typography color="primary" component="span">{k.paciente}</Typography>
                                <Typography component="span">{k.residencia}</Typography>
                                <IconButton aria-label="ver" className={props.classes.p0} onClick={props.select(k)}>
                                    <VisibilityIcon color='primary' />
                                </IconButton>
                            </div>
                        </Paper>
                    </Grid>
                )

            })
            :
            <Grid item xs={12}>
                <Paper className="p-1">
                    <Typography color="primary" component="span">No posee paciente con historia</Typography>
                    
                </Paper>
            </Grid>

        }
    </Grid>
)

export default withStyles(styles)(PacientesConHistoria);