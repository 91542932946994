import React from "react";
import ReactNextPaging from "react-next-paging";

/* MAterial UI */
import { Grid, Typography, IconButton, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

/* Estilos con Material UI */
import { styles } from "./eConsultorioStyle";

const PaginacionCitas = ({ classes, itemsperpage, nocolumns, items, pagesspan, handleModalOpen, handleAtenderCita, handleMarcarAtendida }) => {
    return (
        <ReactNextPaging
            itemsperpage={itemsperpage}
            nocolumns={nocolumns}
            items={items}
            pagesspan={pagesspan}
        >
            {({
                getBackButtonProps,
                getFastBackButtonProps,
                getFwdButtonProps,
                getFastFwdButtonProps,
                getSelPageButtonProps,
                nopages,
                inipagearray,
                pagesforarray,
                currentpage,
                noitems,
                initialitem,
                lastitem,
                goBackBdisabled,
                goFastBackBdisabled,
                goFwdBdisabled,
                goFastFwdBdisabled
            }) => (
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            {items.length === 0 &&
                                <div className="cita">
                                    <div className="body-cita">
                                        <Typography color='primary' component="p">Estimado especialista, no poseé citas para hoy.</Typography>
                                    </div>
                                </div>
                            }
                            {items.slice(initialitem, lastitem).map((k, index) => {
                                return (
                                    <div className="cita" key={k.id}>
                                        <div className="title">
                                            <Typography color='primary'>{k.consulta}</Typography>
                                        </div>
                                        <div className="body-cita">
                                            <Typography className={classes.colorSecundario} component="span">Paciente</Typography>
                                            <Typography color='primary' component="p">
                                                {k.familiar
                                                    ? k.familiar.nombre + ' (' + k.familiar.parentesco + ' de ' + k.nombre + ' ' + k.apellido +' )'
                                                    : k.paciente
                                                }
                                            </Typography>
                                            <div className="espaciado"/>
                                            <Typography className={classes.colorSecundario} component="span">Especialidad seleccionada</Typography>
                                            <Typography color='primary' component="p">{k.especialidad}</Typography>
                                            <div className="espaciado" />
                                            <Typography className={classes.colorSecundario} component="span">Fecha de la cita </Typography>
                                            <Typography color='primary' component="p">{k.fech_quotes}</Typography>
                                            <div className="espaciado" />
                                            <Typography className={classes.colorSecundario} component="span">Hora de la cita </Typography>
                                            <Typography color='primary' component="p">{k.attencion}</Typography>
                                            <IconButton aria-label="ver" onClick={handleModalOpen(k)}>
                                                <VisibilityIcon color='primary' />
                                            </IconButton>
                                        </div>
                                        <div className="footer-cita">
                                            <Button variant="contained" color="primary" className={classes.buttonAtender} onClick={handleAtenderCita(k)}>
                                                Atender cita
                                            </Button>
                                            <Button variant="contained" color="primary" className={classes.buttonAtendida} onClick={handleMarcarAtendida(k)}>
                                                Cita atendida
                                            </Button>
                                        </div>

                                    </div>
                                )
                            })}
                            {items.length > 1 && 
                                <Grid item xs={12} align="center" className={classes.mt07}>
                                    {noitems > 0
                                        ? [
                                            <div key={"pagingrow" + 100}>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFastBackButtonProps()}
                                                    disabled={goFastBackBdisabled}
                                                >
                                                    <FirstPageIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getBackButtonProps()}
                                                    disabled={goBackBdisabled}
                                                >
                                                    <ChevronLeftIcon />
                                                </IconButton>
                                                {Array.from(
                                                    { length: pagesforarray },
                                                    (v, i) => i + inipagearray
                                                ).map(page => {
                                                    return (
                                                        <Button
                                                            key={page}
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            className={classes.numberPagination}
                                                            {...getSelPageButtonProps({ page: page })}
                                                            disabled={currentpage === page}
                                                        >
                                                            {page}
                                                        </Button>
                                                    );
                                                })}
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFwdButtonProps()}
                                                    disabled={goFwdBdisabled}
                                                >
                                                    <ChevronRightIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFastFwdButtonProps()}
                                                    disabled={goFastFwdBdisabled}
                                                >
                                                    <LastPageIcon />
                                                </IconButton>
                                            </div>
                                        ]
                                        : null}
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                )}
        </ReactNextPaging>
    );
};

export default withStyles(styles)(PaginacionCitas);