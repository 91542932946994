import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import {BarRigth} from "../layout/barRight";
import { MenuEspecialistas } from '../layout/MenuEspecialistas';
import ProgressCircular from '../layout/progress/progressCircular';

/* Material UI */
import { Grid, Paper, Typography, Divider } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

/* Icons */
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/*Components */
import Citas from "./citas";
import Calendar from "../layout/calendarioComponent";
import ModalFullScreenCitas from "./modalFullScreenCitas";

/* Actions */
import { citasActions, menuActions } from '../../../../_actions'

/* Estilos con Material UI */
import { styles } from "./homeCitasStyle";

class Homecitas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
        };
        this.handleVolver = this.handleVolver.bind(this)
        this.handleSelectFech = this.handleSelectFech.bind(this)
        const { user, dispatch } = this.props;
        dispatch(menuActions.menuActive(2));
        dispatch(citasActions.getCitas(user))
    }

    handleVolver(event) {
        event.preventDefault();
        this.props.dispatch(citasActions.detalleView())
    }

    handleChangeExpanded = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };
    handleModalOpen = data => () => {
        this.props.dispatch(citasActions.selectCita(data.id))
        this.props.dispatch(citasActions.detalleViewModal())
    };

    handleModalClose = () => {
        this.props.dispatch(citasActions.detalleViewModal())
    };

    handleSelectFech(fecha){
        if (fecha) {
            this.props.dispatch(citasActions.filterCitas(fecha))
        }
    }
    
    render() {
        const { expanded } = this.state;
        const { dispatch, user, fechas, filterCitas, viewDetail, selectCita, loadingCita, openModalDetalle } = this.props;
        return (
            <section className="body" ref={this.container}>
                <MenuEspecialistas />
                <BarRigth
                    cita={true}
                />

                { loadingCita
                    ? <ProgressCircular />
                    : viewDetail 
                    ? <div className="content">
                        <div className="breadcrumbs mb-06">
                            <Typography color="primary" className="cursor-pointer" onClick={this.handleVolver}>
                                Ver calendario
                            </Typography>
                            <NavigateNextIcon color="primary" />
                            <Typography color="secondary">Citas para</Typography>
                        </div>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Citas
                                    citas={fechas}
                                    list={filterCitas}
                                    expanded={expanded}
                                    handleModalOpen={this.handleModalOpen}
                                    handleChangeExpanded={this.handleChangeExpanded}
                                />

                            </Grid>
                        </Grid>
                    </div>
                    : <div className="content">
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Paper className="paper p-0">
                                    <div className="title-paper p-12">
                                        <Typography color="primary" component="span" className="mb-06"> Calendario de citas *</Typography>
                                        <Divider />
                                        <br/>
                                        <Typography color="textSecondary" className="color-gris" component="span">* El día que tengas una cita aparecerá en color morado.</Typography>
                                    </div>
                                    <div className='contBodyPaper'>
                                        <Calendar
                                            user={user}
                                            dispatch={dispatch}
                                            citasAgendadas={fechas}
                                            handleSelectFech={this.handleSelectFech}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                }
                <ModalFullScreenCitas
                    open={openModalDetalle}
                    cita={selectCita}
                    handleModalClose={this.handleModalClose}
                />
                
            </section>

        );
    }
}

Homecitas.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { fechas, filterCitas, selectCita, viewDetail, loadingCita, openModalDetalle } = state.citasReducer;
    return {
        user,
        fechas,
        loadingCita,
        selectCita,
        viewDetail,
        filterCitas,
        openModalDetalle,
    };
}

const connectedHomecitas = connect(mapStateToProps)(withStyles(styles)(Homecitas));
export { connectedHomecitas as Homecitas };