import { incidenciaConstants } from '../../_constants';

const initialState = {
    loading: false,
    preloaderInci: true,
    viewDetail: 'lista',
    submitInci: false,
    fadeInci: false,
    cantidadElementos: 5,
    deleteIncidencia: [],
};

export function incidenciaReducer(state = initialState, action) {
    switch (action.type) {
        case incidenciaConstants.REQUEST_INCIDENCIAS:
            return {
                ...state,
                preloaderInci: action.data
            };
        case incidenciaConstants.GET_INCIDENCIAS:
            return Object.assign({}, state, {
                incidencias: action.data,
                preloaderInci: !state.preloaderInci,
            });
        case incidenciaConstants.FAIL_INCIDENCIAS:
            return Object.assign({}, state, {
                incidencias: [],
                preloaderInci: !state.preloaderInci,
            });
        case incidenciaConstants.GET_TYPE_INCIDEN:
            return Object.assign({}, state, {
                typeInciden: action.data,
            });
        case incidenciaConstants.CAMBIAR_VIEW:
            return Object.assign({}, state, {
                viewDetail: action.data,
            });
        case incidenciaConstants.MOSTRAR_HILO_INCIDENCIA:
            return Object.assign({}, state, {
                hiloIncidencia: action.data,
            });
        case incidenciaConstants.FADE_NEW_INCIDEN:
            return Object.assign({}, state, {
                fadeInci: !state.fadeInci,
            });
        case incidenciaConstants.FORM_NUEVA_INCIDENCIA:
            return Object.assign({}, state, {
                formIncidencia: {
                    ...state.formIncidencia,
                    [action.data.name] : action.data.value
                }
            });
        case incidenciaConstants.SUBMIT_FORM:
            return Object.assign({}, state, {
                submitInci: !state.submitInci
            });
        case incidenciaConstants.SUBMIT_REQUEST:
            return Object.assign({}, state, {
                loading: !state.loading
            });
        case incidenciaConstants.SUBMIT_SUCCES:
            return Object.assign({}, state, {
                formIncidencia: {},
                loading: !state.loading,
                submitInci: !state.submitInci,
                incidencias: action.data
            });
        case incidenciaConstants.CAMBIAR_CANTIDAD_ELEMENTO_POR_PAGINA:
            return Object.assign({}, state, {
                cantidadElementos: action.data
            });
        case incidenciaConstants.DESCRIPTION_YARN_INCIDEN:
            return Object.assign({}, state, {
                descriptionYarn: action.data
            });
        case incidenciaConstants.SUCCES_REGIS_HILO_INCIDEN:
            return Object.assign({}, state, {
                incidencias: state.incidencias.map(k => {
                    if (k.id === action.data.incidencia) {
                        k.hilo.push(action.data.datos);
                    }                    
                    return k
                }),
                loading: !state.loading,
                submitInci: !state.submitInci,
                descriptionYarn: '',

            });
        case incidenciaConstants.SUCCES_REPORTE_VISTO:
            return Object.assign({}, state, {
                incidencias: state.incidencias.map(k => {
                    if (k.id === action.data) {
                        k.viewed = "0";
                    }                    
                    return k
                }),

            });
        case incidenciaConstants.SELECT_INCIDENCIA_DELETE:
            return Object.assign({}, state, {
                deleteIncidencia: state.deleteIncidencia.map(k => {
                    return k
                }),
            });
        case incidenciaConstants.DESELECT_INCIDENCIA_DELETE:
            return Object.assign({}, state, {
                deleteIncidencia: state.deleteIncidencia.filter(q => (q !== action.data))
            });
        case incidenciaConstants.SUCCES_DELETE_INCIENCIA:
            return Object.assign({}, state, {
                incidencias: state.incidencias.filter(k => (
                    k.id !== action.data.filter(q => (q === k.id))[0]
                )),
                deleteIncidencia: []
            });
        default:
            return state
    }
}