import { alertActions } from '../alert.action';
import { configCuentaService } from "../../_services";
import { configConstants } from '../../_constants';

export const horarioActions = {
    getHorario,
    selectDiaTrabajo,
    horarioTrabajo,
    regisHorarioEspecialista,
    diaInactivo,
};

function getHorario(user) {
    return dispatch => {
        configCuentaService.getHorario(user)
            .then(
                data => dispatch(success(data)),
                // error => dispatch(failure(error.toString()))
            );
    };

    function success(data) { return { type: configConstants.GET_HORARIO, data } }
}

function selectDiaTrabajo(selected, chipHorario, user) {
    chipHorario.map(function (e) {
        if (e.id === selected.id) {
            e.selected = !selected.selected;
            e.h_desde = "";     e.min_desde = "";     e.t_desde = "";
            e.h_hasta = "";     e.min_hasta = "";     e.t_hasta = "";
        }
        return { chipHorario }
    });
    const data = chipHorario

    return dispatch => {
        dispatch(request());
        dispatch(success(data));
        if (!selected.selected) {
            configCuentaService.deSelectDiaHorario(user, selected)
        }
    };

    function request() { return { type: configConstants.GET_HORARIO_REQUEST } }
    function success(data) { return { type: configConstants.SELECT_HORARIO, data } }
}

function horarioTrabajo(idDia, name, value, chipHorario) {
    chipHorario.map(function (e) {
        if (e.id === idDia) {
            // e.datos = { [name]: value }
            e[name] = value
        }
        return { chipHorario }
    });
    const data = chipHorario
    return dispatch => {
        dispatch(request());
        dispatch(success(data));
    };

    function request() { return { type: configConstants.GET_HORARIO_REQUEST } }
    function success(data) { return { type: configConstants.SELECT_HORARIO, data } }
    
}

function regisHorarioEspecialista(user, horarioMedico) {
    let validCampo = false;
    horarioMedico.map(k => {
        if (k.selected) {
            // if (k.h_desde === "" || k.h_hasta === "" || k.min_desde === "" || k.min_hasta === "" || k.t_desde === "" || k.t_hasta === "") {
            if (k.h_desde === "" || k.h_hasta === "" || k.min_desde === "" || k.min_hasta === "") {
                validCampo = false;
                return ''
            } else {
                validCampo = true;
            }
        }
        return ''
    })
    return dispatch => {
        dispatch(request(true));
        if (validCampo) {
            configCuentaService.registHorarioMedio(user, horarioMedico)
                .then(
                    data => {
                        dispatch(success(false));
                        if (data.success) {
                            dispatch(alertActions.success(data.message));
                        }else{
                            dispatch(alertActions.error(data.message));
                        }
                    }
                )
        }else{
            dispatch(sumbmited(true));
        }
    };

    function request(data) { return { type: configConstants.REGIS_HORARIO_REQUEST, data } }
    function success(data) { return { type: configConstants.REGIS_HORARIO_REQUEST, data } }
    function sumbmited(data) { return { type: configConstants.SUMBITED_HORARIO_REQUEST, data } }
}

function diaInactivo(dia, fechas, user) {
    let borrar = false;
    if (fechas) {
        fechas.map(k => {
            if (k.selected && k.fech_inactive === dia) {
                borrar = true;
                return ''
            }
            return ''
        })
    }

    return dispatch => {
        if (borrar) {
            configCuentaService.activarInactivarFecha(dia, user, 0)
                .then(
                    data => {
                        if (data.success) {
                            dispatch(delate(dia));
                        }else{
                            dispatch(alertActions.error(data.message));
                        }
                    }
                )
        } else {
            /*EL N° 1 indica que se va a registrar la fecha como dia inactivo*/
            configCuentaService.activarInactivarFecha(dia, user, 1)
                .then(
                    data => {
                        if (data.success) {
                            dispatch(success(data.data));
                        }else{
                            dispatch(alertActions.error(data.message));
                        }
                    }
                )
        }
    }
    // function request(data) { return { type: configConstants.REGIS_HORARIO_REQUEST, data } }
    function success(data) { return { type: configConstants.INACTIVAR_DIA_ESPECIALISTA, data } }
    function delate(data) { return { type: configConstants.ACTIVAR_DIA_ESPECIALISTA, data } }
}