import { apiCardio } from '../_constants';

export const notifyService = {
    getNoificaciones,
    putMarcarVistos,
};

function getNoificaciones(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlNotify}getNotify`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function putMarcarVistos(user, type) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ type })
    };

    return fetch(`${apiCardio.urlNotify}markAsSeen`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}