import React from 'react'
import { Link } from 'react-router-dom'

/* MAterial UI */
import { styles } from "./homePageStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Button} from '@material-ui/core';

/* Iconos */
import DoneIcon from '@material-ui/icons/Done';

const CompletarPerfil = ({ classes, datos, username }) => (
    <Grid container spacing={24}>
        <Grid item xs={12} className="pb-0">
            <Typography component="p" color="textSecondary">Completa tu cuenta</Typography>
            <Typography component="p" color="textSecondary"><b className={classes.completados}>{datos.completado} de 6 </b> COMPLETADOS</Typography>
        </Grid>
        <Grid item xs={6}>
            <Paper className="paper ql-align-center">
                <Typography component="span" color="textSecondary" className={classes.negrita}>
                    Configuración general
                    {datos.general && <DoneIcon className={classes.icondone} />}
                </Typography>
                <Typography component="p" color="textSecondary" className="mb-06">Agrega tus datos y selecciona tu profesión.</Typography>
                <Link to="/configuracion/general">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Agregar
                    </Button>
                </Link>
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper className="paper ql-align-center">
                <Typography component="span" color="textSecondary" className={classes.negrita}>
                    Horario de atención
                    {datos.horario && <DoneIcon className={classes.icondone} />}
                </Typography>
                <Typography component="p" color="textSecondary" className="mb-06">Agrega tu horario según tu disponibilidad.</Typography>
                <Link to="/configuracion/horarios">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Agregar
                    </Button>
                </Link>
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper className="paper ql-align-center">
                <Typography component="span" color="textSecondary" className={classes.negrita}>
                    Honorario profesional
                    {datos.honorario && <DoneIcon className={classes.icondone} />}
                </Typography>
                <Typography component="p" color="textSecondary" className="mb-06">Selecciona la forma de pago y costo de tus consultas en línea </Typography>
                <Link to="/configuracion/formaPagos">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Agregar
                    </Button>
                </Link>
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper className="paper ql-align-center">
                <Typography component="span" color="textSecondary" className={classes.negrita}>
                    Paraclínicos para citas 
                   {datos.paraclinicos && <DoneIcon className={classes.icondone} />}
                </Typography>
                <Typography component="p" color="textSecondary" className="mb-06">Agrega la opción para que tus pacientes puedan adjuntar una imagen, documento o datos. </Typography>
                <Link to="/configuracion/requisitos">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Agregar
                    </Button>
                </Link>
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper className="paper ql-align-center">
                <Typography component="span" color="textSecondary" className={classes.negrita}>
                    Agrega tu foto de perfil 
                    {datos.img_perfil && <DoneIcon className={classes.icondone} />}
                </Typography>
                <Typography component="p" color="textSecondary" className="mb-06">Agrega una foto de perfil representativa.</Typography>
                <Link to={'/epage/'+username}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Agregar
                    </Button>
                </Link>
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <Paper className="paper ql-align-center">
                <Typography component="span" color="textSecondary" className={classes.negrita}>
                    Agrega tus datos profesionales
                    {datos.datos_profecionales && <DoneIcon className={classes.icondone} />}
                </Typography>
                <Typography component="p" color="textSecondary" className="mb-06">Incluye tu experiencia laboral, educación, premios y reconocimientos. </Typography>
                <Link to={'/epage/'+username}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Agregar
                    </Button>
                </Link>
            </Paper>
        </Grid>
        
    </Grid>
)


export default withStyles(styles)(CompletarPerfil);