import React from 'react'

/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

/* Iconos */
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Clear';

/* Estilos con Material UI */
import { styles } from "../configuracionStyle";
import { Typography } from '@material-ui/core';
const FormNewRequisitoCita = (props) => (
    <div>
        <Dialog
            open={props.open}
            onClose={!props.submitted && props.handleOpenCloseDialog('newR')}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="form-dialog-title" className={props.classes.titleDialog} color="primary">
                <Typography color="primary" component="span" className={props.classes.titleDialogText}>Requisitos para la cita  </Typography>
                            
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            type="text"
                            margin="dense"
                            name="nombreRequisito"
                            label="Nombre del requisito"
                            value={props.newRequisito.nombreRequisito}
                            onChange={props.handleChangeInputNuevoRequisito}
                            error={props.submitted && !props.newRequisito.nombreRequisito}
                            helperText={props.submitted && !props.newRequisito.nombreRequisito &&
                                <span className="lblError" >Campo Requerido</span>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <FormControl 
                            fullWidth
                            margin="dense"
                            error={props.submitted && !props.newRequisito.tipoDeDato}
                        >
                            <InputLabel shrink htmlFor="pais-label-placeholder">Tipo de requisito</InputLabel>
                            <Select
                                value={props.newRequisito.tipoDeDato}
                                onChange={props.handleChangeInputNuevoRequisito}
                                input={<Input name="tipoDeDato" id="pais-label-placeholder" />}
                                displayEmpty
                                name="tipoDeDato"
                            >
                                <MenuItem value=""><em>Opción</em></MenuItem>
                                {props.tipoDato &&
                                    props.tipoDato.map(list => {
                                        return (
                                            <MenuItem value={list.id} key={list.id} >{list.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            {props.submitted && !props.newRequisito.tipoDeDato &&
                                <FormHelperText>Campo Requerido</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    {(props.newRequisito.tipoDeDato === '2' || props.newRequisito.tipoDeDato === '3') && 
                        <Grid item xs={12} >
                            <Grid container>
                                <Grid item xs={10} >
                                    <TextField
                                        fullWidth
                                        type="text"
                                        margin="normal"
                                        name="nombreOpcion"
                                        label="Nombre de la opción"
                                        value={props.nombreOpcion}
                                        onChange={props.handleChangeInputOpcion}
                                        error={props.submitted && !props.nombreOpcion}
                                        helperText={props.submitted && !props.nombreOpcion &&
                                            <span className="lblError" >Campo Requerido</span>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2} align="center" className={props.classes.pt_1_2} >
                                    <Tooltip title="Agregar opción">
                                        <IconButton className={props.classes.icon_button} aria-label="Add" onClick={props.handleAddOpcionRequisito}>
                                            <AddIcon className={props.classes.textPrimario} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} >
                                    {props.newRequisito.opciones &&
                                        <List className={props.classes.listOpciones} >
                                            {   
                                                props.newRequisito.opciones.map(j=>{
                                                    let datos = ''
                                                    j.id ? datos = { nombreOpcion: j, condicion: '2', idopcion: j.id } : datos = { nombreOpcion: j, condicion: '1' }
                                                        return(
                                                            <div key={j.id ? j.id : j.value}>
                                                                <ListItem >
                                                                    <ListItemText
                                                                        primary={j.id ? j.value : j.value}
                                                                        className="listaRequisitos"
                                                                    />
                                                                    <ListItemSecondaryAction>
                                                                        <Tooltip title="Eliminar opción">
                                                                            <IconButton aria-label="Delete" onClick={props.handleDeleteOpcionRequisito(datos)}>
                                                                                <DeleteIcon color="primary"/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </ListItemSecondaryAction>
                                                                </ListItem>
                                                                <Divider />
                                                            </div>
                                                        )
                                                })
                                            }
                                        </List>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                    }
                </Grid>
                    
            </DialogContent>
            <DialogActions className={props.classes.contBtnDialog}>
                <Button
                    variant="contained"
                    disabled={props.submitted && props.loading}
                    onClick={props.handleOpenCloseDialog('newR')} 
                >
                    Cancel
                </Button>

                <Button color="primary" variant="contained" 
                    onClick={props.handleSubmitNuevoRequisito}
                    disabled={props.submitted && props.loading}
                >
                    Registrar
                        {props.submitted && props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                </Button>
            </DialogActions>
        </Dialog>
    </div>
)

export default withStyles(styles)(FormNewRequisitoCita);