import React from 'react'

/* MAterial UI */
import { styles } from "./asesoriaChatStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';

/* Url's */
import PaginacionCitas from "./paginationCitas";


const ListCitasChat = (props) => (
    <div>
        {props.list.length
            ?<div>
                <PaginacionCitas
                    user={props.user}
                    itemsperpage={props.list.length}
                    nocolumns={4}
                    items={props.list}
                    pagesspan={4}
                    handleVerHilo={props.handleVerHilo}
                    selectReportesDelete={props.handleSelectReporteForDelete}
                />
            </div>

           
            : <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Paper className="paper-bar-left">
                        <Typography color="primary" component="span">No posee cita para atender.</Typography>
                    </Paper>
                </Grid>
            </Grid>
        }
    </div>
)

export default withStyles(styles)(ListCitasChat);