import React from 'react'

/* MAterial UI */
import { styles } from "../configuracionStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Tooltip, IconButton, TextField, FormControl, Select, Input, MenuItem, FormHelperText, Button, CircularProgress, Divider } from '@material-ui/core';

/* Iconos */
import EditIcon from '@material-ui/icons/Edit';

import SugerenciaProvincia  from "./sugerenciaProvincias";

const FormDatosBasicos = (props) => (
    <Grid item xs={12}>
        <Paper className="paper">
            <div className="title-paper">
                <Typography color="primary" component="span" className="mb-06">Datos personales</Typography>
                <Divider/>
            </div>
            <div className=''>
                <Grid container>
                    <Grid item xs={10} sm={11}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                <Typography component="span" color="primary"> Nombre de usuario:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8}>
                                {!props.editDatosPerso
                                    ? <Typography component="span"> {props.user.username}</Typography>
                                    : <TextField
                                        fullWidth
                                        id="username"
                                        name="username"
                                        margin="none"
                                        autoComplete="off"
                                        value={props.userEdit.username}
                                        onChange={props.handleChangeInput}
                                        onKeyUp={props.handleCreateUsername}
                                        error={props.submitGeneral && !props.userEdit.username}
                                        helperText={props.submitGeneral && !props.userEdit.username &&
                                            <span className="lblError" >Campo Requerido</span>
                                        }
                                    />
                                }

                            </Grid>
                            <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                <Typography component="span" color="primary"> Nombre:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8}>
                                {!props.editDatosPerso
                                    ? <Typography component="span"> {props.user.name}</Typography>
                                    : <TextField
                                        fullWidth
                                        id="name"
                                        name="name"
                                        margin="none"
                                        autoComplete="off"
                                        value={props.userEdit.name}
                                        onChange={props.handleChangeInput}
                                        error={props.submitGeneral && !props.userEdit.name}
                                        helperText={props.submitGeneral && !props.userEdit.name &&
                                            <span className="lblError" >Campo Requerido</span>
                                        }
                                    />
                                }

                            </Grid>
                            <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                <Typography component="span" color="primary"> Apellido:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8}>
                                {!props.editDatosPerso
                                    ? <Typography component="span"> {props.user.surname}</Typography>
                                    : <TextField
                                        fullWidth
                                        id="surname"
                                        margin="none"
                                        name="surname"
                                        autoComplete="off"
                                        value={props.userEdit.surname}
                                        onChange={props.handleChangeInput}
                                        error={props.submitGeneral && !props.userEdit.surname}
                                        helperText={props.submitGeneral && !props.userEdit.surname &&
                                            <span className="lblError" >Campo Requerido</span>
                                        }
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                <Typography component="span" color="primary"> Fecha de nacimiento:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8}>
                                {!props.editDatosPerso
                                    ? <Typography component="span"> {props.user.birthdateView}</Typography>
                                    : <TextField
                                        fullWidth
                                        type="date"
                                        id="birthdate"
                                        margin="none"
                                        name="birthdate"
                                        autoComplete="off"
                                        value={props.userEdit.birthdate}
                                        onChange={props.handleChangeInput}
                                        error={props.submitGeneral && !props.userEdit.birthdate}
                                        helperText={props.submitGeneral && !props.userEdit.birthdate &&
                                            <span className="lblError" >Campo Requerido</span>
                                        }
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                <Typography component="span" color="primary"> Documento de identidad:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8}>
                                {!props.editDatosPerso
                                    ? <Typography component="span"> {props.user.document}</Typography>
                                    : <div className={props.classes.contDocumento}>
                                        <FormControl
                                            className={props.classes.paddingTypeDocument}
                                            error={props.submitGeneral && !props.userEdit.typedocument}
                                        >
                                            <Select
                                                value={props.userEdit.typedocument}
                                                onChange={props.handleChangeInput}
                                                input={<Input name="type-document" id="type-document-label-placeholder" />}
                                                displayEmpty
                                                name="typedocument"
                                            >
                                                <MenuItem value=""><em>Opción</em></MenuItem>
                                                {props.listDocument &&
                                                    props.listDocument.map(list => {
                                                        return (
                                                            <MenuItem value={list.id} key={list.id} >{list.namedoc}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            {props.submitGeneral && !props.userEdit.typedocument &&
                                                <FormHelperText>Campo Requerido</FormHelperText>
                                            }
                                        </FormControl>
                                        <TextField
                                            id="document"
                                            margin="none"
                                            name="document"
                                            autoComplete="off"
                                            className={props.classes.imputDocumento}
                                            value={props.userEdit.document}
                                            onChange={props.handleChangeInput}
                                            error={props.submitGeneral && !props.userEdit.document}
                                            helperText={props.submitGeneral && !props.userEdit.document &&
                                                <span className="lblError" >Campo Requerido</span>
                                            }
                                        />
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                <Typography component="span" color="primary"> Pais de residencia:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8}>
                                {!props.editDatosPerso
                                    ? <Typography component="span"> {props.user.pais}</Typography>
                                    : <FormControl 
                                        fullWidth
                                        error={props.submitGeneral && !props.userEdit.country_work}
                                    >
                                        <Select
                                            value={props.userEdit.country_work}
                                            onChange={props.handleChangeInput}
                                            input={<Input name="country_work" id="pais-label-placeholder" />}
                                            displayEmpty
                                            name="country_work"
                                        >
                                            <MenuItem value=""><em>Opción</em></MenuItem>
                                            {props.listPaises &&
                                                props.listPaises.map(list => {
                                                    return (
                                                        <MenuItem value={list.id} key={list.id} >{list.description}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                        {props.submitGeneral && !props.userEdit.country_work &&
                                            <FormHelperText>Campo Requerido</FormHelperText>
                                        }
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                <Typography component="span" color="primary"> Provincia de residencia:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8}>
                                {!props.editDatosPerso
                                    ? <Typography component="span"> {props.user.name_province}</Typography>
                                    : <SugerenciaProvincia
                                        name="name_province"
                                       // dispatch={dispatch}
                                        list={props.provincias}
                                        handleChangeInput={props.handleChangeInputSugerencia}
                                        valor={props.userEdit.name_province}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                <Typography component="span" color="primary"> Teléfono:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={8}>
                                {!props.editDatosPerso
                                    ? <Typography component="span"> {props.user.phone}</Typography>
                                    : <TextField
                                        fullWidth
                                        id="phone"
                                        name="phone"
                                        margin="none"
                                        autoComplete="off"
                                        value={props.userEdit.phone}
                                        onChange={props.handleChangeInput}
                                        error={props.submitGeneral && !props.userEdit.phone}
                                        helperText={props.submitGeneral && !props.userEdit.phone &&
                                            <span className="lblError" >Campo Requerido</span>
                                        }
                                    />
                                }
                            </Grid>
                            {!props.editDatosPerso &&
                                <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                    <Typography component="span" color="primary"> Correo:</Typography>
                                </Grid>
                            }
                            {!props.editDatosPerso &&
                                <Grid item xs={12} sm={7} md={8}>
                                    <Typography component="span"> {props.user.correo}</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {props.editDatosPerso ?
                        <Grid item xs={12} >
                            <div className="pt-1">
                                <Button variant="contained" className="mr_1" onClick={props.handleEditarInfo}>
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick={props.handleOnSubmitEdit}
                                    disabled={props.submitGeneral && props.loadding}
                                >
                                    Actualizar
                                            {props.submitGeneral && props.loadding && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                            </div>
                        </Grid>
                        : <Grid item xs={2} sm={1} align='center'>
                            <Tooltip title="Editar datos personales" className="toolTip">
                                <IconButton aria-label="Delete" onClick={props.handleEditarInfo}>
                                    <EditIcon color="primary"></EditIcon>
                                </IconButton>
                            </Tooltip>
                            <Button size="small" className={props.classes.minWd48} color="primary" onClick={props.handleEditarInfo}>Editar</Button>
                            
                        </Grid>
                    }
                </Grid>
            </div>
        </Paper>
    </Grid>
)


export default withStyles(styles)(FormDatosBasicos);