import React from 'react'

/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';


/* Icon's */
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


/* Url's */
// import { apiCardio } from '../../../../_constants';

/* Estilos con Material UI */
import { styles } from "./homeCitasStyle";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const ModalFullScreenCitas = (props) => (
    <div>
        {props.cita &&
            props.cita.map(k => {
                let status = <ErrorOutlineIcon className={`${props.classes.iconStatus} ${props.classes.error}`}/>
                if (k.status === 'Verificada'){
                    status = <DoneIcon className={`${props.classes.iconStatus} ${props.classes.succes}`} />
                }
                return (
                    <Dialog
                        key={k.id}
                        fullScreen
                        className={props.classes.modal}
                        open={props.open}
                        onClose={props.handleModalClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar className={props.classes.appBar}>
                            <Toolbar>
                                <IconButton color="inherit" onClick={props.handleModalClose} aria-label="Close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={props.classes.flex}>
                                    Detalle de cita
                                </Typography>
                            </Toolbar>
                        </AppBar>


                        <Grid container>
                            <Grid item xs={12}>
                                <div className='contTitlePaper'>
                                    <Typography className={props.classes.p1} component="h3">
                                        <Tooltip title={k.status}>
                                            {status}
                                        </Tooltip>
                                        Datos de la cita 
                                    </Typography>
                                    
                                    <Divider variant="middle" />
                                </div>
                                <div className='contBodyPaper'>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <div className={props.classes.contHistoria}>
                                                <Typography className={props.classes.span} component="span"> Paciente a atender</Typography>
                                                <Typography className={props.classes.pHistoria} component="p"> 
                                                    {k.familiar
                                                        ? ' ' + k.familiar.name + ' ' + k.familiar.lasname
                                                        : ' ' + k.paciente
                                                    }
                                                </Typography>
                                                <Divider />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <div className={props.classes.contHistoria}>
                                                <Typography className={props.classes.span} component="span"> Fecha y hora de la cita</Typography>
                                                <Typography className={props.classes.pHistoria} component="p"> {k.fech_quotes} {k.hour_attention}</Typography>
                                                <Divider />
                                            </div>
                                        </Grid>
                                        {k.familiar &&
                                            <Grid item xs={12} sm={6} md={4}>
                                                <div className={props.classes.contHistoria}>
                                                    <Typography className={props.classes.span} component="span"> Familiar del paciente</Typography>
                                                <Typography className={props.classes.pHistoria} component="p"> {k.nombre} {k.apellido}</Typography>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                        }
                                        {k.familiar &&
                                            <Grid item xs={12} sm={6} md={4}>
                                                <div>
                                                    <Typography className={props.classes.span} component="span"> Parentesco</Typography>
                                                    <Typography className={props.classes.pHistoria} component="p"> {k.familiar.parentesco}</Typography>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={k.familiar ? 6 : 12} md={k.familiar ? 8 : 12}>
                                            <div>
                                                <Typography className={props.classes.span} component="span"> Motivo de la consulta </Typography>
                                                <Typography className={props.classes.pHistoria} component="p"> {k.motive} </Typography>
                                                <Divider />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                    
                            <Grid item xs={12}>
                                <div className='contTitlePaper'>
                                    <Typography className={props.classes.p1} component="h3"> Paraclínicos de la cita </Typography>
                                    <Divider variant="middle" />
                                </div>
                                <div className='contBodyPaper'>

                                    {k.documentos.length
                                        ?
                                        <Grid container spacing={24}>
                                            {k.documentos &&
                                                k.documentos.map(j => {
                                                    if (j.despcription === 'file'){
                                                        return (
                                                            <Grid item xs={12} sm={4} md={3} key={j.id}>
                                                            
                                                                <a href={j.valor} target="back" download={j.name}>
                                                                    <Paper className={props.classes.carDocumento}>
                                                                        <Typography color="primary" className={props.classes.pDocumento} component="p"> {j.name} </Typography>
                                                                    </Paper>
                                                                </a>
                                                            </Grid>
                                                        )
                                                    }else{
                                                        return (
                                                            <Grid item xs={12} sm={6} md={4} key={j.id}>
                                                                <div className={props.classes.contHistoria}>
                                                                    <Typography className={props.classes.span} component="span"> {j.name}</Typography>
                                                                    <Typography className={props.classes.pHistoria} component="p"> {j.valor}</Typography>
                                                                    <Divider />
                                                                </div>
                                                            </Grid>
                                                        )
                                                    }
                                                })
                                            }
                                        </Grid>
                                        : <Typography className={props.classes.pHistoria} component="p"> No posee paraclínicos registrados </Typography>
                                    }
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className='contTitlePaper'>
                                    <Typography className={props.classes.p1} component="h3"> Historia personal del paciente </Typography>
                                    <Divider variant="middle" />
                                </div>
                                {k.familiar 
                                    ? <div className='contBodyPaper'>
                                        <Grid container spacing={24}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <div className={`${props.classes.contHistoria}`} >
                                                    <Typography className={props.classes.span} component="span"> Fecha de nacimiento  </Typography>
                                                    <Typography className={props.classes.pHistoria} component="p"> {k.familiar.birthday} </Typography>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <div className={`${props.classes.contHistoria}`} >
                                                    <Typography className={props.classes.span} component="span"> Peso  </Typography>
                                                    <Typography className={props.classes.pHistoria} component="p"> {k.familiar.weight} kg </Typography>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <div className={`${props.classes.contHistoria}`} >
                                                    <Typography className={props.classes.span} component="span"> Estatura  </Typography>
                                                    <Typography className={props.classes.pHistoria} component="p"> {k.familiar.height} cm</Typography>
                                                    <Divider />
                                                </div>
                                            </Grid>
                                            { k.familiar.enfermedad && 
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div>
                                                        <Typography className={props.classes.span} component="span"> ¿Padece de alguna enfermedad?  </Typography>
                                                        <Typography className={props.classes.pHistoria} component="p"> {k.familiar.enfermedad}</Typography>
                                                        <Divider />
                                                    </div>
                                                </Grid>
                                            }
                                            {k.familiar.hospitalizado && 
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div>
                                                        <Typography className={props.classes.span} component="span"> ¿Ha sido hospitalizado u operado? Especifique la causa.  </Typography>
                                                        <Typography className={props.classes.pHistoria} component="p"> {k.familiar.hospitalizado}</Typography>
                                                        <Divider />
                                                    </div>
                                                </Grid>
                                            }
                                            {k.familiar.medicamento && 
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div>
                                                        <Typography className={props.classes.span} component="span"> ¿Toma algún medicamento? </Typography>
                                                        <Typography className={props.classes.pHistoria} component="p"> {k.familiar.medicamento}</Typography>
                                                        <Divider />
                                                    </div>
                                                </Grid>
                                            }
                                        </Grid>
                                    </div>
                                    : k.hisotoria 
                                        ?<div className='contBodyPaper'>
                                            <Grid container spacing={24}>
                                                {k.hisotoria &&
                                                    k.hisotoria.map(h =>{
                                                        return(
                                                            <Grid key={h.id} item xs={12} sm={6} md={4}>
                                                                <div className={`${props.classes.contHistoria}`} >
                                                                    <Typography className={props.classes.span} component="span"> {h.name} </Typography>
                                                                    {h.name !== 'Estatura' && h.name !== 'Peso' && !h.campo_espesificar ? <Typography className={props.classes.pHistoria} component="p"> {h.valor} </Typography> : ''}
                                                                    {h.name === 'Estatura' ? <Typography className={props.classes.pHistoria} component="p"> {h.valor} cm </Typography> : '' }
                                                                    {h.name === 'Peso' ? <Typography className={props.classes.pHistoria} component="p"> {h.valor} kg </Typography> : '' }
                                                                    {h.campo_espesificar ? <Typography className={props.classes.pHistoria} component="p"> {h.valor}, {h.campo_espesificar} </Typography> : '' }
                                                                    <Divider />
                                                                </div>

                                                            </Grid>
                                                        )
                                                    })

                                                }
                                            </Grid>
                                        </div>
                                        :<div className='contBodyPaper'>
                                            <Grid container spacing={24}>
                                                <Grid item xs={12}>
                                                    <Typography className={props.classes.pHistoria} component="p"> El paciente no ha llenado su historia personal </Typography>
                                                </Grid>
                                            </Grid>
                                        </div> 
                                }
                            </Grid>
                        </Grid>

                        <Divider />

                    </Dialog>

                )

            })
        }
    </div>
)


export default withStyles(styles)(ModalFullScreenCitas);