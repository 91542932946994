const drawerWidth = 180;
export const styles = theme => ({
    // Menu configuracion 
    root: {
        display: "flex"
    },
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            flexShrink: 0,
        }
    },
    minWd48:{
        minWidth : 48,
        padding: 0,
    },
    drawerPaper: {
        border: 0,
        width: drawerWidth,
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#edf0f1',
    },
    namePage:{
        marginBottom: 0,
        fontSize: '1rem',
        fontWeight: 'bold',
        padding: '12px 6px 6px',
        color: theme.palette.secondary.main,
    },

    // Requisitos
    title_expansion: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    listOpciones: {
        position: 'relative',
        overflow: 'auto',
        maxHeight: 150
    },
    pt_1_2: {
        paddingTop: '1.2rem',
    },
    titleDialog: {
        padding: '8px 24px 0px',
    },
    titleDialogText:{
        fontSize: 17
    },

    paddingTypeDocument:{
        paddingRight: 12,
    },
    contDocumento:{
        display: 'flex',
    },
    imputDocumento:{
        flexGrow: 1,
    },
    txtZelle:{
        width: '50%',
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        }
    },
    p12:{
        padding: '12px',
    },
})