import React from 'react'
import { Link } from 'react-router-dom'

/* MAterial UI */
import '../index.css';
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Button } from '@material-ui/core';

/* Url's */
import { apiCardio } from '../../../../../_constants';
const CardMedicos = ({ classes, medicos, delete_card }) => (
   <section className="cont-card-med mt-1">
        {medicos.length 
            ? medicos.map( k => {
                const paisTrabajo = k.paistrabajo === 'Sin Seleccion' ? '' : k.paistrabajo;
		        const province = k.name_province !== '' ? k.name_province+', ' : '';
                return(
                    <div className={`card-med ${delete_card}`} key={k.num}>
                        <div className="body-card">
                            <div className="cont-img">
                                <div className="portada-card">
                                    <div className="chip"></div>
                                    <img src={`${apiCardio.urlMedia}${k.portada}`} alt={`Portada de ${k.name}`}/>
                                </div>
                                <div className="foto-perfil">
                                    <Avatar alt={`Especialista ${k.name}`} src={`${apiCardio.urlMedia}${k.foto}`} className="avatar-perfil" />
                                </div>
                            </div>
                            <div className="cont-text-card">
                                <Typography color="primary" component="span" className="truncate first-span">{k.name} {k.surname}</Typography>
                                <Typography color="secondary" component="span" className="truncate">{province}{paisTrabajo}</Typography>
                                <Typography color="secondary" component="span" className="truncate">{k.viewEspecialidad}</Typography>
                            </div>
                            <div className="cont-btn-card">
                                {k.cita &&
                                    <Link to={'/interconsulta/' + k.nick}>
                                        <Button variant="contained" color="primary" className="btn-card">
                                            Interconsulta
                                        </Button>
                                    </Link>
                                }
                                <Link to={'/epage/' + k.nick}>
                                    <Button variant="contained" color="primary" className="btn-card last-btn-card">
                                        Ver perfil
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            })
            : <Typography color="primary" component="span" className="truncate first-span">No se encontraron resultados</Typography>
        }
   </section>
)


export default withStyles(styles)(CardMedicos);