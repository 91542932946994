import { fade } from '@material-ui/core/styles/colorManipulator';
const drawerWidth = 180;
export const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    appBar: { },
    toolBarW:{
        width: '100%',
        margin: 'auto',
        maxWidth: 1280,
        paddingLeft: 16,
        paddingRight: 16,
    },
    menuButton: {
        marginRight: 4,
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    logoIcon:{
        marginRight: 14,
    },
    toolbar: theme.mixins.toolbar,
    sectionDesktop: {
        display: 'flex',
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    purpleAvatar:{
        width: 30,
        height: 30,
        fontSize: '1rem',
        color: '#6857B2',
        fontWeight: 'bold'
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginLeft: 0,
        width: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
        },
    },
    searchMovil:{
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },

    /**Notificaciones */
    contBtnNotify:{
        paddingTop: 4,
    },
    p08:{
        [theme.breakpoints.down("xs")]: {
            padding: 7
        }
    },
    buttonActionNotify:{
        padding: 0,
        minHeight: 'auto',
        textTransform: 'none !important',
    },
    badge:{
        color: 'white'
    },
    /*Fin Notificaciones */

    /* Estilos para el paper que mostrara a los especialista que estoy buscando */
    paperList:{
        position: 'absolute',
        top: 44,
        right: 0,
        width: 400,
        [theme.breakpoints.down("xs")]: {
            top: 57,
            width: '100%',
            position: 'fixed',
        }
    },
    contList:{
        padding: 0,
    },
    nomBusqueda:{
        padding: 0,
    },
    iconVisible:{
        fontSize: 18,
    },
    descriptionNotyfy:{
        color: '#595955',
    },
    colorWhite:{
        color: 'white !important',
    },

    /*Estilos menu */
    root: {
        display: "flex"
    },
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            flexShrink: 0,
        }
    },
    drawerPaper: {
        border: 0,
        width: drawerWidth,
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#edf0f1',
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    },
    nameuser: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: 0
    },
    setFlex:{
        flexWrap: 'inherit',
    }
    /*Fin Estilos menu */
});