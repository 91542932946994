import React from 'react'

/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import { Typography, Paper } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import SearchIcon from '@material-ui/icons/Search';

/* Component */
import Chips from '../../layout/chips'

/* Estilos con Material UI */
import { styles } from "../perfilStyle";

const Idiomas = (props) => (
    <Paper className="paper">
        <div className="title-paper">
            <Typography color='primary' component="span" className="mb-06">Idiomas que domino</Typography>
            <Divider/>
        </div>
        <div>
            <Grid container>
                {props.visitor 
                    ? null
                    : <Grid item xs={12} className="mb-06">
                        <TextField
                            id="filterIdioma"
                            name="filterIdioma"
                            label="Escribe un idioma"
                            className={props.classes.margin}
                            onChange={props.handleFilterIdioma}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                }
                
                <Grid item xs={12} className={props.classes.contCardBody}>
                    <div className="cont-info">
                        <Chips
                            list={props.list}
                            handleSelect={props.handleSelect}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    </Paper>
)


export default withStyles(styles)(Idiomas);