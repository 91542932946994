import { apiCardio } from '../_constants';

export const interConsultaService = {
    getEspecialistasInterconsulta,
    getEspecialidadesInterconsulta,
    getSearchEspecialista,
    getInfoCita,
    getDayWork,
    getHorasDisponibles,
    formaPago,
    getForm,
    getBanksCountry,
    insertCita,
    savePayment,
    getMisInterconsulas,
    deletInterconsulta,
    detalleEditQuotes,
    editCita,
};

function getEspecialistasInterconsulta(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlMedico}getforNine`),
        params = { page : 1 }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function getEspecialidadesInterconsulta(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlMedico}getEspecialidad`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getSearchEspecialista(user, array) {
    const requestOptions = {
        method: 'POSt',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify(array)
    };

    return fetch(`${apiCardio.urlMedico}buscarMedicoPorEspecialidadCita`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getInfoCita(user, nickname) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlMedico}getInfoCita`),
        params = { nickname }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getDayWork(user, medico) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlMedico}getDayWork`),
        params = { medico }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getHorasDisponibles(user, fech, medico) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlMedico}getHourWork`),
        params = { medico, fecha : fech.fech, dia: fech.dia }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function formaPago(user, tipocita, medico) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlMedico}formaPagoMedicoCita`),
        params = { medico, tipocita }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getForm(user, medico) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlHistoria}getForm`),
        params = { medico }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getBanksCountry() {
    const user = JSON.parse(localStorage.getItem('user'));
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlBanco}getAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function insertCita(user, data) {
    const formData = new FormData();

    for (let i = 1; i <= data.campos; i++) {
        const indice = data.paraClinicosText['campo' + i]
        formData.append(''+indice, data.paraClinicosFile[indice]);
        formData.append('campo'+i, indice);
    }

    formData.append('paraClinicosText', JSON.stringify(data.paraClinicosText));
    formData.append('cita_dirigida', data.cita_dirigida);
    formData.append('especialidad', data.especialidad);
    formData.append('medicoslect', data.medicoslect);
    formData.append('fechacita', data.fechacita);
    formData.append('tipopago', data.tipopago);
    formData.append('horacita', data.horacita);
    formData.append('motivo', data.motivo);
    formData.append('campos', data.campos);
    formData.append('monto', data.monto);
    formData.append('tipo', data.tipo);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': user.token },
        body: formData,

    };
    return fetch(`${apiCardio.urlCitas}saveMedica`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function editCita(user, data) {
    const formData = new FormData();

    for (let i = 1; i <= data.campos; i++) {
        const indice = data.paraClinicosText['campo' + i]
        if (!data.paraClinicosFile[indice]) {
            if (data.form.filter(k => (k.id === indice))[0].requisitoLleno) {
                formData.append('' + indice, data.form.filter(k => (k.id === indice))[0].requisitoLleno);
            }
        }else{
            formData.append('' + indice, data.paraClinicosFile[indice]);
        }
        formData.append('campo' + i, indice);
    }

    formData.append('paraClinicosText', JSON.stringify(data.paraClinicosText));
    formData.append('medicoslect', data.medicoslect);
    formData.append('forma_pago', data.forma_pago);
    formData.append('fechacita', data.fechacita);
    formData.append('horacita', data.horacita);
    formData.append('motivo', data.motivo);
    formData.append('campos', data.campos);
    formData.append('cita', data.cita);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': user.token },
        body: formData,

    };
    return fetch(`${apiCardio.urlCitas}editQuotesMed`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function savePayment(data, soporte, banck_id) {
    const user = JSON.parse(localStorage.getItem('user'));
    const formData = new FormData();
    formData.append('id_banco_centro_medico', banck_id);
    formData.append('id_banco_venezuela', banck_id);
    formData.append('numero_referencia', data.confirmacion);
    formData.append('monto', data.montopagar);
    formData.append('soporte', soporte);
    formData.append('cita', data.cita);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': user.token },
        body: formData,

    };
    return fetch(`${apiCardio.urlCitas}savePayment`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getMisInterconsulas(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlCitas}getQuotes`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deletInterconsulta(data) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': JSON.parse(localStorage.getItem('user')).token },
        body: JSON.stringify({ var : data.var })
    };

    return fetch(`${apiCardio.urlCitas}deleteQuotes`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function detalleEditQuotes(data) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': JSON.parse(localStorage.getItem('user')).token },
    };

    let url = new URL(`${apiCardio.urlCitas}detalleEditQuotes`),
        params = { medico: data.varmed, cita: data.var }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}