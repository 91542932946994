import React from 'react'

/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

/* Estilos con Material UI */
import { styles } from "./horarioAtencionStyle";

let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24];

const SelectHorario = (props) => (

    <Grid container key={props.hora}>
        <Grid item xs={4} key="1">
            <FormControl className={props.classes.formControl} 
                error={props.submitted && !props.hora}
            >
                <Select
                    displayEmpty
                    value={props.hora}
                    name={props.name_hora}
                    className={props.classes.selectEmpty}
                    onChange={props.handleSelectHora(props.id_dia)}
                >
                    <MenuItem value=""><em>--</em></MenuItem>
                    {

                        numbers.map(hora => {
                            return (
                                <MenuItem value={hora} key={hora.toString()}><em>{hora}</em></MenuItem>
                            )
                        })
                    }
                </Select>
                {props.submitted && !props.hora &&
                    <FormHelperText>Campo Requerido</FormHelperText>
                }
            </FormControl>
        </Grid>
        <Grid item xs={4} key="2">
            <FormControl className={props.classes.formControl} 
                error={props.submitted && !props.minuto}
            >
                <Select
                    displayEmpty
                    value={props.minuto}
                    name={props.name_minuto}
                    className={props.classes.selectEmpty}
                    onChange={props.handleSelectHora(props.id_dia)}
                >   
                    <MenuItem value=""><em>--</em></MenuItem>
                    <MenuItem value="00"><em>00</em></MenuItem>
                    <MenuItem value="30"><em>30</em></MenuItem>

                </Select>
                {props.submitted && !props.minuto &&
                    <FormHelperText>Campo Requerido</FormHelperText>
                }
            </FormControl>
        </Grid>
        {/* <Grid item xs={4} key="3">
            <FormControl className={props.classes.formControl} 
            error={props.submitted && !props.time}
            >
                <Select
                    displayEmpty
                    value={props.time}
                    name={props.name_time}
                    className={props.classes.selectEmpty}
                    onChange={props.handleSelectHora(props.id_dia)}
                >
                    <MenuItem value=""><em>--</em></MenuItem>
                    <MenuItem value="am"><em>am</em></MenuItem>
                    <MenuItem value="pm"><em>pm</em></MenuItem>
                </Select>
                {props.submitted && !props.time &&
                    <FormHelperText>Campo Requerido</FormHelperText>
                }
            </FormControl>
        </Grid> */}
    </Grid>  
)


export default withStyles(styles)(SelectHorario);