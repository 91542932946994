import { alertActions } from '../alert.action';
import { alertConfirmActions } from '../alertConfirm.action';
import { configConstants, loginConstants } from '../../_constants';
import { configCuentaService, especialidadesService } from "../../_services";

export const configActions = {
    // Configuracion General
    selectView,
    viewEditDatosPerso,
    viewEditPassword,
    submit,
    editDatosBasicos,
    editPassWord,

    // Requisitos para la cita
    getRequisitos,
    openModalRequisitos,
    submitRequestRequisito,
    registrarRequisito,
    deleteRequisito,
    selectDeleteRequisito,
    deleteOpcionRequisito,

    //Especialidad
    selectEspecialidad,
    getEspecialidadesMedico,

    //COMPLETAR PERFIL
    getCompleteProfile,
};

function selectView(data) {
    return { type: configConstants.SELECT_VIEW, data };
}

function viewEditDatosPerso(data) {
    return { type: configConstants.VIEW_EDIT_DATOS_PERSO, data };
}

function viewEditPassword(data) {
    return { type: configConstants.VIEW_EDIT_PASSWORD, data };
}

function submit(data) {
    return { type: configConstants.SUBMIT_REQUEST, data };
}

function editDatosBasicos(editUser) {
    return dispatch => {
        dispatch(request(editUser));
        configCuentaService.editUser(editUser)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                        dispatch(alertActions.success(data.message));
                        dispatch(edit_datos(false));
                    } else {
                        dispatch(alertActions.error(data.message));
                    }
                    dispatch(submit_request('submitGeneral'));
                }
            )
    }

    function request(user) { return { type: loginConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: loginConstants.LOGIN_SUCCESS, user } }
    function submit_request(data) { return { type: configConstants.SUBMIT_REQUEST, data } }
    function edit_datos(data) { return { type: configConstants.VIEW_EDIT_DATOS_PERSO, data } }
}

function editPassWord(user, userPassword) {
    return dispatch => {
        dispatch(request());
        configCuentaService.editPassword(user, userPassword)
            .then(
                data => {
                    if (data.success) {
                        dispatch(alertActions.success(data.message));
                        dispatch(edit_pass(false));
                    } else {
                        dispatch(alertActions.error(data.message));
                    }
                    dispatch(submit_request('submitPass'));
                    dispatch(request());
                }
                
            )
    }

    function request() { return { type: configConstants.LOADING_REQUEST } }
    function submit_request(data) { return { type: configConstants.SUBMIT_REQUEST, data } }
    function edit_pass(data) { return { type: configConstants.VIEW_EDIT_PASSWORD, data } }
}

/* REQUISITOS PARA LA CITA */
function getRequisitos(user) {
    return dispatch => {
        configCuentaService.getRequisitos(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                       // console.log(data.message)
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function success(data) { return { type: configConstants.GET_REQUISITOS_CITAS, data } }
}

function openModalRequisitos(open) {
    return dispatch => {
        dispatch(success(open))
    };
    function success(data) { return { type: configConstants.OPEN_MODAL_REQUISITOS, data } }
}

function submitRequestRequisito() {
    return dispatch => {
        dispatch(requestSubmit())
    };

    function requestSubmit() { return { type: configConstants.SUBMIT_REQUISITO_REQUEST } }
}
function registrarRequisito(user, requisitos, idRequisito) {
    return dispatch => {
        dispatch(request())
        configCuentaService.registrarRequisito(user, requisitos, idRequisito)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success(data.message))
                    } else {
                        dispatch(alertActions.error(data.message));
                    }
                    dispatch(requestSubmit())
                }
                // error => dispatch(failure(error.toString()))
            );
    };
    function requestSubmit() { return { type: configConstants.SUBMIT_REQUISITO_REQUEST } }
    function request() { return { type: configConstants.REQUISITOS_CITAS_REQUEST } }
    function success(data) { return { type: configConstants.REQUISITOS_CITAS_INSERT, data } }
}

function deleteRequisito(user, requisito) {
    return dispatch => {
        dispatch(request())
        configCuentaService.deleteRequisito(user, requisito)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(requisito.id))
                        dispatch(alertActions.success(data.message))
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: configConstants.REQUISITOS_CITAS_REQUEST } }
    function success(data) { return { type: configConstants.REQUISITOS_CITAS_DELETE, data } }
}

function selectDeleteRequisito(item) {
    return dispatch => {
        dispatch(success(item));
    }
    function success(data) { return { type: configConstants.SELECT_ITEM_REQUISITO_DELETE, data } }
}

function deleteOpcionRequisito(user, opcionRequisito) {
    return dispatch => {
        dispatch(request())
        configCuentaService.deleteOpcionRequisito(user, opcionRequisito)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        // dispatch(alertActions.success(data.message))
                    } else {
                        // dispatch(alertActions.error(data.message));
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: configConstants.REQUISITOS_CITAS_REQUEST } }
    function success(data) { return { type: configConstants.REQUISITOS_OPCION_CITAS_DELETE, data } }
}

/*ESPECIALIDADES */
function getEspecialidadesMedico(user) {
    return dispatch => {
        dispatch(request());
        especialidadesService.getEspecialidades(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                        dispatch(alertActions.success(data.message));
                    } else {
                        dispatch(alertActions.error(data.message));
                    }
                }
            )
    };

    function request() { return { type: configConstants.GET_ESPECILIDAD_REQUESt } }
    function success(data) { return { type: configConstants.GET_ESPECILIDAD, data } }
}

function selectEspecialidad(selected, user) {
    return dispatch => {
        dispatch(success(selected));
        especialidadesService.selectEspecialidad(user, selected)
    }
    function success(data) { return { type: configConstants.SELET_ESPECIALIDAD, data } }
}

function getCompleteProfile(user) {
    return dispatch => {
        dispatch(request());
        configCuentaService.getCompletarPerdil(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data));
                    }
                }
            )
    }
    function request() { return { type: configConstants.LOADING_REQUEST } }
    function success(data) { return { type: configConstants.COMPLETAR_PERFIL, data } }
}