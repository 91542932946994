import React from 'react'

/* MAterial UI */
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';

/* Estilos con Material UI */
import { styles } from "./perfilPublicoStyle";

/*Components */
import {FormAddLogros} from "./formAddLogros";
import {FormAddEducacion} from "./formAddEducacion";
import {FormAddMembrecia} from "./formAddMembrecia";
import {FormAddExperiencia} from "./formAddExperiencia";

const renderSwitch = (props) => {
    switch (props.typeForm) {
        case "1":
            return <FormAddExperiencia
                insitutos={props.insitutos}
                sugerencias={props.sugerencias}
            />;
        case "2":
            return <FormAddEducacion
                insitutos={props.insitutos}
                sugerencias={props.sugerencias}
            />;
        case "3":
            return <FormAddLogros
                insitutos={props.insitutos}
                ugerencias={props.sugerencias}
            />;
        case "4":
            return <FormAddMembrecia
                insitutos={props.insitutos}
                ugerencias={props.sugerencias}
            />;
        default:
            return '---';
    }
}

const titleModal = (props) => {
    switch (props.typeForm) {
        case "1":
            return <DialogContentText color="primary" className={props.classes.titleModal}>Experiencia</DialogContentText>
        case "2":
            return <DialogContentText color="primary" className={props.classes.titleModal}>Educación</DialogContentText>
        case "3":
            return <DialogContentText color="primary" className={props.classes.titleModal}>Reconocimientos y premios</DialogContentText>
        case "4":
            return <DialogContentText color="primary" className={props.classes.titleModal}>Sociedad científica o colegio de médico</DialogContentText>
        default:
            return '---';
    }
}

const ModalEPage = (props) => (
    <Dialog
        scroll="paper"
        maxWidth="sm"
        fullWidth= {true}
        open={props.open}
        // onClose={props.handleClose()}
        aria-labelledby="scroll-dialog-title"
    >
        <DialogTitle id="scroll-dialog-title" className={props.classes.modalTitle}>
            {props.typeForm && titleModal(props)} 
            <Divider  />
        </DialogTitle>
        <DialogContent>
            
            {
                props.typeForm &&
                renderSwitch(props)
            }
        </DialogContent>
    </Dialog>
)


export default withStyles(styles)(ModalEPage);