export const styles = theme => ({
    p0: {
        padding: 0
    },
    fechainci:{
        fontSize: '.75rem',
    },

    /*Listado de incidencias */
    numItem:{
        padding: '4px 0'
    },
    formControlPage:{
        marginTop: 3,
        marginLeft: 5
    },
    numberPagination:{
        minWidth:40,
        marginLeft: 2,
        marginRight: 2,
    },
    contList:{
        overflow: 'hidden'
    },
    borderBotton:{
        padding: '10px 0',
        borderBottom: '1px solid #bdbdbd',
    },
    mt07:{
        marginTop: 12,
    },
    formSubmitHilo:{
        width: '86%'
    },

    /*Crear Incidencia */
    addIcon: {
        marginRight: theme.spacing.unit,
    },
    uploadImg:{
        marginLeft: 12,
        cursor: 'pointer',
    },
    iconClose:{
        float: 'right',
        top: -13,
    },
    imgInciden:{
        width: 150
    },
    contTipoInciden:{
        display: 'flex'
    },
    iconSuccesInciden:{
        fontSize: 19,
        marginLeft: 6
    },
});