export const citasConstants = {
    GET_CITAS: 'GET_CITAS',
    SELECT_CITA: 'SELECT_CITA',
    FAIL_GET_CITA: 'FAIL_GET_CITA',
    CITAS_REQUEST: 'CITAS_REQUEST',
    FILTER_FECHA_CITA: 'FILTER_FECHA_CITA',
    GET_CITAS_DASHBOARD: 'GET_CITAS_DASHBOARD',

    VIEW_DETALLE: 'VIEW_DETALLE',
    VIEW_DETALLE_MODAL: 'VIEW_DETALLE_MODAL',

    CITA_COMO_ATENDIDA: 'CITA_COMO_ATENDIDA',
    CITA_ATENDIDA_SUCCESS: 'CITA_ATENDIDA_SUCCESS',

    // eConsultorio
    GET_CITAS_CONSULTORIO: 'GET_CITAS_CONSULTORIO',
    
    // Citas Pagadas
    GET_CITAS_PAGADAS: 'GET_CITAS_PAGADAS',

    //Citas via chat
    UPDATE_VIEW: 'UPDATE_VIEW',
    GET_FAIL_CITA_CHAT: 'GET_FAIL_CITA_CHAT',
    CHANGE_DESCRIPTION: 'CHANGE_DESCRIPTION',
    GET_REQUEST_CITAS_CHAT: 'GET_REQUEST_CITAS_CHAT',
    GET_SUCCESS_CITAS_CHAT: 'GET_SUCCESS_CITAS_CHAT',
    GET_SUCCESS_HILO_CITAS_CHAT: 'GET_SUCCESS_HILO_CITAS_CHAT',
    SUBMIT_REQUEST_YARN: 'SUBMIT_REQUEST_YARN',
    SUBMIT_SUCCESS_HILO_CITAS_CHAT: 'SUBMIT_SUCCESS_HILO_CITAS_CHAT',
    ASESORIA_TEXTO_ATENDIDA_SUCCES: 'ASESORIA_TEXTO_ATENDIDA_SUCCES',
    

};