import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/* Material UI */
import './post.css';
import { styles } from "./postStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Avatar, Checkbox, FormControlLabel, Divider, IconButton, Menu, MenuItem, CircularProgress, Fade, Tooltip } from '@material-ui/core';

/* Actions */
import { postActions, alertConfirmActions } from '../../../../_actions'

/* Component */
import CrearComentario from "./crearComentario";
import ListComentarios from "./listComentarios";
import AlertDialog from "../layout/alertDialogo";
import { EditPost } from "./editarPost";

/* Icons */
import Favorite from '@material-ui/icons/Favorite';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import ComentarioIcon from '@material-ui/icons/ChatBubbleOutline';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';

/* Url's */
import { apiCardio } from '../../../../_constants';


class ListPost extends Component {
    constructor() {
        super()
        this.state = {
            anchorEl: null,
            postEl: null,
            sharedEl: null,
            comentario:'',
            openAlerPost: false,
        };
        this.handleUrlify = this.handleUrlify.bind(this)
        this.handleSelectEmoji = this.handleSelectEmoji.bind(this)
        this.handleSubmitComentary = this.handleSubmitComentary.bind(this)
        this.handleSubmitEditComentary = this.handleSubmitEditComentary.bind(this)
        this.handleChangeImputComentary = this.handleChangeImputComentary.bind(this)
        this.handleSelectEmojiEditarComent = this.handleSelectEmojiEditarComent.bind(this)
        this.handleChangeEditImputComentary = this.handleChangeEditImputComentary.bind(this)
    }

    handleMyLike = data => () => {
        const { dispatch, user } = this.props;
        let type = ''
        if (data.mylike) {
            data.like -= 1 ; data.mylike -= 1;   type= 'delete'
        }else{
            data.like += 1 ; data.mylike += 1 ;  type = 'insert'
        }
        dispatch(postActions.likes(data, user, type))
    }

    handleViewComentary = data => () => {
        const { dispatch, user } = this.props;
        dispatch(postActions.viewComments(data.keyd, user))
    }

    /* Handle para editar o eliminar comentario */
    handleOpenMenu = data => (event) => {
        this.props.dispatch(postActions.selectPostComentary(data.itemSelect))
        this.setState({ [data.closeMenu]: event.currentTarget });
    };
    
    handleCloseMenu = data => () => {
        this.setState({ [data]: null });
    };
    /* Fin Handle para editar o eliminar comentario */

    /*Crear y Editar nuevo comentario */
    hlandleComentaryPost = data => () => {
        const { dispatch, selectPost } = this.props;
        if (selectPost !== data) {
            dispatch(postActions.selectPostComentary(data))
            this.setState({ comentario: '', })
        }
    }

    handleChangeImputComentary(e){ this.setState({ comentario: e.target.value }) }
    
    handleSubmitComentary(e){
        const key = window.event ? e.which : e.keyCode;
        if (key === 13) {
            e.preventDefault();
            const { dispatch, user, selectPost } = this.props;
            if (this.state.comentario) {
                this.setState({ comentario: '' })
                dispatch(postActions.submitComentary(this.state.comentario, selectPost, user ))
            }
            
        }
    }

    handleEditarComentary = data => () => {
        this.setState({ anchorEl: null });
        this.props.dispatch(postActions.selectPostComentary(data))
    }

    handleChangeEditImputComentary(e){
        this.props.dispatch(postActions.changeEditComentary(e.target.value, this.props.selectPost))
    }

    handleSubmitEditComentary(e){
        const key = window.event ? e.which : e.keyCode;
        
        if (key === 13) {
            const { dispatch, selectPost, user } = this.props;
            e.preventDefault();
            selectPost.commentary !== '' && dispatch(postActions.submitEditComentary(selectPost, user))
        }

        key === 27 && this.props.dispatch(postActions.selectPostComentary(''))
    }
    /*Fin Crear y Editar nuevo comentario */

    /* Responder Comentario */
    handleReplyComment = data => () =>{
        this.props.dispatch(postActions.selectPostComentary(data))
        this.props.dispatch(postActions.changeComentaryReply(''))
    }
    /* Fin Responder Comentario */

    /* Eliminar Elementos */
    handleCloseAlert = () => {
        this.setState({openAlerPost: false});
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleRemoveElement = data => () => {
        const { dispatch } = this.props;
        this.setState({ [data.closeMenu]: null });
        this.setState({openAlerPost: true});
        dispatch(alertConfirmActions.openCloseAlert())
        dispatch(postActions.selectPostComentary(data))
    }
    
    handleDelateItem = () => {
        const { dispatch, user, selectPost } = this.props;
        dispatch(alertConfirmActions.sumitedRequest())
        if (selectPost.type === 'comentary') {
            dispatch(postActions.delteComentary(selectPost.itemSelect, selectPost.post, user))
        }else{
            dispatch(postActions.deltePost(selectPost.itemSelect, user))
        }
        // dispatch(postActions.removerPacienteHistoria(this.props.user, this.props.personSelected));
    }
    /* Eliminar Elementos */

    /*Editar Post */
    handleEditPost = data => () => {
        this.setState({ postEl: null });
        this.props.dispatch(postActions.selectPostComentary(data))
        this.props.dispatch(postActions.disableBoton(false))
    }

    /* Emojis */
    handleSelectEmoji(code) {
        const emoji = String.fromCodePoint(`0x${code}`)
        this.setState({ comentario: this.state.comentario+' '+emoji })
    }

    handleSelectEmojiEditarComent(code) {
        const emoji = String.fromCodePoint(`0x${code}`)
        const mensaje = this.props.selectPost.comente.commentary + ' ' + emoji
        this.props.dispatch(postActions.changeEditComentary(mensaje, this.props.selectPost))

    }
    
    handleSharedPost = data => () => {
        this.setState({ sharedEl: null });
        const urlShared = apiCardio.urlSharedPost + '' + data.nickname + '/' + data.post
        switch (data.type) {
            case 'fb':
                window.open('https://www.facebook.com/sharer/sharer.php?u='+urlShared, 'sharer', 'toolbar=0,status=0,width=548,height=350');
                break;
            case 'lk':
                window.open('https://www.linkedin.com/sharing/share-offsite/?url='+urlShared, 'sharer', 'toolbar=0,status=0,width=548,height=350');
                break;
            case 'tw':
                window.open('https://twitter.com/intent/tweet?text=Mira%20mi%20nueva%20publicación%20en%20eCardioline&url='+urlShared+'&hashtags=eCardioline', 'sharer', 'toolbar=0,status=0,width=548,height=350');
                break;
            case 'wp':
                window.open('https://api.whatsapp.com/send?text=Mira%20mi%20nueva%20publicación%20en%20eCardiolone%20'+urlShared, 'sharer', 'toolbar=0,status=0,width=548,height=350');
                break;
            default:
                console.log('no selecciono ninguna opcion')
                break;
        }
    }

    handleUrlify(texto){
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return texto.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_back">' + url + '</a>';
        }) 
    }

    render() {
        const { anchorEl, comentario, postEl, openAlerPost, sharedEl } = this.state
        const { classes, post, user, loading, selectPost, openConfirm, loadingConfirm, submitedConfrim, dispatch } = this.props;
        post.sort(function (a, b) { return b.keyd - a.keyd; }); //Ordenar de forma descendente
        return (
            <Grid container spacing={24} direction="column">
                {post.length ?
                    post.map(k =>{
                        const dataPostRemove = { type: 'post', itemSelect: k.keyd, 'closeMenu': 'postEl' }
                        const autorPost = k.autor.nick === user.username ? true : false
                        let anchoImg = '100%';
                        if (k.files){
                            if (k.anchoImg < 750) { anchoImg = k.anchoImg   }
                        }
                        return(
                            <Grid item xs={12} className="cont-list-post" key={k.keyd}>
                                <Paper className="paper post">
                                    <div className="header-post">
                                        <Link to={'/epage/' +k.autor.nick} className="link-epage">
                                            <Avatar alt={k.autor.name} src={`${apiCardio.urlMedia}${k.autor.avatar}`} className={classes.bigAvatar} />
                                            <div className="autor-fech">
                                                <Typography component="span" color="primary">{k.autor.name} {k.autor.surname}</Typography>
                                                <Typography component="span" color="textSecondary">{k.fecha}</Typography>
                                            </div>
                                        </Link>
                                        {k.autor.nick === user.username
                                            ? <div>
                                                <IconButton
                                                    aria-label="Mas Post"
                                                    aria-owns={postEl ? 'menu-post' + k.keyd : undefined}
                                                    aria-haspopup="true"
                                                    onClick={this.handleOpenMenu({ itemSelect: k.keyd, 'closeMenu': 'postEl' })}
                                                >
                                                    <KeyboardArrowDownIcon />
                                                </IconButton>
                                                <Menu
                                                    id={`menu-post${k.keyd}`}
                                                    anchorEl={postEl}
                                                    open={(selectPost === k.keyd) && postEl ? true : false}
                                                    onClose={this.handleCloseMenu('postEl')}

                                                >
                                                    <MenuItem onClick={this.handleEditPost({ editar: true, post: k })}>
                                                        Editar
                                                </MenuItem>
                                                    <MenuItem onClick={this.handleRemoveElement(dataPostRemove)}>
                                                        Eliminar
                                                </MenuItem>
                                                </Menu>
                                            </div>
                                            : null
                                        }
                                        
                                    </div>
                                    <div className="body-post">
                                        {k.description && 
                                            <div className="cont-text mb-06"><Typography component="span" color="textSecondary" dangerouslySetInnerHTML={{ __html: this.handleUrlify(k.description )}}></Typography></div>
                                        }
                                        {k.files ?
                                            k.typeFile === 'video' 
                                                ? <div className="cont-video-post">
                                                    <video src={`${apiCardio.urlMedia}${k.files}`} loop controls ></video>
                                                </div>
                                                :<div className="cont-img-post">
                                                    <img alt={k.fecha} src={`${apiCardio.urlMedia}${k.files}`} style={ { maxWidth: anchoImg }}/>
                                                </div>
                                            :null
                                        }
                                    </div>
                                    <div className="action-body valign-wrappe">
                                        <Tooltip title="Me gusta" aria-label="like">
                                            <FormControlLabel
                                                className="mr-0"
                                                control={
                                                    <Checkbox 
                                                        icon={<FavoriteBorder />} 
                                                        checkedIcon={<Favorite color="primary" />} 
                                                        value="checkedH" checked={k.mylike ? true : false} 
                                                        onClick={this.handleMyLike(k)}
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                        <Typography component="span" color="textSecondary">{k.like === 0 ? '' : k.like}</Typography>
                                        <Tooltip title="Comentarios" aria-label="comentary">
                                            <IconButton aria-label="Comentario" className={classes.margin}>
                                                <ComentarioIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Typography component="span" color="textSecondary">{k.countComentary === 0 ? '' : k.countComentary}</Typography>
                                        <Tooltip title="Compartir publicación" aria-label="shared">
                                            <IconButton aria-label="Compartir" className={classes.margin} onClick={this.handleOpenMenu({ itemSelect: k.keyd, 'closeMenu': 'sharedEl' })}>
                                                <RecordVoiceOver fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        <Menu
                                            id={`menu-shared${k.keyd}`}
                                            anchorEl={sharedEl}
                                            open={(selectPost === k.keyd) && sharedEl ? true : false}
                                            onClose={this.handleCloseMenu('sharedEl')}

                                        >
                                            <MenuItem disabled>
                                                Compartir en
                                            </MenuItem>
                                            <MenuItem onClick={this.handleSharedPost({ type: 'fb', post: k.keyd, nickname: k.autor.nick })}>
                                                Facebook
                                            </MenuItem>
                                            <MenuItem onClick={this.handleSharedPost({ type: 'lk', post: k.keyd, nickname: k.autor.nick })}>
                                                LinkedIn
                                            </MenuItem>
                                            <MenuItem onClick={this.handleSharedPost({ type: 'tw', post: k.keyd, nickname: k.autor.nick })}>
                                                Twitter
                                            </MenuItem>
                                            <MenuItem onClick={this.handleSharedPost({ type: 'wp', post: k.keyd, nickname: k.autor.nick })}>
                                                WhatsApp
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                    <div className="coment-post">
                                        <Divider className="mb-06" />
                                        {k.countComentary > 2 
                                            ? <Typography component="span" className="cursor-pointer" color="primary" onClick={this.handleViewComentary(k)}>Ver más comentarios</Typography>
                                            : ''
                                        } 

                                        {selectPost.loaddingComen && (selectPost.post_request === k.keyd && loading) &&
                                            <div className="cont-progress-comentary"><CircularProgress size={20} className={`ProgressAbsolut`} /></div>
                                        }
                                        
                                        <ListComentarios
                                            post={k.keyd}
                                            loading={loading}
                                            anchorEl={anchorEl}
                                            dispatch={dispatch}
                                            autorPost={autorPost}
                                            selectPost={selectPost}
                                            nombreUser={user.name+' '+user.surname}
                                            list={k.coments.sort(function (a, b) { return a.keyds - b.keyds; })} 
                                            listReply={k.comentaryReply} 
                                            handleOpenMenu={this.handleOpenMenu}
                                            handleCloseMenu={this.handleCloseMenu}
                                            handleReplyComment={this.handleReplyComment}
                                            handleRemoveElement={this.handleRemoveElement}
                                            handleEditarComentary={this.handleEditarComentary}
                                            handleSelectEmoji={this.handleSelectEmojiEditarComent}
                                            handleSubmitEditComentary={this.handleSubmitEditComentary}
                                            handleChangeEditImputComentary={this.handleChangeEditImputComentary}
                                            
                                         />
                                        
                                        <CrearComentario
                                            user={user}
                                            post={k.keyd}
                                            loading={loading}
                                            dispatch={dispatch}
                                            selectPost={selectPost}
                                            comentario={comentario}
                                            handleSelectEmoji={this.handleSelectEmoji}
                                            hlandleComentaryPost={this.hlandleComentaryPost}
                                            handleSubmitComentary={this.handleSubmitComentary}
                                            handleChangeImputComentary={this.handleChangeImputComentary}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        )
                    })
                    :<Grid item xs={12}>
                        <Paper className="paper">
                            <Typography component="span" color="textSecondary">No se encontro ninguna publicación.</Typography>
                        </Paper>
                    </Grid>
                }
                <Fade in={loading}>
                    <Grid item xs={12}>
                        <Paper className="paper">
                            <div className={classes.contLoadingMasPost}>
                                <CircularProgress size={30}  />
                            </div>
                            <Typography component="span" align="center" color="textSecondary">Cargando más publicaciones....</Typography>
                        </Paper>
                    </Grid>
                </Fade>
                <AlertDialog
                    open={openConfirm && openAlerPost}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDelateItem}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de eliminar el item seleccionado?'
                />
                <EditPost/>
            </Grid>
        );
    }
}

ListPost.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { post, selectPost, loading } = state.postReducer;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    return {
        user,
        post, selectPost, loading,
        openConfirm, loadingConfirm, submitedConfrim
    };
}

const connectedListPost = connect(mapStateToProps)(withStyles(styles)(ListPost));
export { connectedListPost as ListPost };