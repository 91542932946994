import React from 'react'

/* MAterial UI */
import { withStyles } from '@material-ui/core/styles';

/* Iconos */

/* Estilos con Material UI */
import { styles } from "./perfilPublicoStyle";
import { Grid, Paper, Typography, Chip } from '@material-ui/core';

const listPaper = (data, key) => {
    return (
        <Grid item xs={12} key={key}>
            <Paper className="paper">
                <div className="title-paper relative">
                    <Typography color='primary' component="span" className="mb-06">Costo de interconsulta vía {data.name_attetion}</Typography>
                </div>
                <div>
                    {data.montoHonorario.map(j => {
                        if (j.free === '1') {
                            return (
                                <Chip
                                    key={data.montoHonorario.indexOf(j)}
                                    label={`Gratis`}
                                    color="primary"
                                />

                            )
                        }
                        return (
                            <Chip
                                key={data.montoHonorario.indexOf(j)}
                                label={`${j.monto} ${j.name}`}
                                color="primary"
                            />

                        )
                    })

                    }
                </div>
            </Paper>
        </Grid>
    )
}

const ModalidadAsesoria = (props) => (
    props.list && 
        props.list.map(k => {
            if (k.name_attetion === "Videoconferencia" && k.montoHonorario.length) {
                return(
                    listPaper(k, props.list.indexOf(k))
                )
            }

            if (k.name_attetion === "Escrita en menos de 24 horas." && k.montoHonorario.length) {
                return(
                    listPaper(k, props.list.indexOf(k))
                )
            }

            return '';
        })
)


export default withStyles(styles)(ModalidadAsesoria);