export const styles = theme => ({
    // Menu configuracion 
    root: {
        display: "flex"
    },
    drawer: {
        [theme.breakpoints.down("md")]: {
            width: 330,
        },
        [theme.breakpoints.up("md")]: {
            width: 400,
            flexShrink: 0,
        }
    },
    drawerPaper: {
        border: 0,
        width: 180,
        padding: 0,
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#edf0f1',
        [theme.breakpoints.down("md")]: {
            padding: 12,
            overflow: 'auto',
        },
        [theme.breakpoints.up("md")]: {
            width: 400,
            padding: 12,
            zIndex: 1000,
            backgroundColor: 'transparent',
        }
    },
    namePage: {
        marginBottom: 0,
        fontSize: '1rem',
        fontWeight: 'bold',
        padding: '12px 6px 6px',
        color: theme.palette.secondary.main,
    },

    // Portada 
    prl_1:{
        padding: '0 1rem'
    },
    bigAvatar:{
        [theme.breakpoints.down("md")]: {
            width: 120,
            height: 120,
        },
        [theme.breakpoints.up("md")]: {
            width: 200,
            height: 200,
        }
    },
    uploadAvatar: {
        zIndex: '2',
        opacity: '0',
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'absolute',
        [theme.breakpoints.down("md")]: {
            width: 145,
            height: 125,
        },
        [theme.breakpoints.up("md")]: {
            width: 200,
            height: 200,
        }
    },
    avatarPlusIcon: {
        right: 0,
        bottom: 10,
        zIndex: '1',
        position: 'absolute',
        border: '2px solid',
        // backgroundColor: theme.palette.primary.main,
    },
    buttonPortada:{
        borderRadius: 'initial'
    },
    imagePortada:{
        padding: 12,
        zIndex: 1,
        position: 'absolute',
    },
    uploadPortada:{
        display: 'none'
    },
    actualizarPortada:{
        paddingTop: 4,
        paddingLeft: 12,
    },
    // FIN Portada 

    verLeerMas:{
        maxHeight: 'initial !important',
    },
    btnMasOption:{
        color: '#6857B2',
        margin: '6px auto',
        padding: '6px 2px',
    },
    seguir:{
        //color: '#6857B2',
        [theme.breakpoints.up("sm")]: {
            // fontSize: '.8rem',
            // margin: '6px auto',
            // padding: '6px 2px',
        },
    },
    seguirTow:{
        color: '#6857B2',
    },
    seguiendo:{
        color: '#6857B2',
        //fontSize: '.7rem',
        //margin: '6px auto',
        //padding: '6px 2px',
    },
    seguiendoTow:{
        marginRight: 10,
    },
    avatarSeguidor:{
        width: 60,
        height: 60,
    },

    titleDialogRecortar:{
        padding: '10px 24px 10px'
    },
    porcentaje:{
        fontSize: 10,
        fontWeight: 800,
        color: theme.palette.primary.main,
    },

    /* Modal Pera mensaje nuevo */
    contentDialogDirect: {
        padding: '0px !important',
    },
    smallAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    },
    nameuser: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: 0
    },
    setFlex: {
        flexWrap: 'inherit',
    }
})