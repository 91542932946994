import React, { Component } from 'react'
import EmojiPicker from 'emoji-picker-react';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/Button';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';


/* Actions */
import { postActions } from '../../../../_actions'

const customNames = {
    people: 'Personas',
    foods: 'Alimentos',
    nature: 'Naturaleza',
    activity: 'Actividad',
    objects: 'Objetos',
    places: 'Lugares',
    flags: 'Banderas',
    symbols: 'Símbolos',
};

class Emojis extends Component {
    constructor(props) {
        super(props)

        this.state = {
            anchorEl: null,
            setAnchorEl: null,
        };
    }

    handleClick = data => (event) => {
        const { dispatch} = this.props
        dispatch(postActions.selectPostComentary(data))
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    render() {
        const { anchorEl } = this.state
        const { handleSelectEmoji, post } = this.props
        return (
            <div className="icon-emoji">
                <IconButton aria-controls="sentiment-satisfied" aria-haspopup="true" onClick={this.handleClick(post)} className="btn-icon-emoji">
                    <SentimentSatisfiedIcon color="primary" />
                </IconButton>
                <Menu
                    id="sentiment-satisfied"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    <EmojiPicker 
                        preload
                        disableDiversityPicker
                        customCategoryNames={customNames}
                        onEmojiClick={handleSelectEmoji} />
                </Menu>
            </div>
        );
    }
}

export default Emojis;