import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
// import Preloader from '../layout/preloader';

/* Material UI */
import { Grid, Typography, Divider } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../configuracionStyle";

/*Components */
import Especialidades from './especialidades'
import FormNuevaClave from './formNuevaClave'
import FormDatosBasicos from './formDatosBasicos'

/* Actions */
import { configActions, alertActions } from '../../../../../_actions';

/* Services */
import { configService } from "../../../../../_services";

class ConfigGeneral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEdit: [],
            userPassword: {
                claveActual: '',
                claveNueva: '',
                reClaveNueva: ''
            },
            provincias:[],
            filterEspecialidad: "",
        };

        configService.getProvincias(this.props.user)
            .then(
                data => {
                    if (data.success) {
                        this.setState({ provincias: data.data });
                    }
                }
            )
        
        this.props.dispatch(configActions.viewEditDatosPerso(false));
        this.props.dispatch(configActions.viewEditPassword(false));

        this.handleOnFilter = this.handleOnFilter.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleOnSubmitEdit = this.handleOnSubmitEdit.bind(this)
        this.handleChangeInputPass = this.handleChangeInputPass.bind(this)
        this.handleOnSubmitEditClave = this.handleOnSubmitEditClave.bind(this)
        this.handleFilterEspecialidad = this.handleFilterEspecialidad.bind(this)
        this.handleSelectEspecialidad = this.handleSelectEspecialidad.bind(this)
        this.handleChangeInputSugerencia = this.handleChangeInputSugerencia.bind(this)
        
    }
    
    handleEditarInfo = (e) =>{
        e.preventDefault();
        this.setState({ userEdit: this.props.user });
        this.props.dispatch(configActions.viewEditDatosPerso(!this.props.editDatosPerso));
    }  
    
    handleChangeInput(e) {
        const { name, value } = e.target
        const userEdit = this.state.userEdit
        userEdit[name] = value
        
        this.setState({ userEdit });
    }

    handleCreateUsername = (e) => {
        const key = window.event ? e.which : e.keyCode;
        const userEdit = this.state.userEdit
        const { name, value } = e.target
        let newValue = '';
        if (key === 32) {
            newValue = value.replace(/ /g, "_")
            userEdit[name] = newValue
            this.setState({ userEdit });
        }else{
            newValue = value.replace("@", "")
            userEdit[name] = newValue
            this.setState({ userEdit });
        }
    }

    handleChangeInputSugerencia(name, value) {
        const userEdit = this.state.userEdit
        userEdit[name] = value
        this.setState({ userEdit });
    }
    
    handleOnSubmitEdit(e) {
        e.preventDefault();
        this.props.dispatch(configActions.submit('submitGeneral'));
        const userEdit = this.state.userEdit
        if (userEdit.name && userEdit.surname && userEdit.typedocument && userEdit.document && userEdit.phone && userEdit.birthdate) {
            this.props.dispatch(configActions.editDatosBasicos(userEdit))
        }
    }

    // HANDLES PARA LA CONTRASEÑA
    handleUpdatePassword = (e) => {
        e.preventDefault();
        this.setState({
            userPassword: {
                claveActual: '',
                claveNueva: '',
                reClaveNueva: ''
            },
        });
        this.props.dispatch(configActions.viewEditPassword(!this.props.editPass));
    }

    handleChangeInputPass(e) {
        const { name, value } = e.target
        const userPassword = this.state.userPassword
        userPassword[name] = value
        this.setState({ userPassword });
    }


    handleOnSubmitEditClave(e) {
        e.preventDefault();
        console.log('ejecuta')
        const { dispatch, user } = this.props;
        dispatch(configActions.submit('submitPass'));
        const userPassword = this.state.userPassword
        if (userPassword.claveActual && userPassword.claveNueva && userPassword.reClaveNueva) {
            if (userPassword.claveNueva === userPassword.reClaveNueva) {
                dispatch(configActions.editPassWord(user, userPassword))
            } else {
                dispatch(alertActions.error('No coinciden las contraseña.'));
                dispatch(configActions.submit('submitPass'));
            }
        }
    }

    /* Especialidades */
    handleFilterEspecialidad(e) {
        const { name, value } = e.target
        this.setState({ [name]: value });
    }

    handleOnFilter(filter, data) {
        let regex = new RegExp(filter, 'i')
        return data.filter(q => (regex.test(q.firstname)))
    }

    handleSelectEspecialidad = data => () => {
        const { user, dispatch } = this.props;
        dispatch(configActions.selectEspecialidad(data, user))
    }

    render() {
        const { userEdit, userPassword, filterEspecialidad, provincias } = this.state;
        const { user, editDatosPerso, editPass, submitGeneral, loadding, listDocument, listPaises, submitPass, loading, especialidades } = this.props
        return (
            <Grid container spacing={24}>
                <Grid item xs={12} className="pb-0">
                    <Typography color="primary" component="span" >Configuración general de la cuenta</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormDatosBasicos
                        user={user}
                        userEdit={userEdit}
                        provincias={provincias}
                        editDatosPerso={editDatosPerso}
                        submitGeneral={submitGeneral}
                        listDocument={listDocument}
                        listPaises={listPaises}
                        loadding={loadding}
                        handleChangeInput={this.handleChangeInput}
                        handleOnSubmitEdit={this.handleOnSubmitEdit}
                        handleEditarInfo={this.handleEditarInfo}
                        handleCreateUsername={this.handleCreateUsername}
                        handleChangeInputSugerencia={this.handleChangeInputSugerencia}
                    />
                    <Divider variant="middle" />
                </Grid>
            
                <Grid item xs={12}>
                    <FormNuevaClave
                        editPass={editPass}
                        submitted={submitPass}
                        loaddingPass={loading}
                        userPassword={userPassword}
                        handleUpdatePassword={this.handleUpdatePassword}
                        handleChangeInputPass={this.handleChangeInputPass}
                        handleOnSubmitEditClave={this.handleOnSubmitEditClave}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Especialidades
                        especialidades={this.handleOnFilter(filterEspecialidad, especialidades)}
                        filterEspecialidad={filterEspecialidad}
                        handleSelect={this.handleSelectEspecialidad}
                        handleFilterEspecialidad={this.handleFilterEspecialidad}
                    />
                </Grid>
            </Grid>
        )
    }
}

ConfigGeneral.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { loadding } = state.authentication;
    const { especialidades } = state.especialidadesReducer;
    const { editDatosPerso, editPass, submitGeneral, loading, submitPass } = state.configReducer;
    return {
        loadding, especialidades,
        editDatosPerso, editPass, submitGeneral, loading, submitPass
    };
}

const connectedConfigGeneral = connect(mapStateToProps)(withStyles(styles)(ConfigGeneral));
export { connectedConfigGeneral as ConfigGeneral };