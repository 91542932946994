import { directConstants } from '../../_constants';

const initialState = {
    preloadingDirect: false,
    openDialogDirect: false,
    listContactDirect: [],
    viewNewMessage: false,
    loadingDirect: false,
    submitDirect: false,
    viewMessage: false,
    movilOn: false,
    listDirect: [],
    participantes:[],
    message:{
        nom_group: '',
        participantes: [],
        yarn:[],
        newComentary: '',
    }
};

export function directReducer(state = initialState, action) {
    switch (action.type) {
        case directConstants.REQUEST_PRELOADER:
            return Object.assign({}, state, {
                preloadingDirect: !state.loadingDirect,
            });
        case directConstants.REQUEST_DIRECT:
            return Object.assign({}, state, {
                loadingDirect: !state.loadingDirect,
            });
        case directConstants.CHECK_WIDTH:
            return Object.assign({}, state, {
                movilOn: action.data,
            });
        case directConstants.GET_REQUEST:
            return Object.assign({}, state, {
                viewDetail: !state.viewDetail,
            });
        case directConstants.OPEN_DIALOG_NEW_MESSAGE:
            return Object.assign({}, state, {
                participantes: [],
                openDialogDirect: !state.openDialogDirect,
            });
        case directConstants.SELECT_COMPETITOR:
            return Object.assign({}, state, {
                participantes: state.participantes.length 
                    ? state.participantes.filter(k => (k.keyd === action.data.keyd)).length 
                        ? state.participantes.filter(k => (k.keyd !== action.data.keyd)) 
                        : state.participantes.concat(action.data)
                    : state.participantes.concat(action.data),
            });
        case directConstants.VIEW_NEW_MESSAGE:
            return Object.assign({}, state, {
                viewMessage: false,
                viewNewMessage: true,
                loadingDirect: !state.loadingDirect,
                openDialogDirect: !state.openDialogDirect,
                message: state.listDirect.length 
                    ? state.listDirect.filter(k => (k.filter === action.data.participantes[0].nickname)).length
                        ? state.listDirect.filter(k => (k.filter === action.data.participantes[0].nickname))[0]
                        : {
                            yarn:[],
                            nom_group: action.data.nombGroup,
                            participantes: action.data.participantes,
                            newComentary: '',
                        }
                    : {
                        yarn:[],
                        nom_group: action.data.nombGroup,
                        participantes: action.data.participantes,
                        newComentary: '',
                    }
            });
        case directConstants.CHANGE_COMENTARY:
            return Object.assign({}, state, {
                message: {
                    ...state.message,
                    newComentary: action.data,
                }
            });
        case directConstants.GET_SUBMIT_BTN:
            return Object.assign({}, state, {
                submitDirect: !state.submitDirect
            });
        case directConstants.CHANGE_VIEWS:
            return Object.assign({}, state, {
                viewNewMessage: false,
                viewMessage: false,
            });
        case directConstants.LIST_DIRECT_SUCCES:
            return Object.assign({}, state, {
                listDirect: action.data,
                preloadingDirect: false,
            });
        case directConstants.LIST_DIRECT_FAIL:
            return Object.assign({}, state, {
                listDirect: [],
                preloadingDirect: false,
            });
        case directConstants.ON_SELECT_DIRECT:
            return Object.assign({}, state, {
                message: action.data,
                viewMessage: true,
                viewNewMessage: false,
            });
        case directConstants.SUCCES_YARN_DIRECT:
            return Object.assign({}, state, {
                listDirect: state.listDirect.map(k => {
                    if (k.keyd === action.data.keyd) {
                        k.yarn = action.data.yarn
                    }
                    return k
                }),
                message:{ 
                    ...state.message,
                    yarn: action.data.yarn,
                },
                loadingDirect: !state.loadingDirect,
            });
        case directConstants.FAIL_YARN_DIRECT:
            return Object.assign({}, state, {
                listDirect: state.listDirect.map(k => {
                    if (k.keyd === action.data.keyd) {
                        k.yarn = []
                    }
                    return k
                }),
                message:{ 
                    ...state.message,
                    yarn: [],
                },
                loadingDirect: !state.loadingDirect,
            });
        case directConstants.SUCCES_INSERT_DIRECT:
            return Object.assign({}, state, {
                listDirect: [
                    ...state.listDirect,
                    action.data
                ],
                message: {
                    ...state.message,
                    newComentary: '',
                    keyd: action.data.keyd, 
                    yarn: [action.data.yarn],
                },
                loadingDirect: false,
            });
        case directConstants.SUCCES_INSERT_COMENTARY:
            return Object.assign({}, state, {
                listDirect: state.listDirect.map(k => {
                    if (k.keyd === action.data.keyd) {
                        k.lastMessage = action.data.yarn.description
                    }
                    return k
                }),
                message: {
                    ...state.message,
                    newComentary: '',
                    yarn: [
                        ...state.message.yarn,
                        action.data.yarn
                    ],
                },
                loadingDirect: false,
            });
        case directConstants.MESSAGE_VIEWED:
            return Object.assign({}, state, {
                listDirect: state.listDirect.map(k => {
                    if (k.keyd === action.data) {
                        k.viewed = true
                    }
                    return k
                }),
            });
        case directConstants.DELETE_CHAT:
            return Object.assign({}, state, {
                listDirect: state.listDirect.filter(k => (k.keyd !== action.data.keyd)),
                viewNewMessage: false,
                viewMessage: false,
                message: {
                    nom_group: '',
                    participantes: [],
                    yarn: [],
                    newComentary: '',
                }
            });
        case directConstants.LIST_DIRECT_CONTACT:
            return Object.assign({}, state, {
                listContactDirect: action.data.filter(k => (k.accepted !== "2")),
            });
        case directConstants.REQUEST_MESSAGE_DIRECT_PARTICIPANT:
            return Object.assign({}, state, {
                message: {
                    ...state.message,
                    participantes: [action.data]
                },
                viewMessage: false,
                loadingDirect: !state.loadingDirect,
            });
        case directConstants.SUCCES_MESSAGE_DIRECT_PARTICIPANT:
            return Object.assign({}, state, {
                message: {
                    ...state.message,
                    keyd : action.data.keyd,
                    yarn: action.data.yarn
                },
                viewMessage: true,
                loadingDirect: !state.loadingDirect,
            });
        case directConstants.FAIL_MESSAGE_DIRECT_PARTICIPANT:
            return Object.assign({}, state, {
                loadingDirect: !state.loadingDirect,
            });
        default:
            return state
    }
}