import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/* Layouts */
import { BarRigth } from "../layout/barRight";
import Preloader from '../layout/preloader';
import { MenuEspecialistas } from '../layout/MenuEspecialistas';

/* Material UI */
import { Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

/* Icons */
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/*Components */
import { DetalleAsesoriaChat } from "./detalleAsesoriaChat";

/* Actions */
import { citasChatActions, menuActions } from '../../../../_actions'

/* Estilos con Material UI */
import { styles } from "./asesoriaChatStyle";

class ViewDetalleDirecto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyd: this.props.match.params.keydQuote
        }
        this.props.dispatch(menuActions.menuActive(5));
        this.props.dispatch(citasChatActions.getYarnCitasChat(this.props.user, { keyd: this.props.match.params.keydQuote}))
    }

    componentDidUpdate() {
        if (this.state.keyd !== this.props.match.params.keydQuote) {
            this.setState({ keyd: this.props.match.params.keydQuote });
            this.props.dispatch(citasChatActions.getYarnCitasChat(this.props.user, { keyd: this.props.match.params.keydQuote}))
        }
    }

    render() {
        const { loadingCitaChat } = this.props;
        return (loadingCitaChat)
            ? <Preloader />
            : (
                <section className="body" ref={this.container}>
                    <MenuEspecialistas />
                    <BarRigth
                        asesoriaText={true}
                    />
                    <div className="content">
                        <div className="breadcrumbs mb-06">
                            <Link to={'/asesoria-via-texto'}>
                                <Typography color="primary" className="cursor-pointer">
                                    Ir a consultas 
                                </Typography>
                            </Link>
                            <NavigateNextIcon color="primary" />
                            <Typography color="secondary">Detalle de la consulta</Typography>
                        </div>
                        <DetalleAsesoriaChat />
                    </div>
                </section>
            )
    }
}

ViewDetalleDirecto.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { loadingCitaChat } = state.citasChatReducer;
    return {
        user,
        loadingCitaChat,
    };
}

const connectedViewDetalleDirecto = connect(mapStateToProps)(withStyles(styles)(ViewDetalleDirecto));
export { connectedViewDetalleDirecto as ViewDetalleDirecto };