import { alertActions } from '../alert.action';
import { postService } from "../../_services";
import { postConstants } from '../../_constants';
import { ePegeConstants } from '../../_constants';
import { alertConfirmActions } from '../alertConfirm.action';

export const postActions = {
    // getpost,
    // mispost,
    // selectArticulo,
    // inserArticulo,
    // campoObligatorios,
    
    // openEditar,
    // selectDeleteArticulo,
    // deleteArticulo,

    //Crear Post
    submitRequest,
    openNewPost,
    clearImgPost,
    llenarNewPost,
    llenarEditPost,
    disableBoton,
    insertNewPost,
    editPost,
    deltePost,
    
    //Listar Post
    resetOffser,
    getUniquePost,
    getPostFeed,
    getMorePost,
    getPostMedico,
    likes,
    getLoading,


    //Comentarios
    viewComments,
    selectPostComentary,
    submitComentary,
    delteComentary,
    changeEditComentary,
    submitEditComentary,

    //Comentarios Respuestas
    changeComentaryReply,
    submitComentaryReply,
    changeEditComentaryReply,
    submitEditComentaryReply,
    delteComentaryReply,

};

function submitRequest(data) {
    return { type: postConstants.SUBMIT_REQUEST_POST, data }
}

function openNewPost() {
    return dispatch => {
        dispatch(request())
    }
    function request() { return { type: postConstants.OPEN_O_CLOSE_NEW_POST } }
}

function clearImgPost(name) {
    return dispatch => {
        dispatch(success(name))
    }
    function success(data) { return { type: postConstants.REMOVE_IMG_POST, data } }
}

function llenarNewPost(name, value) {
    const data = { name, value  }
    return dispatch => {
        dispatch(success(data))
    }
    function success(data) { return { type: postConstants.NEW_POST, data } }
}

function llenarEditPost(name, value, typeFile) {
    const data = { name, value, typeFile }
    return dispatch => {
        dispatch(success(data))
    }
    function success(data) { return { type: postConstants.EDIT_POST, data } }
}

function disableBoton(data) {
    return { type: postConstants.DISABLE_BTN, data }
}

function insertNewPost(user, newPost) {
    return dispatch => {
        dispatch(request(true))
        postService.insertNewPost(user, newPost)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data.data))
                    dispatch(success_cantidad_post())
                }else{

                }
            });
    }
    function request(data) { return { type: postConstants.DISABLE_BTN, data } }
    function success(data) { return { type: postConstants.SUBMIT_POST_SUCCESS, data } }
    function success_cantidad_post() { return { type: ePegeConstants.ADD_POST_QUANTITY } }
    // function fail() { return { type: postConstants.SUBMITTED_NEW_ARTICULO_FAIL } }
}

function editPost(user, post) {
    return dispatch => {
        dispatch(request(true))
        postService.editPost(user, post)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {

                    }
                });
    }
    function request(data) { return { type: postConstants.DISABLE_BTN, data } }
    function success(data) { return { type: postConstants.SUBMIT_POST_SUCCESS_EDIT, data } }
}

function deltePost(post, user) {
    return dispatch => {
        postService.delatePost(post, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(post))
                        dispatch(alertActions.success(data.message))
                        dispatch(alertConfirmActions.success())
                        dispatch(success_cantidad_post())
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            );
    }
    function success(data) { return { type: postConstants.DELETE_POST, data } }
    function success_cantidad_post() { return { type: ePegeConstants.SUBTRACT_POST_QUANTITY } }
}

function getPostMedico(user) {
    return dispatch => {
        dispatch(request(true))
        postService.getPostMedico(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        dispatch(fail())
                    }
                    dispatch(request(false))
                }
            );

    }
    function request(data) { return { type: postConstants.GET_POSTS_REQUEST, data } }
    function success(data) { return { type: postConstants.GET_POSTS_PERFIL, data } }
    function fail() { return { type: postConstants.FAIL_GET_POST_PERFIL } }
}

function resetOffser() {
    return { type: postConstants.RESET_OFFSET }
}

function getUniquePost(user, post_keyd) {
    return dispatch => {
        dispatch(request(true))
        postService.getUniquePost(user, post_keyd)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail())
                    }
                    dispatch(request(false))
                }
            );

    }
    function request(data) { return { type: postConstants.GET_POSTS_REQUEST, data } }
    function success(data) { return { type: postConstants.GET_POST_UNIQUE, data } }
    function fail() { return { type: postConstants.FAIL_GET_POST } }
}

function getPostFeed(user, offset) {
    return dispatch => {
        offset === 0 && dispatch(request(true))
        offset !== 0 && dispatch(request_loadng())
        postService.getPostFeed(user, offset)
        .then(
            data => {
                if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail())
                    }
                    offset > 0 && dispatch(request_loadng())
                    offset === 0 && dispatch(request(false))
                }
            );

    }
    function request(data) { return { type: postConstants.GET_POSTS_REQUEST, data } }
    function request_loadng() { return { type: postConstants.SUBMIT_REQUEST_COMENTARY } }
    function success(data) { return { type: postConstants.GET_POSTS, data } }
    function fail() { return { type: postConstants.FAIL_GET_POST } }
}

function getMorePost(nickname, offset, user) {
    return dispatch => {
        dispatch(request_loadng())
        postService.getMorePost(user, nickname, offset)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail())
                    }
                    dispatch(request_loadng())

                }
            );

    }
    function request_loadng() { return { type: postConstants.SUBMIT_REQUEST_COMENTARY } }
    function success(data) { return { type: postConstants.GET_POSTS, data } }
    function fail() { return { type: postConstants.FAIL_GET_POST } }
}

function likes(data, user, type) {
    return dispatch => {
        dispatch(success(data))
        postService.updateLike(user, type, data)
            .then(
                data => {
                    if (!data.success) {
                        dispatch(fail(data.data))
                    }
                }
            );
    }
    function success(data) { return { type: postConstants.UPDATE_LIKE, data } }
    function fail(data) { return { type: postConstants.FAIL_UPDATE_LIKE, data } }
}

function getLoading() {
    return { type: postConstants.SUBMIT_REQUEST_COMENTARY }
}

function viewComments(post, user) {
    return dispatch => {
        dispatch(request(true))
        dispatch(select({ loaddingComen: true, post_request: post}))
        postService.getComentary(user, post)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }
                }
            );
        
    }
    function request(data) { return { type: postConstants.SUBMIT_REQUEST_COMENTARY, data } }
    function select(data) { return { type: postConstants.SELECT_POST_TO_COMENTARY, data } }
    function success(data) { return { type: postConstants.VIEW_COMMNETS, data } }
    
}

function selectPostComentary(data) {
    return { type: postConstants.SELECT_POST_TO_COMENTARY, data }
}

function submitComentary(comentario, post, user) {
    return dispatch => {
        dispatch(request())
        postService.insertComentario(comentario, post, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }
                }
            );
    }
    function request() { return { type: postConstants.SUBMIT_REQUEST_COMENTARY, } }
    function success(data) { return { type: postConstants.SUBMIT_SUCCESS_COMENTARY, data } }
}

function delteComentary(comentario, post, user ) {
    return dispatch => {
        postService.delateComentario(comentario, post, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success(data.message))
                        dispatch(alertConfirmActions.success())
                    }else{
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            );
    }
    function success(data) { return { type: postConstants.DELETE_COMENTARY, data } }
}

function changeEditComentary(comentary, dataComentary) {
    return { type: postConstants.CHANGE_VALUE_COMENTARY, data: { comentary, idComentary: dataComentary.comente.keyds, post: dataComentary.post} }
}

function submitEditComentary(comentario, user) {
    return dispatch => {
        dispatch(request())
        postService.editComentario(comentario, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(''))
                    }else{
                        dispatch(alertActions.success(data.message))
                    }
                    dispatch(request())
                }
            );
    }
    function request() { return { type: postConstants.SUBMIT_REQUEST_COMENTARY, } }
    function success(data) { return { type: postConstants.SELECT_POST_TO_COMENTARY, data } }
}

//Comentarios Respuestas
function changeComentaryReply(data) {
    return { type: postConstants.CHANGE_VALUE_REPLY_COMENTARY, data } 
}

function submitComentaryReply(user, selectComentary, autorPost, comentaryReply) {
    return dispatch => {
        dispatch(request())
        postService.insertRespuestaComentario(user, selectComentary, autorPost, comentaryReply)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        
                    }
                }
            )
    }
    function request() { return { type: postConstants.SUBMIT_REQUEST_COMENTARY, } }
    function success(data) { return { type: postConstants.SUBMIT_SUCCESS_COMENTARY_REPLY, data } }
}

function changeEditComentaryReply(commentary_reply, dataComentary) {
    return { type: postConstants.CHANGE_VALUE_REPLY_COMENTARY_EDIT, data: { commentary_reply, idComentary: dataComentary.comenteReply.keyds, post: dataComentary.post } }
}

function submitEditComentaryReply(comentarioReply, user) {
    return dispatch => {
        dispatch(request())
        postService.editComentarioReply(comentarioReply, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(''))
                    } else {
                        dispatch(alertActions.success(data.message))
                    }
                    dispatch(request())
                }
            );
    }
    function request() { return { type: postConstants.SUBMIT_REQUEST_COMENTARY, } }
    function success(data) { return { type: postConstants.SELECT_POST_TO_COMENTARY, data } }
}

function delteComentaryReply(comentario, post, user) {
    return dispatch => {
        postService.delateComentarioReply(comentario, post, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success(data.message))
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            );
    }
    function success(data) { return { type: postConstants.DELETE_COMENTARY_REPLY, data } }
}