import React, { Component } from 'react'
import dateFns from "date-fns";

import './calendarioComponent.css';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';


/* Components */
import AlertDialog from "./alertDialogo";

/* Actions */
import { alertActions, horarioActions } from '../../../../_actions';

/* Iconos */
import ClearIcon from '@material-ui/icons/Clear';

// Require Esperanto locale
var esLocale = require('date-fns/locale/es')


class Calendar extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            currentMonth: new Date(),
            selectedDate: new Date(),
            open: false,
            selectedFech: null,
        };

        this.handleSubmitDiaInactivo = this.handleSubmitDiaInactivo.bind(this)
    }

    renderHeader() {
        const dateFormat = "MMMM YYYY";
        return (
            <div className="header rowCalendar flex-middle">
                <div className="col col-start">
                    <div className="icon" onClick={this.prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className="col col-center">
                    <Typography color="primary" component="span">
                        {dateFns.format(this.state.currentMonth, dateFormat, { locale: esLocale })}
                    </Typography>
                </div>
                <div className="col col-end" onClick={this.nextMonth}>
                    <div className="icon">chevron_right</div>
                </div>
            </div>
        );
    }

    renderDays() {
        const dateFormat = "dddd";
        const dateFormatTow = "ddd";
        const days = [];
        let startDate = dateFns.startOfWeek(this.state.currentMonth);
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center" key={i}>
                    <Hidden smUp>
                        {dateFns.format(dateFns.addDays(startDate, i), dateFormatTow, { locale: esLocale })}
                    </Hidden>
                    <Hidden xsDown>
                        {dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale: esLocale })}
                    </Hidden>

                </div>
            );
        }

        return <div className="days rowCalendar">{days}</div>;
    }

    renderCells() {
        const { currentMonth } = this.state;
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart);
        const startDate = dateFns.startOfWeek(monthStart);
        const endDate = dateFns.endOfWeek(monthEnd);

        const dateFormat = "D";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        const { list, fechas, citasAgendadas, interconsulta, handleSelectFech } = this.props;
        const dateFormatTow = "dd";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                let diaSelected = false; 
                let diaInactivo = false;
                const diaActual = dateFns.format(day, 'YYYY-MM-DD');

                //Validacion para el Calendario de citas
                if (citasAgendadas) {
                    citasAgendadas.map(dia => {
                        if (dia.fech_quotes === diaActual) {
                            diaSelected = true
                        }
                        return '';
                    })
                }
                
                // Validacion para Horario medico
                const fil = dateFns.format(dateFns.addDays(startDate, i), dateFormatTow, { locale: esLocale });
                if (list){
                    list.map(dia=>{
                        if (dia.selected && dia.codigo.toLowerCase() === fil) {
                            diaSelected = true
                        }
                        return '';
                    })
                }
                if (fechas) {
                    fechas.map(inactivo=>{
                        if (inactivo.selected && inactivo.fech_inactive === diaActual) {
                            diaInactivo = true
                        }
                        return '';
                    })
                }
                // Fin Validacion para Horario medico
                formattedDate = dateFns.format(day, dateFormat);
                const cloneDay = day;
                
                const diaPaso = interconsulta && (dateFns.compareAsc(day, new Date()) !== 1) ? 'disabled' : '';
                const styleSelectDia = !dateFns.isSameMonth(day, monthStart) ? "disabled" : diaSelected ? "selectedDia" : interconsulta && 'disabled'
                days.push(
                    <div
                        className={`col cell ${styleSelectDia} ${diaPaso}`}
                        key={day}
                        
                    >
                        <span className="number">{formattedDate}</span>
                        {citasAgendadas &&
                            <span className="bg" onClick={diaSelected ? () => handleSelectFech(dateFns.format(cloneDay, 'DD/MM/YYYY')) : () => handleSelectFech()} >{formattedDate}</span>
                        }
                        {list &&
                            interconsulta 
                                ? (dateFns.compareAsc( day, new Date() ) === 1 ) &&
                                    <span className="bg" onClick={diaSelected ? () => handleSelectFech({ fech: dateFns.format(cloneDay, 'YYYY-MM-DD'), dia: i === 0 ? 7 : i }) : () => handleSelectFech()} >{formattedDate}</span>
                                : !citasAgendadas &&
                                    <span className="bg" onClick={diaSelected ? () => this.onDateClick(dateFns.parse(cloneDay)) : () => this.onDateClick()}>{formattedDate}</span>
                        }
                        { diaInactivo &&
                            <ClearIcon/>
                        }
                        
                    </div>
                );
                day = dateFns.addDays(day, 1);
            }
            rows.push(
                <div className="rowCalendar" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="bodyCalendar">{rows}</div>;
    }

    onDateClick = day => {
        const { dispatch } = this.props;
        if (day) {
            const result = dateFns.compareAsc(
                day,
                new Date()
            )
            //console.log(result)
            if (result !== 1) {
                dispatch(alertActions.error('La fecha seleccionada ya paso'));
            }else{
                this.setState({ open: true });
                this.setState({
                    selectedFech: dateFns.format(day, 'YYYY-MM-DD', { locale: esLocale })
                });
            }
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSubmitDiaInactivo(e) {
        const { dispatch, fechas, user } = this.props;
        this.setState({ open: false });
        dispatch(horarioActions.diaInactivo(this.state.selectedFech, fechas, user))
        console.log('acepto '+fechas)

    }
    
    nextMonth = () => {
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
        });
    };

    prevMonth = () => {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
        });
    };

    render() {
        return (
            <div className="calendar">
               
                {this.renderHeader()}
                {this.renderDays()}
                {this.renderCells()}
                <AlertDialog
                    open={this.state.open}
                    handleClose={this.handleClose}
                    message='¿Esta seguro de seleccionar este dia?'
                    procesar={this.handleSubmitDiaInactivo}
                />
            </div>
        );
    }
}

export default Calendar;