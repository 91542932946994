import { apiCardio } from '../_constants';

export const historiaService = {
    getHistoria,
    regisHistoria
};

function getHistoria(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlHistoria}getHistoriaPorCita`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function regisHistoria(user, paciente, historia) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ paciente, historia })
    };

    return fetch(`${apiCardio.urlHistoria}registrarHistoriaPorCita`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}