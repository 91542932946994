import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

/* Components */
import Chips from "../../layout/chips";

/* MAterial UI */
import { Typography, Grid, Paper, Divider, IconButton, CircularProgress, Button, TextField } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../perfilStyle";

// Icons
import EditIcon from '@material-ui/icons/Edit';

/* Actions */
import { ePageActions } from '../../../../../_actions'


class CardExtracto extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leermas: false,
        };
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleOnSubmitExtracto = this.handleOnSubmitExtracto.bind(this)
    }

    handleViewEditExtracto = () => {
        this.props.dispatch(ePageActions.viewEditExtracto())
    }

    handleChangeInput(e) {
        const { value } = e.target
        const { dispatch } = this.props;
        dispatch(ePageActions.addExtracto(value))
    }
    handleOnSubmitExtracto(e) {
        e.preventDefault();
        const { dispatch, extracto, user } = this.props;
        dispatch(ePageActions.sumitedRequest())
        if (extracto) {
            dispatch(ePageActions.inserExtracto(user, extracto))
        }
    }

    handleVermas = () => {
        this.setState({ leermas: !this.state.leermas });
    }

    render() {
        const { leermas } =this.state
        const { classes, user, extracto, especialidades, viewEditExtracto, submitted, loading, nameEspecialista, fech_reg, visitor } = this.props;
        const nombre = nameEspecialista ? nameEspecialista : user.name+' '+user.surname
        const fech_regis = fech_reg ? fech_reg : user.fech_reg 
        return (
            <Paper className="paper">
                <div className="title-paper">
                    <Typography color='primary' component="span" className="nombre-user">{nombre}</Typography>
                    <Typography conponent="span" color="primary" className="time-in-platafor">En eCARDIOLINE desde {fech_regis}</Typography>
                </div>
                <div >
                    { !viewEditExtracto 
                        ? <Grid container>
                            <Grid item xs={visitor ? 12 : 10}>
                                <div className={`extracto ${leermas ? classes.verLeerMas : ''} `} >
                                    {!extracto 
                                        ? <Typography conponent="span" color="textSecondary">Escribe algo sobre ti.</Typography>
                                        : <Typography conponent="span" color="textSecondary" dangerouslySetInnerHTML={{ __html: extracto }}></Typography>
                                    }
                                </div>
                                <Typography conponent="span" color="primary" className="cursor-pointer mb-06" onClick={this.handleVermas} >
                                    {!extracto ? ''
                                        : leermas ? 'Leer menos.' : 'Leer más.'
                                    }
                                    
                                </Typography>
                            </Grid>
                            {visitor 
                                ? null
                                : <Grid item xs={2} align="center">
                                    <IconButton aria-label="Edit" onClick={this.handleViewEditExtracto}>
                                        <EditIcon color="primary" />
                                    </IconButton>
                                </Grid>
                            }
                            
                        </Grid>
                        : <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    rows="3"
                                    rowsMax="8"
                                    id="extracto"
                                    margin="dense"
                                    name="extracto"
                                    value={extracto !== null ? extracto : '' }
                                    placeholder="Queremos saber mas de ti."
                                    onChange={this.handleChangeInput}
                                    error={submitted && !extracto}
                                    helperText={submitted && !extracto &&
                                        <span className="lblError" >Campo Requerido</span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <div className="pt-1 mb-06">
                                    <Button variant="contained" className="mr_1" onClick={this.handleViewEditExtracto}>
                                        Cancelar
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        onClick={this.handleOnSubmitExtracto}
                                        disabled={submitted && loading}
                                    >
                                        Actualizar
                                        {submitted && loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    }
                    
                    <Divider className="mb-06"/>
                    <Chips
                        list={especialidades}
                    />
                </div>
            </Paper>
        );
    }
}

CardExtracto.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { extracto, especialidades, viewEditExtracto, submitted, loading, nameEspecialista, fech_reg, visitor } = state.ePage;
    return {
        user,
        extracto, especialidades, viewEditExtracto, submitted, loading, nameEspecialista, fech_reg, visitor
    };
}

const connectedCardExtracto = connect(mapStateToProps)(withStyles(styles)(CardExtracto));
export { connectedCardExtracto as CardExtracto };