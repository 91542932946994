import React from 'react'
/* MAterial UI */
import './post.css';
import { styles } from "./postStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Avatar, IconButton, Menu, MenuItem, InputBase, CircularProgress, Button } from '@material-ui/core';

/* Iconos */
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {ListComentarioRespuesta} from './listComentarioRespuesta'
import Emojis from '../layout/emojis'

const ListComentarios = (props) => (
    <div>
        {props.list.length 
            ? props.list.map(k=>{
                const data = { type: 'comentary', itemSelect: k.keyds, 'post': props.post, 'closeMenu': 'anchorEl' }
                const listReply = props.listReply.filter(q => (q.keyd_comentary === k.keyds))
                return(
                    <div key={k.keyds}>
                        <div className="comentary valign-wrappe" >
                            <Avatar className={props.classes.purpleAvatar}>
                                {k.letteravatars}
                                {props.selectPost.comente && (props.selectPost.comente.keyds === k.keyds && props.loading) && <CircularProgress size={27} color="secondary" className={`ProgressAbsolut ${props.classes.progresComentario}`} />}
                            </Avatar>
                            <div className={`cont-comentary ${props.selectPost.comente && (props.selectPost.comente.keyds === k.keyds) && 'editar-comentary'}`}>
                                {props.selectPost.comente && (props.selectPost.comente.keyds === k.keyds) 
                                    ?<div>
                                        <InputBase
                                            fullWidth
                                            multiline
                                            autoFocus
                                            rowsMax="6"
                                            name="editComentario"
                                            className="text-area"
                                            onChange={props.handleChangeEditImputComentary}
                                            onKeyDown={props.handleSubmitEditComentary}
                                            value={props.selectPost.comente && props.selectPost.comente.commentary && props.selectPost.comente.commentary}
                                        />
                                        <Emojis
                                            post={{comente: k, post: props.post}}
                                            dispatch={props.dispatch}
                                            handleSelectEmoji={props.handleSelectEmoji}
                                        />
                                    </div> 
                                    : <Typography component="span" color="textSecondary">
                                        <b className='autor-comentary'>{k.autor}</b>
                                        {k.commentary}
                                    </Typography>
                                }
                            </div>
                            { (props.autorPost || k.more)
                                 &&
                                !props.selectPost.comente &&
                                !(props.selectPost.keyds === k.keyds) &&
                                <div>
                                    <IconButton
                                        aria-label="More"
                                        aria-owns={props.anchorEl ? 'long-menu'+k.keyds : undefined}
                                        aria-haspopup="true"
                                        onClick={props.handleOpenMenu({ itemSelect: k.keyds, 'closeMenu': 'anchorEl' })}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    <Menu
                                        id={`long-menu${k.keyds}`}
                                        anchorEl={props.anchorEl}
                                        open={(props.selectPost === k.keyds) && props.anchorEl ? true : false}
                                        onClose={props.handleCloseMenu( 'anchorEl' )}

                                    >   
                                        {props.nombreUser === k.autor && 
                                            <MenuItem onClick={props.handleEditarComentary({comente: k, post: props.post})}>
                                                Editar
                                            </MenuItem>
                                        }
                                        <MenuItem onClick={props.handleRemoveElement(data)}>
                                            Eliminar
                                        </MenuItem>
                                    </Menu>
                                </div>
                            }
                        </div>
                        {props.selectPost.comente &&
                            (props.selectPost.comente.keyds === k.keyds) && <Typography component="span" className="mb-06" color="textSecondary">Presiona <b>Esc</b> para Cancelar o <b>Enter</b> para Guardar</Typography> }
                        <div className={`cont-reply-comment ${listReply.length ? 'mb-12' : ''}`}>
                            <Button color="primary" className="boton-action" onClick={props.handleReplyComment({ replyComentary: k.keyds, post: props.post })}>
                                Responder
                            </Button>
                            {(props.selectPost.replyComentary && (props.selectPost.replyComentary === k.keyds)) || listReply.length
                                ? <ListComentarioRespuesta
                                    keydsComentary={k.keyds}
                                    postComentary={props.post}
                                    lisComentaryReply={listReply}
                                    autorPost={props.autorPost ? 1 : 0}
                                />
                                : null
                            }
                        </div>
                        
                    </div>
                )
            })
            :''
        }
    </div>
)


export default withStyles(styles)(ListComentarios);