export const incidenciaConstants = {
    REQUEST_INCIDENCIAS: 'REQUEST_INCIDENCIAS',
    GET_INCIDENCIAS : 'GET_INCIDENCIAS',
    GET_TYPE_INCIDEN : 'GET_TYPE_INCIDEN',
    FAIL_INCIDENCIAS : 'FAIL_INCIDENCIAS',

    CAMBIAR_VIEW: 'CAMBIAR_VIEW',
    MOSTRAR_HILO_INCIDENCIA: 'MOSTRAR_HILO_INCIDENCIA',

    FADE_NEW_INCIDEN: 'FADE_NEW_INCIDEN',
    FORM_NUEVA_INCIDENCIA: 'FORM_NUEVA_INCIDENCIA',

    SUBMIT_FORM: 'SUBMIT_FORM',
    SUBMIT_REQUEST: 'SUBMIT_REQUEST',
    SUBMIT_SUCCES: 'SUBMIT_SUCCES',

    CAMBIAR_CANTIDAD_ELEMENTO_POR_PAGINA: 'CAMBIAR_CANTIDAD_ELEMENTO_POR_PAGINA',

    DESCRIPTION_YARN_INCIDEN: 'DESCRIPTION_YARN_INCIDEN',
    SUCCES_REGIS_HILO_INCIDEN: 'SUCCES_REGIS_HILO_INCIDEN', 

    SUCCES_REPORTE_VISTO: 'SUCCES_REPORTE_VISTO',

    SUCCES_DELETE_INCIENCIA: 'SUCCES_DELETE_INCIENCIA', 
    SELECT_INCIDENCIA_DELETE: 'SELECT_INCIDENCIA_DELETE', 
    DESELECT_INCIDENCIA_DELETE: 'DESELECT_INCIDENCIA_DELETE', 
};