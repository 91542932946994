import React from 'react'

/* MAterial UI */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button, TextField, CircularProgress, Divider } from '@material-ui/core';

/* Estilos con Material UI */
import { styles } from "../configuracionStyle";

let viewMonto = false
const MontoInterConsulta = (props) => (
    <div >
        {props.list &&
            props.list.map(data => {
                if (data.selected) {
                    viewMonto = true;
                }
                return "";
            })
        }
        {viewMonto &&
            <Grid item xs={12}>
                <Paper className="paper">
                    <div className="title-paper">
                        <Typography color='primary' component="span" className="mb-06">
                            Coloca el precio de tu interconsulta vía {props.modalidadTexto[0]['name']}
                        </Typography>
                        <Divider />
                    </div>
                    {props.modalidadTexto[0]['selected']
                        ? <div>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Grid container spacing={24} >
                                        {props.list.map(data => {
                                            if (data.selected === true) {
                                                return (
                                                    <Grid item xs key={data.id}>
                                                        <TextField
                                                            fullWidth
                                                            disabled
                                                            id={`${props.metodoInterconsulta}${data.id}`}
                                                            name={data.id}
                                                            margin="dense"
                                                            label={data.name}
                                                            autoComplete="off"
                                                            onKeyPress={props.handleNumeric}
                                                            value={data[props.metodoInterconsulta]}
                                                            onChange={props.handleMontoAsesoriaChat(props.typeInterconsulta)}
                                                            className={props.classes.textFieldPrecio}
                                                        // error={props.submittedMontoChat && !data.montoInterconsultaVivo}
                                                        // helperText={props.submittedMontoChat && !data.montoInterconsultaVivo &&
                                                        //     <span className="lblError" >Campo Requerido</span>
                                                        // }
                                                        />
                                                    </Grid>
                                                );
                                            }
                                            return '';
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <div className="mt-1">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="mr_1"
                                    variant="contained"
                                    disabled={(props.loading && props.submittedMontoChat) || props.interConsultaGratis}
                                    onClick={props.handleSubmitMontoChat(props.metodoInterconsulta)}
                                >
                                    Registrar
                                    {(props.loading && props.submittedMontoChat) && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>

                            </div> */}
                            <div className="mt-1">
                                <Typography color="textSecondary" className="color-gris" component="span" >
                                    Si lo deseas puedes colocar tus interconsulta en vivo de forma gratuita, dando clic en el boton "Activar interconsulta gratuita".
                                </Typography>
                                <Button
                                    type="submit"
                                    className="mt-1"
                                    variant="contained"
                                    color={props.interConsultaGratis ? "primary" : 'default'}
                                    onClick={props.handleDesactivarAsesoriaChat({
                                        variableReducer: props.typeInterconsulta === 4 ? 'interConsultaEnvivoGratis' : 'interConsultaTextoGratis',
                                        typeQoute: props.typeInterconsulta
                                    })}
                                    disabled={props.loading && props.submittedMontoChat}
                                >
                                    {props.interConsultaGratis ? 'Desactivar interconsulta gratuita' : 'Activar interconsulta gratuita'}

                                    {(props.loading && props.submittedMontoChat) && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                            </div>
                        </div>
                        : <Typography color="textSecondary" className="color-gris" component="span" >
                            Para completar esta sección debe tener selecciona la modalidad {props.modalidadTexto[0]['name']}
                        </Typography>
                    }
                </Paper>
            </Grid>
        }
    </div>
)


export default withStyles(styles)(MontoInterConsulta);