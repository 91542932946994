import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { history } from '../../../../../_helpers';
import { Link } from 'react-router-dom'
import dateFns from "date-fns";

/* Layouts */
import AlertDialog from "../../layout/alertDialogo";
import { MenuEspecialistas } from '../../layout/MenuEspecialistas';

/*Component */
import DialogHorario from "./dialogHorario";
import Viewcalendario from "./viewCalendario";
import PagarInterconsulta from "./pagarInterconsulta";
import InterconsultaEscrita from "./interconsultaEscrita";
import EspecialidadYModalidad from "./especialidadYModalidad";
import DocumentosInterconsulta from "./documentosInterconsulta";

/* Material UI */
import '../index.css';
import { styles } from "../interConsultaStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper } from '@material-ui/core';

/* Icons */
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/* Actions */
import { interConsultaActions, alertActions, alertConfirmActions } from '../../../../../_actions'

class SolicitudInterConsulta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nicknameSelect: this.props.match.params.nickname,
            paraclinicosFile: [],
            comprobante: '',
        };
        const { user, dispatch } = this.props;
        dispatch(interConsultaActions.getInfoCita(user, this.state.nicknameSelect));
        dispatch(interConsultaActions.viewPagar(false))
        //dispatch(alertConfirmActions.openCloseAlert());
    }
    
    
    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar los props):
        if (this.props.infoInterconsulta.medic !== prevProps.infoInterconsulta.medic) {
            this.props.dispatch(interConsultaActions.getDayWork(this.props.user, this.props.infoInterconsulta.medic));
            this.props.dispatch(interConsultaActions.getForm(this.props.user, this.props.infoInterconsulta.medic));
            this.props.dispatch(interConsultaActions.getBanksCountry());
            const typeQoute = this.props.infoInterconsulta.modalidad_cita.filter(k => (k.selected))
            typeQoute.length && 
                this.props.dispatch(interConsultaActions.formaPago(this.props.user, this.props.infoInterconsulta.medic, typeQoute[0].id));
        }
    }

    handleChangeInput = event => {
        const { name, value } = event.target
        this.props.dispatch(interConsultaActions.changeInput(name, value));
    };
    
    changeInputParaclinicos = data => event => {
        const { name, value } = event.target
        if (data === 'file') {
             this.setState({paraclinicosFile : {
                 ...this.state.paraclinicosFile,
                 [name]: event.target.files[0]
             }})
        }else{
            this.setState({
                paraclinicosFile: {
                    ...this.state.paraclinicosFile,
                    [name]: value
                }
            })
            //this.props.dispatch(interConsultaActions.changeInputParaclinicos(name, value));
        }
    };

    handleSelectChip = data => () => {
        if (data.id) {
            /* Seleccionar la modalidad para la interconsulta*/
            this.props.dispatch(interConsultaActions.seleccionarItem(data, 'modalidad_cita'));
            this.props.dispatch(interConsultaActions.formaPago(this.props.user, this.props.infoInterconsulta.medic, data.id));
        }else{
            /* Seleccionar la especialidad para la interconsulta */
            this.props.dispatch(interConsultaActions.seleccionarItem(data, 'especialidades'));
        }
    }

    /* Handle para seleccionar fecha y hora para la cita */
    handleSelectFech = data  =>{
       const { user, dispatch, infoInterconsulta } = this.props
       dispatch(interConsultaActions.getHorasDisponibles(user, data, infoInterconsulta.medic))
    }

    handleOpenAndCloseDialog = data => () =>{
        this.props.dispatch(interConsultaActions.openCloseDialog(data))
    }

    handleSelectHora = data => () => {
        if (data) {
            this.props.dispatch(interConsultaActions.selectHour(data))
        }
    }
    /* Fin Handle para seleccionar fecha y hora para la cita */

    handleSolicitarInterconsulta = e => {
        e.preventDefault();
        const { dispatch, infoInterconsulta, user } = this.props
        
        const modalidadOn = infoInterconsulta.modalidad_cita && infoInterconsulta.modalidad_cita.filter(k => (k.selected))[0]

        const v1 = infoInterconsulta.hourSelect.attention_id ? true : false
        const v2 = infoInterconsulta.daySelect.fech ? true : false
        const v3 = (infoInterconsulta.formaPagoSelect !== '') ? true : false
        const v4 = (infoInterconsulta.precioSelect !== '') ? true : false
        const v5 = (infoInterconsulta.motivoCita !== '') ? true : false
        const v6 = infoInterconsulta.especialidades.filter(k => (k.selected || k.selected !== '')).length > 0 ? true : false
        const v7 = infoInterconsulta.modalidad_cita.filter(k => (k.selected || k.selected !== '')).length > 0 ? true : false

        if (modalidadOn.id === '4') {
            if (v1 && v2 && v3 && v4 && v5 && v6 && v7) {
                const data ={
                    "paraClinicosText"  : infoInterconsulta.paraClinicos,
                    "paraClinicosFile"  : this.state.paraclinicosFile,
                    "cita_dirigida"     : 0,
                    "especialidad"      : infoInterconsulta.especialidades.filter(k => (k.selected))[0].keyd,
                    "medicoslect"       : infoInterconsulta.medic,
                    "fechacita"         : infoInterconsulta.daySelect.fech,
                    "tipopago"          : infoInterconsulta.formaPagoSelect,
                    "horacita"          : infoInterconsulta.hourSelect.attention_id,
                    "motivo"            : infoInterconsulta.motivoCita,
                    "campos"            : infoInterconsulta.getForm.length,
                    "monto"             : infoInterconsulta.precioSelect,
                    "tipo"              : infoInterconsulta.modalidad_cita.filter(k => (k.selected))[0].id,
                }
                dispatch(interConsultaActions.insertCita(user, data))
            }else{
                dispatch(alertActions.info('Los campos con (*) son obligatorios'))
            }
        }else{
            if (v3 && v4 && v5 && v6 && v7) {
                const data = {
                    "paraClinicosText": infoInterconsulta.paraClinicos,
                    "paraClinicosFile": this.state.paraclinicosFile,
                    "cita_dirigida": 0,
                    "especialidad": infoInterconsulta.especialidades.filter(k => (k.selected))[0].keyd,
                    "medicoslect": infoInterconsulta.medic,
                    "fechacita": dateFns.format(new Date(), 'YYYY-MM-DD'),
                    "tipopago": infoInterconsulta.formaPagoSelect,
                    "horacita": 1,
                    "motivo": infoInterconsulta.motivoCita,
                    "campos": infoInterconsulta.getForm.length,
                    "monto": infoInterconsulta.precioSelect,
                    "tipo": infoInterconsulta.modalidad_cita.filter(k => (k.selected))[0].id,
                }
                // console.log(data)
                dispatch(interConsultaActions.insertCita(user, data))
            }else{
                dispatch(alertActions.info('Los campos con (*) son obligatorios'))
            }
        }
    }

    handleProbando = data  => {
        if (data.valor <= this.props.infoInterconsulta.getForm.length) {          
            this.props.dispatch(interConsultaActions.changeInputParaclinicos('campo'+data.valor, data.indice));
        }
        //e.preventDefault();
    }

    handleCloseAlert = () => {
        const { qoute, dispatch } = this.props
        dispatch(alertConfirmActions.openCloseAlert())
        if (qoute.tipoPago !== '') {
            window.location.href = `/mis-interconsultas`
        }
    };

    handleViewPagar = () => {
        const { qoute, dispatch } = this.props
        if (qoute.tipoPago === '6' || qoute.tipoPago === 8 ) {
            history.push('/mis-interconsultas');
        }else{
            dispatch(interConsultaActions.viewPagar(true))
        }
    }

    
    handleChangePago = data => event =>{
        const { name, value } = event.target
        if (data === 'file') {
            this.setState({
                comprobante: event.target.files[0]
            })
        } else {
            this.props.dispatch(interConsultaActions.changeInpuPagos(name, value));
        }
    }

    handleSubmitPago = e => {
        e.preventDefault();
        const { qoute, bancos, dispatch } = this.props
        const bank = bancos.filter(k => (k.way_to_pay_id === ''+qoute.tipoPago))[0]
        console.log(this.state.comprobante)
        if (this.state.comprobante.name) {
            if (qoute.confirmacion && qoute.montopagar) {
                dispatch(interConsultaActions.insertPago(qoute, this.state.comprobante, bank.banks_id));
            }
        }else{
            dispatch(alertActions.info('Debe adjuntar soporte de pago'))
        }
    }

    render() {
        const { paraclinicosFile, comprobante } = this.state
        const { infoInterconsulta, dispatch, dialogHour, loadingInter, user,
            submitedConfrim, openConfirm, message, viewPagar, qoute, bancos } = this.props
        const modalidadOn = infoInterconsulta.modalidad_cita && infoInterconsulta.modalidad_cita.filter(k => (k.selected))[0]
        return (
            <section className="body" ref={this.container}>
                {/* <Header titlePage='Inicio' /> */}
                <MenuEspecialistas />

                <div className="content sin-bar">
                    <div className="breadcrumbs mb-06">
                        <Link to={'/interconsulta'}>
                            <Typography color="primary" className="cursor-pointer">Regresar</Typography>
                        </Link>
                        <NavigateNextIcon color="primary" />
                        <Typography color="secondary">Detalle de la interconsulta </Typography>
                    </div>
                    {!viewPagar 
                        ? <Grid container spacing={24}>
                            <EspecialidadYModalidad
                                infoInterconsulta={infoInterconsulta}
                                handleSelectChip={this.handleSelectChip}
                            />


                            {!modalidadOn &&
                                <Grid item xs={12} align='center'>
                                    <Paper className="paper">
                                        <Typography component="span" color='primary'>Debe Seleccionar una modalidad de interconsulta para continuar el proceso.</Typography>
                                    </Paper>
                                </Grid>
                            }

                            {modalidadOn && modalidadOn.id === '5' && 
                                <InterconsultaEscrita
                                    loadingInter={loadingInter}
                                    paraclinicosFile={paraclinicosFile}
                                    infoInterconsulta={infoInterconsulta}
                                    handleProbando={this.handleProbando}
                                    handleChangeInput={this.handleChangeInput}
                                    changeInputParaclinicos={this.changeInputParaclinicos}
                                    handleSolicitarInterconsulta={this.handleSolicitarInterconsulta}
                                />
                            }

                            {modalidadOn && modalidadOn.id !== '5' && 
                                <DocumentosInterconsulta
                                    infoInterconsulta={infoInterconsulta}
                                    paraclinicosFile={paraclinicosFile}
                                    handleChangeInput={this.handleChangeInput}
                                    changeInputParaclinicos={this.changeInputParaclinicos}
                                    handleProbando={this.handleProbando}
                                />
                            }

                            {modalidadOn && modalidadOn.id !== '5' &&
                                <Viewcalendario
                                    dispatch={dispatch}
                                    loadingInter={loadingInter}
                                    infoInterconsulta={infoInterconsulta}
                                    handleChangeInput={this.handleChangeInput}
                                    handleSelectFech={this.handleSelectFech}
                                    handleSolicitarInterconsulta={this.handleSolicitarInterconsulta}
                                />
                            }

                            <DialogHorario
                                openDialog={dialogHour}
                                loadingInter={loadingInter}
                                hour={infoInterconsulta.hourWork}
                                handleSelectHora={this.handleSelectHora}
                                closeDialog={this.handleOpenAndCloseDialog}
                            />

                            <AlertDialog
                                open={openConfirm}
                                loading={loadingInter}
                                submitted={submitedConfrim}
                                procesar={this.handleViewPagar}
                                handleClose={this.handleCloseAlert}
                                message={message}
                            />

                        </Grid>
                        : <PagarInterconsulta
                            user={user}
                            qoute={qoute}
                            loading={loadingInter}
                            comprobante={comprobante}
                            bancos={bancos.length && bancos.filter(k => (k.way_to_pay_id === qoute.tipoPago ) )[0]}
                            handleChangePago={this.handleChangePago}
                            handleSubmitPago={this.handleSubmitPago}
                        />
                    }
                    
                </div>
            </section>
        )
    }
}

SolicitudInterConsulta.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { loadingInter, infoInterconsulta, dialogHour, message, qoute, viewPagar, bancos} = state.interConsultaReducer;
    return {
        user,
        openConfirm, loadingConfirm, submitedConfrim,
        loadingInter, infoInterconsulta, dialogHour, message, qoute, viewPagar, bancos
    };
}

const connectedSolicitudInterConsulta = connect(mapStateToProps)(withStyles(styles)(SolicitudInterConsulta));
export { connectedSolicitudInterConsulta as SolicitudInterConsulta };