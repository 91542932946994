export const styles = theme => ({
    p0:{
        padding: 0
    },
    contBtn:{
        margin: 12,
        height: 51,
    },
    mr_1:{
        marginRight: '1rem',
    },
    mb_1:{
        marginBottom: '1rem',
    },
    // Detalle Persona
    contDetalleGeneral:{
        marginTop: 1,
    },
    contImgPerson:{
        width: 200,
        height: 200,
        margin: 'auto',
    },
    imgPerso:{
        width: '100%',
        height: '100%',
    },
    contInfo:{
        width: '100%',
        padding: 12,
        [theme.breakpoints.up("sm")]: {
            padding: '20px 12px 0',
            width: 'calc(100% - 200px)',
        },
    },
    span: {
        fontSize: '.8rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    pHistoria: {
        padding: '6px 0 1px'
    },
    title_expansion: {
        color: theme.palette.primary.main,
    },
});