import { apiCardio } from '../_constants';

export const postService = {
    // insertArticulo,
    // deleteArticulo,
    
    insertNewPost,
    editPost,
    delatePost,
    getUniquePost,
    getPostFeed,
    getMorePost,
    getPostMedico,
    updateLike,
    insertComentario,
    delateComentario,
    editComentario,
    getComentary,
    insertRespuestaComentario,
    editComentarioReply,
    delateComentarioReply,
};

function insertNewPost(user, newPost) {
    const formData = new FormData();
    formData.append('id', newPost.id);
    formData.append('description', newPost.description);
    formData.append('file', newPost.image !== '' ? newPost.image : newPost.video );
    const requestOptions = {
        method: 'POST',
        headers: { 'token': user.token },
        body: formData,

    };
    return fetch(`${apiCardio.urlPost}insertPost`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function editPost(user, post) {
    const formData = new FormData();
    formData.append('keyd', post.keyd);
    formData.append('description', post.description_edit);
    formData.append('file', post.files);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': user.token },
        body: formData,

    };
    return fetch(`${apiCardio.urlPost}editPost`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function delatePost(post, user) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ post })
    };

    return fetch(`${apiCardio.urlPost}deletePost`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getPostMedico(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token  },
    };

    return fetch(`${apiCardio.urlPost}getAllPostMedico`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getUniquePost(user, keyd) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlPost}getPost`),
        params = { keyd }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });

}
function getPostFeed(user, offset) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token  },
    };

    let url = new URL(`${apiCardio.urlPost}getFeedPost`),
        params = { offset }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function getMorePost(user, nickname, offset) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token  },
    };

    let url = new URL(`${apiCardio.urlPost}getMorePostMedico`),
        params = { nickname,  offset }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function updateLike(user, type, data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ data, type })
    };

    return fetch(`${apiCardio.urlPost}updateLike`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function insertComentario(comentario, post, user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ comentario, post })
    };

    return fetch(`${apiCardio.urlPost}insertComentary`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function delateComentario(comentario, post, user) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ comentario, post })
    };

    return fetch(`${apiCardio.urlPost}deleteComentary`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function editComentario(comentario, user) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ comentario })
    };

    return fetch(`${apiCardio.urlPost}editComentary`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getComentary(user, post_id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlPost}getAllComentary`),
        params = { post: post_id }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function insertRespuestaComentario(user, selectComentary, autorPost, comentaryReply) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ selectComentary, autorPost, comentaryReply })
    };

    return fetch(`${apiCardio.urlPost}insertComentaryRepy`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function editComentarioReply(comentarioReply, user) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ comentarioReply })
    };

    return fetch(`${apiCardio.urlPost}editComentaryReply`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function delateComentarioReply(comentario, post, user) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ comentario, post })
    };

    return fetch(`${apiCardio.urlPost}deleteComentaryReply`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}