import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Actions */
import { historiaActions, alertConfirmActions } from '../../../../_actions'

// /* MAterial UI */
import { Grid, Paper, Typography, IconButton, Divider, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// /* Iconos */
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

/*Components */
import AlertDialog from "../layout/alertDialogo";

const styles = theme => ({
    maxh:{
        maxHeight: 300,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    btnHist:{
        padding: 8,
    }
});

class ListaPacientePorHistoria extends Component {
    constructor(props) {
        super(props);
        this.handleSelectPerson = this.handleSelectPerson.bind(this)
    }

    handleSelectPerson = datos => () => {
        this.props.dispatch(historiaActions.SeletPacienteCrearHistoria(datos));
    }

    /* Handles Para Borrar algun elemento */
    handleCloseAlert = () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleRemovePatient = data => () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        this.props.dispatch(historiaActions.SeletPacienteHistoria(data));
    }

    handleDelateItem = () => {
        this.props.dispatch(alertConfirmActions.sumitedRequest())
        this.props.dispatch(historiaActions.removerPacienteHistoria(this.props.user, this.props.personSelected));
    }
    /* Handles Para Burrar algun elemento */

    render() {
        
        const { classes, citasSinHistoria, openConfirm, loadingConfirm, submitedConfrim } = this.props;
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Paper className="paper-bar-left">
                        <div className="title-paper">
                            <Typography color="primary" component="span">Historias por crear</Typography>
                        </div>
                        <div className={classes.maxh}>
                            {citasSinHistoria && citasSinHistoria.length
                                ?
                                citasSinHistoria.map(k => {
                                    return (
                                        <div className="crear-Histo valign-wrappe" key={k.cita}>
                                            <div className="info-paciente">
                                                <Typography color="primary" component="span">{k.paciente}</Typography>
                                                <Typography color="secondary" component="span">{k.fecha_cita}</Typography>
                                            </div>
                                            <div className={`action p-0 ${classes.maxh}`}>
                                                <Tooltip title="Crear Historia">
                                                    <IconButton aria-label="Crear Historia" onClick={this.handleSelectPerson(k)} >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Quitar de la lista">
                                                    <IconButton aria-label="Crear Historia" onClick={this.handleRemovePatient(k)}>
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <Divider />
                                        </div>
                                    )

                                })
                                :
                                <div >
                                    <Typography color="secondary" component="span">Sin citas para crear historia</Typography>
                                </div>
                            }
                        </div>
                    </Paper>
                </Grid>

                <AlertDialog
                    open={openConfirm}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDelateItem}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de quitar al usuario de la lista para crear su historia?'
                />
            </Grid>
        )
    }
}

ListaPacientePorHistoria.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { citasSinHistoria, personSelected } = state.historia;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    return {
        user,
        citasSinHistoria, personSelected,
        openConfirm, loadingConfirm, submitedConfrim
    };
}

const connectedListaPacientePorHistoria = connect(mapStateToProps)(withStyles(styles)(ListaPacientePorHistoria));
export { connectedListaPacientePorHistoria as ListaPacientePorHistoria };