import { apiCardio } from '../_constants';

export const configService = {
    getPais,
    getBancos,
    getProvincias,
    typeDocumento,
};

function getPais() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${apiCardio.urlPais}getAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getBancos(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };
    return fetch(`${apiCardio.urlBanco}getBanksCountry`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getProvincias() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${apiCardio.urlPais}getAllProvince`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function typeDocumento() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    return fetch(`${apiCardio.urlUser}getTdocumento`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}