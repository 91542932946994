import React from 'react'

/* MAterial UI */
import { styles } from "../interConsultaStyle";
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, Typography, Paper, Divider, TextField, MenuItem,
FormControl,
    FormLabel, Button,
RadioGroup,
    FormControlLabel, Radio} from '@material-ui/core';


/* Iconos */
// import DoneIcon from '@material-ui/icons/Done';
var i = 0;
const DocumentosInterconsulta = ({ classes, infoInterconsulta, paraclinicosFile, handleChangeInput, changeInputParaclinicos, handleProbando }) => (
    <Grid item xs={12} sm={6}>
        <Paper className="paper">
            <div className="title-paper">
                <Typography color="primary" component="span" className="mb-06">Motivo de consulta</Typography>
                <Divider />
            </div>
            <div>
                <Typography component="span" align="justify" color="textSecondary">
                    Estimado usuario le informamos que el servicio de interconsulta en línea a través de videollamada está disponible para computadoras, smartphone y tablet con versión 
                    android 6 o superior con el navegador Chrome y Mozilla. El servicio no está disponible para sistema operativo iOs.
                </Typography>
                <Typography component="label" className="obligatorio mt-1">*</Typography>
                <TextField
                    fullWidth
                    multiline
                    id="motivo"
                    rowsMax="5"
                    name="motivoCita"
                    className='pt-0'
                    placeholder="Escribe tú motivo"
                    value={infoInterconsulta.motivo}
                    onChange={handleChangeInput}
                />
            </div>
            <div className="mt-1">
                <Typography component="label" align="justify" color="textSecondary">
                    Si desea puede completar los siguientes campos o adjuntar algún documento, recuerde que no pueden enviar documentos con datos de pacientes (Nombre, apellido, 
                    edad ni número de cédula o identidad) para proteger la privacidad de los pacientes.
                </Typography>
                
                <div className="mt-1">
                    {infoInterconsulta.getForm.length 
                        ? infoInterconsulta.getForm.map(k => {
                            i++;
                            handleProbando({ valor: i, indice: k.id })
                            switch (k.tipo) {
                                case 'select':
                                    return (
                                        <div key={k.id} className="mb-06">
                                            <TextField
                                                select
                                                fullWidth
                                                id={`campo${k.id}`}
                                                name={`${k.id}`}
                                                label={k.name}
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            >
                                                {k.opciones.length &&
                                                    k.opciones.map(j => {
                                                        return (
                                                            <MenuItem value={j.valor} key={j.id}>{j.valor}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </div>
                                    )
                                case 'select-multiple':
                                    return (
                                        <div key={k.id} className="mb-06">
                                            <TextField
                                                fullWidth
                                                id={`campo${k.id}`}
                                                select
                                                multiple
                                                name={`${k.id}[]`}
                                                label={k.name} 
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            >
                                                {k.opciones.length &&
                                                    k.opciones.map(j => {
                                                        return (
                                                            <MenuItem value={j.valor} key={j.id}>{j.valor}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </TextField>
                                        </div>
                                    )
                                case 'textarea':
                                    return (
                                        <div key={k.id}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                id={`campo${k.id}`}
                                                rowsMax="5"
                                                name={k.id}
                                                label={k.name} 
                                                placeholder="Escribe tú motivo"
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            />
                                        </div>
                                    )
                                case 'fech':
                                    return (
                                        <div key={k.id} className="mt-1 mb-06">
                                            <TextField
                                                fullWidth
                                                id={`campo${k.id}`}
                                                name={k.id}
                                                label={k.name} 
                                                type="date"
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    )
                                case 'number':
                                    return (
                                        <div key={k.id}>
                                            <TextField
                                                fullWidth
                                                id={`campo${k.id}`}
                                                name={k.id}
                                                label={k.name}
                                                type="number"
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            />
                                        </div>
                                    )
                                case 'si_o_no':
                                    return (
                                        <div key={k.id} className="mt-1">
                                            <FormControl component="fieldset" fullWidth>
                                                <FormLabel component="legend">{k.name}</FormLabel>
                                                <RadioGroup 
                                                    className={classes.radioButtons}
                                                    name={k.id} 
                                                    onChange={changeInputParaclinicos(k.tipo)}
                                                    value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                                >
                                                    <FormControlLabel value="Si" control={<Radio />} label="Si" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    )
                                case 'file':
                                    return (
                                        <div key={k.id} className="mt-1">
                                            <input
                                                accept="image/*, application/pdf"
                                                className={classes.input}
                                                id={`campo${k.id}`}
                                                type="file"
                                                name={k.id}
                                                onChange={changeInputParaclinicos(k.tipo)}
                                                //value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                            />
                                            <label htmlFor={`campo${k.id}`} className="cont-label-file">
                                                <Button variant="contained" className="text-transform-initial" color="primary" component="span">{k.name}</Button>
                                                <Typography component="label" align="justify" color="textSecondary" className='truncate-text'>{paraclinicosFile[k.id] ? paraclinicosFile[k.id].name : 'Selecciona un documento o una imagen'}</Typography>
                                            </label>
                                        </div>
                                    )
                                default:
                                    return (
                                        <div key={k.id}>
                                            <TextField 
                                                fullWidth
                                                name={k.id}
                                                label={k.name} 
                                                id={`campo${k.id}`}
                                                value={paraclinicosFile[k.id] ? paraclinicosFile[k.id] : ''}
                                                onChange={changeInputParaclinicos(k.tipo)}
                                            />
                                        </div>
                                    )
                            }})
                        :''

                    }
                </div>
            </div>
        </Paper>
    </Grid>
)


export default withStyles(styles)(DocumentosInterconsulta);