import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Actions */
import { citasChatActions, alertConfirmActions } from '../../../../_actions'

// /* MAterial UI */
import { styles } from "./asesoriaChatStyle";
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


/* Layouts */
import AlertDialog from "../layout/alertDialogo";


// /* Iconos */
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import VisibilityIcon from '@material-ui/icons/Visibility';



class BarRightAsesoriaText extends Component {
    // constructor(props) {
    //     super(props);
    // }


    /* Handles Marcar como atendida la cita */
    handleCloseAlert = () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleMarcarAtendida = data => () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        //this.setState({ anchorEl: null });
        //this.props.dispatch(citasActions.selecCitaParaMarcarAtendida(data))
    }

    handleDelateItem = () => {
        this.props.dispatch(alertConfirmActions.sumitedRequest())
        this.props.dispatch(citasChatActions.citaTextoAtendida(this.props.user, this.props.yarnChat))
    }
    /* Handles Para Burrar algun elemento */

    render() {
        const { user, classes, viewDetail, citasChat, yarnChat, openConfirm, loadingConfirm, submitedConfrim } = this.props;
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    {!viewDetail 
                        ? <Paper className="paper-bar-left">
                            <div className="title-paper align-center">
                                <Typography color="primary" component="span" align='center'>Consultas por atender</Typography>
                            </div>
                            <div>
                                <Typography color="secondary" component="span" align='center'>
                                    {citasChat.filter(k => (k.status !== '5' && k.status !== '6')).length}
                                </Typography>
                            </div>
                            <div className="title-paper align-center">
                                <Typography color="primary" component="span" align='center'>Consultas atendidas</Typography>
                            </div>
                            <div>
                                <Typography color="secondary" component="span" align='center'>
                                    {citasChat.filter(k => (k.status === '5' || k.status === '6')).length}
                                </Typography>
                            </div>
                        </Paper>
                        : yarnChat.paciente !== user.name+' '+user.surname &&
                            <div className='align-center'>
                                {yarnChat.status === '1' 
                                    ?<Button variant="contained" color="primary" className={classes.buttonAtendida} onClick={this.handleMarcarAtendida('k')}>
                                            Consulta atendida
                                        </Button>
                                    :null
                                }
                            </div>                    
                    }
                </Grid>
                <AlertDialog
                    open={openConfirm}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDelateItem}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de marcar esta consulta como atendida?'
                />
            </Grid>
        )
    }
}

BarRightAsesoriaText.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { yarnChat, citasChat, viewDetail } = state.citasChatReducer;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    return {
        user,
        yarnChat, citasChat, viewDetail,
        openConfirm, loadingConfirm, submitedConfrim,
    };
}

const connectedBarRightAsesoriaText = connect(mapStateToProps)(withStyles(styles)(BarRightAsesoriaText));
export { connectedBarRightAsesoriaText as BarRightAsesoriaText };