import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';


/* Material UI */
import './post.css';
import { styles } from "./postStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Fade, Divider, Avatar, IconButton, FormControl, InputBase, Button  } from '@material-ui/core';
import { CircularProgress, Tooltip   } from '@material-ui/core';

/* Actions */
import { postActions, alertActions } from '../../../../_actions'

/* Estilos con Material UI */

/* Icons */
import ClearIcon from '@material-ui/icons/Clear';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';

/* Url's */
import { apiCardio } from '../../../../_constants';


class CrearPost extends Component {
    constructor() {
        super()
        this.state = {
            imagePreviewUrl: '',
            videoPreviewUrl: '',
        };
        
        this.handleImgClear = this.handleImgClear.bind(this)
        this.handleVideoClear = this.handleVideoClear.bind(this)
        this.handleSubmitPost = this.handleSubmitPost.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
    }
    handleModalOpenClose = data => () => {
        const { dispatch } = this.props;
        dispatch(postActions.openNewPost())
    };

    handleChangeInput(e) {
        const { name, value } = e.target
        const { dispatch, newPost } = this.props;
        dispatch(postActions.llenarNewPost(name, value))
        if (value === '' && newPost.image === '' ) {
            dispatch(postActions.disableBoton(true))
        }
    }

    handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        const { dispatch } = this.props;
        dispatch(postActions.llenarNewPost('image', file))
        dispatch(postActions.llenarNewPost('video', ''))
        reader.onloadend = () => {
            this.setState({
                imagePreviewUrl: reader.result
            });
        }
        if (file) {
            reader.readAsDataURL(file)
        }
        !this.props.fade && dispatch(postActions.openNewPost())
    }
    handleImgClear(){
        const { dispatch, newPost } = this.props;
        this.setState({ imagePreviewUrl: '' });
        dispatch(postActions.clearImgPost('image'))
        newPost.description === '' && dispatch(postActions.disableBoton(true))
        
    }

    handleVideoChange(e){
        e.preventDefault();
        let file = e.target.files[0];
        if (file.type === 'video/x-ms-wmv' || file.type === 'video/avi') {
            this.props.dispatch(alertActions.error('Formato de vídeo no permitido, se recomienda subir vídeos con formato .mp4'));
        }else{
            /*Obtencion de la duracion del video */
            window.URL = window.URL || window.webkitURL;
            var video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(file);
            video.onloadedmetadata =  () => {
                window.URL.revokeObjectURL(video.src);
                var duration = video.duration;
                this.validarDuracionVideo(duration, file)
            }
            /*Obtencion de la duracion del video */
        }
        
    }

    validarDuracionVideo(duration, file){
        const { dispatch } = this.props;
        if (duration > 60.999) {
            dispatch(alertActions.error('La duración máxima del vídeo es 1 minuto'));
        }else{
            dispatch(postActions.llenarNewPost('video', file))
            dispatch(postActions.llenarNewPost('image', ''))
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    videoPreviewUrl: reader.result
                });
            }
            if (file) {
                reader.readAsDataURL(file)
            }
            !this.props.fade && dispatch(postActions.openNewPost())
        }
    }

    handleVideoClear(){
        const { dispatch, newPost } = this.props;
        this.setState({ videoPreviewUrl: '' });
        dispatch(postActions.clearImgPost('video'))
        newPost.description === '' && dispatch(postActions.disableBoton(true))
        
    }
    
    handleSubmitPost(e){
        e.preventDefault();
        const { dispatch, newPost, user } = this.props;
        
        dispatch(postActions.submitRequest(true))
        if (newPost.description !== '' || newPost.image !== '' || newPost.video !== '') {
            dispatch(postActions.insertNewPost(user, newPost))
        }
    }

    render() {
        const { imagePreviewUrl, videoPreviewUrl } = this.state
        const { classes, user, fade, submitted, newPost, disableBtn} = this.props;

        let $imagePreview = null;
        let $videoPreview = null;
        if (newPost.image) {
            newPost.image.length
                ? $imagePreview = (<img alt={user.name} src={`${apiCardio.urlMedia}${newPost.image}`} className={classes.bigAvatar} />)
                : $imagePreview = (<img alt={user.name} src={imagePreviewUrl} className={classes.bigAvatar} />)
        } 
        if (newPost.video) {
            newPost.video.length
                ? $videoPreview = (<video src={videoPreviewUrl} loop controls ></video>)
                : $videoPreview = (<video src={videoPreviewUrl} loop controls ></video>)
        } 
        return (
            <Grid container spacing={24} direction="column">
                <Grid item xs={12}>
                    <Fade in={fade}>
                        <div className={fade ? 'manto-post' : ''} onClick={this.handleModalOpenClose()}></div>
                    </Fade> 

                    <Paper className={`paper p-0 ${fade ? 'cont-new-post' : ''}`}>
                        <div className={classes.headerNewPost}>
                            <Typography className={classes.paddingTitle} component="h3" color="primary">
                                Crear publicación
                                {fade && <IconButton aria-label="Delete" className={classes.btnClose} onClick={this.handleModalOpenClose()}>
                                    <ClearIcon color="primary" fontSize="small" />
                                </IconButton>}
                            </Typography>
                            
                            
                            <Divider />
                        </div>
                        <div className={classes.textBody} onClick={!fade ? this.handleModalOpenClose() : this.open}>
                            <Avatar alt={user.name} src={`${apiCardio.urlMedia}${user.imgperfil}`} className={classes.bigAvatar} />
                            {fade 
                                ? <div className="cont-text-post">
                                    <FormControl className="text-post"
                                        fullWidth
                                    >
                                        <InputBase
                                            multiline
                                            fullWidth
                                            autoFocus
                                            rowsMax="8"
                                            id="description"
                                            name="description"
                                            autoComplete="off"
                                            placeholder={`¿Que deseas publicar, ${user.name}?`}
                                            value={newPost.description}
                                            onChange={this.handleChangeInput}
                                        />
                                    </FormControl>
                                </div>
                                : <Typography component="h3" color="primary" className={classes.textoPen}>¿Que deseas publicar, {user.name}?</Typography>
                            }
                            
                        </div>
                        <div className="action">
                            {fade &&
                                newPost.image !== '' &&
                                <div className="imgPreviewPost">
                                    <IconButton aria-label="Delete" className="clear-img" onClick={this.handleImgClear} >
                                        <ClearIcon color="primary" fontSize="small" />
                                    </IconButton>
                                    {$imagePreview}
                                </div>
                            }
                            {fade &&
                                newPost.video !== '' &&
                                    <div className="videoView">
                                        <IconButton aria-label="Delete" className="clear-video" onClick={this.handleVideoClear} >
                                            <ClearIcon color="primary" fontSize="small" />
                                        </IconButton>
                                        {$videoPreview}
                                    </div>
                            }
                            <Divider />
                            <input accept="image/*" className={classes.uploadImg} id="image-post" type="file" onChange={(e) => this.handleImageChange(e)} />
                            <label htmlFor="image-post">
                                <Tooltip title="Añadir foto o gif" aria-label="Add">
                                    <IconButton color="primary" className={classes.button} component="span">
                                        <AddPhotoAlternateIcon />
                                    </IconButton>
                                </Tooltip>
                            </label>
                            <input accept="video/*" className={classes.uploadImg} id="video-post" type="file" onChange={(e) => this.handleVideoChange(e)}/>
                            <label htmlFor="video-post">
                                <Tooltip title="Añadir un video" aria-label="Add">
                                    <IconButton color="primary" className={classes.button} component="span">
                                        <LocalMoviesIcon />
                                    </IconButton>
                                </Tooltip>
                            </label>
                            {fade &&
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    className={classes.compartir}
                                    disabled={disableBtn}
                                    onClick={this.handleSubmitPost}
                                >
                                    Compartir
                                    {submitted && disableBtn && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                            }
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

CrearPost.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { newPost, loading, submitted, fade, disableBtn } = state.postReducer;
    return {
        user,
        newPost, loading, submitted, fade, disableBtn
    };
}

const connectedCrearPost = connect(mapStateToProps)(withStyles(styles)(CrearPost));
export { connectedCrearPost as CrearPost };


