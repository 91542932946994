import React from 'react'

/* MAterial UI */

import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

/* Estilos con Material UI */
import { styles } from "./horarioAtencionStyle";

/*Components */
import SelectHorario from "./selectHorario";

let viewHorario = false;

const HorasParaAtencion = (props) => (
    <div className={`${props.classes.cont_horas}`}>
        {
            props.list &&
            props.list.map(data => {
                if (data.selected) {
                    viewHorario = true;
                }
                return "";
            })
        }
        {viewHorario &&
            <form className="pt-1" onSubmit={props.handleOnSubmitHora}>
                {props.list && 
                    props.list.map(data=>{
                        if(data.selected){
                            return(
                                <div key={data.id}>
                                    <Typography color='secondary' component="p">
                                        Horario para el {data.name} <span className="obligatorio">*</span>
                                    </Typography>
                                    <div className={props.classes.contHorarioDesdeHasta }>
                                        Hora desde
                                        <SelectHorario
                                            id_dia={data.id}
                                            hora={data.h_desde}
                                            time={data.t_desde}
                                            minuto={data.min_desde}
                                            name_hora="h_desde"
                                            name_time="t_desde"
                                            name_minuto="min_desde"
                                            submitted={props.submitted}
                                            handleSelectHora={props.handleSelectHora}
                                        />
                                    </div>
                                    <div className={props.classes.contHorarioDesdeHasta}>
                                        Hora hasta
                                        <SelectHorario
                                            id_dia={data.id}
                                            hora={data.h_hasta}
                                            time={data.t_hasta}
                                            minuto={data.min_hasta}
                                            name_hora="h_hasta"
                                            name_time="t_hasta"
                                            name_minuto="min_hasta"
                                            submitted={props.submitted}
                                            handleSelectHora={props.handleSelectHora}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return '';
                    })
                }
                <div className={props.classes.mt_1}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={props.submitted && props.loading}
                    >
                        Registrar
                    {props.submitted && props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                    </Button>
                </div>
            </form>
        }
    </div>
)


export default withStyles(styles)(HorasParaAtencion);