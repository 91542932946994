import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";


/* Components */
import ContImgPortada from "./contmgPortada";
import BarActionMovil from "./barActionMovil";
import BarActionDesktop from "./barActionDesktop";
import { RecorcarImg } from "./recortarImg";
import AlertDialog from "../../layout/alertDialogo";

/* MAterial UI */
import { Hidden } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../perfilStyle";


/* Actions */
import { perfilActions, ePageActions, alertConfirmActions } from '../../../../../_actions'


class PortadaPerfil extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leermas: false,
            optanchorEl: null,
            openAlertSolicitud : false,
        };

        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleOnSubmitExtracto = this.handleOnSubmitExtracto.bind(this)
    }

    handleFileSelected = event => {
        const { user, dispatch } = this.props;
        dispatch(perfilActions.editAvatarPerfil(user, event.target.files[0]))
    }

    handleFilePortadaSelected = event => {
        const { user, dispatch } = this.props;
        dispatch(perfilActions.editAvatarPortada(user, event.target.files[0]))
    }

    handleViewEditExtracto = () => {
        this.props.dispatch(ePageActions.viewEditExtracto())
    }

    handleChangeInput(e) {
        const { value } = e.target
        const { dispatch } = this.props;
        dispatch(ePageActions.addExtracto(value))
    }
    handleOnSubmitExtracto(e) {
        e.preventDefault();
        const { dispatch, extracto, user } = this.props;
        dispatch(ePageActions.sumitedRequest())
        if (extracto) {
            dispatch(ePageActions.inserExtracto(user, extracto))
        }
    }
    handleVermas = () => {
        this.setState({ leermas: !this.state.leermas });
    }

    hlandleSeguirEspecialista = data => (e) => {
        e.preventDefault();
        const { dispatch, user, nickName } = this.props;
        dispatch(ePageActions.seguirEspecialista(user, !data, nickName))
    }

    handleMostrarView = data => (e) =>{
        e.preventDefault();
        this.props.dispatch(ePageActions.mostrarViewEpage(data))
    }

    handleOpenMenuOptSeguir = (event) => {
        this.setState({ optanchorEl: event.currentTarget });
    };

    handleCloseMenuOptSeguir = () => {
        this.setState({ optanchorEl: null });
    };

    /* Enviar Solicitud para conectar */
    handleCloseAlert = () => {
        this.setState({
            openAlertSolicitud: !this.state.openAlertSolicitud
        })
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleSelectnickNameSolicitud = data => () => {
        const { dispatch } = this.props;
        this.setState({
            openAlertSolicitud: !this.state.openAlertSolicitud
        })
        dispatch(alertConfirmActions.openCloseAlert())
    }

    handleEnviarSolicitud = () => {
        const { dispatch, user, nickName, contacto} = this.props;
        dispatch(alertConfirmActions.sumitedRequest())
        if (contacto.contacto) {
            dispatch(ePageActions.eliminarEspecialistaConectado(user, nickName, contacto.type_contact))
        }else{
            dispatch(ePageActions.solicitudConectar(user, nickName))
        }
        this.setState({
            openAlertSolicitud: !this.state.openAlertSolicitud
        })

    }

    handleAceptarSolicitud = () => {
        const { dispatch, user, nickName } = this.props;
        dispatch(ePageActions.aceptarSolicitudContacto(user, nickName))
    }
    /* Enviar Solicitud para conectar */

    render() {
        const { leermas, optanchorEl, openAlertSolicitud } = this.state
        const { user, extracto, cantidadPost, loadding, viewEditExtracto, submitted, loading, foto_perfil, img_portada, nameEspecialista, fech_reg, visitor, seguidor, progresUpload, contacto, cantContacto } = this.props;
        const { siguiendo, seguidores, recomendaciones } = this.props;
        const { openConfirm, loadingConfirm, submitedConfrim } = this.props;
        const nombre = nameEspecialista ? nameEspecialista : user.name + ' ' + user.surname
        const fech_regis = fech_reg ? fech_reg : user.fech_reg 

        return (
            <div className="portada" ref={this.container}>
                <ContImgPortada
                    user={user}
                    img_portada={img_portada}
                    handleFilePortadaSelected={this.handleFilePortadaSelected}
                />
                <RecorcarImg
                />
                <Hidden mdUp implementation="css">
                    <BarActionMovil 
                        user={user}
                        visitor={visitor}
                        leermas={leermas}
                        loading={loadding}
                        post={cantidadPost}
                        contacto={contacto}
                        seguidor={seguidor}
                        extracto={extracto}
                        submitted={submitted} 
                        siguiendo={siguiendo}
                        seguidores={seguidores}
                        fech_regis={fech_regis}
                        foto_perfil={foto_perfil}
                        loadingExtracto={loading}
                        nameEspecialista={nombre}
                        cantContacto={cantContacto}
                        progresUpload={progresUpload}
                        recomendaciones={recomendaciones}
                        viewEditExtracto={viewEditExtracto} 
                        handleVermas={this.handleVermas}
                        handleChangeInput={this.handleChangeInput}
                        handleMostrarView={this.handleMostrarView}
                        handleFileSelected={this.handleFileSelected}
                        handleViewEditExtracto={this.handleViewEditExtracto}
                        handleOnSubmitExtracto={this.handleOnSubmitExtracto}
                        handleAceptarSolicitud={this.handleAceptarSolicitud}
                        hlandleSeguirEspecialista={this.hlandleSeguirEspecialista}
                        handleSelectnickNameSolicitud={this.handleSelectnickNameSolicitud}
                    />
                </Hidden>
                <Hidden smDown implementation="css">
                    <BarActionDesktop
                        user={user}
                        visitor={visitor}
                        loading={loadding}
                        seguidor={seguidor}
                        post={cantidadPost}
                        contacto={contacto}
                        siguiendo={siguiendo}
                        seguidores={seguidores}
                        optanchorEl={optanchorEl}
                        foto_perfil={foto_perfil}
                        img_portada={img_portada}
                        cantContacto={cantContacto}
                        progresUpload={progresUpload}
                        recomendaciones={recomendaciones}
                        handleMostrarView={this.handleMostrarView}
                        handleFileSelected={this.handleFileSelected}
                        handleAceptarSolicitud={this.handleAceptarSolicitud}
                        handleOpenMenuOptSeguir={this.handleOpenMenuOptSeguir}
                        handleCloseMenuOptSeguir={this.handleCloseMenuOptSeguir}
                        hlandleSeguirEspecialista={this.hlandleSeguirEspecialista}
                        handleSelectnickNameSolicitud={this.handleSelectnickNameSolicitud}
                    />
                </Hidden>
                
                <AlertDialog
                    open={(openConfirm && openAlertSolicitud) ? true : false}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleEnviarSolicitud}
                    handleClose={this.handleCloseAlert}
                    message={
                        contacto.contacto ? 'Esta seguro de eliminar de sus contactos al especialista ' + nombre : 'Se enviara la solicitud para conectar al especialista ' + nombre
                    }
                />
            </div>
        );
    }
}

PortadaPerfil.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user, loadding } = state.authentication;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { extracto, cantidadPost, viewEditExtracto, submitted, loading, foto_perfil, img_portada, nameEspecialista, fech_reg, visitor, seguidor, nickName, siguiendo, seguidores, recomendaciones, progresUpload, contacto, cantContacto  } = state.ePage;
    return {
        user, loadding,
        openConfirm, loadingConfirm, submitedConfrim,
        extracto, cantidadPost, viewEditExtracto, submitted, loading, foto_perfil, img_portada, nameEspecialista, fech_reg, visitor, seguidor, nickName, siguiendo, seguidores, recomendaciones, progresUpload, contacto, cantContacto
    };
}

const connectedPortadaPerfil = connect(mapStateToProps)(withStyles(styles)(PortadaPerfil));
export { connectedPortadaPerfil as PortadaPerfil };