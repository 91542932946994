import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Actions */
import { directAction } from '../../../../../_actions'

/* MAterial UI */
import '../../direct/directStyles.css';
import { styles } from "../perfilStyle";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, Divider, InputBase, Typography,
        CircularProgress, List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Avatar, IconButton 
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SendIcon from '@material-ui/icons/Send';

/* Url's */
import { apiCardio } from '../../../../../_constants/';

/* Layouts */
import Emojis from '../../layout/emojis'

class DialogMessageDirect extends Component {
    constructor(props) {
        super(props);
        this.lastMessageRef = React.createRef()   // Create a ref object 
        this.handleSelectEmoji = this.handleSelectEmoji.bind(this)
    }

    componentDidUpdate() {
        const { viewMessage } = this.props
        viewMessage && this.lastMessageRef.current.scrollIntoView(false)
    }

    handleSelectEmoji(code) {
        const { dispatch, message } = this.props
        const emoji = String.fromCodePoint(`0x${code}`)
        const value = message.newComentary + ' ' + emoji
        dispatch(directAction.changeComentaryMessage(value))
    }

    handleChangeImputComentary = (e) => {
        const { value } = e.target
        this.props.dispatch(directAction.changeComentaryMessage(value))
    }

    handleSubmitHilo = (e) => {
        const { dispatch, message, user } = this.props
        if (message.newComentary !== '') {
            dispatch(directAction.submitBtnDirect())
            if (message.keyd) {
                dispatch(directAction.insertDirectComentary(message, user))
            } else {               
                dispatch(directAction.insertDirect(message, user))
            }
        }
    }

    render() {
        const { classes, dispatch, openDialogDirect, message, closeDialog, loadingDirect, submitDirect } = this.props;
        return <Dialog
            maxWidth='sm'
            fullWidth={true}
            open={openDialogDirect}
            onClose={closeDialog}
            aria-describedby="alert-dialog-description"
            className="dialog-direct"
        >
            <DialogContent className={`content-direct ${classes.contentDialogDirect}`}>
                <div className="content-message-modal content-message">
                    <div className="header-message">
                        <List className="p-0">
                            <ListItem alignItems="flex-start" className="li-message">
                                <ListItemAvatar>
                                    {message.participantes.length
                                        ? <Avatar src={`${apiCardio.urlMedia}${message.participantes[0].img}`} alt={message.participantes[0].nombre} />
                                        : <Avatar><AccountCircleIcon /></Avatar>
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={message.participantes.length && message.participantes[0].nombre}
                                    secondary={message.participantes.length && '@' + message.participantes[0].nickname }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="Arrow-Down" onClick={closeDialog(0)}>
                                        <ClearIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                        <Divider />
                    </div>
                    <div className="body-message">
                        {loadingDirect && !submitDirect &&
                            <div className="cont-progres">
                                <CircularProgress size={30} className="ProgressAbsolut" />
                            </div>
                        }
                        {
                            message.yarn.map(k => {
                                return (
                                    <div className="massage" key={k.keyd}>
                                        <div className={`msj ${k.autor ? "right" : "left"}`}>
                                            {/* <div className="msj-header">
                                                <Typography color="primary" component="span">{k.nombre}</Typography>
                                            </div> */}
                                            <div className="msj-boddy">
                                                <Typography color="textSecondary" component="span">{k.description}</Typography>
                                                <Typography color="textSecondary" component="span">{k.fech_reg}</Typography>
                                            </div>
                                        </div>
                                        <div className="clear" />
                                    </div>
                                )
                            })
                        }
                        <div className="last-item" ref={this.lastMessageRef}></div>
                    </div>
                    <div className="footer-message">
                        <div className="cont-imput">
                            <InputBase
                                fullWidth
                                multiline
                                rows="2"
                                rowsMax="2"
                                className="text-area"
                                name="newComentario"
                                // onClick={props.hlandleComentaryPost(props.post)}
                                onChange={this.handleChangeImputComentary}
                                // onKeyPress={props.handleSubmitComentary}
                                value={message.newComentary}
                                placeholder="Escribe un mensaje"
                            />
                            <Emojis
                                post=''
                                dispatch={dispatch}
                                handleSelectEmoji={this.handleSelectEmoji}
                            />
                        </div>
                        <IconButton
                            color="primary"
                            aria-label="Enviar Comentario"
                            disabled={submitDirect && loadingDirect}
                            onClick={this.handleSubmitHilo}
                        >
                            <SendIcon />
                            {submitDirect && loadingDirect && <CircularProgress size={24} className="ProgressAbsolut" />}
                        </IconButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    }
}

DialogMessageDirect.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { openDialogDirect, message, loadingDirect, submitDirect, viewMessage } = state.directReducer;
    return {
        user,
        openDialogDirect, message, loadingDirect, submitDirect, viewMessage
    };
}

const connectedDialogMessageDirect = connect(mapStateToProps)(withStyles(styles)(DialogMessageDirect));
export { connectedDialogMessageDirect as DialogMessageDirect };