import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { menuActions } from '../../../../_actions/menu.action';


import { styles } from './headerStyle'
import Menu from '@material-ui/core/Menu';
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
// import MailIcon from '@material-ui/icons/Mail';
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Avatar from '@material-ui/core/Avatar';
import { Paper, List, ListItem, ListItemIcon, ListItemText, InputBase, Hidden } from '@material-ui/core';

//Import para el campo busqueda de especialistas
import SearchIcon from '@material-ui/icons/Search';
import NotyInfo from "./notificaciones/notifyInfo";
import iconCardio from '../../../assets/media/icon-logo-white.png';

import { apiCardio } from '../../../../_constants';
import { loginAction } from '../../../../_actions'
import { perfilService } from "../../../../_services";



class Header extends Component {
    state = {
        anchorEl: null,
        openSearchEspe: false,
        listEspe: [],
    };

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLogout = () => {
        this.setState({ anchorEl: null });
        this.props.dispatch(loginAction.cerrarSesion())
    }

    handleDrawerToggle = () => {
        const { menuOpen } = this.props;
        const { dispatch } = this.props;
        dispatch(menuActions.isOpen(!menuOpen.isOpen));
    };

    handleSearchEspecialista = (e) => {
        const { value } = e.target
        if (value === '') {
            this.setState({ openSearchEspe: false });
        }else{
            this.setState({ openSearchEspe: true });
            perfilService.searchEspecialista(this.props.user, value)
                .then(
                    data => {
                        if (data.success) {
                            this.setState({ listEspe: data.data });
                        }else{

                        }
                    }
                )
        }
    } 
    handleSearchEspecialistaMovil = (e) => {
        console.log('hola')
        this.setState({ openSearchEspe: !this.state.openSearchEspe });
    }
    handleChangeInputSerachMovil = (e) => {
        const { value } = e.target
        perfilService.searchEspecialista(this.props.user, value)
            .then(
                data => {
                    if (data.success) {
                        this.setState({ listEspe: data.data });
                    } else {
                        this.setState({ listEspe: false  });
                    }
                }
            )
    }

    handlePerdidaFocus = (e) => {
        e.preventDefault();
        setTimeout(() => {
            this.setState({ openSearchEspe: false });
        }, 200);
    }

    handleViewDoc = data => e => {
        e.preventDefault();
        let url = '';
        console.log(data)
        if (data === 1) {
            url = 'https://ecardioline.com/comoconfigurarcuentaespecialista'
        }else{
            url = 'https://ecardioline.com/preguntasfrecuentesespecialistas'
        }
        window.open(url, 'sharer', 'toolbar=0,status=0,width=548,height=548');
        this.setState({ anchorEl: null });
    }

    render() {
        // const { anchorEl } = this.state;
        const { anchorEl, openSearchEspe, listEspe } = this.state;
        const { classes } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const { user, dispatch, notify, directSinLeer } = this.props;
        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <Link to="/configuracion/general">
                    <MenuItem onClick={this.handleMenuClose}>
                        Configuración
                    </MenuItem>
                </Link>
                <Link to={'/epage/'+this.props.user.username}>
                    <MenuItem onClick={this.handleMenuClose}>
                        Perfil
                    </MenuItem>
                </Link>
                <MenuItem onClick={this.handleViewDoc(2)}>
                    Preguntas frecuentes  
                </MenuItem>
                <MenuItem onClick={this.handleViewDoc(1)}>
                    Como configurar tu cuenta
                </MenuItem>
                <MenuItem onClick={this.handleLogout}>
                    Cerrar sesión
                </MenuItem>
                
            </Menu>
        );
        return (
            <div className={classes.toolbar} ref={this.container}>
                <AppBar  className={classes.appBar}>
                    <Toolbar className={classes.toolBarW}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className={classes.logoIcon}>
                            <Link to="/">
                                <img className="circle" src={iconCardio} alt="icon-logo" />
                            </Link>
                        </div>
                        
                        <div className={classes.grow} />
                        {/* Campo busqueda de especialistas */}
                        <div className={classes.search}>
                            <Hidden smUp >
                                <IconButton color="inherit" className={classes.p08} onClick={this.handleSearchEspecialistaMovil}>
                                    <SearchIcon />
                                </IconButton> 
                            </Hidden>
                            <Hidden xsDown>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    onBlur={this.handlePerdidaFocus}
                                    placeholder="Buscar"
                                    onKeyUp={this.handleSearchEspecialista}
                                    // onTransitionEndCapture={this.hendleProbando}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                />
                            </Hidden>
                            {openSearchEspe 
                                ? (<Paper className={classes.paperList}>
                                    <Hidden smUp >
                                        <div className={classes.searchMovil}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase
                                                onBlur={this.handlePerdidaFocus}
                                                placeholder="Buscar"
                                                onKeyUp={this.handleChangeInputSerachMovil}
                                                // onTransitionEndCapture={this.hendleProbando}
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                            />
                                        </div>
                                    </Hidden>
                                    <List className={classes.contList} component="nav" aria-label="main mailbox folders">
                                        {listEspe 
                                            ? listEspe.map(k => {
                                                return (<Link to={'/epage/'+k.nick} key={k.keyd}>
                                                    <ListItem button >
                                                        <ListItemIcon>
                                                            <Avatar alt={k.name} src={`${apiCardio.urlMedia}${k.img}`} className={classes.bigAvatar} />
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.nomBusqueda} primary={k.name+' '+k.surname} />
                                                    </ListItem>
                                                </Link>)
                                            })
                                            :<ListItem button >
                                                <ListItemIcon className={classes.iconAvatar}>
                                                    <SearchIcon />
                                                </ListItemIcon>
                                                <ListItemText className={classes.nomBusqueda} primary="No se encontraron resultados, quizás el especialista no está disponible o no existe." />
                                            </ListItem>

                                        }
                                        
                                    </List>
                                </Paper>) 
                                : null
                            }
                            
                        </div>
                        <div className={classes.sectionDesktop}>
                            <NotyInfo
                                user={user}
                                notify={notify}
                                dispatch={dispatch}
                                directSinLeer={directSinLeer}
                            />
                            <IconButton
                                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                                className={classes.p08}
                            >
                                <Avatar className={classes.purpleAvatar}>{user.letteravatars}</Avatar>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>

                {renderMenu}
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { menuOpen } = state;
    const { user } = state.authentication;
    const { notify, directSinLeer } = state.notifyReducer;
    return {
        user,
        menuOpen,
        notify, directSinLeer,
    };
}

const connectedHeader = connect(mapStateToProps)(withStyles(styles)(Header));
export { connectedHeader as Header };
