import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import { BarRigth } from "../layout/barRight";
import { MenuEspecialistas } from '../layout/MenuEspecialistas';

/* Material UI */
import { styles } from "./homePageStyle";
import { withStyles } from "@material-ui/core/styles";



/*Components */
import CompletarPerfil from './completarPerfil';
import { CrearPost } from "../post/crearPost";
import { ListPost } from "../post/listPost";
import { ListSugeridos } from "./listSugeridos";
import { AccesosDirectos } from "./accesosDirectos";
import { Seguidores } from "../perfil/seguidores/seguidores";
import { BreadcrumbsDashboard } from "./breadcrumbsDashboard";
import ProgressCircular from '../layout/progress/progressCircular';

/* Actions */
import { postActions, configActions, menuActions, ePageActions } from '../../../../_actions'

const bodyDashboard = (props) => {
    switch (props.viewOn) {
        case 2:
            return (
                <div>
                    <BreadcrumbsDashboard
                        title='Seguidores'
                    />
                    <Seguidores />
                </div>
            );
        case 3: 
            return (
                <div>
                    <BreadcrumbsDashboard
                        title='Siguiendo'
                     />
                    <Seguidores />

                </div>
            );
        case 4:
            return (
                <div>
                    <BreadcrumbsDashboard
                        title='Sugeridos'
                    />
                    <ListSugeridos/>
                </div>
            )
        default:
            return (
                <div>
                    <AccesosDirectos/>
                    <CrearPost />
                    {(props.completeProfile.completado < 6) &&
                        <CompletarPerfil
                            username={props.username}
                            datos={props.completeProfile}
                        />

                    }
                    <ListPost />
                </div>
            );
    }
}

class HomePage extends Component {
    constructor(props){
        super(props);
        const { user, dispatch } = this.props;
        dispatch(postActions.resetOffser())
        dispatch(menuActions.menuActive(1));
        dispatch(configActions.getCompleteProfile(user));
        dispatch(ePageActions.sugerenciaSeguidor(user));
        // (offset === 0) && dispatch(postActions.getPostFeed(user, offset))
        dispatch(postActions.getPostFeed(user, 0))
        window.addEventListener('scroll', this.handleMasPost);
    }
   
    componentWillUnmount() {
       window.removeEventListener('scroll', this.handleMasPost);
    }
    handleMasPost = (e) =>{
        if (window.innerHeight + Math.round(document.documentElement.scrollTop) === document.documentElement.offsetHeight){
            const { offset, dispatch} = this.props;
            dispatch(postActions.getPostFeed(this.props.user, offset))
            // console.log('bajando al final' + offset)
        }
    }

    render() {
        const { loaderPost, completeProfile, viewOn } = this.props;
        return (
            <section className="body" ref={this.container}>
                {/* <Header titlePage='Inicio' /> */}
                <MenuEspecialistas/>
                <BarRigth
                    dashboard={true}
                />
                <div className="content">
                    {loaderPost
                        ? <ProgressCircular />
                        : bodyDashboard({
                            viewOn,
                            completeProfile,
                            'username': this.props.user.username,
                        })
                    }
                </div>                          
            </section>
        );
    }
}

HomePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { completeProfile } = state.configReducer;
    const { offset, loaderPost } = state.postReducer;
    const { viewOn } = state.ePage;
    return {
        user,
        completeProfile,
        offset, loaderPost,
        viewOn
    };
}

const connectedHomePage = connect(mapStateToProps)(withStyles(styles)(HomePage));
export { connectedHomePage as HomePage };