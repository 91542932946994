import React from 'react'

/* MAterial UI */
import { Grid, Paper } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */


/* Url's */

/* Estilos con Material UI */
import { styles } from "./historiasStyles";

const ComponentBuscar = (props) => (
    <Grid container spacing={24}>
        <Grid item xs={12}>
            <Paper className="paper-buscar">
                <div className='title-paper'>
                    <span>Buscar paciente</span>
                </div>
                <div className="cont-paper">
                    <InputBase
                        fullWidth
                        id="filter"
                        name="filter"
                        autoComplete="off"
                        className="fz15"
                        placeholder="Escribe un nombre"
                    // value={newArticulo.title}
                        onChange={props.handleFilter}
                    />
                </div>

            </Paper>
        </Grid>
    </Grid>
)

export default withStyles(styles)(ComponentBuscar);