import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import { BarRigth } from "../layout/barRight";
import { MenuEspecialistas } from '../layout/MenuEspecialistas';
import ProgressCircular from '../layout/progress/progressCircular';

import { Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

/* Icons */
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/*Component's */
import HiloIncidencia from "./hiloIncidencia";
import ListaIncidencia from "./listaIncidencia";
import {FormCrearIncidencia} from "./formCrearIncidencia";

/* Estilos con Material UI */
import { styles } from "./incidenciasStyle";

/* Actions */
import { incidenciaActions, menuActions } from '../../../../_actions'

class HomeIncidencias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            expanded: null,
            filterEspecialidad: '',
        }
        const { user, dispatch } = this.props;
        dispatch(menuActions.menuActive(7));
        dispatch(incidenciaActions.getIncidencias(user))

        this.handleView = this.handleView.bind(this)
        this.handleChangeInputHilo = this.handleChangeInputHilo.bind(this)
        this.handleSelectReporteForDelete = this.handleSelectReporteForDelete.bind(this)
        this.handleChangeNumeroDeElementoPorPagina = this.handleChangeNumeroDeElementoPorPagina.bind(this)
    }

    handleView = data => (event) =>{
        event.preventDefault();
        this.props.dispatch(incidenciaActions.mostrasView(data))
    }
    
    handleVerHilo = data => (event) =>{
        event.preventDefault();
        this.props.dispatch(incidenciaActions.reporteVisto(data, this.props.user))
        this.props.dispatch(incidenciaActions.detalleIncidencia(data))
    }

    handleChangeNumeroDeElementoPorPagina(e) {
        const { value } = e.target
        this.props.dispatch(incidenciaActions.cambiarCantidadElementos(value))
    }

    handleSelectReporteForDelete(e) {
        const { value } = e.target
        const { dispatch, deleteIncidencia } = this.props
        const valor = deleteIncidencia.filter(q => (q === value))
        valor.length ? dispatch(incidenciaActions.deSelectIncidenForDelete(value)) : dispatch(incidenciaActions.selectIncidenForDelete(deleteIncidencia, value))
    }

    /*EVENTOS PARA EL HILO DE LA INCIDENCIA */
    handleChangeInputHilo(e){
        const { value } = e.target
        this.props.dispatch(incidenciaActions.formCrearHiloIncidencia(value))
    }

    handleSubmitHilo = (event) => { 
        event.preventDefault();
        const { descriptionYarn, hiloIncidencia, dispatch, user } = this.props

        dispatch(incidenciaActions.submit())
        if (descriptionYarn) {
            dispatch(incidenciaActions.regisHiloInciden(user, descriptionYarn, hiloIncidencia.id))
        }else{
            dispatch(incidenciaActions.submit())
        }
    }

    render() {
        const { preloaderInci, viewDetail, fadeInci, typeInciden, formIncidencia } = this.props
        const { incidencias, cantidadElementos, hiloIncidencia, user, descriptionYarn, submitInci, loading } = this.props
        return (
            <section className="body" ref={this.container}>
                <MenuEspecialistas />
                <BarRigth
                    crearIncidencia={true}
                />
                <div className="content">
                    {preloaderInci
                        ? <ProgressCircular />
                        :viewDetail === 'lista' 
                        ? <ListaIncidencia
                            list={incidencias}
                            handleVerHilo={this.handleVerHilo}
                            cantidadElementos={cantidadElementos}
                            handleChangeNumero={this.handleChangeNumeroDeElementoPorPagina}
                            handleSelectReporteForDelete={this.handleSelectReporteForDelete}
                        />
                        : <div>
                            <div className="breadcrumbs mb-06">
                                <Typography color="primary" className="cursor-pointer" onClick={this.handleView('lista')}>
                                    Ver Reportes
                                </Typography>
                                <NavigateNextIcon color="primary" />
                                <Typography color="secondary">Detalle del reporte</Typography>
                            </div>
                            <HiloIncidencia
                                loading={loading}
                                myRef={this.myRef}
                                submitInci={submitInci}
                                hiloIncidencia={hiloIncidencia}
                                descriptionYarn={descriptionYarn}
                                nombreUs={user.name + ' ' + user.surname}
                                handleSubmitHilo={this.handleSubmitHilo}
                                handleChangeInputHilo={this.handleChangeInputHilo}
                            />
                        </div>
                    }
                    <FormCrearIncidencia 
                        fadeView={fadeInci}
                        typeInciden={typeInciden}
                        formIncidencia={formIncidencia}
                        handleChangeInput={this.handleChangeInput}
                        handleImageChange={this.handleImageChange}
                        handleSubmitIncidencia={this.handleSubmitIncidencia}
                    />
                </div>
            </section>
        )
    }
}

HomeIncidencias.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { preloaderInci, viewDetail, incidencias, fadeInci, typeInciden, 
        formIncidencia, cantidadElementos, hiloIncidencia, descriptionYarn, submitInci, loading, deleteIncidencia  } = state.incidenciaReducer;
    return {
        user,
        preloaderInci, viewDetail, incidencias, fadeInci, typeInciden, formIncidencia, cantidadElementos, hiloIncidencia, descriptionYarn, submitInci, loading, deleteIncidencia
    };
}

const connectedHomeIncidencias = connect(mapStateToProps)(withStyles(styles)(HomeIncidencias));
export { connectedHomeIncidencias as HomeIncidencias };