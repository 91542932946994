import React from "react";

/**Styles And Logo-Icon */
import { CircularProgress, Typography } from '@material-ui/core';



const ProgressCircular = () => (
    <div className='cont-progres'>
        <div>
            <CircularProgress size={50} className="ProgressAbsolut" />
        </div>
        <Typography component='span'>Cargando...</Typography>
    </div>
)

export default ProgressCircular