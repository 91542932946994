import React from 'react'

/* MAterial UI */
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Divider } from '@material-ui/core';

/* Estilos con Material UI */
import { styles } from "../configuracionStyle";

/* Componentes */
import Chips from '../../layout/chips'

const Honorarios = (props) => (
    <Paper className="paper">
        <div className="title-paper">
            <Typography color='primary' component="span" className="mb-06">
                Elige la moneda para tus pagos <span className="obligatorio">*</span>
            </Typography>
            <Divider/>
        </div>
        <div>
            <Chips
                list={props.chipMonedas}
                handleSelect={props.handleSelectMoneda}
            />
        </div>
    </Paper>
)


export default withStyles(styles)(Honorarios);