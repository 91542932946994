export const loginConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    EDIT_REQUEST: 'EDIT_REQUEST',

    RECUPERACION_REQUEST: 'RECUPERACION_REQUEST',
};
