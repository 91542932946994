import { ePegeConstants } from '../../_constants';

const initialState = {
    open: false,
    typeForm: '',
};

export function modalEPage(state = initialState, action) {
    switch (action.type) {
        case ePegeConstants.OPEN_CLOSET_MODAL_EPAGE:
            return {
                ...state,
                open: !state.open,
                typeForm: action.data
            };
        default:
            return state
    }
}