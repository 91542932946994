import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';


/* Material UI */
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../incidencias/incidenciasStyle";
import { Grid, Paper, Typography, Avatar, FormControl, InputBase, IconButton, CircularProgress } from '@material-ui/core';

/* Icons */
import SendIcon from '@material-ui/icons/Send';

/* Url's */
import { apiCardio } from '../../../../_constants/';

/* Actions */
import { citasChatActions } from '../../../../_actions'


class DetalleAsesoriaChat extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         expanded: null,
    //     };

    // }
    handleChangeInputHilo = (e) => {
        const { value } = e.target
        this.props.dispatch(citasChatActions.changeDescrition(value))
    }

    handleSubmitHilo = (e) => {
        e.preventDefault();
        const { descriptionYarn, yarnChat, dispatch, user } = this.props
        if (descriptionYarn) {
            dispatch(citasChatActions.insertYarnAsesoria(user, descriptionYarn, yarnChat.keyd))
        }
    }
    render() {
        const { classes, yarnChat, descriptionYarn, submitChatAse, loadingSubmit } = this.props;
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Paper className={`${classes.contList} paper p-0`}>
                        <div className="header-hilo-inciden">
                            <Avatar className={`cont-img-avatar ${classes.avatar}`}>
                                <img className="img-avatar" src={`${apiCardio.urlMedia}${yarnChat.img_paciente}`} alt={yarnChat.paciente} />
                            </Avatar>
                            <Typography color="primary" component="span">{yarnChat.paciente} </Typography>
                        </div>
                        <div className="body-hilo-inciden">
                            <div>
                                <div className={`msj msj-description left`}>
                                    <div className="msj-header">
                                        <Typography color="primary" component="span">{yarnChat.paciente}</Typography>
                                    </div>
                                    <div className="msj-boddy">
                                        <Typography color="textSecondary" component="span">{yarnChat.motive}</Typography>
                                    </div>
                                </div>
                                <div className="clear" />
                            </div>
                            {yarnChat.yarn_quotes ?
                                yarnChat.yarn_quotes.map(k => {
                                    return(
                                        <div key={k.keyd}>
                                            <div className={`msj ${k.type_user === '2' ? "right" : "left"}`}>
                                                <div className="msj-header">
                                                    <Typography color="primary" component="span">{k.autor}</Typography>
                                                </div>
                                                <div className="msj-boddy">
                                                    <Typography color="textSecondary" component="span">{k.description}</Typography>
                                                    <Typography color="textSecondary" component="span">{k.fecha}</Typography>
                                                </div>
                                            </div>
                                            <div className="clear" />
                                        </div>
                                    )
                                })
                                : null
                            }
                        </div>
                        <div className="action-hilo-inciden">
                            {yarnChat.status === '1'  
                                ? <div>
                                    <FormControl className={classes.formSubmitHilo}
                                        fullWidth
                                        margin="normal"
                                    >
                                        <InputBase
                                            multiline
                                            fullWidth
                                            rowsMax="18"
                                            id="descriptionYarn"
                                            name="descriptionYarn"
                                            autoComplete="off"
                                            value={descriptionYarn && descriptionYarn ? descriptionYarn : ""}
                                            placeholder="Escribe tu respuesta"
                                            onChange={this.handleChangeInputHilo}
                                        />
                                    </FormControl>
                                    <IconButton
                                        color="primary"
                                        aria-label="Enviar Comentario"
                                        disabled={submitChatAse && loadingSubmit}
                                        onClick={this.handleSubmitHilo}
                                    >
                                        <SendIcon />
                                        {submitChatAse && loadingSubmit && <CircularProgress size={24} className="ProgressAbsolut" />}
                                    </IconButton>
                                </div>
                                : <Typography component="span" align="center" color="primary">Culminaste la consulta</Typography>
                            }
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

DetalleAsesoriaChat.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { yarnChat, descriptionYarn, submitChatAse, loadingSubmit } = state.citasChatReducer;
    return {
        user,
        yarnChat, descriptionYarn, submitChatAse, loadingSubmit
    };
}

const connectedDetalleAsesoriaChat = connect(mapStateToProps)(withStyles(styles)(DetalleAsesoriaChat));
export { connectedDetalleAsesoriaChat as DetalleAsesoriaChat };