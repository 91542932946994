//import { history } from '../../_helpers';
import { interConsultaService } from "../../_services";
import { interConsultaConstants } from '../../_constants';
import { alertActions, alertConfirmActions } from '../../_actions';

export const interConsultaActions = {
    crearInfo,
    viewPagar,
    updateFilter,
    openCloseDialog,
    getMedInterconsultas,
    getEspecialidadesInterconsultas,
    searchEspecialista,
    getInfoCita,
    seleccionarItem,
    getDayWork,
    getHorasDisponibles,
    selectHour,
    formaPago,
    changeInput,
    insertCita,
    getForm,
    changeInputParaclinicos,
    changeInpuPagos,
    getBanksCountry,
    insertPago,
    getMisInterconsulas,
    seletInterconsulta,
    deleteInterconsulta,
    detalleEditQuotes,
    editCita,
};

function crearInfo() {
    return { type: interConsultaConstants.CLEAR_INFO_INTERCONSULTA } 
}
function viewPagar(data) {
    return { type: interConsultaConstants.VIEW_PAYMEN, data } 
}
function updateFilter(data) {
    return { type: interConsultaConstants.UPDATE__FILTER, data } 
}

function openCloseDialog(data) {
    return { type: interConsultaConstants.OPEN_CLOSE_DIALOG_HOUR, data } 
}

function getMedInterconsultas(user) {
    return dispatch => {
        interConsultaService.getEspecialistasInterconsulta(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail())
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };
    function success(data) { return { type: interConsultaConstants.GET_SUCCESS_INTER, data } }
    function fail() { return { type: interConsultaConstants.GET_FAILURE_INTER } }
}

function getEspecialidadesInterconsultas(user) {
    return dispatch => {
        interConsultaService.getEspecialidadesInterconsulta(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } 
                }
                // error => dispatch(failure(error.toString()))
            );
    };
    function success(data) { return { type: interConsultaConstants.GET_SUCCESS_ESPECIALIDAD_INTER, data } }
}

function searchEspecialista(user, array) {
    return dispatch => {
        dispatch(request())
        interConsultaService.getSearchEspecialista(user,array)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        dispatch(request())
                        dispatch(alertActions.success(data.message))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: interConsultaConstants.REQUEST_INTERCONSULTA } }
    function success(data) { return { type: interConsultaConstants.SUCCESS_SEARCH_ESPECIALISTA, data } }
    //function fail(data) { return { type: interConsultaConstants.FAIL_SEARCH_ESPECIALISTA,  } }
}

function getInfoCita(user, nickname) {
    return dispatch => {
        dispatch(request())
        interConsultaService.getInfoCita(user, nickname)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(request())
                        dispatch(alertActions.info(data.message))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: interConsultaConstants.REQUEST_INTERCONSULTA } }
    function success(data) { return { type: interConsultaConstants.SUCCESS_GET_INFO_CITA, data } }
}

function seleccionarItem(data, typeReducer) {
    data = { data, typeReducer }
    return { type: interConsultaConstants.SELECT_ITEM_INTERCONSULTA, data }
}

function getDayWork(user, medico) {
    return dispatch => {
        dispatch(request())
        interConsultaService.getDayWork(user, medico)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(request())
                        dispatch(alertActions.info(data.message))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: interConsultaConstants.REQUEST_INTERCONSULTA } }
    function success(data) { return { type: interConsultaConstants.SUCCESS_GET_DAY_WORK, data } }
}

function getHorasDisponibles(user, fech, medico) {
    return dispatch => {
        dispatch(request())
        interConsultaService.getHorasDisponibles(user, fech, medico)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success({ horas: data.data, diaSelect: fech}))
                    } else {
                        dispatch(loadingError())
                        dispatch(alertActions.info(data.message))
                    }
                }
            )
    }
    function request() { return { type: interConsultaConstants.REQUEST_GET_HOUR_WORK } }
    function loadingError() { return { type: interConsultaConstants.REQUEST_INTERCONSULTA } }
    function success(data) { return { type: interConsultaConstants.SUCCESS_GET_HOUR_WORK, data } }
    //function openDialog(data) { return { type: interConsultaConstants.OPEN_CLOSE_DIALOG_HOUR, data } }
}
function selectHour(data) {
    return { type: interConsultaConstants.SELECT_HOUR_WORK, data }
}

function formaPago(user, medico, typeQoute) {
    return dispatch => {
        interConsultaService.formaPago(user, typeQoute, medico)
            .then(
                data =>{
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(alertActions.info(data.message))
                    }
                }
            )
    }
    function success(data) { return { type: interConsultaConstants.SUCCESS_GET_METHOD_PAYMENT, data } }
}

function changeInput(name, value) {
    const data = { name, value}
    return { type: interConsultaConstants.CHANGE_IMPUT, data }
}

function insertCita(user, data) {
    const tipoPago = data.tipopago;
    return dispatch => {
        dispatch(request())
        interConsultaService.insertCita(user, data)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success({ message: data.message, qoute: data.data, tipoPago: tipoPago}))
                        dispatch(alertConfirmActions.openCloseAlert());
                        //dispatch(success(data.data))
                    } else {
                        dispatch(alertActions.info(data.message))
                    }
                }
            )
    }
    function request() { return { type: interConsultaConstants.REQUEST_INTERCONSULTA } }
    function success(data) { return { type: interConsultaConstants.SUCCESS_INSERT_QOUTE, data } }
}

function getForm(user, medico) {
    return dispatch => {
        interConsultaService.getForm(user, medico)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        //dispatch(alertActions.info(data.message))
                    }
                }
            )
    }
    function success(data) { return { type: interConsultaConstants.SUCCESS_GET_FORM_HISTORIA, data } }
}

function changeInputParaclinicos(name, value) {
    const data = { name, value }
    return { type: interConsultaConstants.CHANGE_IMPUT_PARACLINICOS, data }
}

function changeInpuPagos(name, value) {
    const data = { name, value }
    return { type: interConsultaConstants.CHANGE_IMPUT_PAGO, data }
}

function getBanksCountry() {
    return dispatch => {
        interConsultaService.getBanksCountry()
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        //dispatch(alertActions.info(data.message))
                    }
                }
            )
    }
    function success(data) { return { type: interConsultaConstants.SUCCESS_GET_BANK, data } }
}

function insertPago(data, file, bank_id) {
    return dispatch => {
        dispatch(request())
        interConsultaService.savePayment(data, file, bank_id)
        .then(
            data => {
                if (data.success) {
                        // dispatch(success())
                        // dispatch(alertConfirmActions.closeAlert())
                        dispatch(alertActions.success('Estimado especialista, se registró su pago con éxito, el estado de su cita es “Por aprobación”. Recibirá una notificación y correo cuando su cita sea aprobada.'))
                        window.location.href = `/mis-interconsultas`
                        //history.push('/mis-interconsultas');
                    } else {
                        dispatch(request())
                        dispatch(alertActions.info(data.message))
                    }
                }
            )
    }
    function request() { return { type: interConsultaConstants.REQUEST_INTERCONSULTA } }
    //function success(data) { return { type: interConsultaConstants.SUCCESS_INSERT_PAYMENT, data } }
}

function getMisInterconsulas(user) {
    return dispatch => {
        dispatch(request())
        interConsultaService.getMisInterconsulas(user)
        .then(
            data => {
                if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(alertActions.info(data.message))
                    }
                }
            )
    }
    function request() { return { type: interConsultaConstants.REQUEST_INTERCONSULTA } }
    function success(data) { return { type: interConsultaConstants.SUCESS_GET_MIS_INTERCONSULTAS, data } }
}

function seletInterconsulta(data) {
    return { type: interConsultaConstants.SELECT_INTERCONSULTA, data }
}

function deleteInterconsulta(interconsulta) {
    return dispatch => {
        interConsultaService.deletInterconsulta(interconsulta)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(interconsulta.var))
                        dispatch(alertActions.success("Se elimino la intersonculta con éxito."))
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(alertActions.info(data.message))
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            )
    }
    function success(data) { return { type: interConsultaConstants.SUCESS_DELETE_INTERCONSULTA, data } }

}

function detalleEditQuotes(interconsulta) {
    return dispatch => {
        interConsultaService.detalleEditQuotes(interconsulta)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success({
                            'var': interconsulta.var,
                            'medico': interconsulta.varmed,
                            'form': data.data.form,
                            'infocita': data.data.infocita,

                        }))
                    } else {
                        dispatch(alertActions.error(data.message))
                    }
                }
            )
    }
    function success(data) { return { type: interConsultaConstants.SUCESS_UPDATE_INTERCONSULTA, data } }
}

function editCita(user, data) {
    return dispatch => {
        dispatch(request())
        interConsultaService.editCita(user, data)
            .then(
                data => {
                    if (data.success) {
                        dispatch(alertActions.success("Se modifico su interconsulta con exito."))
                        //dispatch(success(data.data))
                    } else {
                        dispatch(alertActions.info(data.message))
                    }
                }
            )
    }
    function request() { return { type: interConsultaConstants.REQUEST_INTERCONSULTA } }
    //function success(data) { return { type: interConsultaConstants.SUCCESS_INSERT_QOUTE, data } }
}