import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import { menu } from "../../../../_helpers";
import { apiCardio } from '../../../../_constants';
import { menuActions } from '../../../../_actions/menu.action';


import { styles } from './headerStyle'
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { withStyles } from "@material-ui/core/styles";

class MenuEspecialistas extends Component {
    handleDrawerToggle = () => {
        const { menuOpen } = this.props;
        const { dispatch } = this.props;
        dispatch(menuActions.isOpen(!menuOpen.isOpen));
    };

    handleCloseMenu = data => () =>{
        const { dispatch } = this.props;
        dispatch(menuActions.menuActive(data));
        dispatch(menuActions.isOpen(false));
    }
    
    
    render() {
        const { classes, theme, user, menuOpen } = this.props;
        const drawer = (
            <div>
                <div >
                    <Link to={'/epage/'+user.username}>
                        <Grid container className={classes.setFlex}>
                            <Avatar alt={user.name} src={`${apiCardio.urlMedia}${user.imgperfil}`} className={classes.bigAvatar} />
                
                            <Typography paragraph className={`${classes.nameuser} valign-wrappe`}>
                                {user.name} {user.surname}
                            </Typography>
                        </Grid>
                    </Link>
                </div>
                <List className="list-item-menu pt-0">
                    {menu.map(k =>{
                            return(
                                <Link to={k.ruta} key={k.id} onClick={this.handleCloseMenu(k.id)}>
                                    <ListItem button className={`fontMenu ${menuOpen.menuActivo === k.id ? 'active-menu': '' }`} >
                                        <ListItemText primary={k.name} />
                                    </ListItem>

                                </Link>
                            )
                        })
                    }
                </List>
            </div>
        );

        return (
            <div className={`contMenu`}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === "rtl" ? "right" : "left"}
                            open={menuOpen.isOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        );
    }
}

MenuEspecialistas.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { menuOpen } = state;
    return {
        user,
        menuOpen
    };
}

const connectedMenuEspecialistas = connect(mapStateToProps)(withStyles(styles, { withTheme: true })(MenuEspecialistas));
export { connectedMenuEspecialistas as MenuEspecialistas };