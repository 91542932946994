import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/* Material UI */
import { styles } from "../perfil/perfilStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Avatar, Button } from '@material-ui/core';
/* Actions */
import { apiCardio } from '../../../../_constants';
import { ePageActions } from '../../../../_actions'

class ListSugeridos extends Component {
    hlandleSeguirODejarSeguir = data => (e) => {
        e.preventDefault();
        const { dispatch, user } = this.props;
        dispatch(ePageActions.seguirEspecialistaSugeridos(user, true, data.nickname))
    }

    render() {
        const { classes, sugerencias } = this.props;
        let i = 0;
        return (
            <Grid container spacing={16}>
                {sugerencias.length
                    ? sugerencias.map(k => {
                        return (
                            <Grid item xs={12} sm={6} key={i++}>
                                <Paper className="paper paperSeguidores">
                                    <div className="cont-info-header">
                                        <Avatar alt={k.nom_seguidor} src={`${apiCardio.urlMedia}${k.foto}`} className={classes.avatarSeguidor} />
                                        <div className="cont-text">
                                            <Typography component="p">{k.name} {k.surname}</Typography>
                                            <Typography component="span">Especialista</Typography>
                                            <Typography component="p" className="truncate">{k.extracto}</Typography>
                                        </div>
                                    </div>

                                    <div className="cont-action">
                                        <div>
                                            <Link to={'/epage/' + k.nickname}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="mr_1"
                                                >
                                                    Perfil
                                                </Button>
                                            </Link>
                                            <Button
                                                variant="contained"
                                                onClick={this.hlandleSeguirODejarSeguir(k)}
                                                className={classes.seguirTow}
                                            >
                                                Seguir
                                            </Button>
                                        </div>
                                    </div>
                                    
                                </Paper>
                            </Grid>
                        )
                    })
                    : <Grid item xs={12}>
                        <Paper className="paper post">
                            <Typography>No se encontraron resultados.</Typography>
                        </Paper>
                    </Grid>
                }
            </Grid>
        );
    }
}

ListSugeridos.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { sugerencias } = state.ePage;
    return {
        user,
        sugerencias
    };
}

const connectedListSugeridos = connect(mapStateToProps)(withStyles(styles)(ListSugeridos));
export { connectedListSugeridos as ListSugeridos };