import { apiCardio } from '../_constants';

export const incidenciaService = {
    getIncidencia,
    getTypeProblem,
    registrarIncidecia,
    registrarHiloIncidencia,
    reporteVisto,
    deleteIncidencia,
};

function getIncidencia(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlIncidencia}getAllIncidencias`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function getTypeProblem(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlIncidencia}getAllTypeIncidence`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function registrarIncidecia(user, formInciden) {
    const formData = new FormData();
    formData.append('title', formInciden.title);
    formData.append('imagen', formInciden.image);
    formData.append('descripcion', formInciden.description);
    formData.append('tipoproblema', formInciden.tipoproblema);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': user.token },
        body: formData,

    };
    return fetch(`${apiCardio.urlIncidencia}regisIncidencia`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function registrarHiloIncidencia(user, hilo, incidencia) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ descripcion_hilo: hilo, incidencia: incidencia })
    };

    return fetch(`${apiCardio.urlIncidencia}regisHiloIncidencia`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function reporteVisto(reporte, user) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ reporte })
    };

    return fetch(`${apiCardio.urlIncidencia}reporteVisto`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function deleteIncidencia(reportes, user) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ reportes })
    };

    return fetch(`${apiCardio.urlIncidencia}deleteInciden`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}