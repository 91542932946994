import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import Preloader from '../layout/preloader';

/* Material UI */
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./configuracionStyle";

/* Icons */

/*Components */
import { MenuConfig } from './menuConfiguracion';
import { ConfigGeneral } from './general/configGeneral';
import { FormaDePago } from "./formaPago/homeFormaPago";
import { HorarioAtencion } from "./horario/horarioAtencion";
import { RequisitosCitas } from './requisitosCitas/requisitosCitas';

/* Actions */
import { configActions, formaPagoAction, horarioActions, menuActions } from '../../../../_actions';

/* Services */
import { configService } from "../../../../_services";

class HomeConfiguracion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listDocument: [],
            listPaises: [],
            listBancos: [],
        };
        configService.typeDocumento().then(data => {this.setState({ listDocument: data.data });})
        configService.getPais().then(data => {  this.setState({ listPaises: data.data });  }  )
        configService.getBancos(this.props.user).then( data => { this.setState({ listBancos: data.data }); } ) 
        this.props.dispatch(configActions.selectView(props.match.params.viewPage));
        this.props.dispatch(menuActions.menuActive(props.match.params.viewPage));
    
        //Forma de pago
        !this.props.chipMetodoPago.length && this.props.dispatch(formaPagoAction.getMetodosCitas(this.props.user))
        !this.props.chipMonedas.length && this.props.dispatch(formaPagoAction.getHonorarios(this.props.user))
        !this.props.chipMetodoPago.length && this.props.dispatch(formaPagoAction.getMetodosDePago(this.props.user))
    
        //Horario Medico
        !this.props.horarioMedico.length && this.props.dispatch(horarioActions.getHorario(this.props.user)) 
    
        //Requisitos
        !this.props.listRequisitos && this.props.dispatch(configActions.getRequisitos(this.props.user))
    
        //Especialisdades
        !this.props.especialidades.length && this.props.dispatch(configActions.getEspecialidadesMedico(this.props.user))
        
    }
    
    render() {
        const { listDocument, listPaises, listBancos } = this.state;
        const { user, viewSelect, preloaderHonorario, preloaderPago, preloaderHorario, preloaderRequi } = this.props
        return (preloaderHonorario && preloaderPago && preloaderHorario && preloaderRequi)
            ? <Preloader />
            :(
                <section className="body" ref={this.container}>
                    <MenuConfig />
                    <div className="content">
                        {viewSelect === 'general' &&
                            <ConfigGeneral
                                user={user}
                                listPaises={listPaises}
                                listDocument={listDocument}
                            />
                        }

                        {viewSelect === 'requisitos' && <RequisitosCitas user={user} />}

                        {viewSelect === 'formaPagos' &&
                            <FormaDePago
                                user={user}
                                listBancos={listBancos}
                            />
                        }

                        {viewSelect === 'horarios' &&
                            <HorarioAtencion />
                        }

                    </div>

                </section>

        );
    }
}

HomeConfiguracion.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { viewSelect } = state.configReducer;
    const { chipMonedas, preloaderHonorario } = state.honorariosReducer;
    const { chipMetodoPago, preloaderPago } = state.metodosdePago;
    const { horarioMedico, preloaderHorario } = state.horarioReducer;
    const { preloaderRequi, listRequisitos } = state.requisitosReducer;
    const { especialidades, loadingEspecialidad } = state.especialidadesReducer;
    return {
        user,
        viewSelect,
        chipMonedas, preloaderHonorario, 
        chipMetodoPago, preloaderPago,
        horarioMedico, preloaderHorario,
        preloaderRequi, listRequisitos,
        especialidades, loadingEspecialidad,
    };
}

const connectedHomeConfiguracion = connect(mapStateToProps)(withStyles(styles)(HomeConfiguracion));
export { connectedHomeConfiguracion as HomeConfiguracion };