import { alertActions } from './';
import { history } from '../_helpers';
import { loginService } from '../_services';
import { loginConstants } from '../_constants';

export const loginAction = {
    login,
    logout,
    cerrarSesion,
    recuperacionPass,
};

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));
        loginService.login(email, password)
            .then(
                user => {
                    if (user.success === true) {
                        dispatch(success(user.data));
                        history.push('/');
                    }else{
                        dispatch(failure(user.message));
                        dispatch(alertActions.error(user.message));
                    }
                },
                error => {
                    console.log(error)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('Error de conexión'));
                }
            );
    };

    function request(user) { return { type: loginConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: loginConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: loginConstants.LOGIN_FAILURE, error } }
}

function recuperacionPass(email) {
    return dispatch => {
        dispatch(request());
        loginService.recuperarPass(email)
            .then(
                data => {
                    if (data.success) {
                        dispatch(alertActions.success(data.message));
                    }else{
                        dispatch(alertActions.error('No se pudo enviar el correo de recuperación.'));
                    }
                    dispatch(request());
                },
                error => {
                    console.log(error)
                }
            );
    };

    function request() { return { type: loginConstants.RECUPERACION_REQUEST } }
}

function logout() {
    loginService.logout();
    return { type: loginConstants.LOGOUT };
}

function cerrarSesion() {
    loginService.cerrarSesion();
    return { type: loginConstants.LOGOUT };
}