import React from 'react'

/* MAterial UI */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, TextField, Divider } from '@material-ui/core';

/* Estilos con Material UI */
import { styles } from "../configuracionStyle";

let viewMonto = false
const MontoAsesoriaChat = (props) => (
    <div >
        {props.list &&
            props.list.map(data => {
                if (data.selected) {
                    viewMonto = true;
                }
                return "";
            })
        }
        {viewMonto &&
            <Grid item xs={12}>
                <Paper className="paper">
                    <div className="title-paper">
                        <Typography color='primary' component="span" className="mb-06">
                            Coloca el precio de tus consultas vía texto en menos de 24 horas
                        </Typography>
                        <Divider />                        
                    </div>
                    {props.modalidadTexto[0]['selected'] 
                        ?<div>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Grid container spacing={24} >
                                        {props.list.map(data => {
                                            if (data.selected === true) {
                                                return (
                                                    <Grid item xs key={data.id}>
                                                        <TextField
                                                            fullWidth
                                                            disabled
                                                            name={data.id}
                                                            id={`asesoriaChat${data.id}`}
                                                            margin="dense"
                                                            label={data.name}
                                                            autoComplete="off"
                                                            onKeyPress={props.handleNumeric}
                                                            value={data.montoAsesoriaChat}
                                                            onChange={props.handleMontoAsesoriaChat(3)}
                                                            className={props.classes.textFieldPrecio}
                                                            // error={props.submittedMontoChat && !data.montoAsesoriaChat}
                                                            // helperText={props.submittedMontoChat && !data.montoAsesoriaChat &&
                                                            //     <span className="lblError" >Campo Requerido</span>
                                                            // }
                                                        />
                                                    </Grid>
                                                );
                                            }
                                            return '';
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <div className="mt-1">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="mr_1"
                                    variant="contained"
                                    disabled={(props.loading && props.submittedMontoChat) || props.asesoriaTextoGratis}
                                    onClick={props.handleSubmitMontoChat('montoAsesoriaChat')}
                                >
                                    Registrar
                                    {(props.loading && props.submittedMontoChat ) && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                                
                            </div> */}
                            {/* <div className="mt-1">
                                <Typography color="textSecondary" className="color-gris" component="span" >
                                    Si lo deseas puedes colocar la consulta vía texto de forma gratuita, dando clic en el boton "Activar consulta gratuita".
                                </Typography>
                                <Button
                                    type="submit"
                                    className="mt-1"
                                    variant="contained"
                                    color={props.asesoriaTextoGratis ? "primary" : 'default'}
                                    onClick={props.handleDesactivarAsesoriaChat({
                                        variableReducer: 'asesoriaTextoGratis',
                                        typeQoute: 3
                                    })}
                                    disabled={props.loading && props.submittedMontoChat}
                                >
                                    {props.asesoriaTextoGratis ? 'Desactivar consulta gratuita' : 'Activar consulta gratuita'}

                                    {(props.loading && props.submittedMontoChat) && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                            </div> */}
                        </div>
                        : <Typography color="textSecondary" className="color-gris" component="span" >
                            Para completar esta sección debe tener selecciona la modalidad {props.modalidadTexto[0]['name']}
                        </Typography>
                    }
                </Paper>
            </Grid>
        }
    </div>
)


export default withStyles(styles)(MontoAsesoriaChat);