import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import { menuActions, configActions } from '../../../../_actions';

import { ListItemText, CssBaseline, List, ListItem, Drawer, Hidden, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./configuracionStyle";


// /* Iconos */
import SettingsIcon from '@material-ui/icons/Settings';
class MenuConfig extends Component {
    // constructor(props) {
    //     super(props);
    //     this.props.dispatch(configActions.selectView(props.vistaPagina));
    // }
    handleDrawerToggle = () => {
        const { menuOpen } = this.props;
        const { dispatch } = this.props;
        dispatch(menuActions.isOpen(!menuOpen.isOpen));
    };

    handleSelectMenu = data => () =>{
        this.props.dispatch(menuActions.menuActive(data));
        this.props.dispatch(configActions.selectView(data));
        this.props.dispatch(menuActions.isOpen(false));
    }


    render() {
        const { classes, theme } = this.props;
        const { menuOpen } = this.props;
        const drawer = (
            <div>
                <Typography paragraph className={`${classes.namePage} valign-wrappe`}>
                    <SettingsIcon color="primary"/> Configuración
                </Typography>
                <List className="pt-0">
                    <ListItem button className={`fontMenu ${menuOpen.menuActivo === 'general' ? 'active-menu': '' }`} onClick={this.handleSelectMenu('general')}>
                        <ListItemText primary="General" className="p-0" />
                    </ListItem>
                    <ListItem button className={`fontMenu ${menuOpen.menuActivo === 'horarios' ? 'active-menu': '' }`} onClick={this.handleSelectMenu('horarios')}>
                        <ListItemText primary="Horario de atención" className="p-0" />
                    </ListItem>
                    <ListItem button className={`fontMenu ${menuOpen.menuActivo === 'formaPagos' ? 'active-menu': '' }`} onClick={this.handleSelectMenu('formaPagos')}>
                        <ListItemText primary="Honorario profesional" className="p-0" />
                    </ListItem>
                    <ListItem button className={`fontMenu ${menuOpen.menuActivo === 'requisitos' ? 'active-menu': '' }`} onClick={this.handleSelectMenu('requisitos')}>
                        <ListItemText primary="Paraclínicos para citas" className="p-0" />
                    </ListItem>
                </List>
            </div>
        );

        return (
            <div className={`contMenu`}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === "rtl" ? "right" : "left"}
                            open={menuOpen.isOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </div>
        );
    }
}

MenuConfig.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { menuOpen } = state;
    return {
        menuOpen
    };
}

const connectedMenuConfig = connect(mapStateToProps)(withStyles(styles, { withTheme: true })(MenuConfig));
export { connectedMenuConfig as MenuConfig };