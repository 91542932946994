import { citasService } from "../../_services";
import { alertActions } from '../alert.action';
import { citasConstants } from '../../_constants';
import { alertConfirmActions } from '../alertConfirm.action';

export const citasActions = {
    getCitas,
    detalleView,
    detalleViewModal,
    getCitasDashboard,
    filterCitas,
    selectCita,
    selecCitaParaMarcarAtendida,
    citaAtendida,

    // eConsultorio
    getCitasConsultorio,

    //Citas Pagadas
    getCitasPagadas
};

function getCitas(user) {
    return dispatch => {
        dispatch(request(true))
        citasService.getCitas(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        dispatch(fail(data.data))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request(data) { return { type: citasConstants.CITAS_REQUEST, data } }
    function success(data) { return { type: citasConstants.GET_CITAS, data } }
    function fail(data) { return { type: citasConstants.FAIL_GET_CITA, data } }
}

function detalleView() {
    return { type: citasConstants.VIEW_DETALLE };
}

function detalleViewModal() {
    return { type: citasConstants.VIEW_DETALLE_MODAL };
}

function getCitasDashboard(user) {
    return dispatch => {
        dispatch(request(true))
        citasService.getCitasDashboard(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        dispatch(fail(data.data))
                    }
                    dispatch(request(false))
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request(data) { return { type: citasConstants.CITAS_REQUEST, data } }
    function success(data) { return { type: citasConstants.GET_CITAS_DASHBOARD, data } }
    function fail(data) { return { type: citasConstants.FAIL_GET_CITA, data } }
}

function filterCitas(data) {
    let regex = new RegExp(data, 'i')
    return dispatch => {
        dispatch(success(regex))
        dispatch(viewSuccess())
    }
    function success(data) { return { type: citasConstants.FILTER_FECHA_CITA, data } }
    function viewSuccess() { return { type: citasConstants.VIEW_DETALLE } }
}

function selectCita(idCita) {
    return dispatch => {
        dispatch(success(idCita))
    }
    function success(data) { return { type: citasConstants.SELECT_CITA, data } }
}

function selecCitaParaMarcarAtendida(data) {
    return { type: citasConstants.CITA_COMO_ATENDIDA, data };
}

function citaAtendida(user, data) {
    const cita = {
        cita: data.id
    }
    return dispatch => {
        citasService.updateStatusCita(user, cita, 5)
            .then(
                data => { 
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success("Se marco la cita como atendida, ya puede crear la historia de dicha cita."))
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            );
    }
    function success(data) { return { type: citasConstants.CITA_ATENDIDA_SUCCESS, data } }
}

// eConsultorio
function getCitasConsultorio(user) {
    return dispatch => {
        dispatch(request(true))
        citasService.getCitasConsultorio(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail(data.data))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request(data) { return { type: citasConstants.CITAS_REQUEST, data } }
    function success(data) { return { type: citasConstants.GET_CITAS_CONSULTORIO, data } }
    function fail(data) { return { type: citasConstants.FAIL_GET_CITA, data } }
}
// Citas Pagadas
function getCitasPagadas(user) {
    return dispatch => {
        dispatch(request(true))
        citasService.getCitasPagadas(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail(data.data))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request(data) { return { type: citasConstants.CITAS_REQUEST, data } }
    function success(data) { return { type: citasConstants.GET_CITAS_PAGADAS, data } }
    function fail(data) { return { type: citasConstants.FAIL_GET_CITA, data } }
}