import React from 'react'

/* MAterial UI */
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import DeleteIcon from '@material-ui/icons/Delete';
import PuntoIcon from '@material-ui/icons/FiberManualRecord';

/* Estilos con Material UI */
import { styles } from "./perfilPublicoStyle";

const InfoList = (props) => (
    <List className={props.classes.root}>
        {props.list
            ? props.list.map(k => {
                const item = { info: k, typeItem: props.typeItem }
                const id = k.id ? k.id : k.keyd
                return (
                    <div key={id}>
                        <ListItem alignItems="flex-start" className={props.classes.p_L_R_0}>
                            {k.img_instituto &&
                                <ListItemAvatar className={props.classes.avatar}>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                    {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                                </ListItemAvatar>
                            }
                            <ListItemText
                                className={`${k.img_instituto ? props.classes.listCont : ''}`}
                                color="primary"
                                primary={
                                    <React.Fragment >
                                        <div className="relative">
                                            <Typography component="p" className={props.classes.tituloItem}>
                                                {k.cargo && k.cargo}
                                                {k.universidad && k.universidad}
                                                {k.sociedad && k.sociedad}
                                            </Typography>
                                            {props.visitor ? null 
                                                :<div>
                                                    <IconButton aria-label="Borrar" className={props.classes.iconFloatBorrar} onClick={props.handleDelateItem(item)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton aria-label="Editar" className={props.classes.iconFloatEdit} onClick={props.handleOpenModalFormEdit(item)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography component="span" className={props.classes.tituloItemDos}>
                                            {k.instituto && k.instituto}
                                            {k.titulo && k.titulo}
                                        </Typography>
                                        {k.diploma &&
                                            <Typography component="span" className={props.classes.tituloItemTres}>
                                                {k.diploma}
                                            </Typography>
                                        }
                                        {k.fech_desde &&
                                            <Typography component="span" className={props.classes.tituloItemTres}>
                                                {k.fech_desde} - {k.fech_hasta} <PuntoIcon className={props.classes.icon} /> {k.fech_trascurrido}
                                            </Typography>
                                        }
                                        {k.inicio &&
                                            <Typography component="span" className={props.classes.tituloItemTres}>
                                                {k.inicio} - {k.fin} 
                                            </Typography>
                                        }
                                        {k.fech &&
                                            <Typography component="span" className={props.classes.tituloItemTres}>
                                                {k.fech}
                                            </Typography>
                                        }
                                        {k.fecha_ingreso &&
                                            <Typography component="span" className={props.classes.tituloItemTres}>
                                                {k.fecha_ingreso}
                                            </Typography>
                                        }
                                        <Typography 
                                            component="span" 
                                            className={`truncate ${props.classes.tituloItemDos} ${props.verMas === props.cdn+''+id ? props.classes.mostar : props.classes.infoTruncate}`}
                                            dangerouslySetInnerHTML={{ __html: k.descripcion }}
                                        >
                                        </Typography>

                                        {   props.verMas === 'NO' ? '' :
                                                k.descripcion.length 
                                                    ? props.verMas !== props.cdn+''+id
                                                        ? <span className={props.classes.verMas} onClick={props.handleVerMas(props.cdn+''+id)}>Ver más</span>
                                                        : <span className={props.classes.verMas} onClick={props.handleVerMenos}>Ver menos</span>
                                                    : null
                                        }

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        {
                            props.verMas === 'NO' 
                                ? <Divider className={props.classes.dividerListDos} variant="inset" component="li" />
                                : <Divider className={props.classes.dividerList} variant="inset" component="li" />
                        }
                        
                    </div>
                )
            })
            : <Typography component="span" color="textSecondary">No se encontraron registros</Typography>

        }
    </List>
)


export default withStyles(styles)(InfoList);