export const ePegeConstants = {
    VER_MAS_ITEM_BAR_LEFT: "VER_MAS_ITEM_BAR_LEFT",
    OPEN_CLOSET_MODAL_EPAGE: "OPEN_CLOSET_MODAL_EPAGE",

    EPAGE_FAIL: "EPAGE_FAIL",
    EPAGE_REQUEST: "EPAGE_REQUEST",
    SUBMITTED_REQUEST: "SUBMITTED_REQUEST",
    ADD_FORM_EPAGE: 'ADD_FORM_EPAGE',
    REMOVE_FORM_EPAGE: 'REMOVE_FORM_EPAGE',
    
    
    SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
    GET_EPAGE: "GET_EPAGE",
    
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    SELECT_ITEM: 'SELECT_ITEM',
    
    ADD_EXTRACTO_EPAGE: 'ADD_EXTRACTO_EPAGE',
    SUBMIT_EXTRACTO_SUCCESS: 'SUBMIT_EXTRACTO_SUCCESS',
    EDIT_SUCCESS: 'EDIT_SUCCESS',
    
    SELECTED_IDIOMA: 'SELECTED_IDIOMA',
    
    CLEAR_NICK_NAME: 'CLEAR_NICK_NAME',
    GET_LIST_CONTACT: 'GET_LIST_CONTACT',
    ELIMINAR_CONTACTO: 'ELIMINAR_CONTACTO',
    SOLICITUD_CONECTAR: 'SOLICITUD_CONECTAR',
    GET_LIST_FOLLOWERS: 'GET_LIST_FOLLOWERS',
    VIEW_EDIT_EXTRACTO: 'VIEW_EDIT_EXTRACTO',
    SEGUIR_ESPECIALISTA: 'SEGUIR_ESPECIALISTA',
    MOSTRAR_VISTA_EN_EPAGE: 'MOSTRAR_VISTA_EN_EPAGE',
    ACEPTAR_SOLICITUD_CONTACTO: 'ACEPTAR_SOLICITUD_CONTACTO',
    SEGUIR_ESPECIALISTA_O_DEJAR_SEGUIR: 'SEGUIR_ESPECIALISTA_O_DEJAR_SEGUIR',
    GET_LIST_RECOMENDACIONES: 'GET_LIST_RECOMENDACIONES',
    
    OPEN_DIALOG_RECORTAR_MODAL: 'OPEN_DIALOG_RECORTAR_MODAL',
    DELETE_SUCCESS_RECOMENDACION: 'DELETE_SUCCESS_RECOMENDACION',
    
    SUGERENCIA_A_SEGUIR: 'SUGERENCIA_A_SEGUIR',
    SEGUIR_ESPECIALISTA_SUGERIDO: 'SEGUIR_ESPECIALISTA_SUGERIDO',

    PROGRESO_DE_SUBIDA_IMG: 'PROGRESO_DE_SUBIDA_IMG',
    ADD_POST_QUANTITY: 'ADD_POST_QUANTITY',
    SUBTRACT_POST_QUANTITY: 'SUBTRACT_POST_QUANTITY',
    
}