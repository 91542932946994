const drawerWidth = 180;
const body = 470
export const styles = theme => ({
    content: {
        flexGrow: 1,
        padding: 16,
        [theme.breakpoints.up("md")]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${body}px)`
        }
    }, 
    negrita:{
        fontWeight: 600,
        color: '#595955',
        textAlign: 'center'
    },
    icondone:{
        color: '#1fbd26',
    },
    completados:{
        color: '#1fbd26',
    },
    resetPaddingListIten:{
        padding: '0px 8px'
    },
    paperConsultorioVirtual:{
        padding: '0 0.5rem 1rem 0',
        '& p':{
            fontSize: 17
        }
    },
    paperConsultorioTexto:{
        padding: '0 0 0.5rem 1rem',
        '& p':{
            fontSize: 17
        }
    },
});