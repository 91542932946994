import { configConstants } from '../../_constants';

const initialState = {
    chipMonedas: [],
    chitMetodoCita: [],
    loading: false,
    submittedMonto:false,
    submittedMontoChat:false,
    preloaderHonorario: true,
    asesoriaTextoGratis: false,
    interConsultaTextoGratis: false,
    interConsultaEnvivoGratis: false,
};

export function honorariosReducer(state = initialState, action) {
    switch (action.type) {
        case configConstants.GET_HONORARIOS_REQUEST:
            return Object.assign({}, state, {
                loading: true
            })
        case configConstants.GET_HONORARIOS:
            return Object.assign({}, state, {
                loading: false,
                chipMonedas: action.data.chipMoneda ? action.data.chipMoneda : action.data,
                preloaderHonorario: !state.preloaderHonorario,
                asesoriaTextoGratis: action.data.asesoriaTextoGratis ? action.data.asesoriaTextoGratis : state.asesoriaTextoGratis,
                interConsultaTextoGratis: action.data.interConsultaTextoGratis ? action.data.interConsultaTextoGratis : state.interConsultaTextoGratis,
                interConsultaEnvivoGratis: action.data.interConsultaEnvivoGratis ? action.data.interConsultaEnvivoGratis : state.interConsultaEnvivoGratis,
            })
        case configConstants.SUBMIT_REQUEST_HONORARIO:
            return Object.assign({}, state, {
                submittedMonto: action.data
            })
        case configConstants.SUBMIT_REQUEST_HONORARIO_CHAT:
            return Object.assign({}, state, {
                submittedMontoChat: action.data
            })
        case configConstants.FAIL_SUMIT_HONORARIO:
            return Object.assign({}, state, {
                loading: false,
                submittedMonto: false,
                submittedMontoChat: false,
            })
        case configConstants.SELECT_HONORARIO:
            return Object.assign({}, state, {
                chipMonedas: action.data,
                // chipMonedas: state.chipMonedas.map(k => {
                //     if (k.id === action.data.id) {
                //         k.selected = action.data.selected;
                //         k.montoAsesoria = "";
                //         k.montoConsulta = "";
                //         k.montoAsesoriaChat = "";
                //         k.montoInterconsultaVivo = "";
                //         k.montoInterconsultaTexto = "";
                //     }
                //     return k
                // }),
                loading: false,
            })
        case configConstants.SUCCES_DESACTIVAR_ASESORIA_CHAT:
            return Object.assign({}, state, {
                loading: !state.loading,
                submittedMontoChat: false,
                chipMonedas: state.chipMonedas.map(k => {
                    if (k.selected) {
                        k.montoAsesoriaChat = "";
                    }
                    return k
                }),
            })
        case configConstants.GET_METODO_ASESORIA:
            return Object.assign({}, state, {
                chitMetodoCita: action.data
            })
        case configConstants.SELECTED_SUCCESS_MODALIDAD:
            return Object.assign({}, state, {
                chitMetodoCita: state.chitMetodoCita.map(k => {
                    if (k.id === action.data.id) {
                        k.selected = !k.selected
                    }
                    return k
                })
            })
        case configConstants.ACTIVAR_MONTO_ASESORIA_TEXTO:
            return Object.assign({}, state, {
                [action.data]: !state[action.data]
            })
        default:
            return state
    }
}