import React from "react";
import ReactNextPaging from "react-next-paging";

/* MAterial UI */
import { styles } from "./pagosStyles";
import { Grid, Paper, Typography, IconButton, Button } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

/* Estilos con Material UI */
import { apiCardio } from '../../../../_constants/';

const PaginacionPagos = ({ classes, itemsperpage, nocolumns, items, pagesspan, expanded, handleChangeExpanded }) => {
    return (
        <ReactNextPaging
            itemsperpage={itemsperpage}
            nocolumns={nocolumns}
            items={items}
            pagesspan={pagesspan}
        >
            {({
                getBackButtonProps,
                getFastBackButtonProps,
                getFwdButtonProps,
                getFastFwdButtonProps,
                getSelPageButtonProps,
                nopages,
                inipagearray,
                pagesforarray,
                currentpage,
                noitems,
                initialitem,
                lastitem,
                goBackBdisabled,
                goFastBackBdisabled,
                goFwdBdisabled,
                goFastFwdBdisabled
            }) => (
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Paper className={classes.headCitasPagadas}>
                                <Typography color='primary' className={classes.heading}>
                                    Fecha
                                </Typography>
                                <Typography className={classes.secondaryHeading}>
                                    Descripción
                                </Typography>
                                <Typography className={classes.headingMonto}>
                                    Monto
                                </Typography>
                            </Paper>
                            {items.slice(initialitem, lastitem).map((k, index) => {
                                return (
                                    <ExpansionPanel key={k.keyd} expanded={expanded === k.keyd} onChange={handleChangeExpanded(k.keyd)}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={`${classes.expansionPanelSummary} contExpandPagos`}>
                                            <Typography color='primary' className={classes.heading}>
                                                {k.fecha_pago}
                                            </Typography>
                                            <Typography className={`${classes.secondaryHeading} truncate`}>
                                                {k.description}
                                            </Typography>
                                            <Typography className={classes.headingMonto}>
                                                {k.costo} {k.moneda}
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container spacing={24}>
                                                <Grid item xs={12} >
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.colorSecundario} component="p">
                                                                <b className={classes.colorSecundario} >Fecha de la cita atendida</b>
                                                            </Typography>
                                                            <Typography className={classes.colorSecundario} component="p">
                                                                {k.fecha_cita}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography className={classes.colorSecundario} component="p">
                                                                <b className={classes.colorSecundario} >Paciente atendido</b>
                                                            </Typography>
                                                            <Typography className={classes.colorSecundario} component="p">
                                                                {k.nombre} {k.apellido}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.colorSecundario} component="p">
                                                        <b className={classes.colorSecundario} >Descripción del pago</b>
                                                    </Typography>
                                                    <Typography className={classes.colorSecundario} component="p">
                                                        {k.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.colorSecundario} component="p">
                                                        <b className={classes.colorSecundario} >Comproabnte de pago </b>
                                                    </Typography>
                                                    <div className={classes.contImgComprobante}>
                                                        <img className={classes.imgComprobante} src={`${apiCardio.urlMedia}${k.comprobante}`} alt={`comprobante-${k.keyd}`} />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            })}
                            {items.length >  10 &&
                                <Grid item xs={12} align="center" className={classes.mt07}>
                                    {noitems > 0
                                        ? [
                                            <div key={"pagingrow" + 100}>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFastBackButtonProps()}
                                                    disabled={goFastBackBdisabled}
                                                >
                                                    <FirstPageIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getBackButtonProps()}
                                                    disabled={goBackBdisabled}
                                                >
                                                    <ChevronLeftIcon />
                                                </IconButton>
                                                {Array.from(
                                                    { length: pagesforarray },
                                                    (v, i) => i + inipagearray
                                                ).map(page => {
                                                    return (
                                                        <Button
                                                            key={page}
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            className={classes.numberPagination}
                                                            {...getSelPageButtonProps({ page: page })}
                                                            disabled={currentpage === page}
                                                        >
                                                            {page}
                                                        </Button>
                                                    );
                                                })}
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFwdButtonProps()}
                                                    disabled={goFwdBdisabled}
                                                >
                                                    <ChevronRightIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFastFwdButtonProps()}
                                                    disabled={goFastFwdBdisabled}
                                                >
                                                    <LastPageIcon />
                                                </IconButton>
                                            </div>
                                        ]
                                        : null}
                                </Grid>
                            }
                            {items.length === 0 &&
                                <Paper className={classes.noCitasPagadas}>
                                    <Typography color='primary' className={classes.heading}>
                                        No posee citas pagadas.
                                    </Typography>
                                </Paper>
                            }
                        </Grid>
                    </Grid>

                )}
        </ReactNextPaging>
    );
};

export default withStyles(styles)(PaginacionPagos);