import React from 'react'

/* MAterial UI */
import { Grid, Typography, ButtonBase, Avatar, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Components */
import MenuSeguidorContacto from "./menuSeguidorContacto";

/* Iconos */
import PlusIcon from '@material-ui/icons/Add';

/* Component */
import { apiCardio } from '../../../../../_constants';

/* Estilos con Material UI */
import { styles } from "../perfilStyle";

const BarActionDesktop = (props) => (
    <div className="cont-bar-action">
        <Grid container>
            <Grid item xs={3} className="br-top br-right">
                <div className="img-and-btn-follow">
                    <div className="cont-img-perfil">
                        {props.foto_perfil 
                            ? <Avatar alt="Especialista" src={`${props.foto_perfil}`} className={props.classes.bigAvatar} />  
                            : <ButtonBase
                                focusRipple
                                className={props.classes.image}
                                disabled={props.loading}
                                focusVisibleClassName={props.classes.focusVisible}
                            >
                                <input type="file" name="imagen" accept="image/*" className={props.classes.uploadAvatar} onChange={props.handleFileSelected} />
                                <Avatar className={props.classes.avatarPlusIcon} >
                                    {!props.loading && <PlusIcon color="primary" />}
                                    {props.loading && <Typography className={props.classes.porcentaje} component="span">{props.progresUpload}%</Typography>}
                                    {props.loading && <CircularProgress size={32} className="ProgressAbsolut" variant="static" value={props.progresUpload} />}
                                </Avatar>
                                <Avatar alt={props.user.name} src={`${apiCardio.urlMedia}${props.user.imgperfil}`} className={props.classes.bigAvatar} />
                            </ButtonBase>
                        }
                    </div>
                </div>
            </Grid>
            <Grid item xs={8} className="br-top br-right">
                <div className="cont-bloques">
                    <div className='bloque cursor-pointer br-right' onClick={props.handleMostrarView(1)}>
                        <Typography conponent="span" color="primary">Publicaciones</Typography>
                        <Typography conponent="span" color="primary" className="ft-bold sifras">{props.post}</Typography>
                    </div>
                    <div className='bloque cursor-pointer br-right' onClick={props.handleMostrarView(2)}>
                        <Typography conponent="span" color="primary">Seguidores</Typography>
                        <Typography conponent="span" color="primary" className="ft-bold sifras">{props.seguidores}</Typography>
                    </div>
                    <div className='bloque cursor-pointer br-right' onClick={props.handleMostrarView(3)}>
                        <Typography conponent="span" color="primary">Siguiendo</Typography>
                        <Typography conponent="span" color="primary" className="ft-bold sifras">{props.siguiendo}</Typography>
                    </div>
                    <div className='bloque cursor-pointer br-right' onClick={props.handleMostrarView(5)}>
                        <Typography conponent="span" color="primary">Contactos</Typography>
                        <Typography conponent="span" color="primary" className="ft-bold sifras">{props.cantContacto}</Typography>
                    </div>
                    <div className='bloque cursor-pointer' onClick={props.handleMostrarView(4)}>
                        <Typography conponent="span" color="primary">Recomendaciones</Typography>
                        <Typography conponent="span" color="primary" className="ft-bold sifras">{props.recomendaciones}</Typography>
                    </div>
                </div>
            </Grid>
            {props.visitor
                ? <MenuSeguidorContacto
                    contacto={props.contacto}
                    seguidor={props.seguidor}
                    optanchorEl={props.optanchorEl}
                    handleAceptarSolicitud={props.handleAceptarSolicitud}
                    handleOpenMenuOptSeguir={props.handleOpenMenuOptSeguir}
                    handleCloseMenuOptSeguir={props.handleCloseMenuOptSeguir}
                    hlandleSeguirEspecialista={props.hlandleSeguirEspecialista}
                    handleSelectnickNameSolicitud={props.handleSelectnickNameSolicitud}
                />
                : null
            }
        </Grid>
    </div>
)


export default withStyles(styles)(BarActionDesktop);