export const styles = theme => ({
    iconSearch:{
        marginTop: 3,
        marginRight: 12,
    },
    // Stylos para el dialogo que contiene los especialsita que se sigue
    p12:{
        padding: '0 12px',
    },
    contentDialogTitle:{
        padding: '6px 12px 0',
    },
    contentDialogDirect:{
        padding: '0 0 12px ',
    },
    contTitleDialog:{
        'div&:nth-child(-n+2)':{
            backgroud: 'red',
        }
    },
});