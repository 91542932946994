import React from "react";
import ReactNextPaging from "react-next-paging";

/* MAterial UI */
import { Grid, Paper, Typography, Avatar, IconButton, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import EmailIcon from '@material-ui/icons/Email';
import DraftsIcon from '@material-ui/icons/Drafts';
import DoneIcon from '@material-ui/icons/Done';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

/* Estilos con Material UI */
import { styles } from "../incidencias/incidenciasStyle";

const PaginacionCitas = ({ user, classes, itemsperpage, nocolumns, items, pagesspan, handleVerHilo, selectReportesDelete }) => {
    return (
        <ReactNextPaging
            itemsperpage={itemsperpage}
            nocolumns={nocolumns}
            items={items}
            pagesspan={pagesspan}
        >
            {({
                getBackButtonProps,
                getFastBackButtonProps,
                getFwdButtonProps,
                getFastFwdButtonProps,
                getSelPageButtonProps,
                nopages,
                inipagearray,
                pagesforarray,
                currentpage,
                noitems,
                initialitem,
                lastitem,
                goBackBdisabled,
                goFastBackBdisabled,
                goFwdBdisabled,
                goFastFwdBdisabled
            }) => (
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Paper className={`${classes.contList} paper-bar-left `}>
                                {items.slice(initialitem, lastitem).map((k, index) => {
                                    return (
                                        <Grid className={`${classes.borderBotton} cursor-pointer`} container key={k.keyd} >
                                            <Grid item xs={3} sm={1} onClick={handleVerHilo(k)}>
                                                <Avatar className={classes.avatar}>
                                                    {k.viewed === '0' ? <DraftsIcon color="primary" /> : <EmailIcon color="primary" />}
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={12} sm={11} onClick={handleVerHilo(k)}>
                                                <Grid container>
                                                    <Grid item xs={9} className={classes.contTipoInciden}>
                                                        <Typography color="primary" component="span">Especialidad: {k.especialidad}</Typography>
                                                        {(k.status === '5' || k.status === '6' )&& <DoneIcon color="primary" className={classes.iconSuccesInciden} />}

                                                    </Grid>
                                                    <Grid item xs={3} align="right">
                                                        <Typography color="primary" className={classes.fechainci} component="span">{k.fech_quotes}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="primary" component="span">Paciente: 
                                                            {k.familiar
                                                                ? k.familiar.nombre + ' (' + k.familiar.parentesco + ' de ' + k.nombre + ' ' + k.apellido + ' )'
                                                                : (k.nombre + ' ' + k.apellido !== user.name + ' ' + user.surname)
                                                                    ? k.nombre + ' ' + k.apellido
                                                                    : 'Mi interconsulta escrita en menos de 24 horas '
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    { (k.nombre + ' ' + k.apellido === user.name + ' ' + user.surname) &&
                                                        <Grid item xs={12}>
                                                            <Typography color="primary" component="span">Medico: 
                                                                {k.name + ' ' + k.surname }
                                                            </Typography>
                                                        </Grid>

                                                    }
                                                    <Grid item xs={12}>
                                                        <Typography color="textSecondary" component="span" className="truncate">{k.motive}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Grid item xs={12} align="center" className={classes.mt07}>
                                    {noitems > 0
                                        ? [
                                            <div key={"pagingrow" + 100}>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFastBackButtonProps()}
                                                    disabled={goFastBackBdisabled}
                                                >
                                                    <FirstPageIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getBackButtonProps()}
                                                    disabled={goBackBdisabled}
                                                >
                                                    <ChevronLeftIcon />
                                                </IconButton>
                                                {Array.from(
                                                    { length: pagesforarray },
                                                    (v, i) => i + inipagearray
                                                ).map(page => {
                                                    return (
                                                        <Button
                                                            key={page}
                                                            variant="outlined"
                                                            size="small"
                                                            color="primary"
                                                            className={classes.numberPagination}
                                                            {...getSelPageButtonProps({ page: page })}
                                                            disabled={currentpage === page}
                                                        >
                                                            {page}
                                                        </Button>
                                                    );
                                                })}
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFwdButtonProps()}
                                                    disabled={goFwdBdisabled}
                                                >
                                                    <ChevronRightIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFastFwdButtonProps()}
                                                    disabled={goFastFwdBdisabled}
                                                >
                                                    <LastPageIcon />
                                                </IconButton>
                                            </div>
                                        ]
                                        : null}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                )}
        </ReactNextPaging>
    );
};

export default withStyles(styles)(PaginacionCitas);