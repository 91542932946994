import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

/* Layouts */
import { MenuEspecialistas } from '../../layout/MenuEspecialistas';

/*Component */

/* Material UI */
import '../index.css';
import { styles } from "../interConsultaStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Paper, Divider } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

/* Actions */
// import { interConsultaActions } from '../../../../../_actions'

class DetalleInterconsulta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: 4,
        };
        //const { user, dispatch } = this.props;
        //dispatch(interConsultaActions.getMisInterconsulas(user));
        if (!this.props.interconsultaSelect.var) {
            window.location.href = '/mis-interconsultas'
        }
    }

    render() {
        const k = this.props.interconsultaSelect
        return (
            <section className="body" ref={this.container}>
                {/* <Header titlePage='Inicio' /> */}
                <MenuEspecialistas />

                <div className="content sin-bar">
                    <div className="breadcrumbs mb-06">
                        <Link to={'/mis-interconsultas'}>
                            <Typography color="primary" className="cursor-pointer">Regresar a mis interconsultas</Typography>
                        </Link>
                        <NavigateNextIcon color="primary" />
                        <Typography color="secondary">Detalle de la interconsulta </Typography>
                    </div>

                    <Grid container className='cont-contador' spacing={24}>
                        <Grid item xs={12}>
                            <Paper className='paper paper-mis-interconsulta'>
                                <div className="title-paper">
                                    <Typography color="primary" component="span" className="mb-06">Datos de la interconsulta</Typography>
                                    <Divider />
                                </div>
                                <div className='body-inter p-0'>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={6} md={4} align='justify'>
                                            <Typography className='lbl-name'>Especialidad</Typography>
                                            <Typography className='lbl-description'>{k.especialidad}</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} align='justify'>
                                            <Typography className='lbl-name'>Especialista</Typography>
                                            <Typography className='lbl-description'>{k.name} {k.surname}</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} align='justify'>
                                            <Typography className='lbl-name'>Costo de la cita</Typography>
                                            <Typography className='lbl-description'>{k.costo} {k.moneda}</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} align='justify'>
                                            <Typography className='lbl-name'>Monto total depositado</Typography>
                                            <Typography className='lbl-description'>{k.monto_pagado} {k.moneda}</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} align='justify'>
                                            <Typography className='lbl-name'>Fecha de la cita</Typography>
                                            <Typography className='lbl-description'>{k.fech_quotes}</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} align='justify'>
                                            <Typography className='lbl-name'>Hora de la cita</Typography>
                                            <Typography className='lbl-description'>{k.attencion}</Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} align='justify'>
                                            <Typography className='lbl-name'>Motivo de la Consulta</Typography>
                                            <Typography className='lbl-description'>{k.motive}</Typography>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className='paper paper-mis-interconsulta'>
                                <div className="title-paper">
                                    <Typography color="primary" component="span" className="mb-06">Documentos adjuntados</Typography>
                                    <Divider />
                                </div>
                                <div className='body-inter p-0'>
                                    <Grid container spacing={24}>
                                        {k.detalle.length
                                            ? k.detalle.map(j => {
                                                if (j.despcription === 'file') {
                                                    return (
                                                        <Grid item xs={12} sm={6} md={4} key={j.history_form_id} align='justify'>
                                                            <a href={j.valor} target="back" download={j.name}>
                                                                <Paper className='carDocumento'>
                                                                    <Typography color="primary" className='pDocumento' component="p"> {j.name} </Typography>
                                                                </Paper>
                                                            </a>
                                                        </Grid>
                                                    )
                                                } else {
                                                    return (
                                                        <Grid item xs={12} sm={6} md={4} key={j.history_form_id} align='justify'>
                                                            <div className=''>
                                                                <Typography className='lbl-name' component="span"> {j.name}</Typography>
                                                                <Typography className='lbl-description' component="p"> {j.valor}</Typography>
                                                                <Divider />
                                                            </div>
                                                        </Grid>
                                                    )
                                                }
                                            })
                                            : <Grid item xs={12} sm={6} md={4} align='justify'>
                                                <Typography color="primary" className='pDocumento' component="p">No adjunto ningún documento</Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </section>
        )
    }
}

DetalleInterconsulta.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { interconsultaSelect } = state.interConsultaReducer;
    return {
        user,
        interconsultaSelect,
    };
}

const connectedDetalleInterconsulta = connect(mapStateToProps)(withStyles(styles)(DetalleInterconsulta));
export { connectedDetalleInterconsulta as DetalleInterconsulta };