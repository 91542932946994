import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux';


/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';


/* Estilos con Material UI */
import { styles } from "./perfilPublicoStyle";

/* Icons */
// import AddIcon from '@material-ui/icons/Add';

/* Actions */
import { ePageActions } from '../../../../../_actions'


class FormAddLogros extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedA: true,
        };
        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleOnSubmitEdit = this.handleOnSubmitEdit.bind(this)
    }

    handleChangeInput(e) {
        const { name, value } = e.target

        const { dispatch } = this.props;
        dispatch(ePageActions.addForm_ePAge(name, value))
    }

    handleOpenModalForm = value => () => {
        const { dispatch } = this.props;
        dispatch(ePageActions.openModal(value))
    };

    handleOnSubmit(e) {
        e.preventDefault();
        const { dispatch, form_ePage, user } = this.props;
        dispatch(ePageActions.sumitedRequest())
        if (form_ePage.nombre && form_ePage.emisor && form_ePage.fecha ) {
            dispatch(ePageActions.inserInfo_ePage(user, form_ePage, 3, 'logros'))
        }
    }
    handleOnSubmitEdit(e) {
        e.preventDefault();
        const { dispatch, form_ePage, user, itemSelected } = this.props;
        dispatch(ePageActions.sumitedRequest())
        if (form_ePage.nombre && form_ePage.emisor && form_ePage.fecha ) {
            dispatch(ePageActions.editInfo_ePage(user, form_ePage, itemSelected.info.id, 3, 'logros'))
        }else{
            const edit = {
                nombre: !form_ePage.nombre ? itemSelected.info.cargo : form_ePage.nombre,
                emisor: !form_ePage.emisor ? itemSelected.info.instituto : form_ePage.emisor,
                fecha: !form_ePage.fecha ? itemSelected.info.fecha : form_ePage.fecha,
                descripcion: !form_ePage.descripcion ? itemSelected.info.descripcion : form_ePage.descripcion,
            }
            dispatch(ePageActions.editInfo_ePage(user, edit, itemSelected.info.id, 3, 'logros'))
        }
    }

    render() {
        const { classes, submitted, loading, itemSelected } = this.props
        return (
            <form onSubmit={itemSelected.info ? this.handleOnSubmitEdit : this.handleOnSubmit}>
                <Grid container>
                
                    <Grid item xs={12} >
                        <TextField
                            required
                            fullWidth
                            id="nombre"
                            name="nombre"
                            margin="dense"
                            label="Titulo"
                            autoComplete="off"
                            className={classes.textField}
                            onChange={this.handleChangeInput}
                            defaultValue={itemSelected.info ? itemSelected.info.cargo : ''}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            required
                            fullWidth
                            id="emisor"
                            name="emisor"
                            margin="dense"
                            label="Emisor"
                            autoComplete="off"
                            className={classes.textField}
                            onChange={this.handleChangeInput}
                            defaultValue={itemSelected.info ? itemSelected.info.instituto : ''}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.contFech}>
                        <TextField
                            required
                            fullWidth
                            id="fecha"
                            type="month"
                            name="fecha"
                            label="Fecha"
                            margin="dense"
                            className={classes.textField}
                            onChange={this.handleChangeInput}
                            defaultValue={itemSelected.info ? itemSelected.info.fecha : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            multiline
                            fullWidth
                            rowsMax="8"
                            margin="dense"
                            id="descripcion"
                            name="descripcion"
                            label="Descripción"
                            placeholder=""
                            onChange={this.handleChangeInput}
                            defaultValue={itemSelected.info ? itemSelected.info.descripcion : ''}
                        />
                    </Grid>
                </Grid>
                <div className={classes.mt_1}>
                    <Button
                        variant="contained"
                        className={classes.mr_1}
                        onClick={this.handleOpenModalForm()}
                        disabled={submitted && loading}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={submitted && loading}
                    >
                        {itemSelected.info ? 'Editar' : 'Registrar'}
                            {submitted && loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                    </Button>
                </div>
            </form>
        )
    }
}


FormAddLogros.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { form_ePage, submitted, loading, itemSelected } = state.ePage;
    return {
        user,
        form_ePage,
        submitted,
        loading,
        itemSelected
    };
}

const connectedFormAddLogros = connect(mapStateToProps)(withStyles(styles)(FormAddLogros));
export { connectedFormAddLogros as FormAddLogros };
