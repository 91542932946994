import { apiCardio } from '../_constants';
//import { authHeader } from '../_helpers';

export const loginService = {
    login,
    logout,
    cerrarSesion,
    recuperarPass,
};

function login(correo, clave) {
    const aplication = 2
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ correo, clave, aplication })
    };


    return fetch(`${apiCardio.urlUser}login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // Si el usuario es valido se crea una session 
            if (user.success === true) {
                localStorage.setItem('user', JSON.stringify(user.data));
                //console.log(user);
            }

            return user;
        });

}

function logout() {
    deleteOnline()
    localStorage.removeItem('user');
}

function cerrarSesion(){
    deleteOnline()
    window.location.href = '/login'
    localStorage.removeItem('user');
}

function deleteOnline() {
    if (JSON.parse(localStorage.getItem('user'))) {
        const user = JSON.parse(localStorage.getItem('user'))
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'token': user.token },
        };

        return fetch(`${apiCardio.urlMedico}offLine`, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data
            });
    }
}

function recuperarPass(correo_recuperacion) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ correo_recuperacion })
    };


    return fetch(`${apiCardio.urlUser}recoveryMail`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
              //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}