import React from 'react'

/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Typography, Divider } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';

/*Icons */
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Create';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/* Estilos con Material UI */
import { styles } from "../configuracionStyle";

/* Componentes */
import FormAddCuentaBancaria from "./formAddCuentaBancaria";

const CuentaBancaria = (props) => (
    <div>
        {props.listSeleccion && 
            props.listSeleccion.map(data => {
                const bancos = data.datos
                if (data.selected === true && data.codg === 'BANK'){
                    return (
                        <Paper className="paper" key={data.id}>
                            <div className="title-paper">
                                <Typography color='primary' component="span" className="mb-06">
                                    Bancos en Venezuela <span className="obligatorio">*</span>
                                </Typography>
                                <Divider/>
                            </div>
                            <div>
                                {!props.viewformbanco &&
                                    <div className="mb-1">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            className='text-transform-initial'
                                            onClick={props.handleViewFormBanco}
                                        >
                                            Agregar cuenta bancaria
                                        </Button>
                                    </div>
                                }
                                
                                {props.viewformbanco 
                                    ? <div className="mb-1">
                                        {props.viewformbanco &&
                                            <FormAddCuentaBancaria
                                                loading={props.loading}
                                                newbanco={props.newbanco}
                                                listBancos={props.listBancos}
                                                submittedMonto={props.submittedMonto}
                                                handleChangeSelect={props.handleChangeSelect}
                                                handleViewFormBanco={props.handleViewFormBanco}
                                                handleSubmitNuevoBanco={props.handleSubmitNuevoBanco}
                                                handleChangeInputNuevoBanco={props.handleChangeInputNuevoBanco}
                                            />
                                        }
                                    </div>
                                    : <div key={data.id}>
                                        {bancos && bancos.map(k => {
                                            return (
                                                <ExpansionPanel key={k.id} expanded={props.expanded === k.id} onChange={props.handleChangeExpansion(k.id)}>
                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography className={props.classes.title_expansion}>{k.name}</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={9} className={props.classes.contCardBody}>
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        <span className={props.classes.textPrimario}>Cedula: </span>
                                                                        {k.cedula}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        <span className={props.classes.textPrimario}>N° de cuenta: </span>
                                                                        {k.num_account}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography>
                                                                        <span className={props.classes.textPrimario}>Correo: </span>
                                                                        {k.email}</Typography>
                                                                </Grid>

                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <Tooltip title="Eliminar banco" className="toolTip">
                                                                    <IconButton className={props.classes.icon_button} aria-label="Delete" onClick={props.handleSelectDelateBanco(k)}>
                                                                        <ClearIcon className={props.classes.textPrimario} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Editar banco" className="toolTip">
                                                                    <IconButton className={props.classes.icon_button} aria-label="Delete" onClick={props.handleEditBanco(k)}>
                                                                        <EditIcon className={props.classes.textPrimario} />
                                                                    </IconButton>

                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            )
                                        })}
                                    </div>
                                }
                        </div>
                    </Paper>
                    )
                }
                return "";
            })

        }
    </div>      
)


export default withStyles(styles)(CuentaBancaria);