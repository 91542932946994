import { configConstants } from '../../_constants';

const initialState = { 
    chipMetodoPago: [],
    loadingPago: false,
    preloaderPago: true,
    submitedBanco:false,
    viewformbanco:false,
    newbanco:{
        bancos: "",
        numCuenta: "",
        cedulaCuenta: "",
        correoCuenta: ""
    },
 };

export function metodosdePago(state = initialState, action) {
    switch (action.type) {
        case configConstants.GET_METODO_PAGO_REQUESt:
            return {
                ...state,
                loadingPago: action.data
            };
        case configConstants.GET_METODO_PAGO:
            return {
                ...state,
                chipMetodoPago: action.data,
                loadingPago: !state.loadingPago,
                preloaderPago: !state.preloaderPago,
                newbanco: {
                    bancos: "",
                    numCuenta: "",
                    cedulaCuenta: "",
                    correoCuenta: ""
                },
            };
        case configConstants.SELECT_METODO_PAGO_DELE:
            return Object.assign({}, state, {
                itemBanco: action.data
            })
        case configConstants.SUBMITED_NUEVO_BANCO_NACIONAL:
            return Object.assign({}, state, {
                submitedBanco: !state.submitedBanco
            })
        case configConstants.EDIT_FORM_NEW_BANCO:
            return Object.assign({}, state, {
                newbanco: {
                    bancos: action.data.banco_id,
                    numCuenta: action.data.num_account,
                    cedulaCuenta: action.data.cedula,
                    correoCuenta: action.data.email
                },
            })
        case configConstants.MOSTRAR_FORMULARIO_NUEVO_BANCO:
            return Object.assign({}, state, {
                viewformbanco: action.data
            })
        case configConstants.ADD_FORM_NEW_BANCO:
            return {
                ...state,
                newbanco: {
                    ...state.newbanco,
                    [action.data.name]: action.data.value
                }
            }
            

        default:
            return state
    }
}