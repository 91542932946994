import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Material UI */
import '../../post/post.css';
import { styles } from "../../post/postStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, CircularProgress, Paper, Avatar, IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

/* Actions */
import { apiCardio } from '../../../../../_constants';
import { ePageActions, alertConfirmActions } from '../../../../../_actions'

/* Component */
import AlertDialog from "../../layout/alertDialogo";

/* Url's */
// import { apiCardio } from '../../../../_constants';


class Recomendaciones extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openAlert: false,
        }
        // console.log(this.props.viewOn)
        const { dispatch, nickName, user } = this.props
        dispatch(ePageActions.getRecomendaciones(nickName ? nickName : user.username, user))
    }

    /* Handles Para Borrar algun elemento */
    handleCloseAlert = () => {
        this.setState({ openAlert: false });
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleSelectDelateItem = data => () => {
        this.setState({ openAlert: true });
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        this.props.dispatch(ePageActions.getSelectItem(data))
    }

    handleDelateItem = () => {
        this.props.dispatch(alertConfirmActions.sumitedRequest())
        this.props.dispatch(ePageActions.deleteRecomendacion(this.props.user, this.props.itemSelected))
    }
    /* Handles Para Burrar algun elemento */

    render() {
        const { openAlert } = this.state
        const { classes, loading, listRecomendacion, openConfirm, loadingConfirm, submitedConfrim } = this.props;
        return (loading)
            ? <Grid container spacing={24}>
                <Grid item xs={12} align="center">
                    <div className="cont-progress-comentary"><CircularProgress size={40} className={`ProgressAbsolut`} /></div>
                </Grid>
            </Grid>
            : (
                <Grid container spacing={16}>
                    {listRecomendacion.length
                        ? listRecomendacion.map(k => {
                            return (
                                <Grid item xs={12} key={k.keyd}>
                                    <Paper className="paper post">
                                        <div className="header-post">
                                            <Avatar alt={k.nombre} src={`${apiCardio.urlMedia}${k.img_profile}`} className={classes.bigAvatar}/>
                                            <div className="autor-fech">
                                                <Typography component="span" color="primary">{k.nombre} {k.apellido}</Typography>
                                                <Typography component="span" color="textSecondary">{k.fech_reg}</Typography>
                                            </div>
                                            {k.more
                                                ? <IconButton onClick={this.handleSelectDelateItem(k)}>
                                                    <Clear/>
                                                </IconButton>
                                                : null
                                            }
                                        </div>
                                        <div className="body-post">
                                            <div className="cont-text mb-06">
                                                <Typography component="span" color="textSecondary" dangerouslySetInnerHTML={{ __html: k.recommendation }}></Typography>
                                            </div>
                                        </div>
                                    </Paper>
                                </Grid>
                            )
                        })
                        : <Grid item xs={12}>
                            <Paper className="paper post">
                                <Typography>No posee recomendaciones.</Typography>
                            </Paper>
                        </Grid>
                    }

                    <AlertDialog
                        open={openConfirm && openAlert}
                        loading={loadingConfirm}
                        submitted={submitedConfrim}
                        procesar={this.handleDelateItem}
                        handleClose={this.handleCloseAlert}
                        message='¿Esta seguro de eliminar la recomendación seleccionada?'
                    />
                </Grid>
            );
    }
}

Recomendaciones.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { loading, nickName, viewOn, listRecomendacion, nameEspecialista, visitor, itemSelected } = state.ePage;
    return {
        user,
        openConfirm, loadingConfirm, submitedConfrim ,
        loading, nickName, viewOn, listRecomendacion, nameEspecialista, visitor, itemSelected
    };
}

const connectedRecomendaciones = connect(mapStateToProps)(withStyles(styles)(Recomendaciones));
export { connectedRecomendaciones as Recomendaciones };