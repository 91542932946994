export const styles = theme => ({
    flex: {
        flex: 1,
    },
    colorSecundario:{
        color: theme.palette.text.secondary,
    },
    buttonAtender:{
        marginRight: '.5rem',
        marginBottom: '.5rem'
    },
    buttonAtendida:{
        marginBottom: '.5rem'
    },
});