import React from 'react'

/* MAterial UI */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';


/* Iconos */
import WarningIcon from '@material-ui/icons/Warning';


const styles = theme => ({
    icon: {
       float: 'left',
       marginTop: 3,
       marginRight: 10
    },
    btnAction:{
        margin: '8px 20px',
        justifyContent: 'end',
    }
});

const AlertDialog = (props) => (
    <div className={`${props.classes.listMonedas} cont-info`}>
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className='colorTitle'> <WarningIcon className={props.classes.icon} color='primary'/> Alerta</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: props.message }}>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={props.classes.btnAction}>
                <Button 
                    autoFocus
                    variant="contained"
                    onClick={props.handleClose} 
                    disabled={props.submitted && props.loading}
                >
                    Cancelar
                    </Button>
                <Button 
                    color="primary" 
                    variant="contained" 
                    onClick={props.procesar} 
                    disabled={props.submitted && props.loading}
                >
                    Aceptar
                        {props.submitted && props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                </Button>
            </DialogActions>
        </Dialog>
    </div>
)



export default withStyles(styles)(AlertDialog);