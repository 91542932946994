import React from 'react'

/* MAterial UI */
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

/* Estilos con Material UI */
import { styles } from "../configuracionStyle";

const FormAddCuentaBancaria = (props) => (
    <form onSubmit={props.handleSubmitNuevoBanco} className="mb-1">
        <div className={props.classes.rootSelect} >
            <FormControl 
                fullWidth
                className={props.classes.formControl} 
                error={props.submittedMonto && !props.newbanco.bancos}
            >
                <InputLabel shrink htmlFor="banco-label-placeholder">Banco</InputLabel>
                <Select
                    value={props.newbanco.bancos}
                    onChange={props.handleChangeInputNuevoBanco}
                    input={<Input name="banco" id="banco-label-placeholder" />}
                    displayEmpty
                    name="bancos"
                    className={props.classes.selectEmpty}
                >
                    <MenuItem value="">
                        <em>Seleccione una opcion</em>
                    </MenuItem>
                    {props.listBancos &&
                        props.listBancos.map(list =>{
                            return(
                                <MenuItem value={list.num} key={list.num} >{list.name}</MenuItem>
                            )
                        })
                        
                    }
                
                </Select>
                {props.submittedMonto && !props.newbanco.bancos &&
                    <FormHelperText>Campo Requerido</FormHelperText>
                }
            </FormControl>
        </div>
        <div>
            <TextField
                fullWidth
                id="numCuenta"
                name="numCuenta"
                autoComplete="off"
                label="Número de cuenta"
                value={props.newbanco.numCuenta}
                className={props.classes.textFieldPagos}
                onChange={props.handleChangeInputNuevoBanco}
                error={props.submittedMonto && !props.newbanco.numCuenta}
                helperText={props.submittedMonto && !props.newbanco.numCuenta &&
                    <span className="lblError" >Campo Requerido</span>
                }
                margin="dense"
            />
            <TextField
                fullWidth
                id="cedulaCuenta"
                autoComplete="off"
                name="cedulaCuenta"
                label="Cedula de identidad"
                className={props.classes.textFieldPagos}
                value={props.newbanco.cedulaCuenta}
                onChange={props.handleChangeInputNuevoBanco}
                error={props.submittedMonto && !props.newbanco.cedulaCuenta}
                helperText={props.submittedMonto && !props.newbanco.cedulaCuenta &&
                    <span className="lblError" >Campo Requerido</span>
                }
                margin="dense"
            />
            <TextField
                fullWidth
                type="email"
                label="Correo"
                id="correoCuenta"
                autoComplete="off"
                name="correoCuenta"
                className={props.classes.textFieldPagos}
                value={props.newbanco.correoCuenta}
                onChange={props.handleChangeInputNuevoBanco}
                error={props.submittedMonto && !props.newbanco.correoCuenta}
                helperText={props.submittedMonto && !props.newbanco.correoCuenta &&
                    <span className="lblError" >Campo Requerido</span>
                }
                margin="dense"
            />
        </div>
        <div className="mt-1">
            <Button variant="contained" className="mr_1" onClick={props.handleViewFormBanco}>
                Cancelar
            </Button>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={props.submittedMonto && props.loading}
            >
                Registrar
                        {props.submittedMonto && props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
            </Button>
        </div>
    </form>
)

export default withStyles(styles)(FormAddCuentaBancaria);