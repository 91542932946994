import { citasConstants } from '../../_constants';

const initialState = {
    loadingCita: false,
    viewDetail: false,
    citas: [],
    openModalDetalle: false,
};

export function citasReducer(state = initialState, action) {
    switch (action.type) {
        case citasConstants.CITAS_REQUEST:
            return {
                ...state,
                loadingCita: action.data
            };
        case citasConstants.GET_CITAS:
            return {
                ...state,
                fechas: action.data.fechas,
                citas: action.data.citas,
                loadingCita: !state.loadingCita,
                // filterArticulo: action.data.filter(q => (q.numero <= 3))
            };
        case citasConstants.FAIL_GET_CITA:
            return {
                ...state,
                loadingCita: !state.loadingCita,
            };
        case citasConstants.FILTER_FECHA_CITA:
            return {
                ...state,
                fechas: state.fechas,
                citas: state.citas,
                filterCitas: state.citas.filter(q => (action.data.test(q.fech_quotes)))
            };
        case citasConstants.SELECT_CITA:
            return {
                ...state,
                fechas: state.fechas,
                citas: state.citas,
                filterCitas: state.filterCitas,
                selectCita: state.citas.filter(q => (q.id === action.data))
            };
        case citasConstants.GET_CITAS_DASHBOARD:
            return {
                citas: action.data,
                // filterArticulo: action.data.filter(q => (q.numero <= 3))
            };
        case citasConstants.VIEW_DETALLE:
            return Object.assign({}, state, {
                viewDetail: !state.viewDetail,
            });
        case citasConstants.VIEW_DETALLE_MODAL:
            return Object.assign({}, state, {
                openModalDetalle: !state.openModalDetalle,
            });
        case citasConstants.CITA_COMO_ATENDIDA:
            return Object.assign({}, state, {
                atendida: action.data,
            });
        case citasConstants.CITA_ATENDIDA_SUCCESS:
            return Object.assign({}, state, {
                citas: state.citas.map(k => {
                    if (k.id === action.data) {
                        k.status = 'Atendida'
                    }
                    return k
                }),
                atendida: ''
            });

        // eConsultorio
        case citasConstants.GET_CITAS_CONSULTORIO:
            return Object.assign({}, state, {
                citas: action.data,
                loadingCita: !state.loadingCita,
            });

        // Citas Pagadas
        case citasConstants.GET_CITAS_PAGADAS:
            return Object.assign({}, state, {
                citas: action.data,
                loadingCita: !state.loadingCita,
            });
        default:
            return state
    }
}