import React, { Component } from 'react';
import { connect } from 'react-redux';

import './login.css';
import logoCardio from '../../../assets/media/logo.png';
import RecuperarPassword from './RecuperarPassword';
import FormLogin from './FormLogin';

import { loginAction } from '../../../../_actions';

class LoginPage extends Component {
	constructor(props){
		super(props)

		this.props.dispatch(loginAction.logout());

		this.state = {
			email: '',
			password: '',
			submitted: false
		};

		/*Referencias */
		this.close = React.createRef()
		this.container = React.createRef()

		/* Bindeos */
		this.handleClose = this.handleClose.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleOnSubmitFormRecuperar = this.handleOnSubmitFormRecuperar.bind(this)
		this.handleRecuperarPasword = this.handleRecuperarPasword.bind(this)
	}
	handleRecuperarPasword(){
		this.container.current.className = 'container active active-rpr'
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleClose(){
		this.container.current.className = 'container'
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ submitted: true	});

		const { email, password } = this.state;
		const { dispatch } = this.props;
		if (email && password) {
			dispatch(loginAction.login(email, password));
			this.setState({ submitted: false });
		}else{
			console.log('vacio todo')
		}
	}
	

	handleOnSubmitFormRecuperar(e){
		e.preventDefault();
		const { email } = this.state;
		const { dispatch } = this.props;
		console.log(email)
		if (email) {
			dispatch(loginAction.recuperacionPass(email));
		}
	}

	render() {
		const { loadding, submit } = this.props;
		const { email, password, submitted } = this.state;
		return (
			<div className="container" ref={this.container}>
				<div className="card"></div>
				<div className="card">
					<FormLogin 
						email={email}
						logo={logoCardio}	
						loading={loadding}
						password={password}
						submitted={submitted}
						onSubmitForm={this.handleSubmit}
						onChangeInputs={this.handleChange}
						onClickPass={this.handleRecuperarPasword} 
					/>
				</div>
				
				<div className="card rpr" id="contenido">
					<RecuperarPassword 
						email={email}
						loading={loadding}
						submitted={submit}
						onClickClose={this.handleClose}
						onChangeInputs={this.handleChange}
						onSubmitFormRecuperar={this.handleOnSubmitFormRecuperar}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { loadding, submit } = state.authentication;
	return {
		loadding,
		submit
	};
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage }; 