import { apiCardio } from '../_constants';

export const perfilService = {
    //DATOS BASICOS
    //editAvatar,
    editPortada,
    recortarImgMedico,

    //ePage
    insertExtracto,
    insertForm_ePage,
    editForm_ePage,
    get_ePage,
    getSugerencias,
    deletItemPerfilPublico,
    deletItemRecomendacion,
    selectedIdioma,
    searchEspecialista,
    getEpegeEspecialista,
    seguirEspecialista,
    getFolowers,
    getRecomendaciones,
    sugerenciaSeguidor,
    solicitudConectar,
    deleteContactoEspecialista,
    getContactos,
    aceptarSolicitudContacto,
};



// function editAvatar(user, file) {
//     const formData = new FormData();
//     formData.append('avatar', file);

//     var http = new XMLHttpRequest();
//     var url = apiCardio.urlMedico +'changeAvatarEspecialista';
//     let data = null;
//     /*Progreso */
//     http.upload.addEventListener("progress", (event) => {
//         let porcentaje = Math.round((event.loaded / event.total) * 100)
//         console.log(porcentaje)
//         data = porcentaje
//         return Promise.resolve(porcentaje);
//     })

//     /*Finalizado */
//     http.addEventListener("load", () => {
//         //console.log('finalizo la carga')
//         data = JSON.parse(http.response)
//         if (data.success === true) {
//             localStorage.setItem('user', JSON.stringify(data.data));
//         }
        
//         return Promise.resolve(data);
//     })

//     /*Enviar Dato */
//     http.open('POST', url, true);
//     http.setRequestHeader( 'token', user.token );
//     http.onreadystatechange = function () {//Call a function when the state changes.
//         if (http.readyState === 4 && http.status === 200) {
//             //handleResponse(http.response)
//             // const data = JSON.parse(http.response)
//             // console.log(data);
//         }
//     }
//     http.send(formData);
//     return Promise.resolve(http);
//     // const requestOptions = {
//     //     method: 'POST',
//     //     headers: { 'token': user.token},
//     //     body: formData,
        
//     // };
//     // return fetch(`${apiCardio.urlMedico}changeAvatarEspecialista`, requestOptions)
//     //     .then(handleResponse)
//     //     .then(data => {
//     //         if (data.success === true) {
//     //             localStorage.setItem('user', JSON.stringify(data.data));
//     //         }
//     //         return data;
//     //     });
// }

function editPortada(user, file) {
    const formData = new FormData();
    formData.append('portada', file);
    const requestOptions = {
        method: 'POST',
        headers: { 'token': user.token},
        body: formData,
        
    };
    return fetch(`${apiCardio.urlMedico}changePortadaEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.success === true) {
                localStorage.setItem('user', JSON.stringify(data.data));
            }
            return data;
        });
}
function recortarImgMedico(user, crop, dimensionView) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ crop, dimensionView })
    };

    return fetch(`${apiCardio.urlMedico}recortarImgMedico`, requestOptions)
        .then(handleResponse)
        .then(data => {
            if (data.success === true) {
                localStorage.setItem('user', JSON.stringify(data.data));
            }
            return data;
        });
}
/* ePage */
function insertExtracto(user, extracto) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ extracto })
    };


    return fetch(`${apiCardio.urlEPage}saveExtracto`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function insertForm_ePage(user, form, type_form) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ form, type_form })
    };

    return fetch(`${apiCardio.urlEPage}saveForm`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function editForm_ePage(user, form, type_form, idItem) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ form, type_form, idItem })
    };


    return fetch(`${apiCardio.urlEPage}editForm`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function get_ePage(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlEPage}getAllPerfilPublico`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getSugerencias(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlEPage}getAllSugerencias`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function deletItemPerfilPublico(user, item) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ id: item.info.id, typeItem: item.typeItem })
    };

    return fetch(`${apiCardio.urlEPage}deleteItemPerfilPublico`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function deletItemRecomendacion(user, item) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ id: item.keyd, typeItem: 5 })
    };

    return fetch(`${apiCardio.urlEPage}deleteItemPerfilPublico`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function selectedIdioma(user, select) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ select })
    };

    return fetch(`${apiCardio.urlEPage}seletedIdioma`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function searchEspecialista(user, search) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ search })
    };

    return fetch(`${apiCardio.urlMedico}buscarEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getEpegeEspecialista(tokenEpege, user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlEPage}epegeEspecialista`),
        params = { tokenEpege }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function seguirEspecialista(user, nickname, seguir) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ nickname, seguir })
    };
    return fetch(`${apiCardio.urlEPage}seguirEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getFolowers(cdn, nickname, user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlEPage}getFolowers`),
        params = { cdn, nickname }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getRecomendaciones(nickname, user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlEPage}getRecomendaciones`),
        params = { nickname }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function sugerenciaSeguidor(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlEPage}getMedicosSugerencia`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function solicitudConectar(user, nickname) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ nickname })
    };
    return fetch(`${apiCardio.urlEPage}solicitarConectar`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function deleteContactoEspecialista(user, nickname, type_contact) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ nickname, type_contact })
    };
    return fetch(`${apiCardio.urlEPage}deleteContactoEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function getContactos(nickname, user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlEPage}getContactos`),
        params = { nickname }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function aceptarSolicitudContacto(user, nickname) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ nickname })
    };
    return fetch(`${apiCardio.urlEPage}aceptarSolicitudContacto`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
/* ePage */

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

