import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Material UI */
import { Grid, Typography, Paper, Divider, Button } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../configuracionStyle";

/* Layout */
import AlertDialog from "../../layout/alertDialogo";

/*Components */
import ListRequisitosAgregados from "./listRequisitosAgregados";
import FormNewRequisitoCita from './formNewRequisitoCita'

/* Actions */
import { configActions, alertConfirmActions } from '../../../../../_actions';

class RequisitosCitas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
            newRequisito: {
                nombreRequisito: "",
                tipoDeDato: "",
                opciones: []
            },
            idRequisito: '0',
            nombreOpcion: "",
        };
        !this.props.listRequisitos && this.props.dispatch(configActions.getRequisitos(this.props.user))

        this.handleChangeInputOpcion = this.handleChangeInputOpcion.bind(this)
        this.handleAddOpcionRequisito = this.handleAddOpcionRequisito.bind(this)
        this.handleSubmitNuevoRequisito = this.handleSubmitNuevoRequisito.bind(this)
        this.handleChangeInputNuevoRequisito = this.handleChangeInputNuevoRequisito.bind(this)
    }

    handleOpenCloseDialog = data => () => {
        data === 'newR' && this.handleLimpiarRequisito()
        data.condicion === 'editR' && this.handleEditRequisito(data.dato)
    }

    handleChangeExpansion = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleLimpiarRequisito() {
        this.setState({
            newRequisito: {
                nombreRequisito: '',
                tipoDeDato: '',
                opciones: []
            },
            idRequisito: 0
        })
        this.props.dispatch(configActions.openModalRequisitos(this.props.openDialogRequisito))
    }

    handleEditRequisito(data) {
        let opcopnes = []
        if (data.opcion) { opcopnes = data.opcion  }
        this.setState({
            newRequisito: {
                nombreRequisito: data.name,
                tipoDeDato: data.tipo,
                opciones: opcopnes
            }, 
            idRequisito: data.id
        })
        this.props.dispatch(configActions.openModalRequisitos(this.props.openDialogRequisito))
    }

    handleChangeInputNuevoRequisito(e) {
        const { name, value } = e.target;
        const newRequisito = this.state.newRequisito
        newRequisito[name] = value
        this.setState({ newRequisito: newRequisito });
    };

    handleChangeInputOpcion(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleAddOpcionRequisito(e) {
        const newOpcion = { value: this.state.nombreOpcion }
        const opciones = this.state.newRequisito.opciones
        if (newOpcion.value !== '') {
            opciones.push(newOpcion)
            this.setState({
                newRequisito: {
                    nombreRequisito: this.state.newRequisito.nombreRequisito,
                    tipoDeDato: this.state.newRequisito.tipoDeDato,
                    opciones: opciones
                },
                nombreOpcion: ''
            })
        }
    }

    handleSubmitNuevoRequisito(e) {
        e.preventDefault();
        const { newRequisito } = this.state
        const { user, dispatch } = this.props;
        dispatch(configActions.submitRequestRequisito())
        if (newRequisito.nombreRequisito && newRequisito.tipoDeDato) {
            if ((newRequisito.tipoDeDato === '2' || newRequisito.tipoDeDato === '3')) {
                newRequisito.opciones.length
                    ? dispatch(configActions.registrarRequisito(user, newRequisito, this.state.idRequisito))
                    : console.log('Marcar error debe haber al menos una seleccion')
            } else {
                dispatch(configActions.registrarRequisito(user, newRequisito, this.state.idRequisito))
            }
        }
    }

    handleCloseAlert = () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleDeleteOpcionRequisito = data => () => {
        const opciones = this.state.newRequisito.opciones
        const regex = opciones.filter(j => j !== data.nombreOpcion)
        this.setState({
            newRequisito: {
                nombreRequisito: this.state.newRequisito.nombreRequisito,
                tipoDeDato: this.state.newRequisito.tipoDeDato,
                opciones: regex
            }
        })

        if (data.condicion === '2') {
            this.props.dispatch(configActions.deleteOpcionRequisito(this.props.user, data.idopcion))
        }
    }
    handleSelectDelateRequisito = data => () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        this.props.dispatch(configActions.selectDeleteRequisito(data))
    }

    handleDelateRequisito = () => {
        const { user, dispatch, itemRequisitoDelete } = this.props;
        dispatch(alertConfirmActions.sumitedRequest())
        dispatch(configActions.deleteRequisito(user, itemRequisitoDelete))
    }

    render() {
        const { expanded, nombreOpcion, newRequisito } = this.state
        const { openConfirm, loadingConfirm, submitedConfrim  } = this.props
        const { listRequisitos, openDialogRequisito, tipoDato, submitRequisito, loadingRequisitos } = this.props
        return (<Grid container spacing={24}>
                <Grid item xs={12} className="pb-0">
                    <Typography color="primary" component="span" >Paraclínicos para citas</Typography>
                </Grid>
                <Grid item xs={12} className="pb-0">
                    <Paper className="paper">
                        <div className="title-paper">
                            <Typography className="color-gris" component="span">
                                Agrega la opción para que tus pacientes puedan adjuntar una imagen, documento o datos que consideres pertinente conocer previo a la cita.
                            </Typography>
                        </div>
                        <Divider />
                        <div>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className="mt-1"
                                onClick={this.handleOpenCloseDialog('newR')}
                            >
                                Incluir paraclínico
                            </Button>
                            <ListRequisitosAgregados
                                expanded={expanded}
                                listRequisitos={listRequisitos}
                                handleChangeExpansion={this.handleChangeExpansion}
                                handleOpenCloseDialog={this.handleOpenCloseDialog}
                                handleDeleteOpcionRequisito={this.handleDeleteOpcionRequisito}
                                handleSelectDelateRequisito={this.handleSelectDelateRequisito}
                            />
                        </div>
                    </Paper>
                </Grid>

                <FormNewRequisitoCita
                    open={openDialogRequisito}
                    tipoDato={tipoDato}
                    submitted={submitRequisito}
                    loading={loadingRequisitos}
                    nombreOpcion={nombreOpcion}
                    newRequisito={newRequisito}
                    handleOpenCloseDialog={this.handleOpenCloseDialog}
                    handleChangeInputNuevoRequisito={this.handleChangeInputNuevoRequisito}
                    handleChangeInputOpcion={this.handleChangeInputOpcion}
                    handleAddOpcionRequisito={this.handleAddOpcionRequisito}
                    handleDeleteOpcionRequisito={this.handleDeleteOpcionRequisito}
                    handleSubmitNuevoRequisito={this.handleSubmitNuevoRequisito}
                />

                <AlertDialog
                    open={openConfirm}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDelateRequisito}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de eliminar el item seleccionado?'
                />
            </Grid>
            )
    }
}

RequisitosCitas.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { listRequisitos, tipoDato, openDialogRequisito, loadingRequisitos, itemRequisitoDelete, submitRequisito } = state.requisitosReducer;
    return {
        openConfirm, loadingConfirm, submitedConfrim,
        listRequisitos, tipoDato, openDialogRequisito, loadingRequisitos, itemRequisitoDelete, submitRequisito, 
    };
}

const connectedRequisitosCitas = connect(mapStateToProps)(withStyles(styles)(RequisitosCitas));
export { connectedRequisitosCitas as RequisitosCitas };