import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import dateFns from "date-fns";

/* Actions */
import { citasActions, alertConfirmActions } from '../../../../_actions'

// /* MAterial UI */
import { Grid, Paper, Typography, IconButton, Divider, Tooltip, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


/* Layouts */
import AlertDialog from "../layout/alertDialogo";


// /* Iconos */
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';

const styles = theme => ({
    maxh: {
        maxHeight: 300,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    btnHist: {
        padding: 8,
    }
});

class ListaCitaPorAtender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        }
        
        this.handleVerDetalle = this.handleVerDetalle.bind(this)
        this.handleAtenderCita = this.handleAtenderCita.bind(this)
    }

    handleAtenderCita = datos => () => {
        var a = document.createElement("a");
        a.href = datos.consultorio;
        if (datos.type_quotes !== '3') {
            a.target = "_blank";
        }
        a.click();
        this.setState({ anchorEl: null });
    }

    handleVerDetalle = datos => () => {
        this.props.dispatch(citasActions.selectCita(datos.id))
        this.props.dispatch(citasActions.detalleViewModal())
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    /* Handles Marcar como atendida la cita */
    handleCloseAlert = () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleMarcarAtendida = data => () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        this.setState({ anchorEl: null });
        this.props.dispatch(citasActions.selecCitaParaMarcarAtendida(data))
    }

    handleDelateItem = () => {
        this.props.dispatch(alertConfirmActions.sumitedRequest())
        this.props.dispatch(citasActions.citaAtendida(this.props.user, this.props.atendida))
    }
    /* Handles Para Burrar algun elemento */

    render() {

        const { anchorEl } = this.state;
        const { classes, citas, openConfirm, loadingConfirm, submitedConfrim } = this.props;
        const fecha = dateFns.format(new Date(), 'DD/MM/YYYY');
        const cita = citas.filter(q => (q.fech_quotes.slice(3, 11) === fecha.toString().slice(3, 11) && q.status === 'Verificada'));
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Paper className="paper-bar-left">
                        <div className="title-paper">
                            <Typography color="primary" component="span">Citas por atender</Typography>
                        </div>
                        <div className={classes.maxh}>
                            {cita.length
                                ?
                                cita.map(k => {
                                    return (
                                        <div className="crear-Histo " key={k.id}>
                                            <div className="valign-wrappe">
                                                <div className="info-paciente">
                                                    <Typography color="primary" component="span">
                                                        {k.familiar
                                                            ? k.familiar.name + ' ' + k.familiar.lasname+' (Familiar)'
                                                            : k.paciente
                                                        }
                                                    
                                                    </Typography>
                                                    <Typography color="secondary" component="span">{k.consulta}</Typography>
                                                    <Typography color="secondary" component="span">{k.fech_quotes}</Typography>
                                                </div>
                                                <div className={`action p-0 align-center ${classes.maxh}`}>
                                                    <Tooltip title="Ver más">
                                                        <IconButton aria-label="Crear Historia" onClick={this.handleVerDetalle(k)}>
                                                            <VisibilityIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {k.fech_quotes === fecha.toString() &&
                                                        <IconButton aria-owns={anchorEl ? 'simple-menu' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={this.handleClick}>
                                                            <MoreVertIcon fontSize="small" />
                                                        </IconButton>
                                                    }
                                                    {k.fech_quotes === fecha.toString() &&
                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={this.handleClose}
                                                        >
                                                            <MenuItem onClick={this.handleAtenderCita(k)}>Atender cita</MenuItem>
                                                            <MenuItem onClick={this.handleMarcarAtendida(k)}>Marcar como atendida</MenuItem>
                                                        </Menu>

                                                    }
                                                    
                                                </div>
                                            </div>
                                            <Divider />
                                        </div>
                                    )

                                })
                                :
                                <div>
                                    <Typography color="secondary" component="span">Hoy no posee citas para atender</Typography>
                                </div>
                            }
                        </div>
                    </Paper>
                </Grid>
                <AlertDialog
                    open={openConfirm}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDelateItem}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de marcar esta cita como atendida?'
                />
            </Grid>
        )
    }
}

ListaCitaPorAtender.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { citas, atendida } = state.citasReducer;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    return {
        user,
        citas, atendida,
        openConfirm, loadingConfirm, submitedConfrim,
    };
}

const connectedListaCitaPorAtender = connect(mapStateToProps)(withStyles(styles)(ListaCitaPorAtender));
export { connectedListaCitaPorAtender as ListaCitaPorAtender };