import React from 'react'


/* MAterial UI */
import './post.css';
import { styles } from "./postStyle";
import { withStyles } from '@material-ui/core/styles';
import { Avatar, InputBase, CircularProgress } from '@material-ui/core';

import Emojis from '../layout/emojis'

/* Iconos */

const CrearComentario = (props) => (
    <div className="cont-new-comentary">
        <div className="cont-letteravatars">
            <Avatar className={`${props.classes.purpleAvatar}`}>
                {props.user.letteravatars}
                {
                    (props.selectPost === props.post && props.loading) && <CircularProgress size={27} color="secondary" className={`ProgressAbsolut ${props.classes.progresComentario}`} />
                }
            </Avatar>
        </div>
        <div className="cont-imput">
            <InputBase 
                fullWidth
                multiline
                rowsMax="6"
                className="text-area"
                name="newComentario"
                onClick={props.hlandleComentaryPost(props.post)}
                onChange={props.handleChangeImputComentary}
                onKeyPress={props.handleSubmitComentary}
                value={(props.selectPost === props.post ) ? props.comentario : '' }
                placeholder={(props.selectPost === props.post && props.loading) ? "Publicando su comentario...": "Deja un comentario..." }
            />
            {/* <Typography component="span" color="textSecondary">Aqui va para crear el comentario</Typography> */}
            <Emojis
                post={props.post}
                dispatch={props.dispatch}
                handleSelectEmoji={props.handleSelectEmoji}
            />
        </div>
    </div>
)


export default withStyles(styles)(CrearComentario);