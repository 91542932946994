export const styles = theme => ({
    pdBtn:{
        paddingTop: '23px !important',
    },
    bigAvatar: {
        margin: 'auto',
        [theme.breakpoints.up("sm")]: {
            width: 100,
            height: 100,
        },
        [theme.breakpoints.down("md")]: {
            width: 150,
            height: 150,
        },
        [theme.breakpoints.up("md")]: {
            width: 170,
            height: 170,
        }
    },
    borderBotonP:{
        borderBottom: '1px solid #bababa',
        height: 23
    },
    labelInterconsulta:{
        color: '#bababa'
    },
    radioButtons:{
        flexDirection: 'row',
    },
    input: {
        display: 'none',
    },
});