import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import Emojis from '../layout/emojis'

/* MAterial UI */
import './post.css';
import { styles } from "./postStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Avatar, IconButton, Menu, MenuItem, InputBase, CircularProgress } from '@material-ui/core';

/* Actions */
import { postActions, alertConfirmActions } from '../../../../_actions'
import AlertDialog from "../layout/alertDialogo";

/* Iconos */
import MoreVertIcon from '@material-ui/icons/MoreVert';

class ListComentarioRespuesta extends Component {
    constructor() {
        super()
        this.state = {
            verRespuesta: false,
            anchorEl: null,
            openAlerReplyComentary: false,
        };
        this.handleSelectEmoji = this.handleSelectEmoji.bind(this)
        this.handleSelectEmojiEditRespuesta = this.handleSelectEmojiEditRespuesta.bind(this)
    }
    handleChangeImputComentary = (e) => { 
        if (this.props.comentaryReply.length < 250) {
            
            this.props.dispatch(postActions.changeComentaryReply(e.target.value))
        }
    }

    hlandleComentaryPost = data => () => {
        const { dispatch, selectPost } = this.props;
        if (selectPost.replyComentary !== data.replyComentary) {
            dispatch(postActions.selectPostComentary(data))
            dispatch(postActions.changeComentaryReply(''))
        }

    }

    handleSubmitComentary = (e) => {
        const key = window.event ? e.which : e.keyCode;
        if (key === 13) {
            e.preventDefault();
            const { dispatch, user, selectPost, autorPost, comentaryReply } = this.props;
            if (comentaryReply) {
                dispatch(postActions.submitComentaryReply(user, selectPost, autorPost, comentaryReply))
            }

        }
    }
    handleVerRespuestas = () =>{
        this.setState({verRespuesta: true})
    } 

    /* Handle para editar o eliminar comentario */
    handleOpenMenu = data => (event) => {
        this.props.dispatch(postActions.selectPostComentary(data.itemSelect))
        this.setState({ [data.closeMenu]: event.currentTarget });
    };

    handleCloseMenu = data => () => {
        this.setState({ [data]: null });
    };

    handleEditarComentary = data => () => {
        this.setState({ anchorEl: null });
        this.props.dispatch(postActions.selectPostComentary(data))
    }

    handleChangeEditImputComentary = (e) => {
        e.preventDefault();
        this.props.dispatch(postActions.changeEditComentaryReply(e.target.value, this.props.selectPost))
    }
    handleSubmitEditComentary = (e) => {
        const key = window.event ? e.which : e.keyCode;

        if (key === 13) {
            const { dispatch, selectPost, user } = this.props;
            e.preventDefault();
            selectPost.comenteReply.commentary_reply !== '' && dispatch(postActions.submitEditComentaryReply(selectPost, user))
        }

        key === 27 && this.props.dispatch(postActions.selectPostComentary(''))
    }
    /* Fin Handle para editar o eliminar comentario */

    /* Eliminar Elementos */
    handleCloseAlert = () => {
        this.setState({ openAlerReplyComentary: false });
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    };

    handleRemoveElement = data => () => {
        const { dispatch } = this.props;
        this.setState({ [data.closeMenu]: null });
        this.setState({ openAlerReplyComentary: true });
        dispatch(alertConfirmActions.openCloseAlert())
        dispatch(postActions.selectPostComentary(data))
    }

    handleDelateItem = () => {
       const { dispatch, user, selectPost } = this.props;
        dispatch(alertConfirmActions.sumitedRequest())
        dispatch(postActions.delteComentaryReply(selectPost.itemSelect, selectPost.post, user))
    }
    /* Eliminar Elementos */

    /* Emojis */
    handleSelectEmoji(code) {
        const emoji = String.fromCodePoint(`0x${code}`)
        const mensaje = this.props.comentaryReply + ' ' + emoji
        this.props.dispatch(postActions.changeComentaryReply(mensaje))
    }
    handleSelectEmojiEditRespuesta(code) {
        const emoji = String.fromCodePoint(`0x${code}`)
        console.log(this.props.selectPost)
        const mensaje = this.props.selectPost.comenteReply.commentary_reply + ' ' + emoji
        this.props.dispatch(postActions.changeEditComentaryReply(mensaje, this.props.selectPost))
        //this.props.dispatch(postActions.changeComentaryReply(mensaje))
    }

    render() {
        const { verRespuesta, anchorEl, openAlerReplyComentary } = this.state
        const { classes, user, loading, selectPost, keydsComentary, postComentary, comentaryReply, lisComentaryReply, autorPost } = this.props;
        const { dispatch, openConfirm, loadingConfirm, submitedConfrim } = this.props;
        return (
            <div>
                {(keydsComentary && verRespuesta) || selectPost.replyComentary === keydsComentary 
                    ? <div>
                        {lisComentaryReply && lisComentaryReply.map(k => {
                            const data = { itemSelect: k.keyds, 'post': postComentary, 'closeMenu': 'anchorEl' }
                            return (
                                <div key={k.keyds}>
                                    <div className="comentary valign-wrappe mb-12" >
                                        <Avatar className={classes.purpleAvatar}>
                                            {k.letteravatars}
                                            {selectPost.comenteReply && (selectPost.comenteReply.keyds === k.keyds && loading) && <CircularProgress size={27} color="secondary" className={`ProgressAbsolut ${classes.progresComentario}`} />}
                                        </Avatar>
                                        <div className={`cont-comentary ${selectPost.comenteReply && (selectPost.comenteReply.keyds === k.keyds) && 'editar-comentary'}`}>
                                            {selectPost.comenteReply && (selectPost.comenteReply.keyds === k.keyds) 
                                                ?<div>
                                                    <InputBase
                                                        fullWidth
                                                        multiline
                                                        autoFocus
                                                        rowsMax="6"
                                                        name="editComentario"
                                                        className="text-area"
                                                        onChange={this.handleChangeEditImputComentary}
                                                        onKeyDown={this.handleSubmitEditComentary}
                                                        value={selectPost.comenteReply && selectPost.comenteReply.commentary_reply && selectPost.comenteReply.commentary_reply}
                                                    />
                                                    <Emojis
                                                        post={{ comenteReply: k, post: postComentary }}
                                                        dispatch={dispatch}
                                                        handleSelectEmoji={this.handleSelectEmojiEditRespuesta}
                                                    />
                                                </div> 
                                                : <Typography component="span" color="textSecondary">
                                                    <b className='autor-comentary'>{k.autor}</b>
                                                    {k.commentary_reply}
                                                </Typography>
                                            }
                                            
                                        </div>
                                        {(autorPost || k.more)
                                            && <div>
                                            <IconButton
                                                aria-label="More"
                                                aria-owns={anchorEl ? 'long-menu-reply'+k.keyds : undefined}
                                                aria-haspopup="true"
                                                onClick={this.handleOpenMenu({ itemSelect: k.keyds, 'closeMenu': 'anchorEl' })}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>

                                            <Menu
                                                id={`long-menu-reply${k.keyds}`}
                                                anchorEl={anchorEl}
                                                open={(selectPost === k.keyds) && anchorEl ? true : false}
                                                onClose={this.handleCloseMenu('anchorEl')}

                                            >
                                                {k.more &&
                                                    <MenuItem onClick={this.handleEditarComentary({ comenteReply: k, post: postComentary })}>
                                                        Editar
                                                    </MenuItem>
                                                }
                                                <MenuItem onClick={this.handleRemoveElement(data)}>
                                                    Eliminar
                                                </MenuItem>
                                            </Menu>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}

                        <div className="cont-new-comentary">
                            <div className="cont-letteravatars">
                                <Avatar className={`${classes.purpleAvatar}`}>
                                    {user.letteravatars}
                                    {
                                        (selectPost.replyComentary === keydsComentary && loading) && <CircularProgress size={27} color="secondary" className={`ProgressAbsolut ${classes.progresComentario}`} />
                                    }
                                </Avatar>
                            </div>
                            <div className="cont-imput">
                                <InputBase
                                    fullWidth
                                    multiline
                                    rowsMax="6"
                                    name="newComentario"
                                    className="text-area"
                                    onClick={this.hlandleComentaryPost({ replyComentary: keydsComentary, post: postComentary })}
                                    onChange={this.handleChangeImputComentary}
                                    onKeyPress={this.handleSubmitComentary}
                                    value={keydsComentary === selectPost.replyComentary ? comentaryReply : ''}
                                    placeholder="Deja una respuesta..."
                                />
                                <Emojis
                                    post={{ replyComentary: keydsComentary, post: postComentary }}
                                    dispatch={dispatch}
                                    handleSelectEmoji={this.handleSelectEmoji}
                                />
                            </div>
                        </div>
                    </div>
                    : lisComentaryReply.length ?
                        <Typography component="span" color="textSecondary" className="ver-respuestas" onClick={this.handleVerRespuestas}>
                            <b className='autor-comentary'>{lisComentaryReply[lisComentaryReply.length - 1].autor}</b> respondió - {lisComentaryReply.length} respuestas
                    </Typography>
                        : null
                }
                <AlertDialog
                    open={openConfirm && openAlerReplyComentary}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDelateItem}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de eliminar el comentario seleccionado?'
                />
            </div>
        )
    }
}

ListComentarioRespuesta.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { comentaryReply, selectPost, loading } = state.postReducer;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    return {
        user,
        comentaryReply, selectPost, loading,
        openConfirm, loadingConfirm, submitedConfrim
    };
}

const connectedListComentarioRespuesta = connect(mapStateToProps)(withStyles(styles)(ListComentarioRespuesta));
export { connectedListComentarioRespuesta as ListComentarioRespuesta };