import { configConstants } from '../../_constants';

const initialState = {
    especialidades: [],
    loadingEspecialidad: false
};

export function especialidadesReducer(state = initialState, action) {
    switch (action.type) {
        case configConstants.GET_ESPECILIDAD_REQUESt:
            return Object.assign({}, state, {
                loadingEspecialidad: true
            })
        case configConstants.GET_ESPECILIDAD:
            return Object.assign({}, state, {
                especialidades: action.data,
                loadingEspecialidad: !state.loadingEspecialidad,
            })
        case configConstants.SELET_ESPECIALIDAD:
            return Object.assign({}, state, {
                especialidades: state.especialidades.map(k => {
                    if (k.id === action.data.id) {
                        k.selected = !action.data.selected;
                    }
                    return k
                }),
            })
        default:
            return state
    }
}