import { alertActions } from '../alert.action';
import { directService } from "../../_services";
import { directConstants } from '../../_constants';
import { notifyConstants } from '../../_constants';
import { alertConfirmActions } from '../alertConfirm.action';

export const directAction = {
    movilOnDirect,
    openDialogDirect,
    selectParticipante,
    NewMessage,
    changeComentaryMessage,
    submitBtnDirect,
    changeViews,
    listDirect,
    onSelectDirect,
    getYarnDirect,
    insertDirect,
    insertDirectComentary,
    marcarVistoMensaje,
    salirOEliminarChat,
    getListContactos,

    /* Mensaje desde lista de contacto */
    selectParticipanteMessage,
};

function movilOnDirect(data) {
    return { type: directConstants.CHECK_WIDTH, data }
}
function openDialogDirect() {
    return { type: directConstants.OPEN_DIALOG_NEW_MESSAGE }
}
function selectParticipante(data) {
    return { type: directConstants.SELECT_COMPETITOR, data }
}
function NewMessage(data) {
    // return { type: directConstants.VIEW_NEW_MESSAGE, data }
    return dispatch => {
        dispatch(request())
        dispatch(success(data))
        
    };

    function request() { return { type: directConstants.REQUEST_DIRECT } }
    function success(data) { return { type: directConstants.VIEW_NEW_MESSAGE, data } }
}
function changeComentaryMessage(data) {
    return { type: directConstants.CHANGE_COMENTARY, data }
}
function submitBtnDirect() {
    return { type: directConstants.GET_SUBMIT_BTN }
}
function changeViews() {
    return { type: directConstants.CHANGE_VIEWS }
}
function listDirect(user) {
    return dispatch => {
        dispatch(request())
        directService.getListDirect(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        dispatch(fail())
                    }
                }
            )

    };

    function fail() { return { type: directConstants.LIST_DIRECT_FAIL } }
    function request() { return { type: directConstants.REQUEST_PRELOADER } }
    function success(data) { return { type: directConstants.LIST_DIRECT_SUCCES, data } }
}
function onSelectDirect(data) {
    return { type: directConstants.ON_SELECT_DIRECT, data }
}
function getYarnDirect(user, keyd) {
    return dispatch => {
        dispatch(request())
        directService.getYarnDirect(user, keyd)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success({
                            keyd, yarn: data.data
                        }))
                    } else {
                        dispatch(fail())
                    }
                }
            )

    };

    function fail() { return { type: directConstants.FAIL_YARN_DIRECT } }
    function request() { return { type: directConstants.REQUEST_DIRECT } }
    function success(data) { return { type: directConstants.SUCCES_YARN_DIRECT, data } }
}
function insertDirect(message, user) {
    return dispatch => {
        dispatch(request())
        directService.insertDirect(message, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        dispatch(alertActions.error('Lo sentimos ha ocurrido un error al registrar, vuelve a intentarlo si persite la falla contacta con soporte.'));
                    }
                }
            )
    }
    function request() { return { type: directConstants.REQUEST_DIRECT } }
    function success(data) { return { type: directConstants.SUCCES_INSERT_DIRECT, data } }
}

function insertDirectComentary(message, user) {
    return dispatch => {
        dispatch(request())
        directService.insertComentary(message, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }
                }
            )
    }
    function request() { return { type: directConstants.REQUEST_DIRECT } }
    function success(data) { return { type: directConstants.SUCCES_INSERT_COMENTARY, data } }
}
function marcarVistoMensaje(keyd, user) {
    return dispatch => {
        directService.marcarVistoMensaje(keyd, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(keyd))
                        dispatch(success_notify(keyd))
                    }
                }
            )
    }
    function success(data) { return { type: directConstants.MESSAGE_VIEWED, data } }
    function success_notify() { return { type: notifyConstants.MARCAR_VISTO_DIRECT_SUCCES } }
}
function salirOEliminarChat(message, user) {
    return dispatch => {
        directService.deleteChat(message, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(message))
                        dispatch(alertActions.success(data.message));
                        dispatch(alertConfirmActions.success())
                    }else{
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            )
    }
    function success(data) { return { type: directConstants.DELETE_CHAT, data } }
}

function getListContactos(user) {
    return dispatch => {
        directService.getDirectContactos(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        //dispatch(fail())
                    }
                }
            )
    }
    function success(data) { return { type: directConstants.LIST_DIRECT_CONTACT, data } }
    //function fail() { return { type: directConstants.EPAGE_FAIL } }
}

function selectParticipanteMessage(data, user) {
    return dispatch => {
        dispatch(request(data))
        directService.getVerificarExisteDirect(user, data.keyd)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        dispatch(fail())
                    }
                }
            )
    }
    function request(data) { return { type: directConstants.REQUEST_MESSAGE_DIRECT_PARTICIPANT, data } }
    function success(data) { return { type: directConstants.SUCCES_MESSAGE_DIRECT_PARTICIPANT, data } }
    function fail() { return { type: directConstants.FAIL_MESSAGE_DIRECT_PARTICIPANT } }
}