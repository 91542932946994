import React from 'react'

/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import DoneIcon from '@material-ui/icons/Done';
import EventIcon from '@material-ui/icons/Event';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

/* Estilos con Material UI */
const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '35%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    iconEvent:{
        float: 'left',
        marginTop: -2,
        marginRight: 6
    },
    colorSecundario:{
        color: theme.palette.text.secondary,
    },
    error: {
        color: '#d32f2f',
    },
    succes: {
        color: '#43a047'
    },
});

const Citas = (props) => (
    <div className="">
        {props.list ?
            props.list.map(k =>{
                let status = <ErrorOutlineIcon className={`${props.classes.iconEvent} ${props.classes.error}`} />
                if (k.status === 'Verificada' || k.status === 'Atendida' ) {
                    status = <DoneIcon className={`${props.classes.iconEvent} ${props.classes.succes}`} />
                }
                return(
                    <ExpansionPanel key={k.id} expanded={props.expanded === k.id} onChange={props.handleChangeExpanded(k.id)}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography color='primary' className={props.classes.heading}>
                                <Tooltip title={k.status}>
                                    {status}
                                </Tooltip>
                                {k.paciente}
                            </Typography>
                            <Typography className={props.classes.secondaryHeading}>
                                <EventIcon className={props.classes.iconEvent}></EventIcon>
                                {k.fech_quotes}  {k.hour_attention}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Typography className={props.classes.colorSecundario} component="p">
                                        <b className={props.classes.colorSecundario} >Tipo de consulta: </b>{k.consulta}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={props.classes.colorSecundario} component="p">
                                        <b className={props.classes.colorSecundario} >Cita dirigida a: </b>
                                        {k.familiar 
                                            ? k.familiar.name + ' ' + k.familiar.lasname + ' (' + k.familiar.parentesco +')'
                                            : k.paciente
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container spacing={24}>
                                        <Grid item xs={6}>
                                            <Typography className={props.classes.colorSecundario} component="p">
                                                <b className={props.classes.colorSecundario} >Estado: </b>{k.status}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={props.classes.colorSecundario} component="p">
                                                <b className={props.classes.colorSecundario} >Especialidad: </b>{k.especialidad}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={props.classes.colorSecundario} component="p">
                                        <b className={props.classes.colorSecundario} >Motivo de la consulta: </b>{k.motive}
                                    </Typography>
                                </Grid>
                                {props.handleModalOpen &&
                                    <Grid item xs={12} sm={2} align="center">
                                        <IconButton aria-label="ver" onClick={props.handleModalOpen(k)}>
                                            <VisibilityIcon color='primary'/>
                                        </IconButton>
                                        <br/>
                                        <Button color="primary" onClick={props.handleModalOpen(k)}>
                                            Ver más
                                        </Button>
                                        
                                    </Grid>
                                }
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )
            })
            : props.citas 
                ? <Typography color='primary' className={props.classes.heading}>
                    Seleccione una fecha para ver sus citas
                </Typography>
                : <Typography color='primary' className={props.classes.heading}>
                    No posee citas agendadas
                </Typography>
        }       
    </div>
)


export default withStyles(styles)(Citas);