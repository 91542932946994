import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Actions */
import { directAction, alertActions } from '../../../../_actions'

// /* MAterial UI */
import { styles } from "./directStyle";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, InputBase, Divider, IconButton, Tooltip  } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';

/* Component */
import DialogNewDirect from "./dialogNewDirect";
import LayoutList from "./layoutList";

/* Layouts */
// import AlertDialog from "../layout/alertDialogo";



class ListMensajesDirectos extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            filterParticipante: '',
            nombGroup: '',
        }  
        this.handleFilterParticipante = this.handleFilterParticipante.bind(this)
    }

    /*Handles para nuevo mensaje directo */
    handleOpenAndClosedDialogDirect = (event) => {
        event.preventDefault();
        this.props.dispatch(directAction.openDialogDirect())
    }
    handleFilterParticipante(value, data){
        let regex = new RegExp(value, 'i')
        return data.filter(q => (regex.test(q.nom_contacto)))
    }
    handleChangeInput = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value });
    }
    handleSelectParticipantes = data => () =>{
        const { dispatch } = this.props
        const array = {
            type: 2,
            img: data.img,
            keyd: data.keyd,
            nickname: data.nickname,
            nombre: data.nom_contacto ? data.nom_contacto : data.nombre,
        }
        dispatch(directAction.selectParticipante(array))
    }

    handleConstructNewMessage = (e) =>{
        e.preventDefault();
        const {  nombGroup  } = this.state
        const { dispatch, participantes } = this.props
        const array = {
            nombGroup,
            participantes
        }
        if (participantes.length > 1) {
            if (nombGroup === '') {
                dispatch(alertActions.error('Debe colocar un nombre al grupo.'));
            }else{
                dispatch(directAction.NewMessage(array))
            }
        } else if (participantes.length === 1){
            dispatch(directAction.NewMessage(array))
        }else{
            dispatch(alertActions.error('Debe seleccionar al menos un especialista.'));
        }
        this.setState({ nombGroup: ''});
    }
    /*Fin Handles para nuevo mensaje directo */

    /*Seleccionar un mensaje */
    handleOnSelectDirect = data => (e) =>{
        this.props.dispatch(directAction.onSelectDirect(data))
        if (!data.viewed) {
            this.props.dispatch(directAction.marcarVistoMensaje(data.keyd, this.props.user))
        }
    }

    render() {
        const { filterParticipante, nombGroup } = this.state;
        const { classes, participantes, openDialogDirect, listDirect, loadingDirect, listContactDirect } = this.props;
        return (
            <div className="content-list-message">
                <Grid container spacing={24} >
                    <Grid item xs={12}>
                        <Paper className="paper-buscar">
                            <div className="title-paper">
                                <Typography color="primary" component="span">Mensajes</Typography>
                                <IconButton onClick={this.handleOpenAndClosedDialogDirect}>
                                    <Tooltip title="Nuevo mensaje">
                                        <ChatIcon/>
                                    </Tooltip>
                                </IconButton>
                            </div>
                            <Divider />
                            <div className="cont-paper">
                                <InputBase
                                    fullWidth
                                    id="filter"
                                    name="filter"
                                    autoComplete="off"
                                    className="fz15"
                                    placeholder="Buscar especialista o grupo"
                                    // value={newArticulo.title}
                                    //onChange={props.handleFilter}
                                />
                            </div>
                            <div className="list-mesages">
                                <LayoutList
                                    list={listDirect}
                                    handleAction={this.handleOnSelectDirect}
                                />
                            </div>
                            <DialogNewDirect
                                classes={classes}
                                nombGroup={nombGroup}
                                openDialog={openDialogDirect}
                                participantes={participantes}
                                loadingDirect={loadingDirect}
                                listSiguiendo={this.handleFilterParticipante(filterParticipante, listContactDirect)}
                                closeDialog={this.handleOpenAndClosedDialogDirect}
                                handleChangeInput={this.handleChangeInput}
                                handleSelectParticipantes={this.handleSelectParticipantes}
                                handleConstructNewMessage={this.handleConstructNewMessage}
                            />
                        </Paper>
                    </Grid>
                    {/* <AlertDialog
                        open={openConfirm}
                        loading={loadingConfirm}
                        submitted={submitedConfrim}
                        procesar={this.handleDelateItem}
                        handleClose={this.handleCloseAlert}
                        message='¿Esta seguro de marcar esta consulta como atendida?'
                    /> */}
                </Grid>
            </div>
        )
    }
}

ListMensajesDirectos.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { openDialogDirect, participantes, listDirect, loadingDirect, listContactDirect} = state.directReducer;
    return {
        user,
        openDialogDirect, participantes, listDirect, loadingDirect, listContactDirect
    };
}

const connectedBarRightMensajesDirectos = connect(mapStateToProps)(withStyles(styles)(ListMensajesDirectos));
export { connectedBarRightMensajesDirectos as ListMensajesDirectos };