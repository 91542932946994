import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Estilos con Material UI */
import { Grid, Typography, Fade, TextField, FormControl, InputBase, Select, MenuItem, Button, ButtonBase, CircularProgress, IconButton   } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./incidenciasStyle";

/* Icons */
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';

/* Actions */
import { incidenciaActions, alertActions } from '../../../../_actions'

class FormCrearIncidencia extends Component {
    constructor(props) {
        super(props);

        this.handleOnClose = this.handleOnClose.bind(this)
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleImageChange = this.handleImageChange.bind(this)
        this.handleSubmitIncidencia = this.handleSubmitIncidencia.bind(this)
    }

    handleChangeInput(e) {
        const { name, value } = e.target
        this.props.dispatch(incidenciaActions.formCrearIncidencia(name, value))
    }
    handleImageChange(e) {
        e.preventDefault();
        let file = e.target.files[0];
        const { dispatch } = this.props;
        dispatch(incidenciaActions.formCrearIncidencia('image', file))
    }
    handleOnClose(){
        this.props.dispatch(incidenciaActions.mostrarSlide())
    }

    handleSubmitIncidencia(e) {
        e.preventDefault();
        const { formIncidencia, dispatch, user } = this.props

        dispatch(incidenciaActions.submit())
        formIncidencia &&
            (formIncidencia.description && formIncidencia.tipoproblema && formIncidencia.title)
            ? dispatch(incidenciaActions.registrarIncidencia(user, formIncidencia))
            : dispatch(alertActions.error('Los campos con * son obligatorios'))
    }

    render() {
        const { classes, fadeInci, typeInciden, formIncidencia, submitInci, loading } = this.props
        return (
            <Fade in={fadeInci}>
                <div className={`crearIncidencia ${fadeInci ? '' : 'dsp-none'} `}>
                    <div className="title-new-inciden">
                        <Typography component="span"> Nuevo reporte</Typography>
                        <IconButton aria-label="Cerrar" className={classes.iconClose} onClick={this.handleOnClose}>
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </div>

                    <div className="content-inciden">
                        <Grid container>
                            <Grid item xs={12} >
                                <FormControl fullWidth required margin="dense" className={classes.formControlEducacion}>
                                    <Select
                                        displayEmpty
                                        name="tipoproblema"
                                        onChange={this.handleChangeInput}
                                        value={formIncidencia && formIncidencia.tipoproblema ? formIncidencia.tipoproblema : ""}
                                        className={classes.selectEmpty}
                                    >
                                        <MenuItem value="">
                                            <em>* Tipo de problema</em>
                                        </MenuItem>
                                        {typeInciden &&
                                            typeInciden.map(q => {
                                                return (
                                                    <MenuItem value={q.id} key={q.id}>{q.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    fullWidth
                                    id="asunto"
                                    name="title"
                                    margin="normal"
                                    autoComplete="off"
                                    placeholder="* Asunto"
                                    value={formIncidencia && formIncidencia.title ? formIncidencia.title : ""}
                                    onChange={this.handleChangeInput}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl className={classes.formControlDatos}
                                    fullWidth
                                    margin="normal"
                                >
                                    <InputBase
                                        multiline
                                        fullWidth
                                        rowsMax="18"
                                        id="description"
                                        name="description"
                                        autoComplete="off"
                                        value={formIncidencia && formIncidencia.description ? formIncidencia.description : ""}
                                        placeholder="* Cuentanos cual es el problema..."
                                        onChange={this.handleChangeInput}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="action-inciden">
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={this.handleSubmitIncidencia}
                            disabled={submitInci && loading}
                        >
                            Enviar
                            {submitInci && loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                        </Button>

                        <ButtonBase
                            focusRipple
                            className={classes.uploadImg}
                        >
                            <input
                                type="file"
                                accept="image/*"
                                name="imagen"
                                className="cargarImage"
                                onChange={(e) => this.handleImageChange(e)}
                            />
                            <AttachFileIcon className="cursor-pointer" color="primary" />
                        </ButtonBase>

                        {formIncidencia && formIncidencia.image && formIncidencia.image.name}
                    </div>
                </div>
            </Fade>
        )
    }
}

FormCrearIncidencia.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { fadeInci, typeInciden, formIncidencia, submitInci, loading } = state.incidenciaReducer;
    return {
        user,
        fadeInci, typeInciden, formIncidencia, submitInci, loading
    };
}

const connectedFormCrearIncidencia = connect(mapStateToProps)(withStyles(styles)(FormCrearIncidencia));
export { connectedFormCrearIncidencia as FormCrearIncidencia };