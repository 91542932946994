import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import { menu } from "../../../../../_helpers";
import { apiCardio } from '../../../../../_constants';
import { menuActions, configActions } from '../../../../../_actions';

import { CssBaseline, Drawer, Hidden, Grid, Avatar, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../perfilStyle";

// Components

import { PerfilPublico } from '../perfilpublico/perfilPublico';

class BarLeftPerfil extends Component {
    handleDrawerToggle = () => {
        const { menuOpen } = this.props;
        const { dispatch } = this.props;
        dispatch(menuActions.isOpen(!menuOpen.isOpen));
    };

    handleSelectMenu = data => () => {
        this.props.dispatch(configActions.selectView(data));
    }

    handleCloseMenu = data => () => {
        const { dispatch } = this.props;
        dispatch(menuActions.menuActive(data));
        dispatch(menuActions.isOpen(false));
    }

    render() {
        const { classes, theme, user } = this.props;
        const { menuOpen } = this.props;
        const drawer = (
            <Grid container spacing={24} direction="column">
                <Grid item xs={12}>
                    <PerfilPublico />
                </Grid>
            </Grid>

        );
        const drawerMovil = (
            <div className={`contMenu`}>
                <div >
                    <Link to={'/epage/' + user.username}>
                        <Grid container className={classes.setFlex}>
                            <Avatar alt={user.name} src={`${apiCardio.urlMedia}${user.imgperfil}`} className={classes.smallAvatar} />

                            <Typography paragraph className={`${classes.nameuser} valign-wrappe`}>
                                {user.name} {user.surname}
                            </Typography>
                        </Grid>
                    </Link>
                </div>
                <List className="list-item-menu pt-0">
                    {menu.map(k => {
                        return (
                            <Link to={k.ruta} key={k.id} onClick={this.handleCloseMenu(k.id)}>
                                <ListItem button className={`fontMenu ${menuOpen.menuActivo === k.id ? 'active-menu' : ''}`} >
                                    <ListItemText primary={k.name} />
                                </ListItem>

                            </Link>
                        )
                    })
                    }
                </List>
            </div>

        );

        return (
            <div className={`barLeftPerfil`}>
                <CssBaseline />
                <div className={classes.drawer}>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={this.props.container}
                            variant="temporary"
                            anchor={theme.direction === "rtl" ? "right" : "left"}
                            open={menuOpen.isOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                        >
                            {drawerMovil}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>

                </div>
            </div>
        );
    }
}

BarLeftPerfil.propTypes = {
    classes: PropTypes.object.isRequired,
    // Injected by the documentation to work in an iframe.
    // You won't need it on your project.
    container: PropTypes.object,
    theme: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { menuOpen } = state;
    return {
        user,
        menuOpen
    };
}

const connectedBarLeftPerfil = connect(mapStateToProps)(withStyles(styles, { withTheme: true })(BarLeftPerfil));
export { connectedBarLeftPerfil as BarLeftPerfil };