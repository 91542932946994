import React from 'react'

/* MAterial UI */
import { Grid, Paper, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PaginacionTabla from "./paginacionTabla";

/* Estilos con Material UI */
import { styles } from "./incidenciasStyle";

const ListaIncidencia = (props) => (
    <div>
        {props.list.length 
            ?<div>
                <Grid container className="mb-06">
                    <Grid item className={props.classes.numItem}>
                        <Typography color="primary" component="span">Elementos por página:</Typography>

                    </Grid>
                    <Grid item >
                        <FormControl margin="dense" className={props.classes.formControlPage}>
                            <Select
                                displayEmpty
                                name="cantidadElementos"
                                onChange={props.handleChangeNumero}
                                value={props.cantidadElementos}
                                className={props.classes.selectEmpty}
                            >
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="10" >10</MenuItem>
                                <MenuItem value="25" >25</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <PaginacionTabla
                    itemsperpage={props.cantidadElementos}
                    nocolumns={4}
                    items={props.list}
                    pagesspan={4}
                    handleVerHilo={props.handleVerHilo}
                    selectReportesDelete={props.handleSelectReporteForDelete}
                />
            </div> 
            : <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Paper className="paper-bar-left">
                        <Typography color="primary" component="span">No posee registro de problemas</Typography>
                    </Paper>
                </Grid>
            </Grid>
        }
            
    </div>

)

export default withStyles(styles)(ListaIncidencia);