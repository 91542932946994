import { notifyConstants } from '../_constants';
import { notifyService } from "../_services";
import { alertActions } from './alert.action';

export const notifyActions = {
    getNotify,
    markAsSeenNotify,
};

function getNotify(user) {
    return dispatch => {
        notifyService.getNoificaciones(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail())
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function success(data) { return { type: notifyConstants.GET_NOTIFY, data } }
    function fail() { return { type: notifyConstants.GET_FAIL_NOTIFY } }
}

function markAsSeenNotify(user, type) {
    return dispatch => {
        dispatch(request())
        notifyService.putMarcarVistos(user, type)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail())
                        dispatch(alertActions.success("Disculpe, a ocurrido un error marcando como vistas las notificaciones."))
                    }
                }
            );
    };

    function request() { return { type: notifyConstants.REQUEST_NOTIFY } }
    function success(data) { return { type: notifyConstants.MARCAR_VISTO_SUCCES, data } }
    function fail() { return { type: notifyConstants.MARCAR_VISTO_FAIL } }
}