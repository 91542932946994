import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Material UI */
import { Grid, Paper, Typography, Divider } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../configuracionStyle";

/*Componnt */
import Honorarios from './honorarios'
import FormMontos from "./formMontos"
import Chips from '../../layout/chips'
import CuentaBancaria from './cuentaBancaria'
import MontoAsesoriaChat from "./montoAsesoriaChat"
import MontoInterConsulta from "./metodoInterconsulta"
import AlertDialog from "../../layout/alertDialogo";
import CuentaInternacional from './cuentaInternacional'

/* Actions */
import { formaPagoAction, alertConfirmActions } from '../../../../../_actions';

class FormaDePago extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submittedCuantaAsociada: false,
            validCampoMontos: false,
            expanded: null,
            open: false,
        };
        this.handleNumeric = this.handleNumeric.bind(this)
        this.handleSelectPago = this.handleSelectPago.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleDelateBanco = this.handleDelateBanco.bind(this)
        this.handleSubmitMonto = this.handleSubmitMonto.bind(this)
        this.handleSelectMoneda = this.handleSelectMoneda.bind(this)
        this.handleViewFormBanco = this.handleViewFormBanco.bind(this)
        this.handleMontoAsesoria = this.handleMontoAsesoria.bind(this)
        this.handleCuentaAsociada = this.handleCuentaAsociada.bind(this)
        this.handleSubmitMontoChat = this.handleSubmitMontoChat.bind(this)
        this.handleSubmitNuevoBanco = this.handleSubmitNuevoBanco.bind(this)
        this.handleSubmitCuentaAsociada = this.handleSubmitCuentaAsociada.bind(this)
        this.handleChangeInputNuevoBanco = this.handleChangeInputNuevoBanco.bind(this)

        // !this.props.chipMonedas.length && this.props.dispatch(formaPagoAction.getHonorarios(this.props.user))
        // !this.props.chipMetodoPago.length && this.props.dispatch(formaPagoAction.getMetodosDePago(this.props.user))
    }

    handleNumeric(e) {
        const key = window.event ? e.which : e.keyCode;
        if (key < 48 || key > 57) {
            e.preventDefault();
        }
    }

    handleSelectPago = data => () => {
        const { user, chipMetodoPago, dispatch } = this.props;
        dispatch(formaPagoAction.selectFormaDePago(data, user, chipMetodoPago))
    };

    handleChangeExpansion = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleEditBanco = data => () => {
        this.props.dispatch(formaPagoAction.viewFormBanco(!this.props.viewformbanco))
        this.props.dispatch(formaPagoAction.editBancoNacional(data))
    }
    handleViewFormBanco(e) {
        this.props.dispatch(formaPagoAction.viewFormBanco(!this.props.viewformbanco))
    }

    handleSelectDelateBanco = data => () => {
        const { dispatch } = this.props;
        dispatch(alertConfirmActions.openCloseAlert())
        dispatch(formaPagoAction.selectBancoDelete(data))
    }

    handleDelateBanco(){
        const { dispatch, user, chipMetodoPago, itemBanco } = this.props;
        dispatch(alertConfirmActions.sumitedRequest())
        dispatch(formaPagoAction.deleteBanco(itemBanco, user, chipMetodoPago))
    }
    handleCloseAlert(){
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    }

    handleChangeInputNuevoBanco(e) {
        const { name, value } = e.target;
        this.props.dispatch(formaPagoAction.addNewBancoForm(name, value))
    };

    handleSubmitNuevoBanco(e) {
        e.preventDefault();
        const { user, chipMetodoPago, dispatch, newbanco } = this.props;
        dispatch(formaPagoAction.submitRequestBanco())
        if (newbanco.bancos && newbanco.numCuenta && newbanco.cedulaCuenta && newbanco.correoCuenta) {
            dispatch(formaPagoAction.registrarBancoNacional(user, chipMetodoPago, newbanco))
        } else {
            dispatch(formaPagoAction.loaddingFail())
        }
    }

    handleCuentaAsociada(e) {
        const { name, value } = e.target;
        const { chipMetodoPago, dispatch } = this.props;
        dispatch(formaPagoAction.addCuentaAsociada(name, value, chipMetodoPago))
    }

    handleSubmitCuentaAsociada(e) {
        e.preventDefault();
        let valirdarCampo = false;
        // this.setState({ submittedCuantaAsociada: true });
        const { user, chipMetodoPago, dispatch } = this.props;
        dispatch(formaPagoAction.submitRequestBanco())
        chipMetodoPago.map(data => {
            if (data.selected === true && data.codg !== 'BANK') {
                if (data.datos.email) {
                    valirdarCampo = true
                } else {
                    valirdarCampo = false
                }
            }
            return ''
        })
        if (valirdarCampo) {
            dispatch(formaPagoAction.regisCuentaAsociada(user, chipMetodoPago))
        } else {
            dispatch(formaPagoAction.submitRequestBanco())
        }
    }

    handleSelectMoneda = data => () => {
        const { user, chipMonedas, dispatch } = this.props;
        dispatch(formaPagoAction.selectHonorario(data, user, chipMonedas))
    };

    handleMontoAsesoria(e) {
        const { name, value } = e.target;
        const { chipMonedas, dispatch } = this.props;
        dispatch(formaPagoAction.montoHonorario(name, value, chipMonedas, 2))
    }

    handleMontoAsesoriaChat = data => (e) => {
        const { name, value } = e.target;
        const { chipMonedas, dispatch } = this.props;
        dispatch(formaPagoAction.montoHonorario(name, value, chipMonedas, data))
    }

    // handleMontoConsulta(e) {
    //     const { name, value } = e.target;
    //     const { chipMonedas, dispatch } = this.props;
    //     dispatch(formaPagoAction.montoHonorario(name, value, chipMonedas, 1))
    // }

    handleSubmitMonto(e) {
        e.preventDefault();
        let validCampoMontos = false
        const { user, chipMonedas, submittedMonto, dispatch } = this.props;
        chipMonedas.map(data => {
            if (data.selected === true) {
                // if (data.montoAsesoria === "" || data.montoConsulta === "") {
                if (data.montoAsesoria === "") {
                    validCampoMontos = false
                    this.setState({ validCampoMontos: false });
                    return ''
                } else {
                    validCampoMontos = true
                    this.setState({ validCampoMontos: true });
                }
            }
            return ''
        })

        dispatch(formaPagoAction.submitRequestHonorario(!submittedMonto))
        if (validCampoMontos) {
            dispatch(formaPagoAction.registrarMontoHonorario(user, chipMonedas))
        }
    }

    handleSubmitMontoChat = data => (e) => {
        e.preventDefault();
        console.log(data)
        let validCampoMontos = false
        const { user, chipMonedas, submittedMontoChat, dispatch } = this.props;
        chipMonedas.map(k => {
            if (k.selected === true) {
                if (k[data] === "" || k[data] === 0 ) {
                    validCampoMontos = false
                    this.setState({ validCampoMontos: false });
                    return ''
                } else {
                    validCampoMontos = true
                    this.setState({ validCampoMontos: true });
                }
            }
            return ''
        })

        console.log(validCampoMontos)
        dispatch(formaPagoAction.submitRequestHonorarioChat(!submittedMontoChat))
        if (validCampoMontos) {
            dispatch(formaPagoAction.registrarMontoHonorarioChat(user, chipMonedas, data))
        }
    }

    handleDesactivarAsesoriaChat = data => (e) => {
        e.preventDefault();
        const { user, submittedMontoChat, dispatch } = this.props;
        //console.log(data)
        this.setState({ validCampoMontos: true });
        /* Verifica si la modalidad interConsultaEnvivoGratis o asesoriaTextoGratis es false */
        if (!this.props[data.variableReducer]) {
            dispatch(formaPagoAction.activarMontosAsesoriaTexto(user, data.variableReducer, data.typeQoute))
        }else{
            dispatch(formaPagoAction.submitRequestHonorarioChat(!submittedMontoChat))
            dispatch(formaPagoAction.desactivarAsesoriaChat(user, data.variableReducer, data.typeQoute))
        }
    }

    handleSelectModalidaCita = data => () => {
        this.props.dispatch(formaPagoAction.selectModalidaCita(this.props.user, data))
    } 

    render() {
        const { expanded } = this.state
        const { openConfirm, loadingConfirm, submitedConfrim } = this.props
        const { chipMetodoPago, newbanco, loadingPago, listBancos, submitedBanco, viewformbanco } = this.props
        const { chipMonedas, loading, submittedMonto, submittedMontoChat, chitMetodoCita, asesoriaTextoGratis, interConsultaEnvivoGratis, interConsultaTextoGratis } = this.props
        return(
            <Grid container spacing={24}>
                <Grid item xs={12} className="pb-0">
                    <Typography color="primary" component="span" >Honorario profesional</Typography>
                </Grid>
                <Grid item xs={12} >
                    <Paper className="paper">
                        <div className="title-paper">
                            <Typography color='primary' component="span" className="mb-06">
                                Selecciona la modalidad para tus servicios <span className="obligatorio">*</span>
                            </Typography>
                            <Divider />
                        </div>
                        <div className="mb-06">
                            <Typography color="textSecondary" className="color-gris mb-06" component="span">Consultas para pacientes</Typography>
                            <Chips
                                list={chitMetodoCita.filter(k => ((k.id === "2") || (k.id === "3")))}
                                handleSelect={this.handleSelectModalidaCita}
                            />
                        </div>
                        
                        <div className="">
                            <Typography color="textSecondary" className="color-gris mb-06" component="span">Interconsultas para especialistas</Typography>
                            <Chips
                                list={chitMetodoCita.filter(k => ((k.id === "4") || (k.id === "5")))}
                                handleSelect={this.handleSelectModalidaCita}
                            />
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} >
                    <Paper className="paper">
                        <div className="title-paper">
                            <Typography color='primary' component="span" className="mb-06">
                                ¿Cómo quieres recibir tu pagos? <span className="obligatorio">*</span>
                            </Typography>
                            <Divider />
                            <br />
                            <Typography color="textSecondary" className="color-gris" component="span" >El pago de tu honorario profesional se realizará a la cuenta registrada en un lapso menor de 48 horas luego de culminada la consulta o interconsulta en línea</Typography>
                        </div>
                        <div>
                            <Chips
                                list={chipMetodoPago}
                                handleSelect={this.handleSelectPago}
                            />
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} >
                    <CuentaBancaria 
                        expanded={expanded}
                        newbanco={newbanco}
                        loading={loadingPago}
                        listBancos={listBancos.filter(k => (k.banck === '232'))}
                        viewformbanco={viewformbanco}
                        listSeleccion={chipMetodoPago}
                        submittedMonto={submitedBanco}
                        handleEditBanco={this.handleEditBanco}
                        handleChangeSelect={this.handleChangeSelect}
                        handleViewFormBanco={this.handleViewFormBanco}
                        handleChangeExpansion={this.handleChangeExpansion}
                        handleSubmitNuevoBanco={this.handleSubmitNuevoBanco}
                        handleSelectDelateBanco={this.handleSelectDelateBanco}
                        handleChangeInputNuevoBanco={this.handleChangeInputNuevoBanco}
                    />
                </Grid>

                <Grid item xs={12} >
                    <CuentaInternacional
                        loading={loadingPago}
                        listSeleccion={chipMetodoPago}
                        handleCuentaAsociada={this.handleCuentaAsociada}
                        submittedCuantaAsociada={submitedBanco}
                        handleSubmitCuentaAsociada={this.handleSubmitCuentaAsociada}
                    />
                </Grid>

                <Grid item xs={12} >
                    <Honorarios
                        chipMonedas={chipMonedas}
                        handleSelectMoneda={this.handleSelectMoneda}
                    />
                </Grid>

                <Grid item xs={12} >
                    <FormMontos
                        list={chipMonedas}
                        loading={loading}
                        submittedMonto={submittedMonto}
                        handleNumeric={this.handleNumeric}
                        handleSubmitMonto={this.handleSubmitMonto}
                        handleMontoAsesoria={this.handleMontoAsesoria}
                        handleMontoConsulta={this.handleMontoConsulta}
                        modalidadVideo={chitMetodoCita.filter(k => (k.id === '2'))}
                    />
                </Grid>

                <Grid item xs={12} >
                    <MontoAsesoriaChat
                        list={chipMonedas}
                        loading={loading}
                        submittedMontoChat={submittedMontoChat}
                        asesoriaTextoGratis={asesoriaTextoGratis}
                        modalidadTexto={chitMetodoCita.filter(k => (k.id === '3'))}
                        handleNumeric={this.handleNumeric}
                        handleSubmitMontoChat={this.handleSubmitMontoChat}
                        handleMontoAsesoriaChat={this.handleMontoAsesoriaChat}
                        handleDesactivarAsesoriaChat={this.handleDesactivarAsesoriaChat}
                    />
                </Grid>

                <Grid item xs={12} >
                    <MontoInterConsulta
                        list={chipMonedas}
                        loading={loading}
                        typeInterconsulta={4}
                        submittedMontoChat={submittedMontoChat}
                        metodoInterconsulta='montoInterconsultaVivo'
                        interConsultaGratis={interConsultaEnvivoGratis}
                        modalidadTexto={chitMetodoCita.filter(k => (k.id === '4'))}
                        handleNumeric={this.handleNumeric}
                        handleSubmitMontoChat={this.handleSubmitMontoChat}
                        handleMontoAsesoriaChat={this.handleMontoAsesoriaChat}
                        handleDesactivarAsesoriaChat={this.handleDesactivarAsesoriaChat}
                    />
                </Grid>
                <Grid item xs={12} >
                    <MontoInterConsulta
                        list={chipMonedas}
                        loading={loading}
                        typeInterconsulta={5}
                        metodoInterconsulta='montoInterconsultaTexto'
                        submittedMontoChat={submittedMontoChat}
                        interConsultaGratis={interConsultaTextoGratis}
                        modalidadTexto={chitMetodoCita.filter(k => (k.id === '5'))}
                        handleNumeric={this.handleNumeric}
                        handleSubmitMontoChat={this.handleSubmitMontoChat}
                        handleMontoAsesoriaChat={this.handleMontoAsesoriaChat}
                        handleDesactivarAsesoriaChat={this.handleDesactivarAsesoriaChat}
                    />
                </Grid>

                <AlertDialog
                    open={openConfirm}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDelateBanco}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de eliminar el item seleccionado?'
                />
            </Grid>
        )
    }
}

FormaDePago.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { chipMonedas, loading, submittedMonto, submittedMontoChat, chitMetodoCita, asesoriaTextoGratis, interConsultaEnvivoGratis, interConsultaTextoGratis } = state.honorariosReducer;
    const { chipMetodoPago, loadingPago, itemBanco, submitedBanco, newbanco, viewformbanco } = state.metodosdePago;
    return {
        openConfirm, loadingConfirm, submitedConfrim,
        chipMonedas, loading, submittedMonto, submittedMontoChat, chitMetodoCita, asesoriaTextoGratis, interConsultaEnvivoGratis, interConsultaTextoGratis,
        chipMetodoPago, loadingPago, itemBanco, submitedBanco, newbanco, viewformbanco
    };
}

const connectedFormaDePago = connect(mapStateToProps)(withStyles(styles)(FormaDePago));
export { connectedFormaDePago as FormaDePago };