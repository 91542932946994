import React from "react";
import ReactNextPaging from "react-next-paging";

/* MAterial UI */
import { Grid, Paper, Typography, Avatar, IconButton, Button, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import EmailIcon from '@material-ui/icons/Email';
import DraftsIcon from '@material-ui/icons/Drafts';
import DoneIcon from '@material-ui/icons/Done';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

/* Estilos con Material UI */
import { styles } from "./incidenciasStyle";

const PaginacionTabla = ({ classes, itemsperpage, nocolumns, items, pagesspan, handleVerHilo, selectReportesDelete }) => {
    return (
        <ReactNextPaging
            itemsperpage={itemsperpage}
            nocolumns={nocolumns}
            items={items}
            pagesspan={pagesspan}
        >
            {({
                getBackButtonProps,
                getFastBackButtonProps,
                getFwdButtonProps,
                getFastFwdButtonProps,
                getSelPageButtonProps,
                nopages,
                inipagearray,
                pagesforarray,
                currentpage,
                noitems,
                initialitem,
                lastitem,
                goBackBdisabled,
                goFastBackBdisabled,
                goFwdBdisabled,
                goFastFwdBdisabled
            }) => (
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Paper className={`${classes.contList} paper-bar-left `}>
                                {items.slice(initialitem, lastitem).map((k, index) => {
                                    return (
                                        <Grid className={`${classes.borderBotton} cursor-pointer`} container key={k.id} >
                                            <Grid item xs={2} sm={1}>
                                                <Checkbox value={k.id} onChange={selectReportesDelete} />
                                            </Grid>
                                            <Grid item xs={3} sm={1} onClick={handleVerHilo(k)}>
                                                <Avatar className={classes.avatar}>
                                                    {k.viewed === '0' ? <DraftsIcon color="primary" /> : <EmailIcon color="primary" />}
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={12} sm={10} onClick={handleVerHilo(k)}>
                                                <Grid container>
                                                    <Grid item xs={9} className={classes.contTipoInciden}>
                                                        <Typography color="primary" component="span">{k.tipo}</Typography>
                                                        { k.status === '1' && <DoneIcon color="primary" className={classes.iconSuccesInciden}/> }
                                                        
                                                    </Grid>
                                                    <Grid item xs={3} align="right">
                                                        <Typography color="primary" className={classes.fechainci} component="span">{k.fecha}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="primary" component="span">{k.title}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography color="textSecondary" component="span" className="truncate">{k.description}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Grid item xs={12} align="center" className={classes.mt07}>
                                    {noitems > 0
                                        ? [
                                            <div key={"pagingrow" + 100}>
                                                <IconButton 
                                                    aria-label="Primero" 
                                                    className={classes.margin}
                                                    {...getFastBackButtonProps()}
                                                    disabled={goFastBackBdisabled}
                                                >
                                                    <FirstPageIcon />
                                                </IconButton>
                                                <IconButton 
                                                    aria-label="Primero" 
                                                    className={classes.margin}
                                                    {...getBackButtonProps()}
                                                    disabled={goBackBdisabled}
                                                >
                                                    <ChevronLeftIcon/>
                                                </IconButton>
                                                {Array.from(
                                                    { length: pagesforarray },
                                                    (v, i) => i + inipagearray
                                                ).map(page => {
                                                    return (
                                                        <Button 
                                                            key={page} 
                                                            variant="outlined" 
                                                            size="small"
                                                            color="primary"
                                                            className={classes.numberPagination}
                                                            {...getSelPageButtonProps({ page: page })}
                                                            disabled={currentpage === page}
                                                        >
                                                            {page}
                                                        </Button>
                                                    );
                                                })}
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFwdButtonProps()}
                                                    disabled={goFwdBdisabled}
                                                >
                                                    <ChevronRightIcon />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="Primero"
                                                    className={classes.margin}
                                                    {...getFastFwdButtonProps()}
                                                    disabled={goFastFwdBdisabled}
                                                >
                                                    <LastPageIcon />
                                                </IconButton>
                                            </div>
                                        ]
                                        : null}
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                )}
        </ReactNextPaging>
    );
};

export default withStyles(styles)(PaginacionTabla);