import React from 'react'

/* MAterial UI */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, TextField, Divider } from '@material-ui/core';

/* Estilos con Material UI */
import { styles } from "../configuracionStyle";

let viewMonto = false

const FormMontos = (props) => (
    <div>
        {
            props.list &&
            props.list.map(data => {
                if (data.selected) {
                    viewMonto = true;
                }
                return "";
            })
        }
        {viewMonto &&
            <Paper className="paper">
                <div className="title-paper">
                    <Typography color='primary' component="span" className="mb-06">
                        Coloca el precio de tu consultas vía videochat en vivo <span className="obligatorio">*</span>
                    </Typography>
                    <Divider />
                </div>
                {props.modalidadVideo[0]['selected'] 
                    ? <div>
                        <form className={props.classes.formMontos} onSubmit={props.handleSubmitMonto}>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Grid container spacing={24} >
                                        {props.list.map(data => {
                                            if (data.selected === true) {
                                                return (
                                                    <Grid item xs key={data.id}>
                                                        <TextField
                                                            fullWidth
                                                            disabled
                                                            id={`asesoriaVivo${data.id}`}
                                                            name={data.id}
                                                            margin="dense"
                                                            label={data.name}
                                                            autoComplete="off"
                                                            onKeyPress={props.handleNumeric}
                                                            defaultValue={data.montoAsesoria}
                                                            onChange={props.handleMontoAsesoria}
                                                            className={props.classes.textFieldPrecio}
                                                            error={props.submittedMonto && !data.montoAsesoria}
                                                            helperText={props.submittedMonto && !data.montoAsesoria &&
                                                                <span className="lblError" >Campo Requerido</span>
                                                            }
                                                        />
                                                    </Grid>
                                                );
                                            }
                                            return '';
                                        })}
                                    </Grid>
                                </Grid>
                                {/* <div className={props.classes.contPrecioCita}>
                                        <Typography color='secondary' component="p">Consulta</Typography>
                                        <div >
                                            {props.list.map(data => {
                                                if (data.selected === true) {
                                                    return (
                                                        <TextField
                                                            key={data.id}
                                                            name={data.id}
                                                            label={data.name}
                                                            autoComplete="off"
                                                            onKeyPress={props.handleNumeric}
                                                            defaultValue={data.montoConsulta}
                                                            onChange={props.handleMontoConsulta}
                                                            className={props.classes.textFieldPrecio}
                                                            error={props.submittedMonto && !data.montoConsulta}
                                                            helperText={props.submittedMonto && !data.montoConsulta &&
                                                                <span className="lblError" >Campo Requerido</span>
                                                            }
                                                            margin="normal"
                                                        />
                                                    );
                                                }
                                                return '';
                                            })}
                                        </div>
                                    </div> */}
                            </Grid>
                            {/* <div className="mt-1">
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={props.loading && props.submittedMonto}
                                >
                                    Registrar
                                    {(props.loading && props.submittedMonto) && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                            </div> */}
                        </form>
                    </div>
                    :<Typography color="textSecondary" className="color-gris" component="span" >
                        Para completar esta sección debe tener selecciona la modalidad {props.modalidadVideo[0]['name']}
                    </Typography>
                }
                
            </Paper>
        }
    </div>
)


export default withStyles(styles)(FormMontos);