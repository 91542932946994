import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#8678c1',
            main: '#6857B2',
            dark: '#6857B2',
            contrastText: '#fff',
        },
        secondary: {
            light: '#7a7a77',
            main: '#595955',
            dark: '#3e3e3b',
            contrastText: '#000',
        },
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInput: {
            // Name of the styleSheet
            underline: {
                '&:hover:not($disabled):before': {
                    borderBottom: '1px solid #6857B2 !important',
                    height: 1,
                },
            },
        },
    },
});