import { citasService } from "../../_services";
import { alertActions } from '../alert.action';
import { citasConstants } from '../../_constants';
import { alertConfirmActions } from '../alertConfirm.action';

export const citasChatActions = {
    getCitasViaChat,
    getYarnCitasChat,
    mostrasView,
    changeDescrition,
    insertYarnAsesoria,
    citaTextoAtendida,
};

function getCitasViaChat(user) {
    return dispatch => {
        dispatch(request())
        citasService.getCitasChat(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail())
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: citasConstants.GET_REQUEST_CITAS_CHAT } }
    function success(data) { return { type: citasConstants.GET_SUCCESS_CITAS_CHAT, data } }
    function fail() { return { type: citasConstants.GET_FAIL_CITA_CHAT } }
}

function getYarnCitasChat(user, citaChat) {
    return dispatch => {
        dispatch(request())
        citasService.getYarnCitasChat(user, citaChat.keyd)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        //dispatch(fail())
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: citasConstants.GET_REQUEST_CITAS_CHAT } }
    function success(data) { return { type: citasConstants.GET_SUCCESS_HILO_CITAS_CHAT, data } }
    // function fail() { return { type: citasConstants.GET_FAIL_CITA_CHAT } }
}
function mostrasView(data) {
    return { type: citasConstants.UPDATE_VIEW, data } 
}

function changeDescrition(data) {
    return { type: citasConstants.CHANGE_DESCRIPTION, data } 
}

function insertYarnAsesoria(user, descriptionYarn, keyd) {
    return dispatch => {
        dispatch(request())
        citasService.insertYarnAsesoriaChat(user, descriptionYarn, keyd)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data.yarn_quotes))
                    } else {
                        dispatch(request())
                        dispatch(alertActions.error("Ocurrio un error al registrar su respuesta."));
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: citasConstants.SUBMIT_REQUEST_YARN } }
    function success(data) { return { type: citasConstants.SUBMIT_SUCCESS_HILO_CITAS_CHAT, data } }
    // function fail() { return { type: citasConstants.GET_FAIL_CITA_CHAT } }
}

function citaTextoAtendida(user, data) {
    const cita = {
        cita: data.keyd
    }
    return dispatch => {
        citasService.updateStatusCita(user, cita, 5)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success("Se marco la consulta como atendida, ya puede crear la historia de dicha consulta."))
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            );
    }
    function success(data) { return { type: citasConstants.ASESORIA_TEXTO_ATENDIDA_SUCCES, data } }
}