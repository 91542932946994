export const styles = theme => ({
    flex: {
        flex: 1,
    },
    colorSecundario: {
        color: theme.palette.text.secondary,
    },

    expansionPanelSummary:{
        [theme.breakpoints.down("xs")]: {
            'padding': '0 7px 0 7px',
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '20%',
        flexShrink: 0,
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '60%',
        flexShrink: 0,
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    headingMonto: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '20%',
        flexShrink: 0,
        [theme.breakpoints.down("xs")]: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    iconEvent: {
        float: 'left',
        marginTop: -2,
        marginRight: 6
    },
    error: {
        color: '#d32f2f',
    },
    succes: {
        color: '#43a047'
    },
    headCitasPagadas:{
        'display': 'flex',
        'padding': '12px 26px',
        [theme.breakpoints.down("xs")]: {
            'padding': '12px 7px',
        },
    },
    noCitasPagadas:{
        'padding': 12,
        'textAlign': 'center',
        'marginTop': 10,
    },
    contImgComprobante:{
        'width': '100%',
        'height': 'auto',
    },
    imgComprobante:{
        'width': '100%',
        'height': '100%',
    },
    contExpand:{
        'width': '100%',
    },
});