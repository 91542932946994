import { alertConstants } from '../_constants';

const initialState = {
    openConfirm: false,
    loadingConfirm: false,
    submitedConfrim: false
};

export function alertConfirm(state = initialState, action) {
    switch (action.type) {
        case alertConstants.OPEN_CLOSE_CONFIRM:
            return Object.assign({}, state, {
                openConfirm: !state.openConfirm
            });
        case alertConstants.CLOSE_CONFIRM:
            return Object.assign({}, state, {
                openConfirm: false,
                loadingConfirm: false,
                submitedConfrim: false
            });
        case alertConstants.SUMITED_REQUEST:
            return Object.assign({}, state, {
                loadingConfirm: !state.loadingConfirm,
                submitedConfrim: !state.submitedConfrim,
            });
        case alertConstants.SUMITED_SUCCES:
            return Object.assign({}, state, {
                openConfirm: !state.openConfirm,
                loadingConfirm: !state.loadingConfirm,
                submitedConfrim: !state.submitedConfrim,
            });
        default:
            return state
    }
}