import React from 'react'
import { apiCardio } from '../../../../../_constants';

/* MAterial UI */
import { Grid, Typography, Avatar, ButtonBase, Button, IconButton, TextField, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "../perfilStyle";

/* Iconos */
import PlusIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

const BarActionMovil = (props) => (
    <div className="cont-bar-action">
        <div className="img-and-btn-follow">
            <div className="cont-img-perfil">
                {props.foto_perfil
                    ? <Avatar alt="Especialista" src={`${props.foto_perfil}`} className={props.classes.bigAvatar} />
                    : <ButtonBase
                        focusRipple
                        disabled={props.loading}
                        className={props.classes.image}
                        focusVisibleClassName={props.classes.focusVisible}
                    >
                        <input type="file" name="imagen" accept="image/*" className={props.classes.uploadAvatar} onChange={props.handleFileSelected} />
                        <Avatar className={props.classes.avatarPlusIcon}>
                            {!props.loading && <PlusIcon color="primary" />}
                            {props.loading && <Typography className={props.classes.porcentaje} component="span">{props.progresUpload}%</Typography>}
                            {props.loading && <CircularProgress size={32} className="ProgressAbsolut" variant="static" value={props.progresUpload} />}
                        </Avatar>
                        <Avatar alt={props.user.name} src={`${apiCardio.urlMedia}${props.user.imgperfil}`} className={props.classes.bigAvatar} />
                    </ButtonBase>
                }
            </div>
        </div>
        <Grid container spacing={24} className={`${props.classes.prl_1} pb-1`}>
            <Grid item xs={12}>
                <Typography conponent="span" className="name-user" color="primary">{props.nameEspecialista}</Typography>
                <Typography conponent="span" color="primary">En eCARDIOLINE desde {props.fech_regis}</Typography>
            </Grid>
            <Grid item xs={12} className="pt-0">
                { !props.viewEditExtracto 
                    ?<Grid container>
                        <Grid item xs={props.visitor ? 12 : 10}>
                            <div className={`extracto ${props.leermas ? props.classes.verLeerMas : ''} `}>
                                {!props.extracto
                                    ? <Typography conponent="span" color="textSecondary">Escribe algo sobre ti.</Typography>
                                    : <Typography conponent="span" color="textSecondary" dangerouslySetInnerHTML={{ __html: props.extracto }}></Typography>
                                }
                            </div>
                            <Typography conponent="span" color="primary" className="cursor-pointer" onClick={props.handleVermas}>
                                {props.leermas ? 'Leer menos.' : 'Leer más.'}
                            </Typography>
                        </Grid>
                        {props.visitor
                            ? null
                            : <Grid item xs={2} align="center">
                                <IconButton aria-label="Edit" onClick={props.handleViewEditExtracto}>
                                    <EditIcon color="primary" />
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                    :<Grid container>
                        <Grid item xs={12}>
                            <TextField
                                    multiline
                                    fullWidth
                                    rows="3"
                                    rowsMax="8"
                                    id="extracto"
                                    margin="dense"
                                    name="extracto"
                                    value={props.extracto}
                                    placeholder="Queremos saber mas de ti."
                                    onChange={props.handleChangeInput}
                                    error={props.submitted && !props.extracto}
                                    helperText={props.submitted && !props.extracto &&
                                        <span className="lblError" >Campo Requerido</span>
                                    }
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="pt-1 mb-06">
                                <Button variant="contained" className="mr_1" onClick={props.handleViewEditExtracto}>
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick={props.handleOnSubmitExtracto}
                                    disabled={props.submitted && props.loadingExtracto}
                                >
                                    Actualizar
                                    {props.submitted && props.loadingExtracto && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item xs={4} className="cursor-pointer br-top br-right" align="center" onClick={props.handleMostrarView(1)}>
                <Typography conponent="span" color="primary">Publicaciones</Typography>
                <Typography conponent="span" color="primary" className="ft-bold sifras">{props.post}</Typography>
            </Grid>
            <Grid item xs={4} className="cursor-pointer br-top br-top br-right" align="center" onClick={props.handleMostrarView(2)}>
                <Typography conponent="span" color="primary">Seguidores</Typography>
                <Typography conponent="span" color="primary" className="ft-bold sifras">{props.seguidores}</Typography>
            </Grid>
            <Grid item xs={4} className="cursor-pointer br-top " align="center" onClick={props.handleMostrarView(3)}>
                <Typography conponent="span" color="primary">Siguiendo</Typography>
                <Typography conponent="span" color="primary" className="ft-bold sifras">{props.siguiendo}</Typography>
            </Grid>
            <Grid item xs={6} className="cursor-pointer br-top br-right" align="center" onClick={props.handleMostrarView(5)}>
                <Typography conponent="span" color="primary">Contactos</Typography>
                <Typography conponent="span" color="primary" className="ft-bold sifras">{props.cantContacto}</Typography>
            </Grid>
            <Grid item xs={6} className="cursor-pointer br-top" align="center" onClick={props.handleMostrarView(4)}>
                <Typography conponent="span" color="primary">Recomendaciones</Typography>
                <Typography conponent="span" color="primary" className="ft-bold sifras">{props.recomendaciones}</Typography>
            </Grid>
            {props.visitor
                ? ''
                :<Grid item xs={12} className="cursor-pointer br-top br-top br-right" align="center" onClick={props.handleMostrarView(6)}>
                    <Typography conponent="span" color="primary" className="ft-bold ">Editar mis datos profesionales</Typography>
                </Grid>
            }
            {props.visitor
                ? <Grid item xs={6} className="cursor-pointer br-right br-top" align="center">
                    <Button
                        variant="contained"
                        color={props.seguidor ? 'primary' : null}
                        onClick={props.hlandleSeguirEspecialista(props.seguidor)}
                        className={!props.seguidor ? props.classes.seguir : null}
                    >
                        {props.seguidor ? 'Siguiendo' : 'Seguir'}
                    </Button>
                </Grid>
                : null
            }
            {props.visitor
                ? <Grid item xs={6} className="cursor-pointer br-top" align="center">
                    {props.contacto.type_contact 
                        ? props.contacto.contacto === '2'
                            ? <Typography component="span" color="primary">Enviaste una solicitud para conectar</Typography>
                            : <Button
                                variant="contained"
                                color={props.contacto.contacto ? 'primary' : null}
                                onClick={props.handleSelectnickNameSolicitud(props.contacto.contacto)}
                                className={!props.contacto.contacto ? props.classes.seguir : null}
                            >
                                {props.contacto.contacto ? 'Contacto' : 'Conectar'}
                            </Button>
                        : props.contacto.contacto === '2'
                            ? <Button
                                variant="contained"
                                color={props.contacto.contacto ? 'primary' : null}
                                onClick={props.handleAceptarSolicitud}
                                className={!props.contacto.contacto ? props.classes.seguir : null}
                            >
                                Aceptar solicitud
                            </Button>
                            : <Button
                                variant="contained"
                                color={props.contacto.contacto ? 'primary' : null}
                                onClick={props.handleSelectnickNameSolicitud(props.contacto.contacto)}
                                className={!props.contacto.contacto ? props.classes.seguir : null}
                            >
                                {props.contacto.contacto ? 'Contacto' : 'Conectar'}
                            </Button>
                    }
                    
                </Grid>
                : null
            }
            
        </Grid>
    </div>
)


export default withStyles(styles)(BarActionMovil);