import { apiCardio } from '../_constants';

export const citasService = {
    getCitas,
    getCitasDashboard,
    updateStatusCita,

    //eConsultorio
    getCitasConsultorio,

    //Citas PAgadas
    getCitasPagadas,

    //Citas Chat
    getCitasChat,
    getYarnCitasChat,
    insertYarnAsesoriaChat,
};

function getCitas(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlCitas}getFechasCitasEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getCitasDashboard(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlCitas}getQuotesHomeMedico`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function updateStatusCita(user, cita, estado) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ cita, estado })
    };

    return fetch(`${apiCardio.urlCitas}updateStatusQoutes`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

//eConsultorio
function getCitasConsultorio(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlCitas}getQuotesSalaEsperaPaciente`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getCitasPagadas(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlCitas}getCitasPagadasEspecialista`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

//Citas Chat
function getCitasChat(user) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    return fetch(`${apiCardio.urlCitas}getQuotesChat`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function getYarnCitasChat(user, keyd) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
    };

    let url = new URL(`${apiCardio.urlCitas}getYarnQuotesChat`),
        params = { keyd }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function insertYarnAsesoriaChat(user, descripcion_hilo, asesoria) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'token': user.token },
        body: JSON.stringify({ descripcion_hilo, asesoria })
    };

    return fetch(`${apiCardio.urlCitas}regisHiloCitaChat`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                //  location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}