import { alertConstants } from '../_constants';

export function alert(state = {}, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                type: 'alert-success',
                open: true,
                classe: 'notify success',
                message: action.message
            };
        case alertConstants.ERROR:
            return {
                type: 'alert-danger',
                open: true,
                classe: 'notify error',
                message: action.message
            };
        case alertConstants.INFO:
            return {
                type: 'alert-success',
                open: true,
                classe: 'notify info',
                message: action.message
            };
        case alertConstants.CLEAR:
            return {
                open: true
            };
        default:
            return state
    }
}