import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import { MenuEspecialistas } from '../../layout/MenuEspecialistas';

/*Component */
import CardMedicos from "./cardMedicos";
import BuscarEspecialistas from "./buscarEspecialistas";
import ProgressCircular from '../../layout/progress/progressCircular';

/* Material UI */
import '../index.css';
import { styles } from "../interConsultaStyle";
import { withStyles } from "@material-ui/core/styles";
//import { Fade } from '@material-ui/core';

/* Actions */
import { interConsultaActions, alertConfirmActions } from '../../../../../_actions'

class HomeInterConsulta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            select: '',
            inputSearch: ''
        };
        const { user, dispatch } = this.props;
        dispatch(alertConfirmActions.closeAlert())
        dispatch(interConsultaActions.crearInfo())
        dispatch(interConsultaActions.getMedInterconsultas(user));
        dispatch(interConsultaActions.getEspecialidadesInterconsultas(user));
    }

    handleChangeSearch = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value,
        });
    };

    handleSearchEspecialista = e => {
        e.preventDefault();
        if (this.state.select !== '') {
            const array = {
                "tipoCita": 2,
                "text": this.state.inputSearch,
                "especialidad" : this.state.select,
            }
            const { user, dispatch } = this.props;
            dispatch(interConsultaActions.searchEspecialista(user, array));
        }
    }

    render() {
        const { select, inputSearch  } = this.state
        const { medicos, especialidades, loadingInter, preloaderInter } = this.props
        const delete_card = (medicos.length < 4) ? '' : (medicos.length > 4) 
            ? (medicos.length % 2 === 0) ? 'delete-two-card' : 'delete-one-card'
            : 'delete-one-card' 
        return(
            <section className="body" ref={this.container}>
                {/* <Header titlePage='Inicio' /> */}
                <MenuEspecialistas />

                <div className="content sin-bar">
                    {preloaderInter
                        ? <ProgressCircular />
                        : <div>
                            <BuscarEspecialistas
                                select={select}
                                inputSearch={inputSearch}
                                loadingInter={loadingInter}
                                especialidades={especialidades}
                                handleChangeSearch={this.handleChangeSearch}
                                handleSearchEspecialista={this.handleSearchEspecialista}
                            />
                            <CardMedicos 
                                delete_card={delete_card}
                                medicos={medicos}
                            />
                        </div>
                    }
                </div>
            </section>
        )
    }
}

HomeInterConsulta.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { medicos, especialidades, loadingInter, preloaderInter  } = state.interConsultaReducer;
    return {
        user,
        medicos, especialidades, loadingInter, preloaderInter
    };
}

const connectedHomeInterConsulta = connect(mapStateToProps)(withStyles(styles)(HomeInterConsulta));
export { connectedHomeInterConsulta as HomeInterConsulta };