import React from 'react'
import { apiCardio } from '../../../../../_constants';

/* MAterial UI */
import { Typography, Tooltip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

/* Component */


/* Estilos con Material UI */
import { styles } from "../perfilStyle";


const ContImgPortada = (props) => (
    <div className="cont-foto-portada">
        {!props.img_portada
            ? <div className={props.classes.imagePortada}>
                <input accept="image/*" className={props.classes.uploadPortada} id="icon-button-file" type="file" onChange={props.handleFilePortadaSelected} />
                <label htmlFor="icon-button-file">
                    <Tooltip title="Dimensiones recomendadas 1200x300 " aria-label="Add">
                        <IconButton className={props.classes.buttonPortada} component="span">
                            <AddAPhotoIcon />
                            <Typography component="span" className={props.classes.actualizarPortada}> Actualizar foto de portada</Typography>
                        </IconButton>
                    </Tooltip>
                </label>
            </div>
            : null
        }
        
        <div className="img-portada">
            {props.img_portada
                ? <img src={`${props.img_portada}`} alt={`Portada de ${props.user.name}`} />
                : <img src={`${apiCardio.urlMedia}${props.user.imgportada}`} alt={`Portada de ${props.user.name}`} />
            }
            
        </div>
    </div>
)


export default withStyles(styles)(ContImgPortada);