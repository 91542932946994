import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { menuOpen } from './menu.reducer';
import { ePage } from './perfil/ePage.reducer'
import { notifyReducer } from './notiify.reducer';
import { postReducer } from './post/post.reducer';
import { citasReducer } from './citas/citas.reducer';
import { alertConfirm } from './alertConfirm.reducer';
import { historia } from './historias/historia.reducer';
import { directReducer } from './direct/direct.reducer';
import { modalEPage } from './perfil/modalEPage.reducer';
import { authentication } from './authentication.reducer';
import { citasChatReducer } from './citas/citasChat.reducer';
import { horarioReducer } from './configuracion/horario.reducer';
import { metodosdePago } from './configuracion/metodoPago.reducer';
import { incidenciaReducer } from './incidencias/incidencia.reducer';
import { configReducer } from './configuracion/configuracion.reducer';
import { honorariosReducer } from './configuracion/honorarios.reducer';
import { requisitosReducer } from './configuracion/requisitos.reducer';
import { especialidadesReducer } from './configuracion/especilidades.reducer';
import { interConsultaReducer } from './interconsultas/interconsultas.reducer';


const rootReducer = combineReducers({
    alert,
    ePage,
    menuOpen,
    historia,
    modalEPage,
    postReducer,
    alertConfirm,
    citasReducer,
    directReducer,
    metodosdePago,
    notifyReducer,
    configReducer,
    authentication,
    horarioReducer,
    citasChatReducer,
    honorariosReducer,
    requisitosReducer,
    incidenciaReducer,
    especialidadesReducer,
    interConsultaReducer,
});

export default rootReducer;