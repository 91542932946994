import React from 'react'

/* MAterial UI */
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button, TextField, CircularProgress, Divider, Grid } from '@material-ui/core';


/* Estilos con Material UI */
import { styles } from "../configuracionStyle";
let viewCuenta = false ;

const CuentaInternacional = (props) => (
    <div>
        {props.listSeleccion && 
            props.listSeleccion.map( data => {
                if (data.selected === true && data.codg !== 'BANK') {
                     viewCuenta = true;
                }
                return "";
            })
        }
        {viewCuenta && 
            <Paper className="paper">
                <div className="title-paper">
                    <Typography color='primary' component="span" className="mb-06">
                        Correos asociados a cuentas digitales <span className="obligatorio">*</span>
                    </Typography>
                    <Divider/>
                </div>
                <div>
                    <form className={props.classes.formaPago} onSubmit={props.handleSubmitCuentaAsociada}>
                        {props.listSeleccion.map(data => {
                            if (data.selected === true && data.codg !== 'BANK') {
                                let textLabel = `Correo afiliado a tu cuenta ${data.name}`
                                textLabel = data.codg === 'Biz' ? `Número de móvil asociado a  ${data.name}`: textLabel
                                const sm = (data.codg === 'Biz' || data.codg === 'ZELLE') ? 6 : 12
                                return (
                                    <Grid container spacing={16} key={data.id}>
                                        <Grid item xs={12} sm={sm}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                id={data.id}
                                                type={`${data.codg === 'Biz' ? 'text' : 'email'}`}
                                                name={data.id}
                                                autoComplete="off"
                                                defaultValue={data.datos.email}
                                                onChange={props.handleCuentaAsociada}
                                                label={`${textLabel}`}
                                                // className={`${props.classes.mt_0} ${props.classes.textFieldPagos} ${(data.codg === 'ZELLE') || (data.codg === 'Biz') ? props.classes.txtZelle : null}`}
                                                error={props.submittedCuantaAsociada && !data.datos.email}
                                                helperText={props.submittedCuantaAsociada && !data.datos.email &&
                                                    <span className="lblError" >Campo Requerido</span>
                                                }
                                            />
                                        </Grid>
                                        {data.codg === 'ZELLE'
                                            && <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    margin="dense"
                                                    id={`${data.name}${data.id}`}
                                                    type="text"
                                                    name={data.codg}
                                                    autoComplete="off"
                                                    defaultValue={data.datos.titular}
                                                    onChange={props.handleCuentaAsociada}
                                                    label={`Titular de la cuenta ${data.name}`}
                                                    // className={`${props.classes.mt_0} ${props.classes.textFieldPagos} ${props.classes.txtZelle}`}
                                                    error={props.submittedCuantaAsociada && !data.datos.titular}
                                                    helperText={props.submittedCuantaAsociada && !data.datos.titular &&
                                                        <span className="lblError" >Campo Requerido</span>
                                                    }
                                                />
                                            </Grid>
                                        }
                                        {data.codg === 'Biz'
                                            && <Grid item xs={12} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    margin="dense"
                                                    id={`${data.name}${data.id}`}
                                                    type="text"
                                                    name={data.codg}
                                                    autoComplete="off"
                                                    defaultValue={data.datos.titular}
                                                    onChange={props.handleCuentaAsociada}
                                                    label={`Nombre del titular ${data.name}`}
                                                    // className={`${props.classes.mt_0} ${props.classes.textFieldPagos} ${props.classes.txtZelle}`}
                                                    error={props.submittedCuantaAsociada && !data.datos.titular}
                                                    helperText={props.submittedCuantaAsociada && !data.datos.titular &&
                                                        <span className="lblError" >Campo Requerido</span>
                                                    }
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    
                                )
                            }
                            return ''
                        })
                        }
                        <div className="mt-1">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={props.submittedCuantaAsociada && props.loading}
                            >
                                Registrar
                                    {props.submittedCuantaAsociada && props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                            </Button>
                        </div>
                    </form>
                </div>
                {/* <div className='cardDatosPersonales'>
                    <div className='info-cuenta'>
                        <Typography className={props.classes.p1} component="h3">
                            Correos asociados a cuentas digitales
                        </Typography>
                        <Divider variant="middle" />

                        <form className={props.classes.formaPago} onSubmit={props.handleSubmitCuentaAsociada}>
                            {props.listSeleccion.map(data => {
                                    if (data.selected === true && data.codg !== 'BANK') {
                                        return (
                                            <div key={data.id}>
                                                <TextField
                                                    margin="normal"
                                                    id={data.id}
                                                    type="email"
                                                    name={data.id}
                                                    autoComplete="off"
                                                    defaultValue={data.datos.email}
                                                    onChange={props.handleCuentaAsociada}
                                                    label={`Correo afiliado a tu cuenta ${data.name}`}
                                                    className={`${props.classes.mt_0} ${props.classes.textFieldPagos}`}
                                                    error={props.submittedCuantaAsociada && !data.datos.email}
                                                    helperText={props.submittedCuantaAsociada && !data.datos.email &&
                                                        <span className="lblError" >Campo Requerido</span>
                                                    }
                                                />
                                            </div>
                                        )
                                    }
                                    return ''
                                })
                            }
                            <div className={props.classes.mt_1}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={props.submittedCuantaAsociada && props.loading}
                                >
                                    Registrar
                                {props.submittedCuantaAsociada && props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div> */}
            </Paper>
        }
    </div>

)


export default withStyles(styles)(CuentaInternacional);