import React from 'react'

/* MAterial UI */
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import DoneIcon from '@material-ui/icons/Done';

const styles = theme => ({

    chip: {
        margin: '5px'
        // margin: theme.spacing.unit / 2,
    },
    listMonedas: {
        maxHeight: 200,
        width: '100%',
        overflowY: 'auto',
    },
});

const Chips = (props) => (
    <div className={`${props.classes.listMonedas} cont-info`}>
        {props.list &&
            props.list.map(data => {
                let colorchip = "default";
                let icon = null;
                if (data.selected === true) {
                    colorchip = "primary";
                    icon = <DoneIcon />;
                }
                return (
                    <Chip
                        icon={icon}
                        key={data.id ? data.id : data.keyd}
                        label={data.name}
                        clickable
                        className={props.classes.chip}
                        color={colorchip}
                        onClick={props.handleSelect && props.handleSelect(data)}
                    />
                );
            }
        )}
    </div>
)


export default withStyles(styles)(Chips);