import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
/* Material UI */
import { styles } from "../perfilStyle";
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, CircularProgress, Paper, Avatar, Button } from '@material-ui/core';

/* Actions */
import { apiCardio } from '../../../../../_constants';
import { ePageActions, alertConfirmActions, directAction } from '../../../../../_actions'

/* Component */
import AlertDialog from "../../layout/alertDialogo";
import {DialogMessageDirect} from "./dialogMessageDirect";

/* Url's */
// import { apiCardio } from '../../../../_constants';


class Contactos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openAlertContacto: 0,
            nick: '',
        }
        const { dispatch, nickName, user } = this.props
        dispatch(ePageActions.getContactos( nickName ? nickName : user.username, user))
    }

    handleAceptarSolicitud = data => () =>{
        const { dispatch, user } = this.props;
        dispatch(ePageActions.aceptarSolicitudContacto(user, data.nickname))
    }

    /*Eliminar contacto o eliminar solicitud */
    handleCloseAlert = () => {
        this.setState({
            openAlertContacto: 0
        })
    };
    
    handleSelectnickNameSolicitud = data => () => {
        const { dispatch } = this.props;
        this.setState({
            openAlertContacto: data
        })
        dispatch(alertConfirmActions.openCloseAlert())
    }

    handleEliminarContacto = () => {
        const { openAlertContacto } = this.state;
        const { dispatch, user } = this.props;
        dispatch(alertConfirmActions.sumitedRequest())
        dispatch(ePageActions.eliminarEspecialistaConectado(user, openAlertContacto.nickname, openAlertContacto.type_contact))
    }
    /* Eliminar contacto o eliminar solicitud*/

    handleOpenAndClosedModalMessageDirect = data => (event) => {
        event.preventDefault();
        const { dispatch, user } = this.props
        if (data !== 0) {
            const array = {
                type: 2,
                img: data.img,
                keyd: data.keyd,
                nickname: data.nickname,
                nombre: data.nom_contacto
            }
            dispatch(directAction.selectParticipanteMessage(array, user))
        }else{
            
        }
        dispatch(directAction.openDialogDirect())
        
    }

    render() {
        const { openAlertContacto } = this.state
        const { loadingConfirm, submitedConfrim } = this.props;
        const { classes, user, loading, listContact, visitor} = this.props;
        return (loading)
            ? <Grid container spacing={24}>
                <Grid item xs={12} align="center">
                    <div className="cont-progress-comentary"><CircularProgress size={40} className={`ProgressAbsolut`} /></div>
                </Grid>
            </Grid>
            : (
                <Grid container spacing={16}>
                    {listContact.length
                        ? listContact.map(k => {
                            return (
                                <Grid item xs={12} sm={6} key={k.keyd}>
                                    <Paper className="paper paperSeguidores">
                                        <Link to={'/epage/' + k.nickname}>
                                            <div className="cont-info-header">
                                                    <Avatar alt={k.nom_contacto} src={`${apiCardio.urlMedia}${k.img}`} className={classes.avatarSeguidor} />
                                                    <div className="cont-text">
                                                        <Typography component="p">{k.nom_contacto}</Typography>
                                                        <Typography component="span">{k.type_user}</Typography>
                                                        <Typography component="p" className="truncate">{k.extracto}</Typography>
                                                    </div>
                                            </div>
                                        </Link>

                                        <div className="cont-action">
                                            <div>
                                                {k.nickname === user.username && 
                                                    <Typography component="p">{k.accepted === '1' ? 'Tu y el especialista ' + k.nom_contacto + ' son contactos' : k.nom_contacto + ' aun no responde tu solicitud'}  </Typography>
                                                }
                                                {!visitor && k.accepted === '1' && 
                                                    <Button
                                                        variant="contained"
                                                        onClick={this.handleOpenAndClosedModalMessageDirect(k)}
                                                        className={`mr_1 ${classes.seguirTow}`}
                                                    >
                                                        Mensaje
                                                    </Button>
                                                }
                                                {!visitor && k.accepted === '1' &&
                                                    <Button
                                                            variant="contained"
                                                            onClick={this.handleSelectnickNameSolicitud(k)}
                                                            className={`mr_1 ${classes.seguirTow}`}
                                                        >
                                                            Eliminar
                                                        </Button>
                                                }
                                                {!visitor && !k.type_contact && k.accepted === '2' &&
                                                    <Button
                                                        variant="contained"
                                                        onClick={this.handleAceptarSolicitud(k)}
                                                        className={`mr_1 ${classes.seguirTow}`}
                                                    >
                                                        Aceptar Solicitud
                                                    </Button>
                                                }

                                                {!visitor && k.accepted === '2' &&
                                                    <Button
                                                        variant="contained"
                                                        onClick={this.handleSelectnickNameSolicitud(k)}
                                                        className={classes.seguirTow}
                                                    >
                                                        Eliminar Solicitud
                                                    </Button>
                                                }
                                            </div>
                                            
                                            <AlertDialog
                                                open={(k.keyd === openAlertContacto.keyd) ? true : false }
                                                loading={loadingConfirm}
                                                submitted={submitedConfrim}
                                                procesar={this.handleEliminarContacto}
                                                handleClose={this.handleCloseAlert}
                                                message={
                                                    k.accepted === '1' 
                                                        ? 'Esta seguro/a de eliminar de sus contactos al especialista ' + k.nom_contacto
                                                        : k.type_contact 
                                                            ? 'Esta seguro/a de eliminar la solicitud para conectar con ' + k.nom_contacto
                                                            : 'Esta seguro/a de eliminar la solicitud que te envio ' + k.nom_contacto
                                                    
                                                }
                                            />
                                        </div>
                                    </Paper>
                                </Grid>
                            )
                        })
                        : <Grid item xs={12}>
                            <Paper className="paper post">
                                <Typography>No se encontraron resultados.</Typography>
                            </Paper>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <DialogMessageDirect
                            closeDialog={this.handleOpenAndClosedModalMessageDirect}
                        /> 

                    </Grid>
                </Grid>
            );
    }
}

Contactos.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { loading, nickName, listContact, visitor,  } = state.ePage;
    return {
        user,
        loading, nickName, listContact, visitor, 
    };
}

const connectedContactos = connect(mapStateToProps)(withStyles(styles)(Contactos));
export { connectedContactos as Contactos };