import { alertActions } from '../alert.action';
import { incidenciaService } from "../../_services";
import { incidenciaConstants } from '../../_constants';
import { alertConfirmActions } from '../alertConfirm.action';

export const incidenciaActions = {
    getIncidencias,
    submit,
    mostrasView,
    mostrarSlide,
    detalleIncidencia,
    formCrearIncidencia,
    registrarIncidencia,
    cambiarCantidadElementos,
    formCrearHiloIncidencia,
    regisHiloInciden,
    reporteVisto,
    selectIncidenForDelete,
    deSelectIncidenForDelete,
    deleteIncidens,
};

function getIncidencias(user) {
    return dispatch => {
        dispatch(request(true))
        incidenciaService.getIncidencia(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail(data.data))
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
        incidenciaService.getTypeProblem(user)
            .then(
                data => {
                    dispatch(successTypeProblem(data.data))
                }
            )
    };

    function request(data) { return { type: incidenciaConstants.REQUEST_INCIDENCIAS, data } }
    function success(data) { return { type: incidenciaConstants.GET_INCIDENCIAS, data } }
    function fail(data) { return { type: incidenciaConstants.FAIL_INCIDENCIAS, data } }
    function successTypeProblem(data) { return { type: incidenciaConstants.GET_TYPE_INCIDEN, data } }
}

function submit() {
    return { type: incidenciaConstants.SUBMIT_FORM };
}
function mostrasView(data) {
    return { type: incidenciaConstants.CAMBIAR_VIEW, data };
}

function mostrarSlide() {
    return { type: incidenciaConstants.FADE_NEW_INCIDEN };
}

function detalleIncidencia(data) {
    return dispatch => {
        dispatch(request(data))
        dispatch(success('detalle'))
    };
    function request(data) { return { type: incidenciaConstants.MOSTRAR_HILO_INCIDENCIA, data } }
    function success(data) { return { type: incidenciaConstants.CAMBIAR_VIEW, data } }
}

function formCrearIncidencia(name, value) {
    const datos = { name, value }
    return dispatch => {
        dispatch(success(datos))
    };
    function success(data) { return { type: incidenciaConstants.FORM_NUEVA_INCIDENCIA, data } }
}

function registrarIncidencia(user, datos) {
    return dispatch => {
        dispatch(request())
        incidenciaService.registrarIncidecia(user, datos)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success(data.message))
                    }else{
                        dispatch(alertActions.error(data.message))
                    }
                    // dispatch(successTypeProblem(data.data))
                }
            )
        // dispatch(success('detalle'))
    };
    function request() { return { type: incidenciaConstants.SUBMIT_REQUEST } }
    function success(data) { return { type: incidenciaConstants.SUBMIT_SUCCES, data } }
}

function cambiarCantidadElementos(data) {
    return { type: incidenciaConstants.CAMBIAR_CANTIDAD_ELEMENTO_POR_PAGINA, data };
}
function formCrearHiloIncidencia(data) {
    return { type: incidenciaConstants.DESCRIPTION_YARN_INCIDEN, data };
}

function regisHiloInciden(user, hilo, incidencia) {
    return dispatch => {
        dispatch(request())
        incidenciaService.registrarHiloIncidencia(user, hilo, incidencia)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success(data.message))
                    } else {
                        dispatch(alertActions.error(data.message))
                    }
                }
            )
    };
    function request() { return { type: incidenciaConstants.SUBMIT_REQUEST } }
    function success(data) { return { type: incidenciaConstants.SUCCES_REGIS_HILO_INCIDEN, data } }
}

function reporteVisto(k, user) {
    return dispatch => {
        incidenciaService.reporteVisto(k.id, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(k.id))
                    } else {
                        console.log(data.message)
                    }
                }
            )
    };
    function success(data) { return { type: incidenciaConstants.SUCCES_REPORTE_VISTO, data } }
}

function selectIncidenForDelete(data, value) {
    data.push(value)
    return { type: incidenciaConstants.SELECT_INCIDENCIA_DELETE};
}

function deSelectIncidenForDelete(data) {
    return { type: incidenciaConstants.DESELECT_INCIDENCIA_DELETE, data };
}

function deleteIncidens(user, reportes) {
    // const data = ["1","6","7"]
    return dispatch => {
        // dispatch(success(data))
        // dispatch(alertConfirmActions.success())
        incidenciaService.deleteIncidencia(reportes, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertConfirmActions.success())
                        dispatch(alertActions.success(data.message))
                    }else{
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            )
    };
    function success(data) { return { type: incidenciaConstants.SUCCES_DELETE_INCIENCIA, data } }
}