import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

/* Layout */
import Preloader from '../layout/preloader';
import { BarLeftPerfil } from './barLeft/barLeftPerfil';
import './perfil.css';


/* Components */
import { PortadaPerfil } from './portada/homePortada';
import { CrearPost } from "../post/crearPost";
import { ListPost } from "../post/listPost";
import { Seguidores } from "./seguidores/seguidores";
import { Contactos } from "./contactos/contactos";
import { Recomendaciones } from "./recomendacion/recomendaciones";
import { PerfilPublico } from "./perfilpublico/perfilPublico";


/* MAterial UI */
import { styles } from "./perfilStyle";
import { Hidden } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

/* Actions */
import { ePageActions, postActions } from '../../../../_actions'

const bodyEpage = (props, visitor) => {
    switch (props) {
        case 2:
            return <Seguidores/>;
        case 3:
            return <Seguidores />;
        case 4:
            return <Recomendaciones />;
        case 5:
            return <Contactos />;
        case 6:
            return <PerfilPublico />;
        default:
            return <div>
                {visitor && <Hidden mdUp> <PerfilPublico /> </Hidden>} 
                <ListPost />;

            </div>
    }
}

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: this.props.match.params.nickname
        }
        if (this.props.user.username === this.props.match.params.nickname) {
            this.props.dispatch(postActions.getPostMedico(this.props.user))
            this.props.dispatch(ePageActions.getPerfilPublico(this.props.user))
        } else {
            this.props.dispatch(ePageActions.getEpegeEspecialista(this.props.match.params.nickname, this.props.user))
        }
        window.addEventListener('scroll', this.handleMasPost);
    }
    componentDidUpdate(){
        if (this.state.token !== this.props.match.params.nickname) {
            this.setState({ token: this.props.match.params.nickname });
            if (this.props.user.username === this.props.match.params.nickname) {
                this.props.dispatch(postActions.getPostMedico(this.props.user))
                this.props.dispatch(ePageActions.getPerfilPublico(this.props.user))
            } else {
                this.props.dispatch(ePageActions.getEpegeEspecialista(this.props.match.params.nickname, this.props.user))
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleMasPost);
    }
    handleMasPost = (e) => {
        const { offset, dispatch, user, viewOn } = this.props;
        if (viewOn === 1) {
            if (window.innerHeight + Math.round(document.documentElement.scrollTop) === document.documentElement.offsetHeight) {
                dispatch(postActions.getMorePost(this.state.token, offset, user))
                // console.log('bajando al final' + offset)
                // console.log(this.state.token)
            }
        }
    }

    render() {
        const { loaderPost, visitor, viewOn } = this.props
        return (loaderPost)
            ? <Preloader />
            : (<section className="body" ref={this.container}>
                <PortadaPerfil />
                <BarLeftPerfil />

                <div className="content contPerfil">  
                    {viewOn === 1 &&
                        !visitor && <CrearPost />
                    }
                    {bodyEpage(viewOn, visitor)}
                </div>
            </section>
        );
    }
}

Profile.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { visitor, viewOn } = state.ePage;
    const { loaderPost, offset } = state.postReducer;
    return {
        user,
        visitor, viewOn,
        loaderPost, offset,
    };
}

const connectedProfile = connect(mapStateToProps)(withStyles(styles)(Profile));
export { connectedProfile as Profile };

// const connectedProfile= withStyles(styles)(Profile);
// export { connectedProfile as Profile };