import { ePegeConstants } from '../../_constants';

const initialState = {
    logros:[],
    viewOn: 1,
    idiomas:[],
    horario:[],
    formaPago:[],
    membrecia:[],
    educacion:[],
    form_ePage:{},
    experiencia:[],
    listContact:[],
    sugerencias: [],
    cantContacto: 0,
    cantidadPost: 0,
    listFollowers:[],
    itemSelected: {},
    especialidades:[],
    modalidad_cita:[],
    progresUpload : 0,
    condicionItem: '',
    listRecomendacion:[],
    dialogRecorte: false,
    viewEditExtracto: false,
    contacto: {
        contacto : false,
        type_contact : true,
    },
};

export function ePage(state = initialState, action) {
    switch (action.type) {
        case ePegeConstants.GET_EPAGE:
            return {
                ...state,
                viewOn: 1,
                condicionItem: '',
                logros: action.data.logros,
                idiomas: action.data.idiomas,
                membrecia: action.data.membrecia,
                siguiendo: action.data.siguiendo,
                seguidores: action.data.seguidores,
                cantidadPost: action.data.cantidadPost,
                cantContacto: action.data.cant_contacto,
                especialidades: action.data.especialidades,
                recomendaciones: action.data.recomendaciones,
                visitor: action.data.fech_reg ? true : false,
                foto_perfil: action.data.foto ? action.data.foto  :'',
                horario: action.data.horario ? action.data.horario : '',
                extracto: !action.data.extracto ? '' : action.data.extracto,
                fech_reg: action.data.fech_reg ? action.data.fech_reg : null,
                seguidor: action.data.seguidor ? action.data.seguidor : null,
                nickName: action.data.username ? action.data.username : null,
                formaPago: action.data.formaPago ? action.data.formaPago : '',
                img_portada: action.data.img_portada ? action.data.img_portada :'',
                educacion: action.data.educacion === "" ? [] : action.data.educacion,
                contacto: action.data.fech_reg ? action.data.contacto : state.contacto,
                experiencia: action.data.experiencia === "" ? [] : action.data.experiencia,
                modalidad_cita: action.data.modalidad_cita ? action.data.modalidad_cita : '',
                nameEspecialista: action.data.name ? action.data.name+' '+action.data.surname : '',
            };
        case ePegeConstants.EPAGE_REQUEST:
            return {
                ...state,
                loading: action.data,
            };
        case ePegeConstants.EPAGE_FAIL:
            return Object.assign({}, state, {
                loading: false,
                listContact: [],
                listFollowers: [],
                listRecomendacion: [],
            })
        case ePegeConstants.SUBMITTED_REQUEST:
            return {
                ...state,
                submitted: true,
            };
        case ePegeConstants.SUBMIT_SUCCESS:
            return Object.assign({}, state, {
                [action.data.nameType]: [
                    ...state[action.data.nameType],
                    action.data.form
                ],
                form_ePage: {},
                submitted: !state.submitted,
                loading: !action.loading,
            })
        case ePegeConstants.VIEW_EDIT_EXTRACTO:
            return Object.assign({}, state, {
                viewEditExtracto: !state.viewEditExtracto
            })
        case ePegeConstants.ADD_FORM_EPAGE:
            return {
                ...state,
                form_ePage: {
                    ...state.form_ePage,
                    [action.data.name]: action.data.value
                }
            }
        case ePegeConstants.REMOVE_FORM_EPAGE:
            return {
                ...state,
                form_ePage: {},
                itemSelected: {}
            }
        case ePegeConstants.SELECT_ITEM:
            return Object.assign({}, state, {
                itemSelected: action.data
            })
        case ePegeConstants.DELETE_SUCCESS:
            return Object.assign({}, state, {
                itemSelected:{},
                loading: !state.loading,
                submitted: !state.submitted,
                [action.data.typeItem]: state[action.data.typeItem].filter(q => (q.id !== action.data.idItem)),
            })
        case ePegeConstants.DELETE_SUCCESS_RECOMENDACION:
            return Object.assign({}, state, {
                itemSelected:{},
                loading: !state.loading,
                submitted: !state.submitted,
                recomendaciones: state.recomendaciones - 1,
                listRecomendacion: state.listRecomendacion.filter(q => (q.keyd !== action.data.idItem)),
            })
        case ePegeConstants.ADD_EXTRACTO_EPAGE:
            return Object.assign({}, state, {
                extracto: action.data
            })
        case ePegeConstants.SUBMIT_EXTRACTO_SUCCESS:
            return Object.assign({}, state, {
                extracto: action.data,
                loading: !state.loading,
                submitted: !state.submitted,
                viewEditExtracto: !state.viewEditExtracto,
            })
        case ePegeConstants.EDIT_SUCCESS:
            return Object.assign({}, state, {
                [action.data.typeItem]: action.data.info,
                loading: !state.loading,
                submitted: !state.submitted,
            })
        case ePegeConstants.SELECTED_IDIOMA:
            return Object.assign({}, state, {
                idiomas: state.idiomas.map(k => {
                    if (k.id === action.data.id) {
                        k.selected = !action.data.selected;
                    }
                    return k
                }),
                loading: !state.loading,
            })
        case ePegeConstants.SEGUIR_ESPECIALISTA:
            return Object.assign({}, state, {
                seguidor: action.data,
                seguidores: action.data ? state.seguidores + 1 : state.seguidores - 1
            })
        case ePegeConstants.SEGUIR_ESPECIALISTA_O_DEJAR_SEGUIR:
            return Object.assign({}, state, {
                listFollowers: state.listFollowers.map(k => {
                    if (k.nickname === action.data.nickname) {
                        k.sigo = !k.sigo
                    }
                    return k;
                }),
                siguiendo: (!action.data.visitor && !action.data.seguir) ? state.siguiendo - 1 : state.siguiendo + 1
            })
        case ePegeConstants.MOSTRAR_VISTA_EN_EPAGE:
            return Object.assign({}, state, {
                viewOn: action.data,
            })
        case ePegeConstants.GET_LIST_FOLLOWERS:
            return Object.assign({}, state, {
                loading: !state.loading,
                listFollowers: action.data,
            })
        case ePegeConstants.GET_LIST_RECOMENDACIONES:
            return Object.assign({}, state, {
                loading: !state.loading,
                listRecomendacion: action.data,
            })
        case ePegeConstants.OPEN_DIALOG_RECORTAR_MODAL:
            return Object.assign({}, state, {
                dialogRecorte: !state.dialogRecorte,
            })
        case ePegeConstants.SUGERENCIA_A_SEGUIR:
            return Object.assign({}, state, {
                sugerencias: action.data.result.sugerencias.filter(k => (k.nickname !== action.data.nickname)),
                siguiendo: action.data.result.siguiendo,
                seguidores: action.data.result.seguidores,
            })
        case ePegeConstants.SEGUIR_ESPECIALISTA_SUGERIDO:
            return Object.assign({}, state, {
                sugerencias: state.sugerencias.filter(k => (k.nickname !== action.data)),
                siguiendo: state.siguiendo+1,
            })
        case ePegeConstants.PROGRESO_DE_SUBIDA_IMG:
            return Object.assign({}, state, {
                progresUpload: action.porcentaje,
            })
        case ePegeConstants.SOLICITUD_CONECTAR:
            return Object.assign({}, state, {
                contacto: {
                    contacto: action.data,
                    type_contact: true,
                }
            })
        case ePegeConstants.GET_LIST_CONTACT:
            return Object.assign({}, state, {
                loading: !state.loading,
                listContact: action.data,
            })
        case ePegeConstants.ELIMINAR_CONTACTO:
            return Object.assign({}, state, {
                cantContacto: !state.visitor ? state.cantContacto - 1 : state.cantContacto,
                listContact: !state.visitor && state.listContact.filter(k => (k.nickname !== action.data)),
                contacto: state.visitor ? false : state.contacto,
            })
        case ePegeConstants.ACEPTAR_SOLICITUD_CONTACTO:
            return Object.assign({}, state, {
                listContact:  state.listContact.map(k => {
                    if (k.nickname === action.data) {
                        k.accepted = '1'
                    }
                    return k
                }),
                contacto: {
                    contacto: true,
                    type_contact: state.contacto.type_contact,
                },
            })
        case ePegeConstants.CLEAR_NICK_NAME:
            return Object.assign({}, state, {
                nickName: null,
                visitor: false,
            })
        case ePegeConstants.ADD_POST_QUANTITY:
            return Object.assign({}, state, {
                cantidadPost: state.cantidadPost + 1,
            })
        case ePegeConstants.SUBTRACT_POST_QUANTITY:
            return Object.assign({}, state, {
                cantidadPost: state.cantidadPost - 1,
            })
        case ePegeConstants.VER_MAS_ITEM_BAR_LEFT:
            return Object.assign({}, state, {
                condicionItem: action.data
            })
        default:
            return state
    }
}