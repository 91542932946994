import React from 'react'

/* MAterial UI */
import { styles } from "./directStyle";
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, Button, InputBase, Divider, Chip, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';


import LayoutList from "./layoutList";

const DialogNewDirect = ({ classes, nombGroup, openDialog, closeDialog, listSiguiendo, participantes, loadingDirect, handleSelectParticipantes, handleChangeInput, handleConstructNewMessage}) => (
    <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={openDialog}
        onClose={closeDialog}
        aria-describedby="alert-dialog-description"
        className="dialog-direct"
    >
        <DialogTitle id="alert-dialog-title" className={`colorTitle ${classes.contentDialogTitle}`}>Nuevo mensaje</DialogTitle>
        <DialogContent className={classes.contentDialogDirect}>
            <Grid container >
                <Grid item xs={12} >
                    <Divider/>
                    <div className="search-seguidor">
                        <SearchIcon color="primary" className={classes.iconSearch}/>
                        <InputBase
                            fullWidth
                            id="filterParticipante"
                            name="filterParticipante"
                            autoComplete="off"
                            className="fz15"
                            placeholder="Buscar especialista"
                        // value={newArticulo.title}
                            onChange={handleChangeInput}
                        />
                        
                    </div>
                    <Divider/>
                </Grid>
                {(participantes.length > 1)
                    ? <Grid item xs={12}>
                        <div className="nom-grup">
                            <InputBase
                                fullWidth
                                id="nombGroup"
                                name="nombGroup"
                                autoComplete="off"
                                className="fz15"
                                placeholder="Nombre del grupo"
                                value={nombGroup}
                                onChange={handleChangeInput}
                            />

                        </div>
                        <Divider />
                    </Grid>
                    : null 
                }
                {(participantes.length > 0)
                    ? <Grid item xs={12}>
                        <div className="select">
                            {participantes.map(k => {
                                return (<Chip key={k.keyd} label={k.nombre} onDelete={handleSelectParticipantes(k)} color="primary" />)
                            })}    
                        </div>
                    </Grid>
                    : null
                }
                
                <Grid item xs={12} className="list-seguidores">
                    <LayoutList
                        list={listSiguiendo}
                        handleAction={handleSelectParticipantes}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions className={classes.btnAction}>
            <Button variant="contained" onClick={closeDialog}>
                Cencelar
            </Button>
            <Button 
                color="primary" 
                variant="contained" 
                onClick={handleConstructNewMessage}
                disabled={openDialog && loadingDirect}
            >
                Siguiente
                {openDialog && loadingDirect && <CircularProgress size={24} className="ProgressAbsolut" />}
            </Button>
        </DialogActions>
    </Dialog>
);

export default withStyles(styles)(DialogNewDirect);