import React from 'react'

/* Material UI */
import { styles } from "../configuracionStyle";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, Tooltip, IconButton, TextField, CircularProgress, Button, Divider } from "@material-ui/core";

/* Iconos */
import EditIcon from '@material-ui/icons/Edit'

const FormNuevaClave = ({ classes, editPass, submitted, userPassword, loaddingPass, handleUpdatePassword, handleChangeInputPass, handleOnSubmitEditClave}) => {
    return (
        <Grid item xs={12}>
            <Paper className="paper">
                <div className="title-paper">
                    <Typography color="primary" component="span" className="mb-06">Modificar mi contraseña actual</Typography>
                    <Divider/>
                </div>
                <div className=''>
                    {!editPass 
                        ?<Grid container>
                            <Grid item xs={10} sm={11} className="valign-wrappe">
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                        <Typography component="span" color="primary"> Contraseña:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={8}>
                                        <Typography component="span"> *****************</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} sm={1} align='center'>
                                <Tooltip title="Cambiar contraseña" className="toolTip">
                                    <IconButton aria-label="Cambio-password" onClick={handleUpdatePassword}>
                                        <EditIcon color="primary"></EditIcon>
                                    </IconButton>
                                </Tooltip>
                                <Button size="small" className={classes.minWd48}  color="primary" onClick={handleUpdatePassword}>Editar</Button>
                            </Grid>
                        </Grid>
                        : <Grid container>
                            <Grid item xs={10} sm={11} >
                                <Grid container spacing={16}> 
                                    <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                        <Typography component="span" color="primary"> Contraseña actual:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={8}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            id="claveActual"
                                            name="claveActual"
                                            autoComplete="off"
                                            type='password'
                                            value={userPassword.claveActual}
                                            onChange={handleChangeInputPass}
                                            error={submitted && !userPassword.claveActual}
                                            helperText={submitted && !userPassword.claveActual &&
                                                <span className="lblError" >Campo Requerido</span>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                        <Typography component="span" color="primary"> Contraseña nueva:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={8}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            id="claveNueva"
                                            name="claveNueva"
                                            autoComplete="off"
                                            type='password'
                                            value={userPassword.claveNueva}
                                            onChange={handleChangeInputPass}
                                            error={submitted && !userPassword.claveNueva}
                                            helperText={submitted && !userPassword.claveNueva &&
                                                <span className="lblError" >Campo Requerido</span>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={4} className="valign-wrappe">
                                        <Typography component="span" color="primary"> Repita su nueva contraseña:</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={8}>
                                        <TextField
                                            fullWidth
                                            margin="none"
                                            id="reClaveNueva"
                                            name="reClaveNueva"
                                            autoComplete="off"
                                            type='password'
                                            onChange={handleChangeInputPass}
                                            error={submitted && !userPassword.reClaveNueva}
                                            helperText={submitted && !userPassword.reClaveNueva &&
                                                <span className="lblError" >Campo Requerido</span>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <div className="pt-1">
                                            <Button variant="contained" className="mr_1" onClick={handleUpdatePassword}>
                                                Cancelar
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                onClick={handleOnSubmitEditClave}
                                                disabled={submitted && loaddingPass}
                                            >
                                                Actualizar
                                                {submitted && loaddingPass && <CircularProgress size={24} className="ProgressAbsolut" />}
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    
                </div>
            </Paper> 
        </Grid>
    )
}

export default withStyles(styles)(FormNuevaClave)