export const directConstants = {
    GET_REQUEST: 'GET_REQUEST',
    
    DELETE_CHAT: 'DELETE_CHAT',
    CHECK_WIDTH: 'CHECK_WIDTH',
    CHANGE_VIEWS: 'CHANGE_VIEWS',
    REQUEST_DIRECT: 'REQUEST_DIRECT',
    GET_SUBMIT_BTN: 'GET_SUBMIT_BTN',
    MESSAGE_VIEWED: 'MESSAGE_VIEWED',
    ON_SELECT_DIRECT: 'ON_SELECT_DIRECT',
    VIEW_NEW_MESSAGE: 'VIEW_NEW_MESSAGE',
    CHANGE_COMENTARY: 'CHANGE_COMENTARY',
    FAIL_YARN_DIRECT: 'FAIL_YARN_DIRECT',
    LIST_DIRECT_FAIL: 'LIST_DIRECT_FAIL',
    REQUEST_PRELOADER: 'REQUEST_PRELOADER',
    SELECT_COMPETITOR: 'SELECT_COMPETITOR',
    SUCCES_YARN_DIRECT: 'SUCCES_YARN_DIRECT',
    LIST_DIRECT_SUCCES: 'LIST_DIRECT_SUCCES',
    LIST_DIRECT_CONTACT: 'LIST_DIRECT_CONTACT',
    SUCCES_INSERT_DIRECT: 'SUCCES_INSERT_DIRECT',
    OPEN_DIALOG_NEW_MESSAGE: 'OPEN_DIALOG_NEW_MESSAGE',
    SUCCES_INSERT_COMENTARY: 'SUCCES_INSERT_COMENTARY',
    FAIL_MESSAGE_DIRECT_PARTICIPANT: 'FAIL_MESSAGE_DIRECT_PARTICIPANT',
    SUCCES_MESSAGE_DIRECT_PARTICIPANT: 'SUCCES_MESSAGE_DIRECT_PARTICIPANT',
    REQUEST_MESSAGE_DIRECT_PARTICIPANT: 'REQUEST_MESSAGE_DIRECT_PARTICIPANT',
};