import React, { Component } from "react";
import {
    Link
} from 'react-router-dom'
import './error404.css';
import logoCardio from '../../../assets/media/icon-logo.png';

export default class Error404 extends Component {
    render() {
        return (
            <div className="bodyError">
                <div id="clouds">
                    <div className="cloud x1">
                        <img src={logoCardio} alt="x1"/>
                    </div>
                    <div className="cloud x1_5">
                            <img src={logoCardio} alt="x1_5"/>
                    </div>
                    <div className="cloud x2">
                        <img src={logoCardio} alt="x2"/>
                        </div>
                    <div className="cloud x3">
                        <img src={logoCardio} alt="x3"/>
                        </div>
                    <div className="cloud x4">
                        <img src={logoCardio} alt="x4"/>
                        </div>
                    <div className="cloud x5">
                        <img src={logoCardio} alt="x5"/>
                    </div>
                </div>
                <div className='c'>
                    <div className='_404'>404</div>
                    <hr/><br/>
                    <div className='_1'>LA PÁGINA</div>
                    <br/>
                    <div className='_2'>NO FUE ENCONTRADA</div>
                    <br/>
                    <Link to="/login" className="btn">Inicio</Link>
                </div>
           </div>
        )
    }
}