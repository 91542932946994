import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
const RecuperarPassword = (props) => (
    <div>
        <h2 className="title">
            Enviar correo <br/> de recuperación
	        <div className="close close-rpr" onClick={props.onClickClose}>
                <i className="material-icons">clear</i>
            </div>
        </h2>
        <div className="cont-regis" >
            <div className="container mb-0">
                <div className="section">
                    <div className="row mb-0">
                        <div className="col s12 login">
                            <section className="cont_form">
                                <form className="form row" id="formmail_pass" name="formmail_pass" onSubmit={props.onSubmitFormRecuperar}>
                                    <div className="col s12">
                                        <div className="row">
                                            <div className="input-field col s12">
                                                <TextField
                                                    fullWidth
                                                    id="re_email"
                                                    name="email"
                                                    label="Correo"
                                                    margin="normal"
                                                    defaultValue={props.email}
                                                    onChange={props.onChangeInputs}
                                                    error={props.submitted && !props.email}
                                                    helperText={props.submitted && !props.email &&
                                                        <span className="lblError" >Campo Requerido</span>
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="loading"></div>
                                        <div className="cont-btn">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                disabled={props.loading}
                                            >
                                                Recuperar
                                                {props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                                            </Button> 
                                        </div>
                                        <div id="aviso" className="cont-aviso">
                                            <br></br>
                                            <br></br>
                                        </div>
							        </div>
						        </form>
					        </section>
		                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default RecuperarPassword