import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

/* MAterial UI */
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../perfilStyle";
// import ClearIcon from '@material-ui/icons/Clear';

import { apiCardio } from '../../../../../_constants';

/* Actions */
import { perfilActions } from '../../../../../_actions'

class RecorcarImg extends Component {
    constructor(props) {
        super(props)
        this.state = {
            crop: {
                unit: 'px',
                x: 10,
                y: 10,
                width: 150,
                height: 150,
            },
            dimensionView:{
                widthView:null,
                heightView:null,
            }
        };
    }

    onImageLoaded = image => {
        // this.setState({
        //     crop: {
        //         ...this.state.crop,
        //         widthView: image.width,
        //         heightView: image.height
        //     }
        // });
        this.setState({
            dimensionView: {
                widthView: image.width,
                heightView: image.height
            }
        });
        //return false;
    };

    onCropComplete = crop => {
        //this.makeClientCrop(crop);
        // console.log(crop)
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    handleClose = (e) => {
        e.preventDefault();
        this.props.dispatch(perfilActions.openOClosetDialog())
    }
    
    handleRecorarImg = (e) => {
        e.preventDefault();
        const { crop, dimensionView } = this.state
        const { user, dispatch } = this.props;
        dispatch(perfilActions.recortarImgMedico(user, crop, dimensionView ))
    }
    render() {
        const {  crop  } = this.state;
        const { classes, user, dialogRecorte, loadding } = this.props;
        return (
            <Dialog
                open={dialogRecorte}
                //onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.titleDialogRecortar}> Recortar imagen </DialogTitle>
                <DialogContent>
                    <div className='contRecorteImg'>
                        <ReactCrop
                            src={`${apiCardio.urlMedia}${user.imgperfil}`}
                            crop={crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={loadding}
                        onClick={this.handleClose}
                    >
                        Cancelar
                        {loadding && <CircularProgress size={24} className="ProgressAbsolut" />}
                    </Button>
                    <Button 
                        variant="contained"
                        color="primary"
                        disabled={loadding}
                        onClick={this.handleRecorarImg} 
                    >
                        Recortar
                        {loadding && <CircularProgress size={24} className="ProgressAbsolut" />}
                    </Button>
                </DialogActions>
            </Dialog>
           
        );
    }
}

RecorcarImg.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user, loadding } = state.authentication;
    const { dialogRecorte } = state.ePage;
    return {
        user, loadding,
        dialogRecorte,
    };
}

const connectedRecorcarImg = connect(mapStateToProps)(withStyles(styles)(RecorcarImg));
export { connectedRecorcarImg as RecorcarImg };