import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import Preloader from '../layout/preloader';
import { MenuEspecialistas } from '../layout/MenuEspecialistas';

/* Estilos con Material UI */
import './directStyles.css';
import { styles } from "./directStyle";
import { withStyles } from "@material-ui/core/styles";

/* Icons */

/*Component's */
import { DirectMesage } from "./directMesage";
import { ListMensajesDirectos } from "./listMensajesDirectos";

/* Actions */
import { directAction, menuActions } from '../../../../_actions'

class HomeDirect extends Component {
    constructor(props) {
        super(props);

        const { dispatch, user } = this.props
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        dispatch(menuActions.menuActive(''));
        dispatch(directAction.movilOnDirect((window.innerWidth < 600) ? true : false))
        dispatch(directAction.getListContactos(user))
        dispatch(directAction.listDirect(user))
    }

    resize() {
        const { dispatch } = this.props
        if (window.innerWidth < 600) {
            dispatch(directAction.movilOnDirect(true))
        } else {
            dispatch(directAction.movilOnDirect(false))

        }
    }

    render() {
        const { movilOn, viewNewMessage, viewMessage, preloadingDirect } = this.props;
        return (preloadingDirect)
            ? <Preloader />
            :(<section className="body" ref={this.container}>
                <MenuEspecialistas />
                <div className="content sin-bar">
                    <div className="content-direct">
                        {movilOn 
                            ? (viewNewMessage || viewMessage)  
                                ? <DirectMesage />
                                : null
                            : <DirectMesage />
                        }
                        
                        {movilOn
                            ? (!viewNewMessage && !viewMessage) 
                                ? <ListMensajesDirectos />
                                : null
                            : <ListMensajesDirectos />
                        }                       
                    </div>
                </div>
            </section>)
    }
}

HomeDirect.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { movilOn, viewNewMessage, viewMessage, preloadingDirect } = state.directReducer;
    return {
        user,
        movilOn, viewNewMessage, viewMessage, preloadingDirect
    };
}

const connectedHomeDirect = connect(mapStateToProps)(withStyles(styles)(HomeDirect));
export { connectedHomeDirect as HomeDirect };