import React from 'react'

/* MAterial UI */
import { Grid, Paper, Typography, FormControl, InputBase, IconButton, CircularProgress  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* Iconos */
import iconCardio from '../../../assets/media/icon-logo.gif';
import SendIcon from '@material-ui/icons/Send';

/* Estilos con Material UI */
import { styles } from "./incidenciasStyle";

/* Url's */
import { apiCardio } from '../../../../_constants/';

let j = 0;
const HiloIncidencia = (props) => (
    <Grid container spacing={24}>
        <Grid item xs={12}>
            <Paper className={`${props.classes.contList} paper p-0`}>
                <div className="header-hilo-inciden">
                    <figure>
                        <img className="circle" src={iconCardio} alt="icon-logo" />
                    </figure>
                    <Typography color="primary" component="span">eCardioline </Typography>
                </div>
                <div className="body-hilo-inciden">
                    <div>
                        <div className={`msj msj-description left`}>
                            <div className="msj-boddy">
                                <Typography color="textSecondary" component="span">{props.hiloIncidencia.description}</Typography>
                            </div>
                        </div>
                        <div className="clear" />
                    </div>
                    {props.hiloIncidencia.image !== '' &&
                        <div>
                            <div className={`msj msj-description left`}>
                                <div className="msj-boddy">
                                    <img className={props.classes.imgInciden} src={`${apiCardio.urlMedia}${props.hiloIncidencia.image}`} alt={props.hiloIncidencia.title} />
                                </div>
                            </div>
                            <div className="clear" />
                        </div>
                    }
                    {props.hiloIncidencia.hilo && 
                        props.hiloIncidencia.hilo.map(k => {
                            j = j+1
                            const nombre = k.nombre_medico ? '' : k.nombre_persona 
                            return(
                                <div key={j}>
                                    <div  className={`msj ${nombre === '' ? "right" : "left"}`}>
                                        <div className="msj-header">
                                            {nombre && <Typography color="primary" component="span">{nombre}</Typography> }
                                        </div>
                                        <div className="msj-boddy">
                                            <Typography color="textSecondary" component="span">{k.description}</Typography>
                                            <Typography color="textSecondary" component="span">{k.fecha}</Typography>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="action-hilo-inciden">
                    {props.hiloIncidencia.status !== '1' 
                        ?<div>
                            <FormControl className={props.classes.formSubmitHilo}
                                fullWidth
                                margin="normal"
                            >
                                <InputBase
                                    multiline
                                    fullWidth
                                    rowsMax="18"
                                    id="descriptionYarn"
                                    name="descriptionYarn"
                                    autoComplete="off"
                                    value={props.descriptionYarn && props.descriptionYarn ? props.descriptionYarn : ""}
                                    placeholder="Escribe un mensaje"
                                    onChange={props.handleChangeInputHilo}
                                />
                            </FormControl>
                            <IconButton
                                color="primary"
                                aria-label="Enviar Comentario"
                                disabled={props.submitInci && props.loading}
                                onClick={props.handleSubmitHilo}
                            >
                                <SendIcon />
                                {props.submitInci && props.loading && <CircularProgress size={24} className="ProgressAbsolut" />}
                            </IconButton>
                        </div>
                        : <Typography component="span" align="center" color="primary">Reporte cerrado y solucionado</Typography>
                    }
                    
                </div>
            </Paper>
        </Grid>
    </Grid>

)

export default withStyles(styles)(HiloIncidencia);