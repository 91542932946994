import { alertActions } from '../alert.action';
import { perfilService } from "../../_services";
import { ePegeConstants, postConstants } from '../../_constants';
import { alertConfirmActions } from '../alertConfirm.action';

export const ePageActions = {
    verMasItem,
    clearNickname,
    openModal,
    viewEditExtracto,
    getPerfilPublico,
    addExtracto,
    inserExtracto,
    addForm_ePAge,
    removeForm_ePAge,
    sumitedRequest,
    inserInfo_ePage,
    editInfo_ePage,
    getSelectItem,
    deleteItem,
    deleteRecomendacion,
    selectIdioma,
    mostrarViewEpage,
    getFolowers,
    getRecomendaciones,
    /*Action para traer la informacion del epage de otro especialsita */
    getEpegeEspecialista,
    seguirEspecialista,
    hlandleSeguirODejarSeguir,
    seguirEspecialistaSugeridos,
    sugerenciaSeguidor,
    
    /*Conectar Especialistas */
    getContactos,
    solicitudConectar,
    eliminarEspecialistaConectado,
    aceptarSolicitudContacto,
};
function verMasItem(data) {
    return { type: ePegeConstants.VER_MAS_ITEM_BAR_LEFT, data };
}
function clearNickname() {
    return { type: ePegeConstants.CLEAR_NICK_NAME };
}
function openModal(type) {
    return dispatch => {
        dispatch(success(type))
    };

    function success(data) { return { type: ePegeConstants.OPEN_CLOSET_MODAL_EPAGE, data } }
}

function viewEditExtracto() {
    return { type: ePegeConstants.VIEW_EDIT_EXTRACTO };
}

function getPerfilPublico(user) {
    return dispatch => {
        perfilService.get_ePage(user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(alertActions.error(data.message));
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    };

    function success(data) { return { type: ePegeConstants.GET_EPAGE, data } }
}

function addExtracto(value) {
    return dispatch => {
        dispatch(success(value))
    };

    function success(data) { return { type: ePegeConstants.ADD_EXTRACTO_EPAGE, data } }
}

function inserExtracto(user, extracto) {
    return dispatch => {
        dispatch(request(true))
        perfilService.insertExtracto(user, extracto)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(extracto))
                        dispatch(alertActions.success(data.message))
                    } else {
                        // dispatch(fail())
                        dispatch(alertActions.error(data.message));
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.SUBMIT_EXTRACTO_SUCCESS, data } }
}

function addForm_ePAge(name, value) {
    const data = { name, value }
    return dispatch => {
        dispatch(success(data))
    };

    function success(data) { return { type: ePegeConstants.ADD_FORM_EPAGE, data } }
}
function removeForm_ePAge() {
    return dispatch => {
        dispatch(success())
    };

    function success() { return { type: ePegeConstants.REMOVE_FORM_EPAGE } }
}

function sumitedRequest() {
    return dispatch => {
        dispatch(request())
    }
    function request() { return { type: ePegeConstants.SUBMITTED_REQUEST } }
}

function inserInfo_ePage(user, form, tipo, nameType) {
    return dispatch => {
        dispatch(request(true))
        perfilService.insertForm_ePage(user, form, tipo)
        .then(
            data => {
                if (data.success) {
                    const datos = {
                        form: data.data,
                        nameType
                    } 
                    dispatch(success(datos))
                    dispatch(closeModal(0))
                    dispatch(alertActions.success(data.message))
                } else {
                    // dispatch(fail())
                    dispatch(alertActions.error(data.message));
                }
            }
            // error => dispatch(failure(error.toString()))
        );
    }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.SUBMIT_SUCCESS, data } }
    function closeModal(data) { return { type: ePegeConstants.OPEN_CLOSET_MODAL_EPAGE, data } }
}

function editInfo_ePage(user, form, idItem, tipo, nameType) {
    return dispatch => {
        dispatch(request(true))
        perfilService.editForm_ePage(user, form, tipo, idItem)
            .then(
                data => {
                    console.log(data)
                    if (data.success) {
                        const datos = {
                            info: data.data.info,
                            typeItem: data.data.typeItem,
                        }
                        dispatch(success(datos))
                        dispatch(closeModal(0))
                        dispatch(alertActions.success(data.message))
                    } else {
                        // dispatch(fail())
                        dispatch(alertActions.error(data.message));
                    }
                }
                // error => dispatch(failure(error.toString()))
            );
    }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.EDIT_SUCCESS, data } }
    function closeModal(data) { return { type: ePegeConstants.OPEN_CLOSET_MODAL_EPAGE, data } }
}

function getSelectItem(idItem) {
    return dispatch => {
        dispatch(request(idItem))
    }
    function request(data) { return { type: ePegeConstants.SELECT_ITEM, data } }
}

function deleteItem(user, item) {
    return dispatch => {
        dispatch(request(true))
        perfilService.deletItemPerfilPublico(user, item)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success(data.message))
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            );
    }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.DELETE_SUCCESS, data } }
}

function deleteRecomendacion(user, item) {
    return dispatch => {
        dispatch(request(true))
        perfilService.deletItemRecomendacion(user, item)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                        dispatch(alertActions.success('Se elimino con exito la recomendación seleccionada.'))
                        dispatch(alertConfirmActions.success())
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            );
    }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.DELETE_SUCCESS_RECOMENDACION, data } }
}

function selectIdioma(select, user, idiomas) {
    idiomas.map(function (e) {
        if (e.id === select.id) {
            e.selected = !e.selected;
        }
        return { idiomas }
    });
    return dispatch => {
        dispatch(request(true))
        dispatch(success(select.id))
        perfilService.selectedIdioma(user, select)
    }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.SELECTED_IDIOMA, data } }
}

function mostrarViewEpage(data) {
    return { type: ePegeConstants.MOSTRAR_VISTA_EN_EPAGE, data };
}

function getFolowers(cdn, nickname, user) {
    return dispatch => {
        dispatch(request(true))
        perfilService.getFolowers(cdn, nickname, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    }else{
                        dispatch(fail())
                    }
                }
            )
    }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.GET_LIST_FOLLOWERS, data } }
    function fail() { return { type: ePegeConstants.EPAGE_FAIL } }
}
function getRecomendaciones(nickname, user) {
    return dispatch => {
        dispatch(request(true))
        perfilService.getRecomendaciones(nickname, user)
        .then(
            data => {
                if (data.success) {
                    dispatch(success(data.data))
                }else{
                    dispatch(fail())
                }
            }
            )
        }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.GET_LIST_RECOMENDACIONES, data } }
    function fail() { return { type: ePegeConstants.EPAGE_FAIL } }
}

function getEpegeEspecialista(tokenEpege, user) {
    return dispatch => {
        dispatch(request(true))
        perfilService.getEpegeEspecialista(tokenEpege, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data.post))
                        dispatch(successEpage(data.data))
                    }else{
                        dispatch(fail())
                    }
                    dispatch(request(false))
                }
            )
    }
    function request(data) { return { type: postConstants.GET_POSTS_REQUEST, data } }
    function success(data) { return { type: postConstants.GET_POSTS_PERFIL, data } }
    function successEpage(data) { return { type: ePegeConstants.GET_EPAGE, data } }
    function fail() { return { type: postConstants.FAIL_GET_POST_PERFIL } }
}

function seguirEspecialista(user, seguir, nickname) {
    return dispatch => {
        dispatch(success(seguir))
        perfilService.seguirEspecialista(user, nickname,seguir)
    }
    function success(data) { return { type: ePegeConstants.SEGUIR_ESPECIALISTA, data } }
}

function hlandleSeguirODejarSeguir(user, seguir, nickname, cdn, visitor) {
    const datos = {  nickname, cdn, visitor, seguir  }
    return dispatch => {
        dispatch(success(datos))
        perfilService.seguirEspecialista(user, nickname, seguir)        
    }
    function success(data) { return { type: ePegeConstants.SEGUIR_ESPECIALISTA_O_DEJAR_SEGUIR, data } }
}

function seguirEspecialistaSugeridos(user, seguir, nickname) {
    return dispatch => {
        dispatch(success(nickname))
        perfilService.seguirEspecialista(user, nickname, seguir)
    }
    function success(data) { return { type: ePegeConstants.SEGUIR_ESPECIALISTA_SUGERIDO, data } }
}

function sugerenciaSeguidor(user) {
    return dispatch => {
        perfilService.sugerenciaSeguidor(user)
        .then(
            data => {
                if (data.success) {
                    dispatch(success({ 'nickname': user.username,'result':data.data}))
                    }
                }
            )
    }
    function success(data) { return { type: ePegeConstants.SUGERENCIA_A_SEGUIR, data } }
}

function solicitudConectar(user, nickname) {
    return dispatch => {
        perfilService.solicitudConectar(user, nickname)
        .then(
            data => {
                if (data.success) {
                    dispatch(success('2'))
                    dispatch(alertConfirmActions.success())
                    dispatch(alertActions.success(data.message))
                }else{
                    dispatch(alertActions.error(data.message));
                    dispatch(alertConfirmActions.sumitedRequest())
                }
            }
        )
    }
    function success(data) { return { type: ePegeConstants.SOLICITUD_CONECTAR, data } }
}
function eliminarEspecialistaConectado(user, nickname, type_contact) {
    return dispatch => {
        perfilService.deleteContactoEspecialista(user, nickname, type_contact)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(nickname))
                        dispatch(alertConfirmActions.success())
                        dispatch(alertActions.success(data.message))
                    } else {
                        dispatch(alertActions.error(data.message));
                        dispatch(alertConfirmActions.sumitedRequest())
                    }
                }
            )
    }
    function success(data) { return { type: ePegeConstants.ELIMINAR_CONTACTO, data } }
}
function getContactos(nickname, user) {
    return dispatch => {
        dispatch(request(true))
        perfilService.getContactos(nickname, user)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.data))
                    } else {
                        dispatch(fail())
                    }
                }
            )
    }
    function request(data) { return { type: ePegeConstants.EPAGE_REQUEST, data } }
    function success(data) { return { type: ePegeConstants.GET_LIST_CONTACT, data } }
    function fail() { return { type: ePegeConstants.EPAGE_FAIL } }
}
function aceptarSolicitudContacto(user, nickname) {
    return dispatch => {
        dispatch(success(nickname))
        perfilService.aceptarSolicitudContacto(user, nickname)
    }
    function success(data) { return { type: ePegeConstants.ACEPTAR_SOLICITUD_CONTACTO, data } }
}