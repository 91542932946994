export const notifyConstants = {
    GET_NOTIFY: 'GET_NOTIFY',
    REQUEST_NOTIFY: 'REQUEST_NOTIFY',
    GET_FAIL_NOTIFY: 'GET_FAIL_NOTIFY',

    MARCAR_VISTO_FAIL: 'MARCAR_VISTO_FAIL',
    MARCAR_VISTO_SUCCES: 'MARCAR_VISTO_SUCCES',

    MARCAR_VISTO_DIRECT_SUCCES: 'MARCAR_VISTO_DIRECT_SUCCES',

    

};
