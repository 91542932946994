import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Layouts */
import { MenuEspecialistas } from '../../layout/MenuEspecialistas';

/* Material UI */
import { styles } from "../perfilStyle";
import { withStyles } from "@material-ui/core/styles";

/*Components */
import { Contactos } from './contactos';

/* Actions */
import { ePageActions } from '../../../../../_actions'

class HomeContactos extends Component {
    constructor(props) {
        super(props)

        this.props.dispatch(ePageActions.clearNickname())
    }
    render() {
        return  <section className="body" ref={this.container}>
            {/* <Header titlePage='Inicio' /> */}
            <MenuEspecialistas />
            <div className="content">
                <Contactos/>
            </div>
        </section>
               
    }
}

HomeContactos.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    return {
        user,
    };
}

const connectedHomeContactos = connect(mapStateToProps)(withStyles(styles)(HomeContactos));
export { connectedHomeContactos as HomeContactos };