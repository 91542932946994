import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

/* Actions */
import { directAction, alertConfirmActions } from '../../../../_actions'

// /* MAterial UI */
import { styles } from "./directStyle";
import {    Paper, Typography, Button, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, 
    ListItemSecondaryAction, Divider, InputBase, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SendIcon from '@material-ui/icons/Send';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

/* Layouts */
import Emojis from '../layout/emojis'
import AlertDialog from "../layout/alertDialogo";

/* Url's */
import { apiCardio } from '../../../../_constants/';

class DirectMesage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            postEl: null,
            direct: null,
        };
        this.lastMessageRef = React.createRef()   // Create a ref object 
        this.handleSelectEmoji = this.handleSelectEmoji.bind(this)
        
    }
    componentDidUpdate() {
        const { direct } = this.state
        const { dispatch, message, user, viewMessage } = this.props
        if (message.keyd && (direct !== message.keyd)) {
            this.setState({ direct: message.keyd });
            dispatch(directAction.getYarnDirect(user, message.keyd))
        }
        viewMessage && this.lastMessageRef.current.scrollIntoView(false)
    }
    handleOpenAndClosedDialogDirect = (e) => {
        e.preventDefault();
        this.props.dispatch(directAction.openDialogDirect())
    }

    handleChangeImputComentary = (e) => {
        const { value } = e.target
        this.props.dispatch(directAction.changeComentaryMessage(value))
    }

    handleSubmitHilo = (e) => {
        const { dispatch, message, user} = this.props
        if (message.newComentary !== '' ) {
            dispatch(directAction.submitBtnDirect())
            if (message.keyd) {
                dispatch(directAction.insertDirectComentary(message, user))
            }else{
                console.log(message)
                dispatch(directAction.insertDirect(message, user))
            }
        }
    }
    handleSelectEmoji(code) {
        const { dispatch, message } = this.props
        const emoji = String.fromCodePoint(`0x${code}`)
        const value = message.newComentary + ' ' + emoji
        dispatch(directAction.changeComentaryMessage(value))
    }

    handleOpenMenu = (e) =>{
        this.setState({ postEl: e.currentTarget });
    }
    handleCloseMenu = () => {
        this.setState({ postEl: null });
    };

    handleOcultarViews = () => {
        this.props.dispatch(directAction.changeViews())
        this.setState({ postEl: null });
    }
    
    /*Salir o eliminar chat */
    handleCloseAlert = () => {
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        this.setState({ postEl: null });
    };

    handleSalirOEliminarChat = () => {
        const { message, user, dispatch } = this.props
        dispatch(alertConfirmActions.sumitedRequest())
        dispatch(directAction.salirOEliminarChat(message, user))
        // this.props.dispatch(citasActions.citaAtendida(this.props.user, this.props.atendida))
    }
    /*Fin Salir o eliminar chat */
    render() {
        const { postEl } = this.state
        const { openConfirm, loadingConfirm, submitedConfrim } = this.props
        const { dispatch, message, viewNewMessage, viewMessage, submitDirect, loadingDirect } = this.props
        return (
            (viewNewMessage || viewMessage) 
                ?<Paper className="content-message" >
                    <div className="header-message">
                        <List>
                            <ListItem alignItems="flex-start" className="li-message">
                                <ListItemAvatar>
                                    {message.participantes.length 
                                        ?(message.participantes.length > 1 )
                                            ? <Avatar><SupervisorAccountIcon/></Avatar>
                                            : <Avatar src={`${apiCardio.urlMedia}${message.participantes[0].img}`} alt={message.participantes[0].nombre} />
                                        : <Avatar><SupervisorAccountIcon /></Avatar>
                                    }
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        message.participantes.length 
                                            ? message.participantes.length > 1 ? message.nom_group : message.participantes[0].nombre
                                            : 'No hay participantes dentro de este chat'
                                    }
                                    secondary={
                                        message.participantes.map(k=>{
                                            return '@'+k.nickname+' '
                                        })
                                    }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="Arrow-Down" onClick={this.handleOpenMenu}>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-message-direct"
                                        anchorEl={postEl}
                                        open={postEl ? true : false}
                                        onClose={this.handleCloseMenu}

                                    >
                                        <MenuItem onClick={this.handleOcultarViews}>
                                            Atras
                                        </MenuItem>
                                        {message.keyd &&
                                            <MenuItem onClick={this.handleCloseAlert}>
                                                Salir de la conversación
                                            </MenuItem>
                                        }
                                    </Menu>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider/>
                        </List>
                    </div>
                    <div className="body-message">
                        {loadingDirect && !submitDirect &&
                            <div className="cont-progres">
                                <CircularProgress size={30} className="ProgressAbsolut" />
                            </div>
                        }
                        {
                            message.yarn.map(k => {
                                return (
                                    <div className="massage" key={k.keyd}>
                                        <div className={`msj ${k.autor ? "right" : "left"}`}>
                                            {/* <div className="msj-header">
                                                <Typography color="primary" component="span">{k.nombre}</Typography>
                                            </div> */}
                                            <div className="msj-boddy">
                                                <Typography color="textSecondary" component="span">{k.description}</Typography>
                                                <Typography color="textSecondary" component="span">{k.fech_reg}</Typography>
                                            </div>
                                        </div>
                                        <div className="clear" />
                                    </div>
                                )
                            })
                        }
                        <div className="last-item" ref={this.lastMessageRef}></div>
                    </div>
                    {message.participantes.length 
                        ? <div className="footer-message">
                            <div className="cont-imput">
                                <InputBase
                                    fullWidth
                                    multiline
                                    rows="2"
                                    rowsMax="2"
                                    className="text-area"
                                    name="newComentario"
                                    // onClick={props.hlandleComentaryPost(props.post)}
                                    onChange={this.handleChangeImputComentary}
                                    // onKeyPress={props.handleSubmitComentary}
                                    value={message.newComentary}
                                    placeholder="Escribe un mensaje"
                                />
                                <Emojis
                                    post=''
                                    dispatch={dispatch}
                                    handleSelectEmoji={this.handleSelectEmoji}
                                />
                            </div>
                            <IconButton
                                color="primary"
                                aria-label="Enviar Comentario"
                                disabled={submitDirect && loadingDirect}
                                onClick={this.handleSubmitHilo}
                            >
                                <SendIcon />
                                {submitDirect && loadingDirect && <CircularProgress size={24} className="ProgressAbsolut" />}
                            </IconButton>
                        </div>
                        : <div className="footer-message">
                            <Typography component="span" color="secondary">Este conversación ya no esta disponible para enviar nuevos mensajes.</Typography>
                        </div>
                    }
                    
                     <AlertDialog
                        open={openConfirm}
                        loading={loadingConfirm}
                        submitted={submitedConfrim}
                        handleClose={this.handleCloseAlert}
                        procesar={this.handleSalirOEliminarChat}
                        message={message.participantes.length > 0
                            ? '¿Esta seguro de querer salir del chat'
                            : '¿Esta seguro de querer eliminar el chat?'
                        } 
                     />
                </Paper>
                : <Paper className="content-message" >
                    <div className="aviso-seleccion">
                        <Typography component="span" color="primary">No tienes ningún mensaje seleccionado</Typography>
                        <Typography component="span" color="secondary">Elije uno de tus mensajes existentes o comienza uno nuevo.</Typography>
                        <Button variant="contained" color="primary" onClick={this.handleOpenAndClosedDialogDirect} >
                            Nuevo mensaje
                        </Button>
                    </div>
                </Paper>
        )
    }
}

DirectMesage.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { message, viewNewMessage, viewMessage, submitDirect, loadingDirect } = state.directReducer;
    return {
        user,
        openConfirm, loadingConfirm, submitedConfrim,
        message, viewNewMessage, viewMessage, submitDirect, loadingDirect
    };
}

const connectedBarRightMensajesDirectos = connect(mapStateToProps)(withStyles(styles)(DirectMesage));
export { connectedBarRightMensajesDirectos as DirectMesage };