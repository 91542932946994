export const postConstants = {
    
    // MIS_POSTS: 'MIS_POSTS',
    // SELECT_POST: 'SELECT_POST',
    // DELETE_POST: 'DELETE_POST',
    // SUBMITTED_REQUEST: 'SUBMITTED_REQUEST',
    // SUBMITTED_NEW_POST: 'SUBMITTED_NEW_POST',
    // SELECT_POST_DELETE: 'SELECT_POST_DELETE',
    // GET_PRELOADER_POSTS: 'GET_PRELOADER_POSTS',
    // SUBMITTED_NEW_POST_FAIL: 'SUBMITTED_NEW_POST_FAIL',
    
    // OPEN_EDIT_POST: 'OPEN_EDIT_POST',
    NEW_POST: 'NEW_POST',
    EDIT_POST: 'EDIT_POST',
    GET_POSTS: 'GET_POSTS',
    DELETE_POST: 'DELETE_POST',
    DISABLE_BTN: 'DISABLE_BTN',
    RESET_OFFSET: 'RESET_OFFSET',
    FAIL_GET_POST: 'FAIL_GET_POST',
    GET_POST_UNIQUE: 'GET_POST_UNIQUE',
    REMOVE_IMG_POST: 'REMOVE_IMG_POST',
    GET_POSTS_PERFIL: 'GET_POSTS_PERFIL',
    GET_POSTS_REQUEST: 'GET_POSTS_REQUEST',
    SUBMIT_REQUEST_POST: 'SUBMIT_REQUEST_POST',
    SUBMIT_POST_SUCCESS: 'SUBMIT_POST_SUCCESS',
    FAIL_GET_POST_PERFIL: 'FAIL_GET_POST_PERFIL',
    OPEN_O_CLOSE_NEW_POST: 'OPEN_O_CLOSE_NEW_POST',
    SUBMIT_POST_SUCCESS_EDIT: 'SUBMIT_POST_SUCCESS_EDIT',


    UPDATE_LIKE: 'UPDATE_LIKE',
    FAIL_UPDATE_LIKE: 'FAIL_UPDATE_LIKE',
    
    VIEW_COMMNETS: 'VIEW_COMMNETS',
    SELECT_POST_TO_COMENTARY: 'SELECT_POST_TO_COMENTARY',
    SUBMIT_REQUEST_COMENTARY: 'SUBMIT_REQUEST_COMENTARY',
    SUBMIT_SUCCESS_COMENTARY: 'SUBMIT_SUCCESS_COMENTARY',
    DELETE_COMENTARY: 'DELETE_COMENTARY',
    CHANGE_VALUE_COMENTARY: 'CHANGE_VALUE_COMENTARY',
    
    DELETE_COMENTARY_REPLY: 'DELETE_COMENTARY_REPLY',
    CHANGE_VALUE_REPLY_COMENTARY: 'CHANGE_VALUE_REPLY_COMENTARY',
    SUBMIT_SUCCESS_COMENTARY_REPLY: 'SUBMIT_SUCCESS_COMENTARY_REPLY',
    CHANGE_VALUE_REPLY_COMENTARY_EDIT: 'CHANGE_VALUE_REPLY_COMENTARY_EDIT',
    

};