import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/* MAterial UI */
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography, Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

/* Estilos con Material UI */
import { styles } from "./perfilPublicoStyle";

/* Icons */
import AddIcon from '@material-ui/icons/Add';

/* Actions */
import { ePageActions, alertConfirmActions } from '../../../../../_actions'

/*Components */
import InfoList from "./infoList";
import Idiomas from "./idiomas";
import ModalEPage from "./modalContForm";
import FormaPago from "./formaPagoEpage";
import ModalidadAsesoria from "./modalidadAsesoria";
import AlertDialog from "../../layout/alertDialogo";
import { CardExtracto } from "../barLeft/cardExtracto";
import Calendar from "../../layout/calendarioComponent";

/* Services */
import { perfilService } from "../../../../../_services";

class PerfilPublico extends Component {
    constructor(props) {
        super(props)
        this.state = {
            verMas:'',
            insitutos:'',
            sugerencias:'',
            filterIdioma:'',
            openAlert: false,
        }

        perfilService.getSugerencias(this.props.user)
            .then(
                data => {
                    if (data.success) {
                        this.setState({ insitutos: data.data.institutos, sugerencias: data.data.sugerencias });
                    }
                }
            )
        
            this.handleOnFilter = this.handleOnFilter.bind(this)
            this.handleCloseAlert = this.handleCloseAlert.bind(this)
            this.handleDelateItem = this.handleDelateItem.bind(this)
            this.handleFilterIdioma = this.handleFilterIdioma.bind(this)
            this.handleSelectDelateItem = this.handleSelectDelateItem.bind(this)
    }

    handleOpenModalForm = value => () => {
        const { dispatch, open } = this.props;
        dispatch(ePageActions.openModal(value))
        if (!open) {
            dispatch(ePageActions.removeForm_ePAge())
        }
    };

    handleOpenModalFormEdit = value => () => {
        const { dispatch, open } = this.props;
        dispatch(ePageActions.getSelectItem(value))
        dispatch(ePageActions.openModal(value.typeItem))
        if (open) { dispatch(ePageActions.removeForm_ePAge())  }
    };
    
    handleVerMas = data => () =>{
        this.setState({ verMas: data})
    }
    handleVerMenos = () =>{
        this.setState({ verMas: ''})
    }

    /* Select idioma */
    handleSelectIdioma = data => () => {
        const { user, idiomas, dispatch } = this.props;
        dispatch(ePageActions.selectIdioma(data, user, idiomas))
    }

    handleOnFilter(filterIdioma, data) {
        let regex = new RegExp(filterIdioma, 'i')
        return data.filter(q => (regex.test(q.name)))
    }

    handleFilterIdioma(e) {
        const { name, value } = e.target
        this.setState({ [name]: value });
    }
    /* fin Select idioma */

    /* Handles Para Borrar algun elemento */
    handleCloseAlert = () => {
        this.setState({openAlert: false});
        this.props.dispatch(alertConfirmActions.openCloseAlert())
    }; 
    
    handleSelectDelateItem = data => () => {
        this.setState({openAlert: true});
        this.props.dispatch(alertConfirmActions.openCloseAlert())
        this.props.dispatch(ePageActions.getSelectItem(data))
    }
    
    handleDelateItem = () => {
        this.props.dispatch(alertConfirmActions.sumitedRequest())
        this.props.dispatch(ePageActions.deleteItem(this.props.user,this.props.itemSelected))
    }
    /* Handles Para Burrar algun elemento */

    handleVermasItem = data => e => {
        e.preventDefault()
        const valor = (data === this.props.condicionItem) ? '' : data
        this.props.dispatch(ePageActions.verMasItem(valor))
    }

    handleSelectFech = data => {
        // console.log('selec_fech')
    }

    render() {
        const { verMas, insitutos, sugerencias, filterIdioma, openAlert } = this.state
        const { classes, open, typeForm, experiencia, educacion, logros, idiomas, openConfirm, horario, especialidades, 
            loadingConfirm, submitedConfrim, membrecia, visitor, condicionItem, modalidad_cita, nickName, formaPago } = this.props

        experiencia.sort(function (a, b) { return b.id - a.id; }); //Ordenar de forma descendente
        educacion.sort(function (a, b) { return b.id - a.id; }); //Ordenar de forma descendente

        const videoConferencia = modalidad_cita ? modalidad_cita.filter(k => (k.name_attetion === 'Videoconferencia')) : []
        const escrita24Horas = modalidad_cita ? modalidad_cita.filter(k => (k.name_attetion === 'Escrita en menos de 24 horas.')) : []
        return (
            <Grid container spacing={16} direction="column">
                <Hidden smDown>
                    <Grid item xs={12}>
                        <CardExtracto />
                    </Grid>
                </Hidden>
                <Grid item xs={12}>
                    {/* IDIOMAS */}
                    <Idiomas
                        visitor={visitor}
                        list={this.handleOnFilter(filterIdioma, idiomas)}
                        handleSelect={visitor ? null : this.handleSelectIdioma}
                        handleFilterIdioma={this.handleFilterIdioma}
                    />
                </Grid>
                <Grid item xs={12}>
                    {/* EXPERIENCIA LABORAL */}
                    <Paper className="paper">
                        <div className="title-paper relative">
                            <Typography color='primary' component="span" className="mb-06">Experiencia laboral</Typography>
                            {visitor ? null 
                                : <Tooltip title="Agregar experiencia" className="toolTip">
                                    <IconButton aria-label="Agregar experiencia" className={classes.btnFlotante} onClick={this.handleOpenModalForm("1")}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Divider />
                        </div>
                        <div>
                            {experiencia.length 
                                ?<InfoList
                                    cdn='Ex'
                                    typeItem="1"
                                    verMas={verMas}
                                    visitor={visitor}
                                    // list={experiencia}
                                    list={condicionItem === 'Ex' ? experiencia : experiencia.slice(0, 2)}
                                    handleVerMas={this.handleVerMas}
                                    handleVerMenos={this.handleVerMenos}
                                    handleDelateItem={this.handleSelectDelateItem}
                                    handleOpenModalFormEdit={this.handleOpenModalFormEdit}
                                />
                                : <Typography component="span" color="textSecondary">
                                    {visitor ? 'El especialista no ha llenado su experiencia laboral.' : 'No has llenado tu experiancia laboral.'}
                                </Typography>            
                                
                            }
                        </div>
                        {experiencia.length >= 3 &&
                            <Button color="primary" className={classes.btnTransform} onClick={this.handleVermasItem('Ex')}>
                                {condicionItem !== 'Ex' 
                                    ? 'Ver más experiencia laboral'
                                    : 'Ocultar experiencia laboral'
                                }
                                
                            </Button>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {/* EDUCACION*/}
                    <Paper className="paper">
                        <div className="title-paper relative">
                            <Typography color='primary' component="span" className="mb-06">Educación</Typography>
                            {visitor ? null 
                                : <Tooltip title="Agregar educación" className="toolTip">
                                    <IconButton aria-label="Agregar educación" className={classes.btnFlotante} onClick={this.handleOpenModalForm("2")}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Divider />
                        </div>
                        <div>
                            {educacion.length 
                                ?<InfoList
                                    cdn='Ed'
                                    typeItem="2"
                                    verMas={verMas}
                                    // list={educacion}
                                    visitor={visitor}
                                    handleVerMas={this.handleVerMas}
                                    handleVerMenos={this.handleVerMenos}
                                    handleDelateItem={this.handleSelectDelateItem}
                                    handleOpenModalFormEdit={this.handleOpenModalFormEdit}
                                    list={condicionItem === 'Ed' ? educacion : educacion.slice(0,2)}
                                />
                                : <Typography component="span" color="textSecondary">
                                    {visitor ? 'El especialista no ha llenado su educación.' : 'No has llenado tu educación.'}
                                </Typography> 
                            }
                        </div>
                        {educacion.length >= 3 &&
                            <Button color="primary" className={classes.btnTransform} onClick={this.handleVermasItem('Ed')}>
                                {condicionItem !== 'Ed'
                                    ? 'Ver más educación'
                                    : 'Ocultar educación'
                                }
                            </Button>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {/* Miembro*/}
                    <Paper className="paper">
                        <div className="title-paper relative">
                            <Typography color='primary' component="span" className="mb-06">Miembro de sociedad científica</Typography>
                            {visitor ? null 
                                :<Tooltip title="Agregar educación" className="toolTip">
                                    <IconButton aria-label="Agregar educación" className={classes.btnFlotante} onClick={this.handleOpenModalForm("4")}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Divider />
                        </div>
                        <div>
                            {membrecia.length 
                                ? <InfoList
                                    cdn='Me'
                                    typeItem="4"
                                    verMas='NO'
                                    // list={membrecia}
                                    visitor={visitor}
                                    handleVerMas={this.handleVerMas}
                                    handleVerMenos={this.handleVerMenos}
                                    handleDelateItem={this.handleSelectDelateItem}
                                    handleOpenModalFormEdit={this.handleOpenModalFormEdit}
                                    list={condicionItem === 'Me' ? membrecia : membrecia.slice(0, 2)}
                                />
                                : <Typography component="span" color="textSecondary">
                                    {visitor ? 'El especialista aún no es miembro de una sociedad científica.' : 'Dinos si perteneces a una de sociedad científica.'}
                                </Typography> 
                            }
                        </div>
                        {membrecia.length >= 3 &&
                            <Button color="primary" className={classes.btnTransform} onClick={this.handleVermasItem('Me')}>
                                {condicionItem !== 'Me'
                                    ? 'Ver más membresías'
                                    : 'Ocultar membresías'
                                }
                            </Button>
                        }
                    </Paper>
                </Grid>
                
                <Grid item xs={12}>
                    {/* LOGROS */}
                    <Paper className="paper">
                        <div className="title-paper relative">
                            <Typography color='primary' component="span" className="mb-06">Premios y reconocimientos</Typography>
                            {visitor ? null 
                                :<Tooltip title="Agregar logros" className="toolTip">
                                    <IconButton aria-label="Agregar logros" className={classes.btnFlotante} onClick={this.handleOpenModalForm("3")}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <Divider />
                        </div>
                        <div>
                            {logros.length
                                ? <InfoList
                                    cdn='Re'
                                    verMas='NO'
                                    typeItem="3"
                                    // list={logros}
                                    visitor={visitor}
                                    handleVerMas={this.handleVerMas}
                                    handleVerMenos={this.handleVerMenos}
                                    handleDelateItem={this.handleSelectDelateItem}
                                    handleOpenModalFormEdit={this.handleOpenModalFormEdit}
                                    list={condicionItem === 'Re' ? logros : logros.slice(0, 2)}
                                />
                                : <Typography component="span" color="textSecondary">
                                    {visitor ? 'El especialista aún no registra sus premios y reconocimientos.' : 'Cuentanos sobre tus premios y reconocimientos.'}
                                </Typography> 
                            }
                        </div>
                        {logros.length >= 3 &&
                            <Button color="primary" className={classes.btnTransform} onClick={this.handleVermasItem('Re')}>
                                {condicionItem !== 'Re'
                                    ? 'Ver más premios y reconocimientos'
                                    : 'Ocultar premios y reconocimientos'
                                }
                            </Button>
                        }
                    </Paper>
                </Grid>

                {/* Horario */}
                {horario.length 
                    ? <Grid item xs={12}>
                        <Paper className="paper">
                            <div className="title-paper relative">
                                <Typography color='primary' component="span" className="mb-06">Horario de atención</Typography>
                            </div>
                            <div>
                                <Calendar
                                    // dispatch={dispatch}
                                    interconsulta={true}
                                    list={horario}
                                    handleSelectFech={this.handleSelectFech}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    : ''
                }
                
                {/* MODALIDAD DE CITAS */}
                <ModalidadAsesoria
                    list={modalidad_cita}
                />

                {/* FORMA DE PAGO EPAGE */}
                {formaPago 
                    ? formaPago.filter(k => (k.selected)).length 
                        ? <FormaPago
                            list={formaPago}
                        />
                        : ''
                    :''
                }

                {visitor && especialidades.length && horario.length && formaPago.filter(k => (k.selected)).length &&
                    ((videoConferencia.length && videoConferencia[0].montoHonorario.filter(k => ((k.monto !== '0' && k.free === '0') || k.free === '1')).length)
                    || (escrita24Horas.length && escrita24Horas[0].montoHonorario.filter(k => ((k.monto !== '0' && k.free === '0') || k.free === '1')).length) ) 
                        ? <Grid item xs={12} align='center'>
                            <Paper className="paper">
                                <Link to={`/interconsulta/${nickName}`}>
                                    <Button color="primary" variant="contained" className={classes.btnTransform}>
                                        Solicitar interconsulta
                                </Button>
                                </Link>
                            </Paper>
                        </Grid>
                        : ''
                }

                
                <ModalEPage
                    open={open}
                    typeForm={typeForm}
                    insitutos={insitutos}
                    sugerencias={sugerencias}
                    handleClose={this.handleOpenModalForm}
                    handleOnSubmit={this.handleOnSubmit}
                />
                <AlertDialog
                    open={openConfirm && openAlert}
                    loading={loadingConfirm}
                    submitted={submitedConfrim}
                    procesar={this.handleDelateItem}
                    handleClose={this.handleCloseAlert}
                    message='¿Esta seguro de eliminar el item seleccionado?'
                />
            </Grid>
        )
    }
}


PerfilPublico.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { user } = state.authentication;
    const { open, typeForm } = state.modalEPage;
    const { openConfirm, loadingConfirm, submitedConfrim } = state.alertConfirm;
    const { experiencia, educacion, logros, itemSelected, idiomas, membrecia, visitor, condicionItem, modalidad_cita, nickName, formaPago, horario, especialidades } = state.ePage;
    return {
        user,
        open, typeForm,
        openConfirm, loadingConfirm, submitedConfrim,
        experiencia, educacion, logros, itemSelected, idiomas, membrecia, visitor, condicionItem, modalidad_cita, nickName, formaPago, horario, especialidades
    };
}

const connectedPerfilPublico = connect(mapStateToProps)(withStyles(styles)(PerfilPublico));
export { connectedPerfilPublico as PerfilPublico };
